/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useState, useEffect, useMemo} from "react";

// react-router components
import {useLocation, Link, useNavigate} from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import StorageUtil from "common/StorageUtil";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {menuRecoilState, sseForUserRecoilState} from "store/recoilState";
import MDButton from "components/MDButton";
import 'react-country-flag'
import ReactCountryFlag from "react-country-flag";
import {useTranslation} from "react-i18next";
import defaultPack from 'translation/default-pack.cjs'
import MenuItem from "@mui/material/MenuItem";
import {LANGUAGE, SSE, USER_ID} from "common/Constant";
import {useQuery} from "@tanstack/react-query";
import {DefaultResponse, UserAlarm} from "common/Types";
import {AxiosError} from "axios";
import AlarmService from "service/AlarmService";

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
  pageTitle? : string;
}

function DashboardNavbar({ absolute, light, isMini, pageTitle }: Props): JSX.Element {
  const navigate = useNavigate()
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState<any>(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const setMenuList = useSetRecoilState(menuRecoilState);

  const [ openLanguageMenu, setOpenLanguageMenu ] = useState<any>(false);
  function handleLanguageExpand(e: any) {
    setOpenLanguageMenu(e.currentTarget)
  }

  function handleCloseLanguageMenu() {
    setOpenLanguageMenu(false)
  }

  function renderLanguageMenu() {
    return (
      <Menu
        anchorEl={openLanguageMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openLanguageMenu)}
        onClose={handleCloseLanguageMenu}
        sx={{ mt: 2 }}
      >
        <MenuItem onClick={() => handleLanguaeSelect("ko-KR")}>
          <MDBox flexDirection={"row"} p={1}>
            <ReactCountryFlag countryCode={"KR"} aria-label={"한국어"} />
            &nbsp;한국어
          </MDBox>
        </MenuItem>
        <MenuItem onClick={() => handleLanguaeSelect("en-US")}>
          <MDBox flexDirection={"row"} p={1}>
              <ReactCountryFlag countryCode={"US"} aria-label={"English"} />
              &nbsp;English
          </MDBox>
        </MenuItem>
      </Menu>
    )
  }

  const { i18n } = useTranslation();

  const {t} = useTranslation();

  function handleLanguaeSelect(value: string) {
    i18n.changeLanguage(value);
    localStorage.setItem(LANGUAGE, value);
    setOpenLanguageMenu(false)
  }

  const [alarms, setAlarms] = useState<UserAlarm[]>(undefined);

  const userId = useMemo(() => localStorage.getItem(USER_ID), []);

  const {refetch: getAlarms} = useQuery<DefaultResponse, AxiosError>(
    ["getAlarms"],
    () => AlarmService.getAlarms(userId),
    {
      onSuccess : (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          setAlarms(res.data)
        }
      }
    }
  )

  /* SSE 설정 */
  const setSseForUser = useSetRecoilState(sseForUserRecoilState);
  const sseForUser = useRecoilValue(sseForUserRecoilState);

  const handleConnectedEvent = (e: any) => {
    const { data: receivedData } = e;
    console.log(receivedData);
  };

  const handleAlarmEvent = (e: any) => {
    const {data : receivedData} = e
    console.log(receivedData)
    getAlarms()
  };

  useEffect(()=>{
    const baseURI = process.env.REACT_APP_BACKEND_URI+process.env.REACT_APP_API_VERSION
    if(!sseForUser.sse){
      setSseForUser({sse: new EventSource(baseURI + "/sse/connect/" + userId)})
    }else{
      sseForUser.sse.addEventListener("Connected", handleConnectedEvent)

      sseForUser.sse.addEventListener(SSE.ALARM, handleAlarmEvent)
    }
  }, [sseForUser.sse])
   /* SSE 설정 끝 */

  const [alarmId, setAlarmId] = useState(undefined)

  const {refetch: readAlarm} = useQuery<DefaultResponse, AxiosError>(
    ["readAlarm", alarmId],
    () => AlarmService.readAlarm(alarmId),
    {
      onSuccess : (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          console.log(res.data)
        }
      }
    }
  )

  function handleAlarmClick(alarmId: number) {
    setAlarmId(alarmId)
  }

  useEffect(() => {
    getAlarms()

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      if (sseForUser.sse) {
        sseForUser.sse.removeEventListener('Connected', handleConnectedEvent);
        sseForUser.sse.removeEventListener(SSE.ALARM, handleAlarmEvent);
      }
    };
  }, []);

  useEffect(() => {
    if (alarmId !== undefined) {
      readAlarm()
      getAlarms()
      setAlarmId(undefined)
    }
  }, [alarmId]);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
    {alarms &&
      (alarms.map((alarm: UserAlarm, key: number) => (
        <NotificationItem key={alarm.alarmId} icon={<Icon>email</Icon>} title={alarm.alarmDescription}
                          disabled={!alarm.alarmStatus} onClick={() => {handleAlarmClick(alarm.alarmId)}}/>
      ))) ||
      <NotificationItem icon={<Icon>mark_email_read_icon</Icon>} title="Nothing New" disabled/>
    }
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const logout = () => {
    StorageUtil.initStorage()
    navigate("/authentication/sign-in")
    setMenuList({list: null})
  }

  return (
    <AppBar
      position={"relative"}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={navbarContainer}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={pageTitle || route[route.length - 1]} route={route} light={light} />
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton sx={navbarIconButton} size="small" disableRipple onClick={logout}>
                <Icon sx={iconsStyle}>exit_to_app</Icon>
              </IconButton>
              {/*<Link to="/authentication/sign-in">*/}
              <Link to="/">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenMenu}
              >
              {alarms &&
                <MDBadge badgeContent={alarms.length} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge> ||
                  <Icon sx={iconsStyle}>notifications</Icon>}
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleLanguageExpand}>
                <Icon sx={iconsStyle}>language</Icon>
                <Icon sx={iconsStyle}>
                  { openLanguageMenu ? "expand_less" : "expand_more" }
                </Icon>
              </IconButton>
              {renderMenu()}
              {renderLanguageMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;
