import { useState } from "react";
import {Link, useNavigate} from "react-router-dom";

import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import {useQuery} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {menuRecoilState} from "store/recoilState";
import {AxiosError} from "axios";
import StringUtil from "common/StringUtil";
import StorageUtil from "common/StorageUtil";
import {ACCESS_TOKEN, USER_NAME, PERMISSIONS, USER_ID, BRAND_NAME, USER_EMAIL} from "common/Constant"
import AuthService from "service/AuthService";
import {DefaultResponse, LoginInfo} from "common/Types";
import Icon from "@mui/material/Icon";

function Basic(): JSX.Element {
  const {t} = useTranslation()
  const [id, setId] = useState<string>("")
  const [pw, setPw] = useState<string>("")
  const [apiReq, setApiReq] = useState<Boolean>(false)
  const [errMsg, setErrMsg] = useState<String>("")
  const [openErrMsg, setOpenErrMsg] = useState<Boolean>(false)
  const navigate = useNavigate()

  const setMenuList = useSetRecoilState(menuRecoilState)

  const {refetch: signIn} = useQuery<DefaultResponse, AxiosError>(
    ["signIn"],
    () => AuthService.signIn(id, pw),
    {
      onSuccess: (res: DefaultResponse ) => {
        setApiReq(false)
        if(res.code === 200 && res.subCode === 0){
          executeLogin(res.data)
        }else{
          setErrMsg(res.error)
          setOpenErrMsg(true)
        }
      }
    }
  )

  const executeLogin = (data: LoginInfo) => {
    StorageUtil.setLocalStorage(ACCESS_TOKEN, data.accessToken)
    StorageUtil.setLocalStorage(PERMISSIONS, StringUtil.jsonString2object(data.permissions))
    StorageUtil.setLocalStorage(USER_NAME, data.userName)
    StorageUtil.setLocalStorage(USER_EMAIL, data.userEmail)
    StorageUtil.setLocalStorage(USER_ID, String(data.userId))
    goMain()
  }

  const goMain = () => {
    navigate("/dashboards/qaSummary")
  }

  const clickLogin = async() => {
    if(!apiReq && id !=="" && pw !==""){
      StorageUtil.initStorage()
      setMenuList({list: null})
      setApiReq(true)
      await signIn()
    }
  }

  const activeEnter = (ev:KeyboardEvent) => {
    if(ev.key === "Enter") {
      clickLogin();
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {BRAND_NAME}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="id" label={t("login.id")} onChange={(ev:React.ChangeEvent<HTMLInputElement>) => {setId(ev.target.value); setOpenErrMsg(false);}} onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label={t("login.password")} onChange={(ev:React.ChangeEvent<HTMLInputElement>) => {setPw(ev.target.value); setOpenErrMsg(false);}} onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)} fullWidth />
            </MDBox>
            {
              openErrMsg && <MDTypography color="error" variant="caption" fontWeight="regular">{errMsg}</MDTypography>
            }

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={clickLogin} fullWidth>
                {apiReq?<Icon>loop</Icon>:t("login.siginin")}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {t("login.siginupHint")+" "}
                <MDTypography
                    component={Link}
                    to="/authentication/sign-up/cover"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                >
                  {t("login.siginup")}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
