import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {useQuery} from "@tanstack/react-query";
import {DefaultResponse, RecentNotice} from "common/Types";
import {AxiosError} from "axios";
import DashboardService from "service/DashboardService";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Skeleton} from "@mui/material";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sseForAllRecoilState} from "store/recoilState";
import {SSE} from "common/Constant";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function Notice(): JSX.Element {

  const {t} = useTranslation();


  const navigate = useNavigate()
  const [ notices, setNotices ] = useState([]);

  const {refetch: getRecentNotices} = useQuery<DefaultResponse, AxiosError>(
    ["getRecentNotices"],
    () => DashboardService.getRecentNotices(),
    {
      onSuccess : (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          setNotices(res.data)
        }
      }
    }
  )

 const setSseForAll = useSetRecoilState(sseForAllRecoilState);
 const sseForAll = useRecoilValue(sseForAllRecoilState);

  const handleConnectedEvent = (e: any) => {
    const { data: receivedData } = e;
    console.log(receivedData);
  };

  const handleNoticeEvent = (e: any) => {
    const { data: receivedData } = e;
    console.log(receivedData);
    getRecentNotices()
  };

  useEffect(()=>{
    const baseURI = process.env.REACT_APP_BACKEND_URI+process.env.REACT_APP_API_VERSION
    if(!sseForAll.sse){
      setSseForAll({sse: new EventSource(baseURI + "/sse/connect")})
    }else{
      sseForAll.sse.addEventListener("Connected", handleConnectedEvent)
      sseForAll.sse.addEventListener(SSE.NOTICE, handleNoticeEvent)
    }
  }, [sseForAll.sse])

  useEffect(() => {
    getRecentNotices()
    return () => {
      if(sseForAll.sse) {
        sseForAll.sse.removeEventListener('Connected', handleConnectedEvent);
        sseForAll.sse.removeEventListener(SSE.NOTICE, handleNoticeEvent);
        //sseForAll.sse.close()
      }
    }
  }, []);

  return (
    <Card sx={{width: "100%"}}>
      <Grid container pr={1.5} pb={1.5} m={"auto"} columnSpacing={1.5} rowSpacing={1.5}
            sx={({breakpoints}) => ({
              [breakpoints.down("lg")] : {
                gridTemplateRows : "1fr 1fr",
                gridTemplateColumns : "1fr 1fr",
                justifyContent : "space-between",
                rowSpacing : 2,
                columnSpacing : 0
              }
            })}
      >

        <Grid item xs={6} md={6} lg={1.5} m={"auto"}
              sx={({breakpoints}) => ({
                [breakpoints.down("lg")] : {
                  gridRow : 1,
                  order : 1,
                }
              })}
        >
          <Grid container alignItems={"center"} m={"auto"}>
            <Grid item xs={3} md={3} lg={12} xl={4} m={"auto"}>
              <MDBox
                display="flex"
                m={"auto"}
                justifySelf={"flex-start"}
                justifyContent="center"
                alignItems="center"
                bgColor={"secondary"}
                color="white"
                width="2.5rem"
                height="2.5rem"
                shadow="md"
                borderRadius="lg"
                variant="gradient"
                sx={({breakpoints}) => ({
                  [breakpoints.down("lg")] : {
                    m : 0
                  }
                })}
              >
                <Icon fontSize={"medium"}>{"notifications"}</Icon>
              </MDBox>
            </Grid>
            <Grid item xs={9} md={9} lg={12} xl={8} m={"auto"}>
              <MDBox display={"flex"} justifyContent={"center"}
                     sx={({breakpoints}) => ({
                       [breakpoints.down("lg")] : {
                         justifyContent : "left"
                       }
                     })}
              >
                <MDTypography variant={visualViewport.width > 2048 ? "h4" : "h5"}>
                  {t("qaSummary.notice.notice")}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={9} alignItems={"center"}
              sx={({breakpoints}) => ({
                [breakpoints.down("lg")] : {
                  girdRow : 2,
                  order : 3,
                  pr : 1.5,
                }
              })}
        >
          <Grid container columnSpacing={1} rowSpacing={1}>
          {notices.length !== 0 ?
            (notices.map((notice: RecentNotice, key: number) => (
            <Grid item xs={12} md={12} lg={4} key={key}>
              <MDBox
                key={notice.noticeId}
                display={"flex"}
                height={"100%"}
                alignItems={"center"}
                border={"1px solid lightgrey"}
                borderRadius={"10px"}
              >
                <MDBox px={2} py={1} display={"flex"} flexDirection={"column"}>
                  <MDTypography variant={"h5"}>
                    {notice.noticeTitle}
                  </MDTypography>
                  <MDTypography variant={"caption"}>
                    {notice.noticeRegDt}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            ))) :
            ([...Array(3).keys()].map((_: number, key: number) => (
            <Grid item xs={12} md={12} lg={4} key={key}>
              <MDBox borderRadius={"10px"} width={"100%"} height={"100%"}>
                <MDBox
                  alignItems={"center"}
                  border={"1px solid lightgrey"}
                  borderRadius={"10px"}
                >
                  <MDBox px={2} py={1} display={"flex"} flexDirection={"column"}>
                    <Skeleton variant={"text"} height={"2rem"} width={"100%"}/>
                    <Skeleton variant={"text"} height={"1rem"} width={"100%"}/>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            ))
            )
          }
          </Grid>
        </Grid>

        <Grid item xs={6} md={6} lg={1.5} m={"auto"}
          sx={({breakpoints}) => ({
            [breakpoints.down("lg")] : {
              gridRow : 1,
              order : 2,
              pr : 1.5
            }
          })}
        >
          <MDBox display={"flex"} justifyContent={"center"}
                 sx={({breakpoints}) => ({
                   [breakpoints.down("lg")] : {
                     justifyContent : "right"
                   }
                 })}
          >
            <MDButton variant={"gradient"} color={"info"} onClick={() => {navigate('/notice')}}>
              {t("button.more")}
            </MDButton>
          </MDBox>
        </Grid>

      </Grid>
    </Card>
  )

}

export default Notice