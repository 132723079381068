import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import {useQuery} from "@tanstack/react-query";
import {DefaultResponse, LineChartData, PowerExecutionReport, PowerResult,} from "common/Types";
import {AxiosError} from "axios";
import React, {Fragment, useEffect, useState} from "react";
import {getDetailByCode} from "common/Constant";
import TableCell from "@mui/material/TableCell";
import {Skeleton} from "@mui/material";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import MDBadge from "components/MDBadge";
import TableBody from "@mui/material/TableBody";
import {useTranslation} from "react-i18next";
import PowerService from "../../../../../service/PowerService";
import Icon from "@mui/material/Icon";


interface Props {
    historyId: number
    historyStatus: "P" | "F" | "R" | "E"
    buildName: string
    closeModalBtn:()=>void
}

function dataParser(data: any): PowerExecutionReport {
    const scriptJson = JSON.parse(data.scripts)
    const resultJson = JSON.parse(data.result)

    return {
        id: data.id,
        buildNo: data.buildNo,
        period: scriptJson.duration,
        interval: scriptJson.interval,
        deviceName: data.deviceName,
        result: resultJson,
    }
}

function PowerExecutionHistoryDetailsModal({historyId, historyStatus, buildName, closeModalBtn}: Props): JSX.Element {

    const {t} = useTranslation();

    const [data, setData] = useState<PowerExecutionReport>(undefined)

    type Tcolumn = {Header: string,
        floatAccessor?: "max" | "min" | "avg",
        intAccessor?:"period",
        stringAccessor1? : "deviceName" | "interval",
        stringAccessor2? : "origin_result_data"
    }

    const columns: Tcolumn[] = [
        {Header : "Used Device", stringAccessor1 : "deviceName"},
        {Header : "Period", intAccessor : "period"},
        {Header : "Interval (s)", stringAccessor1 : "interval"},
        {Header : "Minimum Value (Watt)", floatAccessor : "max"},
        {Header : "Maximum Value (Watt)", floatAccessor : "min"},
        {Header : "Average Value (Watt)", floatAccessor : "avg"},
        {Header : "Test Values (Watt)", stringAccessor2 : "origin_result_data"},
    ]


    const {refetch: getPowerExecutionHistoryReport} = useQuery<DefaultResponse, AxiosError>(
        ["getPowerExecutionHistoryReport"],
        () => PowerService.getPowerExecutionHistoryReport(historyId),
        {
            onSuccess: (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    setData(dataParser(res.data))
                }
            }
        }
    )

    function transformToLineChartData(rawData: PowerResult) {
        console.log(rawData)
        if (!rawData) {
            return null
        }

        const chartData: LineChartData = {
            labels : [],
            datasets : [
                {
                    label : "Values",
                    color : "info",
                    data : []
                }
            ]
        }
        for (const value of rawData.origin_result_data) {
            chartData.datasets[0].data.push(Number(value[2]))
            chartData.labels.push(value[1])
        }
        console.log(chartData)
        return chartData
    }

    useEffect(() => {
        getPowerExecutionHistoryReport()
    }, []);

    const periodHandleForm = (duration:number) => {
        if(duration<3600){
            return ((duration % 3600)/60)+t("MINUTE")
        }
        else{
            return (duration % 3600)/60?(Math.floor(duration / 3600))+t("HOUR")+((duration % 3600)/60)+t("MINUTE"):(Math.floor(duration / 3600))+t("HOUR")
        }

    }

    function renderStatus(status: "P" | "F" | "R" | "E", size?: "xs" | "sm" | "md" | "lg") {
        let color : "success" | "primary" | "warning" | "secondary"
        if (status === "P") {
            color = "success"
        } else if (status === "F") {
            color = "primary"
        } else if (status === "R") {
            color = "warning"
        } else {
            color = "secondary"
        }

        return (
            <MDBadge container badgeContent={t(getDetailByCode("TEST_EXECUTION_STATUS", status))} size="xs" color={color} variant="contained"/>
        )
    }

    return (

        <Card sx={{width : "100%", px : 4, py : 3}}>
            <Grid container spacing={3}
                  sx={{
                      overflow : "auto",
                      "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                      "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                      ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                  }}>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container direction={"column"}>
                        <Grid item display={"flex"} justifyContent={"space-between"}>
                            <Grid container direction={"row"}>
                                <MDTypography variant={"h5"} sx={{ marginRight: "10px" }}>
                                    Power Test Report
                                </MDTypography>
                                {renderStatus(historyStatus, "lg")}
                            </Grid>
                            <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
                                <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
                            </MDBox>
                        </Grid>
                        <Grid item>
                            <Grid container direction={"row"} gap={1}>
                                <MDTypography variant={"subtitle2"}>
                                    {buildName}
                                </MDTypography>
                                <MDTypography variant={"subtitle2"}>
                                    {data && data.buildNo}
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2} direction={"row"}>
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                            <TableContainer sx={{
                                minHeight:"60vh", maxHeight:"70vh", overflowY: "scroll", "&::-webkit-scrollbar" : { opacity : 0.2}}}>
                                <Table>
                                    <TableBody>
                                        {columns.map((column: Tcolumn, index: number) => (
                                            column.Header !== "Test Values (Watt)" ?
                                                <TableRow key={index}>
                                                    <TableCell variant={"head"} key={"head" + index}>{column.Header}</TableCell>
                                                    <TableCell variant={"body"} key={"body" + index} align={"right"} colSpan={2}>
                                                        {data
                                                            &&
                                                            (
                                                                column.floatAccessor
                                                                && (data.result[column.floatAccessor]? data.result[column.floatAccessor].toFixed(3):data.result[column.floatAccessor])
                                                                || ( column.stringAccessor1 && data[column.stringAccessor1]
                                                                )
                                                                || ( column.intAccessor&& periodHandleForm(data[column.intAccessor])
                                                                )
                                                            )
                                                            || "-"}
                                                    </TableCell>
                                                </TableRow> :
                                                <Fragment key={"origin_result_data"}>
                                                    <TableRow key={index}>
                                                        <TableCell variant={"head"} key={"head" + index} rowSpan={data? data.result.origin_result_data.length + 1 : 0}>{column.Header}</TableCell>
                                                    </TableRow>
                                                    {
                                                        data
                                                        &&
                                                        (
                                                            data.result[column.stringAccessor2].map((value, subIndex)=>(
                                                                <TableRow key={"body" + index + "-" + subIndex}>
                                                                    <TableCell variant={"head"} key={"head" + subIndex} >{data && value[0]+" "+value[1]}</TableCell>
                                                                    <TableCell variant={"body"} key={"body" + subIndex} align={"right"}>{data && value[2]}</TableCell>
                                                                </TableRow>
                                                            ))
                                                        )
                                                        || "-"
                                                    }
                                                </Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} sm={7} md={7} lg={7}>
                            <Grid container direction={"column"} gap={1}>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item xs={12} sm={11.5} md={11.5} lg={11.5} xl={11.5}>
                                            {data ?
                                                <VerticalBarChart title={"Statistics"} description={"Minimum, Maximum, Average"} height={"100%"}
                                                                  chart={{
                                                                      labels : ["statistics"],
                                                                      datasets : [{
                                                                          label : "Minimum",
                                                                          color : "success",
                                                                          data : [data.result.min? data.result.min : null]
                                                                      },
                                                                          {
                                                                              label : "Maximum",
                                                                              color : "info",
                                                                              data : [data.result.max? data.result.max : null]
                                                                          },
                                                                          {
                                                                              label : "Average",
                                                                              color : "dark",
                                                                              data : [data.result.avg? data.result.avg : null]
                                                                          }]}}/> :
                                                <Card sx={{height : "12rem"}}>
                                                    <MDBox py={2} pr={2} pl={2}>
                                                        <MDBox px={1} pt={1}>
                                                            <MDBox width={"100%"}>
                                                                <Skeleton variant={"text"} width={"100%"} height={"2rem"}/>
                                                                <MDBox mb={2}>
                                                                    <Skeleton variant={"text"} width={"100%"} height={"1rem"}/>
                                                                </MDBox>
                                                            </MDBox>
                                                        </MDBox>
                                                        <MDBox height={"100%"}>
                                                            <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}/>
                                                        </MDBox>
                                                    </MDBox>
                                                </Card>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    {data ? <MDBox display={"grid"}
                                                   sx={{
                                                       overflow : "auto",
                                                       "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                                                       "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                                                       ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                                                   }}><GradientLineChart title={"Result Values (Watt)"} chart={transformToLineChartData(data.result)} /></MDBox> :
                                        <Card sx={{height : "24rem"}}>
                                            <MDBox py={2} pr={2} pl={2}>
                                                <MDBox display="flex" px={1} pt={1}>
                                                    <MDBox width={"100%"}>
                                                        <Skeleton variant={"text"} width={"100%"} height={"2rem"}/>
                                                        <MDBox mb={2}>
                                                            <Skeleton variant={"text"} width={"100%"} height={"1rem"}/>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                                <MDBox height={"100%"}>
                                                    <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}/>
                                                </MDBox>
                                            </MDBox>
                                        </Card>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>
        </Card>

    )
}

export default PowerExecutionHistoryDetailsModal