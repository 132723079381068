import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import {useQuery} from "@tanstack/react-query";
import {DefaultResponse, LineChartData, MlPerfExecutionReport, reportResult} from "common/Types";
import {AxiosError} from "axios";
import React, {Fragment, useEffect, useState} from "react";
import {getDetailByCode} from "common/Constant";
import TableCell from "@mui/material/TableCell";
import {Skeleton} from "@mui/material";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import MDBadge from "components/MDBadge";
import TableBody from "@mui/material/TableBody";
import {useTranslation} from "react-i18next";
import MlPerfService from "service/MlPerfService";
import Icon from "@mui/material/Icon";


interface Props {
  historyId: number
  historyStatus: "P" | "F" | "R" | "E"
  buildName: string
  closeModalBtn:()=>void
}

function dataParser(result: any): reportResult {
  let main:any;
  const resultJson = JSON.parse(result)
  Object.keys(resultJson).forEach(key => {
    if(key === 'TestScenario.Offline'
      || key === 'TestScenario.SingleStream'
      || key === 'TestScenario.MultiStream'
      || key === 'TestScenario.Server'
        || key === 'origin_json_data'
    ){
      main = resultJson[key]
    }
  })

  return {
    count: main.count || resultJson.args.count,
    good_items: main.good_items,
    mAP: main.mAP,
    mean: main.mean,
    percentiles: main.percentiles,
    took: main.took,
    total_items: main.total_items,
    samples_per_query: main.samples_per_query
  }
}
function MlPerfExecutionHistoryDetailsModal({historyId, historyStatus, buildName, closeModalBtn}: Props): JSX.Element {

  const {t} = useTranslation();

  const [data, setData] = useState<MlPerfExecutionReport>(undefined)

  type TpercentilesHeader = "50.0" | "80.0" | "90.0" | "95.0" | "99.0" | "99.9"
  type Tcolumn = {Header: string,
                                  intAccessor?: "count" | "good_items" | "total_items",
                                  floatAccessor1?: "accuracy" | "qps",
                                  floatAccessor2?: "mAP" | "mean" | "took"  ,
                                  stringAccessor? : "percentiles"}

  const columns: Tcolumn[] = [
    {Header : "Accuracy (%)", floatAccessor1 : "accuracy"},
    {Header : "qps", floatAccessor1 : "qps"},
    {Header : "mAP (%)", floatAccessor2 : "mAP"},
    {Header : "Count", intAccessor : "count"},
    {Header : "Mean (s)", floatAccessor2 : "mean"},
    {Header : "Percentiles (s)", stringAccessor : "percentiles"},
    {Header : "Took (s)", floatAccessor2 : "took"},
    {Header : "Good Items", intAccessor : "good_items"},
    {Header : "Total Items", intAccessor : "total_items"},
  ]

  const percentilesHeader: TpercentilesHeader[] = ["50.0", "80.0", "90.0", "95.0", "99.0", "99.9"]

  const {refetch: getMlPerfExecutionHistoryReport} = useQuery<DefaultResponse, AxiosError>(
    ["getMlPerfExecutionHistoryReport"],
    () => MlPerfService.getMlPerfExecutionHistoryReport(historyId),
    {
      onSuccess: (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          const data = res.data
          if (data) {
            data.result = dataParser(data.result)
          }
          setData(data)
        }
      }
    }
  )

  function transformToLineChartData(rawData: MlPerfExecutionReport) {
    if (!rawData) {
      return null
    }
    const dataSet = rawData.result
    const chartData: LineChartData = {
      labels : percentilesHeader,
      datasets : [
        {
          label : "Values",
          color : "info",
          data : []
        },
        {
          label : "Mean",
          color : "primary",
          data : []
        }
      ]
    }
    for (const header of percentilesHeader) {
      chartData.datasets[0].data.push(dataSet.percentiles[header])
      chartData.datasets[1].data.push(dataSet.mean)
    }
    return chartData
  }

  useEffect(() => {
    getMlPerfExecutionHistoryReport()
  }, []);

  function renderStatus(status: "P" | "F" | "R" | "E", size?: "xs" | "sm" | "md" | "lg") {
    let color : "success" | "primary" | "warning" | "secondary"
    if (status === "P") {
      color = "success"
    } else if (status === "F") {
      color = "primary"
    } else if (status === "R") {
      color = "warning"
    } else {
      color = "secondary"
    }

    return (
      <MDBadge container badgeContent={t(getDetailByCode("TEST_EXECUTION_STATUS", status))} size="xs" color={color} variant="contained"/>
    )
  }

  return (

    <Card sx={{width : "100%", px : 4, py : 3}}>
     <Grid container spacing={3}
           sx={{
             overflow : "auto",
             "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
             "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
             ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
           }}>

       <Grid item xs={12} sm={12} md={12} lg={12}>
         <Grid container direction={"column"}>
           <Grid item display={"flex"} justifyContent={"space-between"}>
             <Grid container direction={"row"}>
               <MDTypography variant={"h5"} sx={{ marginRight: "10px" }}>
                 ML Perf Report
               </MDTypography>
               {renderStatus(historyStatus, "lg")}
             </Grid>
             <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
               <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
             </MDBox>
           </Grid>
           <Grid item>
             <Grid container direction={"row"} gap={1}>
             <MDTypography variant={"subtitle2"}>
               {buildName}
             </MDTypography>
             <MDTypography variant={"subtitle2"}>
               {data && data.buildNo}
             </MDTypography>
             </Grid>
           </Grid>
         </Grid>
       </Grid>


       <Grid item xs={12} sm={12} md={12} lg={12}>
         <Grid container spacing={2} direction={"row"}>
           <Grid item xs={12} sm={5} md={5} lg={5}>
             <TableContainer>
               <Table>
                 <TableBody>
                 {columns.map((column: Tcolumn, index: number) => (
                   column.Header !== "Percentiles (s)" ?
                     <TableRow key={index}>
                       <TableCell variant={"head"} key={"head" + index}>{column.Header}</TableCell>
                       <TableCell variant={"body"} key={"body" + index} align={"right"} colSpan={2}>
                         {data
                           && (
                             column.floatAccessor1
                             && (data[column.floatAccessor1]? data[column.floatAccessor1].toFixed(3):data[column.floatAccessor1])
                             || ( column.floatAccessor2 &&
                                     (data.result[column.floatAccessor2]? data.result[column.floatAccessor2].toFixed(3):data.result[column.floatAccessor2])
                             ) || (column.intAccessor &&
                              data.result[column.intAccessor]
                             )
                           )
                           || "-"}
                       </TableCell>
                     </TableRow> :
                     <Fragment key={"percentiles"}>
                       <TableRow key={index}>
                         <TableCell variant={"head"} key={"head" + index} rowSpan={7}>{column.Header}</TableCell>
                       </TableRow>
                       {percentilesHeader.map((header: TpercentilesHeader, subIndex: number) => (
                         <TableRow key={"body" + index + "-" + subIndex}>
                           <TableCell variant={"head"} key={"head" + subIndex}>{header}</TableCell>
                           <TableCell variant={"body"} key={"body" + subIndex}  align={"right"}>{data && data.result.percentiles[header].toFixed(3) || "-"}</TableCell>
                         </TableRow>
                       ))}
                     </Fragment>
                 ))}
                 </TableBody>
               </Table>
             </TableContainer>
           </Grid>

           <Grid item xs={12} sm={7} md={7} lg={7}>
             <Grid container direction={"column"} gap={1}>
               <Grid item xs={12} sm={4} md={4} lg={4}>
                 <Grid container spacing={2}>
                   <Grid item xs={12} sm={6} md={6} lg={6}>
                     {data ?
                       <VerticalBarChart title={"Results"} description={"Accuracy, qps, mAP"} height={"100%"}
                                         chart={{
                                           labels : ["Results"],
                                           datasets : [{
                                             label : "Accuracy",
                                             color : "success",
                                             data : [data.accuracy? data.accuracy : null]
                                           },
                                             {
                                               label : "qps",
                                               color : "info",
                                               data : [data.qps? data.qps : null]
                                             },
                                             {
                                               label : "mAP",
                                               color : "dark",
                                               data : [data.result.mAP? data.result.mAP : null]
                                             }]}}/> :
                       <Card sx={{height : "12rem"}}>
                         <MDBox py={2} pr={2} pl={2}>
                           <MDBox px={1} pt={1}>
                             <MDBox width={"100%"}>
                               <Skeleton variant={"text"} width={"100%"} height={"2rem"}/>
                               <MDBox mb={2}>
                                 <Skeleton variant={"text"} width={"100%"} height={"1rem"}/>
                               </MDBox>
                             </MDBox>
                           </MDBox>
                           <MDBox height={"100%"}>
                             <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}/>
                           </MDBox>
                         </MDBox>
                       </Card>
                     }
                   </Grid>

                   <Grid item xs={12} sm={6} md={6} lg={6}>
                     {data ?
                       <VerticalBarChart title={"Items"} description={"Good, Total Items"} height={"100%"}
                                         chart={{
                                           labels : ["Items"],
                                           datasets : [{
                                             label: "Good Items",
                                             color: "primary",
                                             data: [data.result.good_items]
                                           },{
                                             label: "Total Items",
                                             color: "dark",
                                             data: [data.result.total_items]
                                           }
                                           ]}}/> :
                       <Card sx={{height : "12rem"}}>
                         <MDBox py={2} pr={2} pl={2}>
                           <MDBox display="flex" px={1} pt={1}>
                             <MDBox width={"100%"}>
                               <Skeleton variant={"text"} width={"100%"} height={"2rem"}/>
                               <MDBox mb={2}>
                                 <Skeleton variant={"text"} width={"100%"} height={"1rem"}/>
                               </MDBox>
                             </MDBox>
                           </MDBox>
                           <MDBox height={"100%"}>
                             <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}/>
                           </MDBox>
                         </MDBox>
                       </Card>
                     }
                   </Grid>
                 </Grid>
               </Grid>


               <Grid item xs={12} sm={8} md={8} lg={8}>
                 {data ? <MDBox display={"grid"}
                     sx={{
                     overflow : "auto",
                     "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                     "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                     ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                   }}><GradientLineChart title={"Percentiles"} chart={transformToLineChartData(data)} /></MDBox> :
                   <Card sx={{height : "24rem"}}>
                     <MDBox py={2} pr={2} pl={2}>
                       <MDBox display="flex" px={1} pt={1}>
                         <MDBox width={"100%"}>
                           <Skeleton variant={"text"} width={"100%"} height={"2rem"}/>
                           <MDBox mb={2}>
                             <Skeleton variant={"text"} width={"100%"} height={"1rem"}/>
                           </MDBox>
                         </MDBox>
                       </MDBox>
                       <MDBox height={"100%"}>
                         <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}/>
                       </MDBox>
                     </MDBox>
                   </Card>
                 }
               </Grid>
             </Grid>
           </Grid>


         </Grid>
       </Grid>

     </Grid>
    </Card>

  )
}

export default MlPerfExecutionHistoryDetailsModal