/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


import {useEffect, useState} from "react";
import MDTypography from "components/MDTypography";
import DetailLayout from "examples/LayoutContainers/CustomLayout/detail/DetailLayout";
import MDButton from "components/MDButton";
import DetailContentsLayout from "examples/LayoutContainers/CustomLayout/detail/DetailContentsLayout";
import Footer from "examples/Footer";
import {LANGUAGE, PERMISSIONS} from "common/Constant";
import {useTranslation} from "react-i18next";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {DefaultResponse, Notice} from "../../../common/Types";
import StorageUtil from "../../../common/StorageUtil";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import NoticeService from "../../../service/NoticeService";

function NoticeDetailView(...rest: any): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation();
    const navigate = useNavigate()
    const permissions = StorageUtil.getLocalStorage(PERMISSIONS);


    //수정 일때 list에서 전달 받은 데이터
    const [notice, setNotice] = useState<Notice>(location.state.notice);


    const goToEdit = () => {
        navigate({pathname: `/notice/${notice.id}/edit`}, {state: {menu_name: "Notice", notice: notice, type:"Edit"}})
    }


    // 공지사항 조회 내용 세팅
    useEffect(()=>{
        if(location.state.type === "Edit"){
            getNotice()
        }
    },[])

    const {refetch:  getNotice} = useQuery<any, AxiosError>(
        ['getNotice'],
        () => NoticeService.getNotice(location.state.notice.id),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    const resultNotice: Notice = res.data
                    setNotice(resultNotice);
                }
            }
        }
    )

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(100)

    return (
        <DetailLayout  pageTitle={location.state.menu_name}>
            {/* 이름 입력 영역*/}
            <DetailContentsLayout>
                <Grid item >
                    <Card sx={{ height: "100%" }}>
                        <MDBox p={3}>
                            <MDBox>
                                <MDBox>
                                    <MDBox lineHeight={0} display="flex" justifyContent={"space-between"}>
                                        <MDTypography variant="h5">{notice.title}</MDTypography>
                                        {
                                            permissions.includes("E23")
                                            ?<MDButton type="submit" size={"medium"} variant={"outlined"} color={"info"} onClick={goToEdit} sx={{minWidth : buttonMinWidth}}>
                                                    {t("button.edit")}
                                                </MDButton>
                                                : null
                                        }

                                    </MDBox>

                                    <MDBox mt={1.625} height="70vh" display="flex" flexDirection="column">
                                        <Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={1} sx={()=>({textAlign:"center"})}>
                                                    {t("notice.detail.writer")}
                                                </Grid>
                                                <Grid item xs={11}>
                                                    {notice.writer}
                                                </Grid>
                                                <Grid item xs={1} sx={()=>({textAlign:"center"})}>
                                                    {t("notice.detail.regDt")}
                                                </Grid>
                                                <Grid item xs={11}>
                                                    {notice.regDt}
                                                </Grid>
                                            </Grid>
                                            <Grid item dangerouslySetInnerHTML={{__html:notice.content}}  sm={12} sx={()=>({height:"65vh", borderRadius:"10px", border:"1px solid #7b809a", padding:"10px", marginTop:"10px"})}/>

                                        </Grid>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                        <MDBox py={3} px={3} display={"flex"} justifyContent={"flex-end"}>
                            <MDBox display={"inline-block"} p={0.5} />
                        </MDBox>
                    </Card>

                </Grid>
            </DetailContentsLayout>

            <Footer />
        </DetailLayout>
    );
}

export default NoticeDetailView;