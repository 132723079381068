import MDBox from "components/MDBox";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {DefaultResponse,} from "common/Types";
import {getDetailByCode, LANGUAGE, SSE, USER_ID} from "common/Constant";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import NodeService from "../../../../service/NodeService";
import TableFooter from "../../../../examples/LayoutContainers/CustomLayout/list/TableFooter";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MDButton from "../../../../components/MDButton";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sseForUserRecoilState} from "../../../../store/recoilState";
import StorageUtil from "../../../../common/StorageUtil";
import DeviceConfigService from "../../../../service/DeviceConfigService";

interface Props {
    closeModalBtn:()=>void
}

function DeviceSearchModal({closeModalBtn}: Props): JSX.Element {

    const {t} = useTranslation();
    const navigate = useNavigate()

    const [nodeAllList, setNodeAllList] = useState([])
    const [nodeList, setNodeList] = useState([])
    const [deviceList, setDeviceList] = useState([])
    const [deviceAllList, setDeviceAllList] = useState([])
    const nodeColumns = [
        { Header: t("node.thead.nodeName"), accessor: "name", width: "15%", sortable: false},
        { Header: t("node.thead.ip"), accessor: "ip", width: "15%", sortable: false },
        { Header: t("node.thead.userName"), accessor: "userName", width: "15%", sortable: false },
        { Header: t("node.thead.description"), accessor: "description", width: "20%", sortable: false },
        { Header: t("node.thead.status"), accessor: "availability", width: "10%", sortable: false},
        { Header: t("node.thead.regDate"), accessor: "regDt", width: "15%", sortable: false },

    ];
    const deviceColumns = [
        { Header: t("dut.thead.nodeName"), accessor: "nodeName", width: "15%", sortable: false},
        { Header: t("dut.thead.nodeIp"), accessor: "nodeIp", width: "15%", sortable: false },
        { Header: t("dut.thead.vendorId"), accessor: "vendorId", width: "15%", sortable: false },
        { Header: t("dut.thead.productId"), accessor: "productId", width: "15%", sortable: false },
        { Header: t("dut.thead.serialKeyNo"), accessor: "serialCableKeyNo", width: "15%", sortable: false },
    ];

    /*paging start*/
    const entries = ["5"];
    const [entriesPerPage, setEntriesPerPage] = useState( 5 )
    const [page, setPage] = useState(1)
    const [nodeTotal, setNodeTotal] = useState(0)
    // const [deviceTotal, setDeviceTotal] = useState(0)
    const [searchingState, setSearchingState] = useState(true)
    // Setting the entries starting point
    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;

    // Setting the entries ending point
    let entriesEnd;
    const totalPageLength = Math.ceil(nodeList.length / entriesPerPage);

    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength ) {
        entriesEnd = nodeList.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }
    /*paging end*/

    // 체크박스 체크리스트 선택
    const [ checkList, setCheckList ] = useState(new Set<any>([]));

    // 체크박스 단일 선택
    const handleSingleCheck = ((row: any) => {
        if ( checkList.has( row.id ) ) {
            checkList.delete( row.id )
            setCheckList( new Set<any>( [ ...checkList ] ) );
            deleteSelectedNode(row.id)
        } else {
            setCheckList( new Set<any>([ ...checkList.add( row.id ) ] ) );
            addSelectedNode(row.id)
        }

    });

    // 체크박스 전체 선택
    const handleAllCheck =  (( checked: boolean ) => {
        if( checked ) {
            // 전체 선택 클릭 시 모든 로우의 키들 담음
            const set = new Set<any>([...checkList]);
            for (let i = 0; i < Math.min(entriesPerPage, nodeList.length); i++ ) {
                set.add(nodeList[i].id);
                addSelectedNode(nodeList[i].id)
            }
            setCheckList( set );
        } else {
            const set = new Set<any>([...checkList]);
            for (const node of nodeList ) {
                set.delete(node.id);
                deleteSelectedNode(node.id)
            }
            setCheckList( set );
        }
    });


    // 전체 체크박스 체크 여부 판단
    const isCheckedAllCheck = () =>{
        for(const node of nodeList){
            if(!checkList.has(node.id)){
                return false
            }
        }
        return true
    }



    const {refetch: getNodeListForAuto} = useQuery<any, AxiosError>(
        ['getNodeListForAuto'],
        () => NodeService.getNodeListForAuto(),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    const nodeNums = new Set<any>([]);
                    for(const node of res.data){
                        nodeNums.add(node.id)
                    }
                    setCheckList(nodeNums)
                    setNodeAllList(res.data)
                    setNodeTotal(res.data.length)
                }
            }
        }
    )

    const {refetch: searchNewDevice} = useQuery<any, AxiosError>(
        ['searchNewDevice'],
        () => DeviceConfigService.searchNewDevice(),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    console.log("searching...")
                }
            }
        }
    )

    const nodePaging = () => {
        const nodes = [];
        for(let i = (page-1)*entriesPerPage; i < Math.min(nodeTotal,page*entriesPerPage); i++){
            nodes.push(nodeAllList[i])
        }
        setNodeList(nodes)
    }

    /* SSE 설정 */
    const setSseForUser = useSetRecoilState(sseForUserRecoilState);
    const sseForUser = useRecoilValue(sseForUserRecoilState);

    const handleConnectedEvent = (e: any) => {
        const { data: receivedData } = e;
        console.log(receivedData);
    };

    const handleFindNewDeviceEvent = (e: any) => {
        const receivedData = JSON.parse(e.data);
        console.log(receivedData)
        if(receivedData.result){
            setDeviceList([...deviceList,receivedData]);
            setDeviceAllList([...deviceAllList,receivedData]);
        }
        searchingState?setSearchingState(false):null;
    };

    useEffect(()=>{
        const baseURI = process.env.REACT_APP_BACKEND_URI+process.env.REACT_APP_API_VERSION
        if(!sseForUser.sse){
            setSseForUser({sse: new EventSource(baseURI + "/sse/connect/" + StorageUtil.getLocalStorage(USER_ID))})
        }else{
            sseForUser.sse.addEventListener("Connected", handleConnectedEvent)
            sseForUser.sse.addEventListener(SSE.SEARCH_NEW_DUT, handleFindNewDeviceEvent)
        }
    }, [sseForUser.sse])

    useEffect(()=>{
        return () => {
            // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
            if (sseForUser.sse) {
                sseForUser.sse.removeEventListener('Connected', handleConnectedEvent);
                sseForUser.sse.removeEventListener(SSE.SEARCH_NEW_DUT, handleFindNewDeviceEvent);
            }
        };
    },[]);

    /* SSE 설정 끝 */

    // 페이지 변경시 전체 체크
    useEffect( () => {
        getNodeListForAuto()
        if(searchingState) searchNewDevice()
    }, [ ] );

    useEffect(() => {
        nodePaging()
    }, [ page, nodeAllList ]);

    function renderStatusIcon(status: string) {
        let content
        let color : "warning" | "success" | "error" | "primary" | "secondary" | "info" | "light" | "dark"
        if (status == "A") {
            content = t(getDetailByCode("AVAILABILITY", status))
            color = "success"
        } else if (status == "U")  {
            content = t(getDetailByCode("AVAILABILITY", status))
            color = "info"
        } else {
            return null
        }
        return <MDBadge container badgeContent={content} size="sm" color={color} variant={"contained"} minWidth={110}/>
    }

    const deleteSelectedNode = (nodeId:number)=>{
        const updatedDataList = deviceList.filter(device => device.nodeId !== nodeId);
        setDeviceList(updatedDataList);
    }

    const addSelectedNode = (nodeId:number) => {
        const findDevice = deviceAllList.find(device => device.nodeId === nodeId);
        if(findDevice){
            setDeviceList([...deviceList, findDevice]);
        }
    }

    // 페이지 이동
    const goToRegister = (row: any | undefined, type: string)=>{
        navigate({pathname:'/device/DUT'}, {state:{menu_name: "Device", device: row, type: type}});
    }

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(80) : pxToRem(95);

    return (
        <Card sx={{width : "100%", px : 6, py : 2,
            overflow : "auto",
            "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
            "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
            ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
        }}>
            <MDBox display={"flex"} justifyContent={"flex-end"}>
                <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
                    <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
                </MDBox>
            </MDBox>
            <MDBox gap={2} marginBottom={"2%"}>
                <MDBox display={"flex"} justifyContent={"space-between"} pb={1}>
                    <MDTypography variant={"h5"}>
                        Node List
                    </MDTypography>
                </MDBox>
                <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < nodeList.length ? entriesEnd : nodeTotal} total={nodeTotal} />
                {/* 테이블 전체 layout */}
                <TableContainer
                    sx={{
                        boxShadow : "none", overflow : "auto",
                        "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                        "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                        ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                    }}
                >
                    {/* 테이블 contents */}
                    <Table>
                        <MDBox component={"thead"}>
                            <TableRow>
                                <DataTableHeadCell
                                    width={ "1%" }
                                    align={ "center" }
                                    direction={ "none" }
                                    sortable={false}
                                >
                                    <Checkbox size={"small"}
                                              onChange={( e) => {
                                                  handleAllCheck(e.target.checked);
                                              }}
                                              checked={isCheckedAllCheck()} />

                                </DataTableHeadCell>

                                {nodeColumns.map(( column : any, index : number ) => (
                                    <DataTableHeadCell
                                        key={index}
                                        width={column.width}
                                        align={"left"}
                                        accessor={column.accessor}
                                        sortable={column.sortable}
                                    >
                                        {column.Header}
                                    </DataTableHeadCell>
                                ))}
                            </TableRow>
                        </MDBox>
                        <TableBody>
                            {nodeList.map(( row : any, index : number ) => (
                                <TableRow key={index} >
                                    <DataTableBodyCell
                                        align={ "center" }
                                    >
                                        <Checkbox
                                            checked={ checkList.has( row.id ) }
                                            onChange={ (e) => {
                                                handleSingleCheck( row );
                                            }}
                                        />
                                    </DataTableBodyCell>
                                    {nodeColumns.map(( column : any, index) => (
                                        <DataTableBodyCell
                                            key={index}
                                            align={ "left" }
                                            row={row}
                                        >
                                            { column.accessor === "availability" ? renderStatusIcon(row["availability"]) : row[column.accessor]
                                            }

                                        </DataTableBodyCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={nodeTotal} page={page} buttonDisabled
                                       setEntriesPerPage={setEntriesPerPage} onChangePage={setPage}/>
                </TableContainer>
            </MDBox>
            <MDBox gap={2}>
                <MDBox display={"flex"} justifyContent={"space-between"} pb={1}>
                    <MDTypography variant={"h5"}>
                        New Device List
                    </MDTypography>
                </MDBox>
                <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < deviceList.length ? entriesEnd : deviceList.length} total={deviceList.length} />
                {/* 테이블 전체 layout */}
                    <TableContainer
                        sx={{
                            boxShadow : "none", overflow : "auto",
                            "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                            "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                            ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                        }}
                    >
                        {searchingState?
                            <MDBox display={"flex"} justifyContent={"center"} alignItems={"center"} height={"10rem"}>
                                <CircularProgress color={"info"}/>
                                <span style={{ marginLeft: '10px' }}>SEARCHING...</span>
                            </MDBox>
                            :
                            <>
                                {/* 테이블 contents */}
                                <Table>
                                    <MDBox component={"thead"}>
                                        <TableRow>
                                            <DataTableHeadCell
                                                width={"10%"}
                                                align={"center"}
                                                accessor={"no"}
                                            >
                                                {t("dut.thead.no")}
                                            </DataTableHeadCell>

                                            {deviceColumns.map(( column : any, index : number ) => (
                                                <DataTableHeadCell
                                                    key={index}
                                                    width={column.width}
                                                    align={"left"}
                                                    accessor={column.accessor}
                                                    sortable={column.sortable}
                                                >
                                                    {column.Header}
                                                </DataTableHeadCell>
                                            ))}
                                            <DataTableHeadCell width={"15%"} align={"left"}>
                                                {}
                                            </DataTableHeadCell>
                                        </TableRow>
                                    </MDBox>
                                    <TableBody>
                                        {deviceList.length === 0?
                                            <TableRow key={0}>
                                                <TableCell colSpan={ nodeColumns.length+1 } sx={{textAlign:"center"}}>
                                                    No New Device
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <>
                                                {deviceList.map(( row : any, index : number ) => (
                                                    <TableRow key={index} >
                                                        <DataTableBodyCell
                                                            key={index}
                                                            align={ "center" }
                                                            row={row}
                                                        >
                                                            {entriesPerPage*(page-1)+index+1}

                                                        </DataTableBodyCell>
                                                        {deviceColumns.map(( column : any, index) => (
                                                            <DataTableBodyCell
                                                                key={index}
                                                                align={ "left" }
                                                                row={row}
                                                            >
                                                                {row[column.accessor]}

                                                            </DataTableBodyCell>
                                                        ))}
                                                        <DataTableBodyCell row={row} align={"left"}>
                                                            <MDButton variant={"outlined"} onClick={()=>goToRegister(row, "AutoCreate")} size="small" color={"info"} sx={{minWidth : buttonMinWidth}}>
                                                                {t("button.register")}
                                                            </MDButton>
                                                        </DataTableBodyCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        }
                                    </TableBody>
                                </Table>
                            </>
                        }
                    </TableContainer>
            </MDBox>
        </Card>
    );

}

export default DeviceSearchModal