import moment from "moment-timezone";
import StringUtil from "common/StringUtil";

function isPastDay(str:string) : boolean{
    if(StringUtil.isEmpty(str)) return true

    const nowDate = moment(new Date()).tz("Asia/Seoul").format('YYYY-MM-DD 00:00:00')

    if(str.localeCompare(nowDate) == 0)
        return false
    else
        return true

}

function getNowTime(): String {
    const today = moment();
    return today.format("YYYY-MM-DD hh:mm").toString();
}

function formattedDate(dateTime: string) {
    if (StringUtil.isEmpty(dateTime)) {
        return null
    } else {
        return dateTime.substring(0, 10);
    }
}

function getWeekOfMonth() {
    const today = moment()
    const firstDayOfMonth = moment().year(today.year()).month(today.month()).date(1).day()
    const weekOfMonth = Math.ceil((today.date() + firstDayOfMonth) / 7)

    let result

    if (weekOfMonth.toString().endsWith("1")) {
        result = weekOfMonth + "st"
    } else if (weekOfMonth.toString().endsWith("2")) {
        result = "2nd"
    } else if (weekOfMonth.toString().endsWith("3")) {
        result = "3rd"
    } else {
        result = weekOfMonth + "th"
    }

    return `${result} week of ${today.format("MMMM, YYYY")}`

}

function getDateStringFormat(date: Date){
    return date?date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0"):""
}

function getDefaultFromDate(){
    const defaultFromDate = new Date();
    defaultFromDate.setDate(defaultFromDate.getDate() - 7);
    return defaultFromDate;
}

function getDefaultToDate(){
    const defaultToDate = new Date();
    defaultToDate.setHours(0, 0, 0, 0);
    return defaultToDate;
}

function timeDiff(startDt: string, endDt: string): number {

    const from = Date.parse(startDt)
    const to = Date.parse(endDt)

    return Math.floor((to - from) / 1000)
}

function secondsToTimeString(seconds: number): string {
    if (!seconds) {
        return "00"
    }
    const hh = Math.floor(seconds / 3600)
    const remainder= seconds % 3600

    const mm = Math.floor(remainder / 60)
    const ss = (remainder % 60).toString().padStart(2, "0")

    const hour = hh > 0 ?  hh.toString().padStart(2, "0") + ":" : ""
    const minute = hh > 0 || mm > 0 ? mm.toString().padStart(2, "0") + ":" : ""

    return hour + minute + ss
}

function timeDiffToTimeString(startDt: string, endDt: string): string {
    const result = timeDiff(startDt, endDt)
    return secondsToTimeString(result)
}


export default {
    isPastDay,
    getNowTime,
    formattedDate,
    getWeekOfMonth,
    getDateStringFormat,
    getDefaultFromDate,
    getDefaultToDate,
    timeDiff,
    secondsToTimeString,
    timeDiffToTimeString,
}