import {multipartApiClient} from "service/ApiClient";
import {DefaultResponse} from "../common/Types";



const uploadFile = async function (data:FormData){
    return await multipartApiClient.post<DefaultResponse>("logAnalysis/fileUpload", data).then((res:any)=>res.data)
}


const logAnalysisService = {
    uploadFile
}

export default logAnalysisService;