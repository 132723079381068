import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from "./locales/en-US/translation.json";
import translationKO from "./locales/ko-KR/translation.json";
import defaultPack from '../translation/default-pack.cjs';

const resources = {
  'ko-KR': { translation: translationKO },
  'en-US': { translation: translationEN },
};


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: defaultPack['default-language'],
    returnNull: false,
    returnEmptyString: false,
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      prefix: '%{',
      suffix: '}',
    },
    parseMissingKeyHandler(key) {
      console.warn('Missing i18n key:', key, `${key}`);
      const keySeparator = '~~';
      const value = key.includes(keySeparator) ? key.split(keySeparator)[1] : key;

      return value;
    },
  })
  .then();

export default i18n;