import {apiClient} from "service/ApiClient";
import {DefaultResponse} from "common/Types";

const getDeviceList = async function ( page: number, entriesPerPage: number, sort: string, direction: "none" | "ASC" | "DESC") {
  return await apiClient.get<DefaultResponse>(`/dashboard/device?page=${page}&size=${entriesPerPage}&sort=${sort},${(direction === "ASC" || direction === "DESC") ? direction : ""}`)
                        .then((res:any) => res.data)
}

async function getDeviceTotalInfo() {
  return await apiClient.get<DefaultResponse>(`/dashboard/device/total`).then((res:any) => res.data)
}

const getRegularDevice = async function (domainType: string) {
  return await apiClient.get<DefaultResponse>(`/device/regular/${domainType}`).then((res:any) => res.data)
}

async function getGPUValidationDevice(){
  return await apiClient.get<DefaultResponse>('/device/gpuValidation/list').then((res:any) => res.data)
}

const DeviceService = {
  getDeviceList,
  getDeviceTotalInfo,
  getRegularDevice,
  getGPUValidationDevice
}

export default DeviceService;