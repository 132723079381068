/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import ReservationView from "./layouts/device/ReservationView";
import DeviceSummaryView from "./layouts/dashboards/deviceSummary/DeviceSummaryView";
import SignIn from "./layouts/authentication/sign-in";
import TestSuiteListView from "./layouts/test/suite/list/TestSuiteListView";
import TestSuiteView from "./layouts/test/suite/TestSuiteView";
import React from "react";
import QaSummaryView from "./layouts/dashboards/QaSummary/QaSummaryView";
import TestExecutionListView from "./layouts/test/execution/list/TestExecutionListView";

import TestExecutionView from "./layouts/test/execution/TestExecutionView";
import TestExecutionHistoryListView from "./layouts/test/execution/history/common/TestExecutionHistoryListView";
import MlPerfExecutionHistoryListView from "./layouts/mlperf/execution/history/MlPerfExecutionHistoryListView";
import MlPerfExecutionListView from "./layouts/mlperf/execution/list/MlPerfExecutionListView";
import IntegrationTestListView from "./layouts/test/execution/list/IntegrationTestListView";
import NoticeListView from "./layouts/notice/list/NoticeListView";
import NoticeView from "./layouts/notice/modal/NoticeView";
import NoticeDetailView from "./layouts/notice/modal/NoticeDetailView";
import DUTListView from "./layouts/deviceConfig/DUT/list/DUTListView";
import DUTConfigView from "./layouts/deviceConfig/DUT/DUTConfigView";
import NodeListView from "./layouts/deviceConfig/tedNode/list/NodeListView";
import FarmListView from "./layouts/deviceConfig/tedFarm/list/FarmListView";
import MlPerfExecutionView from "./layouts/mlperf/execution/MlPerfExecutionView";
import NodeDetailView from "./layouts/deviceConfig/tedNode/NodeDetailView";
import FarmDetailView from "./layouts/deviceConfig/tedFarm/FarmDetailView";
import PowerExecutionListView from "./layouts/power/execution/list/PowerExecutionListView";
import PowerExecutionView from "./layouts/power/execution/PowerExecutionView";
import PowerExecutionHistoryListView from "./layouts/power/execution/history/PowerExecutionHistoryListView";
import UserRemoteAccessListView from "./layouts/device/RemoteAccess/list/UserRemoteAccessListView";
import PduListView from "./layouts/deviceConfig/tedPdu/list/PduListView";
import LogAnalysisDetailView from "./layouts/logAnalysis/LogAnalysisDetailView";
import PduDetailView from "./layouts/deviceConfig/tedPdu/PduDetailView";
import ModelGenList from "./layouts/validation/gen/list/ModelGenList";
import ModelGenView from "./layouts/validation/gen/ModelGenView";
import CurrentModelTestList from "./layouts/validation/test/list/CurrentModelTestList";
import CurrentModelTestView from "./layouts/validation/test/CurrentModelTestView";

const routes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    collapse: [
      {
        name: "QA Summary",
        key: "qaSummary",
        route: "/dashBoards/QASummary",
        component: <QaSummaryView />,
      },
      {
        name: "Device Summary",
        key: "deviceSummary",
        route: "/dashBoards/DeviceSummary",
        component: <DeviceSummaryView />,
      },
    ],
  },
  {
    type: "collapse",
    name: "GPU/NPU Validation",
    key: "gpuValidation",
    collapse: [
      {
        name: "Pre-defined Model",
        key: "preDefinedModel",
        route: "/validation/predefined",
        component: <ModelGenList />,
      },
      {
        name: "Pre-defined Model View",
        key: "preDefinedModelDetail",
        route: "/validation/predefined/model",
        component: <ModelGenView />,
      },
      {
        name: "Pre-defined Model View",
        key: "preDefinedModelEdit",
        route: "/validation/predefined/model/:id",
        component: <ModelGenView />,
      },
      {
        name: "Custom Model",
        key: "customModel",
        route: "/validation/custom",
        component: <ModelGenList />,
      },
      {
        name: "Custom Model View",
        key: "customModelDetail",
        route: "/validation/custom/model",
        component: <ModelGenView />,
      },
      {
        name: "Custom Model View",
        key: "customModelEdit",
        route: "/validation/custom/model/:id",
        component: <ModelGenView />,
      },
      {
        name: "Model Compatibility Test",
        key: "currentTest",
        route: "/validation/compatibilitytest",
        component: <CurrentModelTestList />,
      },
      {
        name: "Model Compatibility Test View",
        key: "currentTestDetail",
        route: "/validation/compatibilitytest/execute",
        component: <CurrentModelTestView />,
      },
    ],
  },
  {
    key: "testSuite",
    route: "/test/suite/TestSuite",
    component: <TestSuiteListView />,
  },
  {
    key: "testSuiteDetail",
    route: "/test/suite/:id",
    component: <TestSuiteView />,
  },
  {
    key: "testSuiteDetail",
    route: "/test/suite",
    component: <TestSuiteView />,
  },
  {
    type: "collapse",
    name: "Board Execution",
    key: "boardExecution",
    collapse: [
      {
        name: "User Fusing",
        key: "userFusing",
        route: "/test/board/UserFusing",
        component: <QaSummaryView />,
      },
      {
        name: "User Test",
        key: "userTest",
        route: "/test/board/UserTest",
        component: <TestExecutionListView />,
      },

      {
        name: "Integration Test",
        key: "integrationTest",
        route: "/test/board/IntegrationTest",
        component: <IntegrationTestListView />,
      },
      {
        name: "Test History",
        key: "testHistory",
        route: "/test/board/TestHistory",
        component: <TestExecutionHistoryListView />,
      },
      {
        name: "Remote Access",
        key: "remoteAccess",
        route: "/test/board/RemoteAccess",
        component: <UserRemoteAccessListView />,
      },
    ],
  },
  {
    type: "collapse",
    name: "GPU/GPU Execution",
    key: "GPU/NPUExecution",
    collapse: [
      {
        name: "MLPerf Test",
        key: "mlperfTest",
        route: "/test/gpu/MLPerfTest",
        component: <MlPerfExecutionListView />,
      },
      {
        name: "MLPerf Create",
        key: "mlPerfExecution",
        route: "/test/gpu/MLPerfTest/execution",
        component: <MlPerfExecutionView />
      },
      {
        name: "MLPerf Edit",
        key: "mlPerfExecution",
        route: "/test/gpu/MLPerfTest/execution/:id",
        component: <MlPerfExecutionView />
      },
      {
        name: "Power Test",
        key: "powerTest",
        route: "/test/gpu/PowerTest",
        component: <PowerExecutionListView />,
      },
      {
        name: "Power Create",
        key: "powerExecution",
        route: "/test/gpu/PowerTest/execution",
        component: <PowerExecutionView />
      },
      {
        name: "Power Edit",
        key: "powerExecution",
        route: "/test/gpu/PowerTest/execution/:id",
        component: <PowerExecutionView />
      },
      {
        name: "Test History",
        key: "testHistory",
        route: "/test/gpu/TestHistory",
        component: <TestExecutionHistoryListView />,
      },
    ],
  },
  {
    type: "collapse",
    name: "WEB Execution",
    key: "webExecution",
    collapse: [
      {
        name: "WebUserTest",
        key: "webUserTest",
        route: "/test/web/UserTest",
        component: <TestExecutionListView />,
      },
      {
        name: "Integration Test",
        key: "integrationTest",
        route: "/test/web/IntegrationTest",
        component: <IntegrationTestListView />,
      },
      {
        name: "Test History",
        key: "testHistory",
        route: "/test/web/TestHistory",
        component: <TestExecutionHistoryListView />,
      },
    ],
  },
  {
    key: "integrationTest",
    route: "/test/execution/IntegrationTest",
    component: <IntegrationTestListView />,
  },
  {
    key: "userTest",
    route: "/test/execution/UserTest",
    component: <TestExecutionListView />,
  },
  {
    key: "testExecutionHistory",
    route: "/test/execution/TestHistory",
    component: <TestExecutionHistoryListView />
  },
  {
    key: "testExecutionDetail",
    route: "/test/execution",
    component: <TestExecutionView />,
  },
  {
    key: "testExecutionDetail",
    route: "/test/execution/:id",
    component: <TestExecutionView />,
  },
  {
    key: "mlPerfExecution",
    route: "/mlperf/execution/MLPerfExecution",
    component: <MlPerfExecutionListView />
  },
  {
    key: "mlPerfExecutionHistory",
    route: "/mlperf/execution/MLPerfHistory",
    component: <MlPerfExecutionHistoryListView />
  },
  {
    key: "mlPerfExecution",
    route: "/mlperf/execution",
    component: <MlPerfExecutionView />
  },
  {
    key: "mlPerfExecution",
    route: "/mlperf/execution/:id",
    component: <MlPerfExecutionView />
  },
  {
    key: "sign-in",
    route: "/authentication/sign-in",
    component: <SignIn/>
  },
  {
    key: "reservation",
    route: "/device/reservation",
    component: <ReservationView />,
  },
  {
    key: "reservation",
    route: "/device/remoteAccess",
    component: <UserRemoteAccessListView />,
  },
  {
    key: "notice",
    route: "/notice",
    component: <NoticeListView />
  },
  {
    key: "notice",
    route: "/notice/write",
    component: <NoticeView />
  },
  {
    key: "notice",
    route: "/notice/:id",
    component: <NoticeDetailView />
  },
  {
    key: "notice",
    route: "/notice/:id/edit",
    component: <NoticeView />
  },
  {
    key: "dut",
    route: "/deviceConfig/DUTs",
    component: <DUTListView />
  },
  {
    key: "dutDetail",
    route: "/device/DUT/:id",
    component: <DUTConfigView/>,
  },
  {
    key: "dutDetail",
    route: "/device/DUT",
    component: <DUTConfigView/>,
  },
  {
    key: "tedNode",
    route: "/deviceConfig/Node",
    component: <NodeListView/>,
  },
  {
    key: "nodeDetail",
    route: "/device/node/:id",
    component: <NodeDetailView/>,
  },
  {
    key: "nodeDetail",
    route: "/device/node",
    component: <NodeDetailView/>,
  },
  {
    key: "tedFarm",
    route: "/deviceConfig/Farm",
    component: <FarmListView/>,
  },
  {
    key: "farmDetail",
    route: "/device/farm/:id",
    component: <FarmDetailView/>,
  },
  {
    key: "farmDetail",
    route: "/device/farm",
    component: <FarmDetailView/>,
  },
  {
    key: "tedPdu",
    route: "/deviceConfig/Pdu",
    component: <PduListView />
  },
  {
    key: "pduDetail",
    route: "/device/pdu/:id",
    component: <PduDetailView/>,
  },
  {
    key: "pduDetail",
    route: "/device/pdu",
    component: <PduDetailView/>,
  },
  {
    key: "powerExecution",
    route: "/power/execution/PowerExecution",
    component: <PowerExecutionListView />
  },
  {
    key: "powerExecutionHistory",
    route: "/power/execution/PowerHistory",
    component: <PowerExecutionHistoryListView />
  },
  {
    key: "powerExecution",
    route: "/power/execution",
    component: <PowerExecutionView />
  },
  {
    key: "powerExecution",
    route: "/power/execution/:id",
    component: <PowerExecutionView />
  },
  {
    key: "logAnalysis",
    route: "/logAnalysis",
    component: <LogAnalysisDetailView />
  },
  {
    key: "logAnalysis",
    route: "/logAnalysis",
    component: <LogAnalysisDetailView />
  }

];

export default routes;
