import MDBox from "components/MDBox";
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import React, {useEffect, useState} from "react";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import DetailLayout from "examples/LayoutContainers/CustomLayout/detail/DetailLayout";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import DetailContentsLayout from "examples/LayoutContainers/CustomLayout/detail/DetailContentsLayout";
import FormField from "../../component/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import {DefaultResponse, TestExecution,} from "common/Types";
import {PowerExecutionSaveConstructor} from "common/Constructors";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import {BRAND_NAME, LANGUAGE} from "common/Constant";
import pxToRem from "assets/theme/functions/pxToRem";
import {useTranslation} from "react-i18next";
import PowerService from "../../../service/PowerService";
import ConfirmationDialog from "../../component/ConfirmationDialog";
import ProjectService from "../../../service/ProjectService";

function PowerExecutionView(...rest: any): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation();
    const navigate = useNavigate()
    const [formValue, setFormValue] = useState(null);
    const [apiReq, setApiReq] = useState<Boolean>(false);
    const [_projectName, setProjectName] = useState(location.state.power_execution.projectName ? {
        label: location.state.power_execution.projectName,
        id: location.state.power_execution.projectName
    } : undefined)
    const [projectList, setProjectList] = useState(null);
    const [_project, setProject] = useState(location.state.power_execution.projectId?{label:location.state.power_execution.projectName, id:location.state.power_execution.projectId}:null)

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/

    //수정 일때 list에서 전달 받은 데이터
    const [testExecution, setTestExecution] = useState<TestExecution>();

    /* from validation start*/
    const form = {
        formId: "create-test_execution-form",
        formField: {
            name: {
                name: "name",
                label: t("Name"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.NAME"),
            },
            projectName: {
                name: "projectName",
                label: t("Project Name"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.PROJECT.NAME"),
            },
            durationHour:{
                name: "durationHour",
                label: t("HOUR"),
                type: "number",
                errorMsg: t("ERRORMESSAGE.POWER.INPUT.DURATION"),
            },
            durationMinute:{
                name: "durationMinute",
                label: t("MINUTE"),
                type: "number",
                errorMsg: t("ERRORMESSAGE.POWER.INPUT.DURATION"),
            },
            interval:{
                name: "interval",
                label: t("INTERVAL"),
                type: "number",
                errorMsg: t("ERRORMESSAGE.POWER.INPUT.INTERVAL"),
            }
        },
    }

    const {
        formField: {name, projectName, durationHour, durationMinute, interval},
    } = form;

    const validations = Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [projectName.name]: Yup.string().required(projectName.errorMsg),
        [durationHour.name]: Yup.number().required(durationHour.errorMsg).min(0, t("ERRORMESSAGE.FORM.REQUIRED.POWER.DURATION")),
        [durationMinute.name]: Yup.number().required(durationMinute.errorMsg).min(0, t("ERRORMESSAGE.FORM.REQUIRED.POWER.DURATION")),
        [interval.name]: Yup.number().required(durationMinute.errorMsg).min(0, t("ERRORMESSAGE.FORM.REQUIRED.POWER.INTERVAL"))
    })

    const [initialValues, setInitialValues] = useState({
        [name.name]: location.state.power_execution?.name ? location.state.power_execution.name : "",
        [projectName.name]: location.state.power_execution.projectName ? location.state.power_execution.projectName : null,
        [durationHour.name]: location.state.power_execution?.durationHour ? location.state.power_execution.durationHour : 0,
        [durationMinute.name]: location.state.power_execution?.durationMinute ? location.state.power_execution.durationMinute : 0,
        [interval.name]: location.state.power_execution?.interval ? location.state.power_execution.interval : 0,
    })


    const {formId, formField} = form;
    const currentValidation = validations;
    /* from validation end*/

    const _powerExecution = new PowerExecutionSaveConstructor(
        undefined,
        '',
        '',
        undefined,
        undefined,
        undefined,
    )

    const {refetch: saveTestExecution} = useQuery<any, AxiosError>(
        ['saveTestExecution'],
        () => PowerService.savePowerExecution(_powerExecution),
        {
            onSuccess: (res: DefaultResponse) => {
                setApiReq(false)
                if (res.code === 200 && res.subCode === 0) {
                    goBack()
                }
            }
        }
    )

    const {refetch: getProjectListByDomainType} = useQuery<any, AxiosError>(
        ['getProjectListByDomainType'],
        () => ProjectService.getProjectListByDomainType(location.state.domain),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    console.log(res.data)
                    setProjectList(res.data)
                }
            }
        }
    )

    const handleSubmit = (values: any, actions: any) => {
        const duration = values.durationHour*3600+values.durationMinute*60
        if(values.interval > duration){
            setErrMsg(t("ERRORMESSAGE.POWER.LIMIT.INTERVAL"))
            setOpenErrMsg(true)
        }else{
            setFormValue(values);
            openConfirm(t("confirm.save"), "info");
        }

    }

    const getProjectOption = (list:any[])=>{
        const projectList:{id:string, label:string}[] = []
        list?.forEach(i => {
            projectList.push({
                id : i.id,
                label:i.name
            })
        })
        return projectList
    }

    const handleYes = ()=>{
        if (!apiReq) {
            _powerExecution.id = testExecution?.id ? testExecution.id : undefined;
            _powerExecution.name = formValue.name
            _powerExecution.domainType = location.state.domain
            _powerExecution.projectId = _project.id
            _powerExecution.duration = formValue.durationHour*3600+formValue.durationMinute*60
            _powerExecution.interval = formValue.interval

            setApiReq(true)
            saveTestExecution()
        }
    }

    const handleNo = () => {
        toggleDialog()
    }

    const goBack = () => {
        navigate(-1);
    }


    //수정일때 시작
    useEffect(() => {
        getProjectListByDomainType()
        if (location.state.type === "Edit") {
            setTestExecution(location.state.power_execution)
        }
    }, [])


    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(100)

    function getStepContent(formData: any): JSX.Element {
        const {formField, values, errors, touched} = formData;
        const {name, projectName, durationHour, durationMinute, interval} = formField;
        const {
            name: nameV,
            projectName: projectNameV,
            durationHour: durationHourV,
            durationMinute: durationMinuteV,
            interval: intervalV
        } = values;

        return <MDBox>
            <MDBox lineHeight={0}>
                <MDTypography variant="h5">
                    {t(`title.path.${location.state.type}`, {title: location.state.menu_name})}
                </MDTypography>
            </MDBox>

            <Grid container spacing={2} mt={1.625}>
                <Grid container item={true} xs={6} display="flex" justifyContent="center" >
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={nameV}
                            placeholder={name.placeholder}
                            error={errors.name && touched.name}
                            success={nameV?.length > 0 && !errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Autocomplete
                            value={_project}
                            options={getProjectOption(projectList)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label || ""}
                            onChange={(event, newValue) => {
                                values.projectName = newValue.label
                                setProject(newValue)
                            }}
                            renderInput={(params) => (
                                <FormField {...params}
                                           type={projectName.type}
                                           label={projectName.label}
                                           name={projectName.name}
                                           value={projectNameV}
                                           placeholder={projectName.placeholder}
                                           error={errors.projectName && touched.projectName}
                                           success={projectNameV?.length > 0 && !errors.projectName}
                                           onChange={() => {
                                           }}  //text 입력후에 selectbox에 값이 없어도 성공으로 나오는데 onchange 넣으니 해결되어서 추가함. 이유는 모르겠음
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={interval.type}
                            label={interval.label}
                            name={interval.name}
                            value={intervalV}
                            placeholder={interval.placeholder}
                            error={errors.interval && touched.interval}
                            success={intervalV?.length > 0 && !errors.interval}
                        />
                    </Grid>
                </Grid>
                <Grid item={true} xs={6} display="flex" justifyContent="center" flexDirection={"column"}>
                    <Grid container display="flex" direction={"column"} alignItems={"center"} justifyContent={"space-evenly"} sx={{border:"1px solid black", height:"100%", borderRadius:"10px", marginBottom:"20px"}}>
                        <Grid item width={"90%"}>Period</Grid>
                        <Grid item width={"80%"} display="flex" justifyContent={"space-between"}>
                            <FormField
                                type={durationHour.type}
                                label={durationHour.label}
                                name={durationHour.name}
                                value={durationHourV}
                                placeholder={durationHour.placeholder}
                                error={errors.durationHour && touched.durationHour}
                                success={durationHourV?.length > 0 && !errors.durationHour}
                            />
                            <FormField
                                type={durationMinute.type}
                                label={durationMinute.label}
                                name={durationMinute.name}
                                value={durationMinuteV}
                                placeholder={durationMinute.placeholder}
                                error={errors.durationMinute && touched.durationMinute}
                                success={durationMinuteV?.length > 0 && !errors.durationMinute}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>;
    }

    return (
        <DetailLayout pageTitle={location.state.menu_name}>
            {/* 이름 입력 영역*/}
            <DetailContentsLayout>
                <Grid item>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({values, errors, touched, isSubmitting}) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{height: "100%"}}>
                                    <MDBox px={3} pt={3}>
                                        <MDBox>
                                            {getStepContent({
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                            })}
                                        </MDBox>
                                    </MDBox>
                                    <MDBox py={3} px={3} display={"flex"} justifyContent={"flex-end"}>
                                        <MDButton size={"medium"} variant={"outlined"} color={"secondary"}
                                                  onClick={() => {
                                                      goBack()
                                                  }} sx={{minWidth: buttonMinWidth}}>
                                            {t("button.cancel")}
                                        </MDButton>
                                        <MDBox display={"inline-block"} p={0.5}/>
                                        <MDButton type="submit" size={"medium"} variant={"outlined"} color={"info"}
                                                  onClick={() => {
                                                  }} sx={{minWidth: buttonMinWidth}}>
                                            {apiReq ? <Icon>loop</Icon> : t("button.save")}
                                        </MDButton>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </DetailContentsLayout>

            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color="warning"
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
            />
            <Footer/>
        </DetailLayout>
    );
}

export default PowerExecutionView;