import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDPagination from "components/MDPagination";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";
import {LANGUAGE} from "../../common/Constant";
import pxToRem from "../../assets/theme/functions/pxToRem";

// Declaring props types for PageLayout
interface Props {
    entriesPerPage: number,
    entries: any[],
    totalCount: number,
    page: number,
    setEntriesPerPage: (newValue: number) => void
    buttonDisabled? : boolean
    customBtnName? : string
    onClickCustom? : () => void
    onClickSave?: () => void
    onClickDelete?: () => void
    onChangePage : (x : number) => void
}

function CustomTableFooter({entriesPerPage, entries, totalCount, page, setEntriesPerPage, customBtnName, onClickCustom, onClickSave, onClickDelete, onChangePage, buttonDisabled}: Props): JSX.Element {

    const {t} = useTranslation()
    const [ currentPage, setCurrentPage ] = useState(page)

    const totalPages = Math.ceil( totalCount / entriesPerPage )

    function goToPreviousPage() {
        setCurrentPage( currentPage - 1 )
    }

    function goToNextPage() {
        setCurrentPage( currentPage + 1 )
    }

    useEffect(() => {
        onChangePage( currentPage )
    }, [ currentPage ]);

    useEffect(() => {
        setCurrentPage(page)
    }, [page]);

    function renderPreviousButton() {
        if ( currentPage <= 1 ) {
            return (
                <MDPagination item disabled>
                    <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
            )
        } else {
            return (
                <MDPagination item onClick={goToPreviousPage}>
                    <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
            )
        }
    }

    function renderNextButton() {
        if ( currentPage >= totalPages ) {
            return (
                <MDPagination item disabled>
                    <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
            )
        } else {
            return (
                <MDPagination item onClick={goToNextPage}>
                    <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
            )
        }
    }

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(110)

    function renderPaginationButton() {
        if ( totalPages <= 6 ) {
            const array = Array.from({length: totalPages}, (_, index) => index)
            return (
                array.map(( pageNum : any, key : number ) => (
                    <MDPagination
                        item
                        key={ key }
                        onClick={() => setCurrentPage( pageNum + 1 ) }
                        active={ currentPage === pageNum + 1 }
                    >
                        {pageNum + 1}
                    </MDPagination> ))
            )
        } else if ( currentPage >= 3 && totalPages > currentPage + 4 ) {
            const array = Array.from({length: 6}, (_, index) => index + currentPage - 1)
            return (
                array.map(( pageNum : any, key : number ) => (
                    <MDPagination
                        item
                        key={ key }
                        onClick={() => setCurrentPage( pageNum ) }
                        active={ pageNum === currentPage }
                    >
                        { pageNum }
                    </MDPagination> ))
            )
        } else if ( currentPage >= 3 && totalPages > 6 && totalPages <= currentPage + 4 ) {
            const array = Array.from({length: 6}, (_, index) => totalPages - index).sort((a, b) => a - b)
            return (
                array.map(( pageNum : any, key : number ) => (
                    <MDPagination
                        item
                        key={ key }
                        onClick={() => setCurrentPage( pageNum ) }
                        active={ pageNum === currentPage }
                    >
                        { pageNum }
                    </MDPagination> ))
            )
        } else if (totalPages > 6 && currentPage < 3 ) {
            const array = Array.from({length: 6}, (_, index) => index)
            return (
                array.map(( pageNum : any, key : number ) => (
                    <MDPagination
                        item
                        key={ key }
                        onClick={() => setCurrentPage( pageNum + 1 ) }
                        active={ currentPage === pageNum + 1 }
                    >
                        {pageNum + 1}
                    </MDPagination> ))
            )
        } else {
            return null;
        }
    }

    return (
        <MDBox
            display={"flex"}
            flexDirection={{ xs : "column", sm : "row" }}
            justifyContent={"space-between"}
            alignItems={{ xs: "flex-start", sm: "center" }}
            pt={3}
            px={3}
            mb={5}
        >
            <MDBox display={"flex"}>
                <MDBox display={"flex"} alignItems={"center"} gap={1}>
                    <Autocomplete
                        disableClearable
                        value={entriesPerPage.toString()}
                        options={entries}
                        onChange={(event, newValue) => {
                            setEntriesPerPage(parseInt(newValue, 10));
                        }}
                        size="small"
                        sx={{ width: "5rem" }}
                        renderInput={(params) => <MDInput {...params} />}
                    />
                    <MDTypography variant="caption" color="secondary">
                        {t("tfoot.perPage")}
                    </MDTypography>
                </MDBox>
            </MDBox>

            <MDBox
                display={"flex"}
                flexDirection={{ xs : "column", sm : "row" }}
                justifyContent={"space-between"}
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={2}
            >

                <MDBox display={"inline-block"}>
                    <MDPagination
                        variant={"gradient"}
                        color={"info"}
                    >
                        { renderPreviousButton() }
                        { renderPaginationButton() }
                        { renderNextButton() }

                    </MDPagination>
                </MDBox>
            </MDBox>

            <MDBox display={"flex"}>
                <MDBox display={"flex"} alignItems={"center"}>
                </MDBox>
                {buttonDisabled &&
                    <MDBox display={"flex"} justifyContent={"space-between"}>
                        <MDButton size={"medium"} disabled={true}/><MDBox display={"inline-block"} p={0.5}/>
                        <MDButton size={"medium"} disabled={true}/>
                    </MDBox> ||
                    <MDBox display={"flex"} justifyContent={"space-between"}>
                        <MDButton size={"medium"} variant={"outlined"} color={"success"} onClick={onClickCustom} sx={{minWidth : buttonMinWidth}}>
                            {t(customBtnName)}
                        </MDButton>
                        <MDBox display={"inline-block"} p={0.5}/>
                        <MDButton size={"medium"} variant={"outlined"} color={"info"} onClick={onClickSave} sx={{minWidth : buttonMinWidth}}>
                            {t("button.register")}
                        </MDButton>
                        <MDBox display={"inline-block"} p={0.5}/>
                        <MDButton size={"medium"} variant={"outlined"} color={"primary"} onClick={onClickDelete} sx={{minWidth : buttonMinWidth}}>
                            {t("button.delete")}
                        </MDButton>
                    </MDBox> }
            </MDBox>
        </MDBox>
    );
}
export default CustomTableFooter;
