import MDBox from "components/MDBox";
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import React, {useEffect, useState} from "react";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import DetailLayout from "examples/LayoutContainers/CustomLayout/detail/DetailLayout";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import DetailContentsLayout from "examples/LayoutContainers/CustomLayout/detail/DetailContentsLayout";
import FormField from "../../component/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import {DefaultResponse, ModelExecution,} from "common/Types";
import {ModelGenExecutionConstructor} from "common/Constructors";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import {
    BRAND_NAME, getCodeByDetail,
    getCodeListForInput,
    getCodeListForInputByCategory,
    getDetailByCode,
    LANGUAGE
} from "common/Constant";
import pxToRem from "assets/theme/functions/pxToRem";
import {useTranslation} from "react-i18next";
import ConfirmationDialog from "../../component/ConfirmationDialog";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import DeviceService from "../../../service/DeviceService";
import GPUValidationService from "../../../service/GPUValidationService";
import CodeMirrorTextArea from "../component/CodeMirrorTextArea";

function ModelGenView(...rest: any): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation();
    const navigate = useNavigate()
    const [formValue, setFormValue] = useState(null);
    const [apiReq, setApiReq] = useState<Boolean>(false);

    // input parameter
    const [type, setType] = useState('');
    const [deviceList, setDeviceList] = useState([]);
    const [deviceOption, setDeviceOption] = useState([]);
    const iterCntList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/


    /* from validation start*/
    const form = {
        formId: "create-model_execution-form",
        formField: {
            name: {
                name: "name",
                label: t("Name"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.NAME"),
            },
            inputShape: {
                name: "inputShape",
                label: t("Input Shape"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.INPUTSHAPE"),
            },
            modelType: {
                name: "modelType",
                label: t("Model Type"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.MODELTYPE"),
            },
            generateMethod: {
                name: "generateMethod",
                label: t("Generate Method"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.GENERATEMETHOD"),
            },
            iterCnt: {
                name: "iterCnt",
                label: t("Iter Cnt"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.ITERCNT"),
            },
            targetDevice: {
                name: "targetDevice",
                label: t("Target Device"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.TARGETDEVICE"),
            }
        },
    }

    const {
        formField: {name, inputShape, modelType, generateMethod, iterCnt, targetDevice},
    } = form;

    const validations = Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [inputShape.name]: Yup.string().required(inputShape.errorMsg),
        [modelType.name]: Yup.string().required(modelType.errorMsg),
        [generateMethod.name]: Yup.string().required(generateMethod.errorMsg),
        [targetDevice.name]: Yup.object().required(targetDevice.errorMsg)
    })

    const [initialValues, setInitialValues] = useState({
        [name.name]: location.state.model?.name ? location.state.model.name : "",
        [inputShape.name]: location.state.model.inputShape ? location.state.model.inputShape : "",
        [modelType.name]: location.state.model.modelType ? location.state.model.modelType : "",
        [generateMethod.name]: location.state.model.generateMethod ? location.state.model.generateMethod : "",
        [iterCnt.name]: location.state.model.iterCnt ? location.state.model.iterCnt : 1,
        [targetDevice.name]: location.state.model.deviceName ? { id: location.state.model.deviceId, label: location.state.model.deviceName} : null
    })


    const {formId, formField} = form;
    const currentValidation = validations;
    /* from validation end*/

    const _modelExecution = new ModelGenExecutionConstructor(
        undefined,
        '',
        '',
        undefined,
        '',
        '',
        '',
        '',
        undefined,
        ''
    )

    const {refetch: saveModelExecution} = useQuery<any, AxiosError>(
        ['saveModelExecution'],
        () => GPUValidationService.saveModelExecution(_modelExecution),
        {
            onSuccess: (res: DefaultResponse) => {
                setApiReq(false)
                if (res.code === 200 && res.subCode === 0) {
                    goBack()
                }
            }
        }
    )

    const {refetch: getGPuValidationDevice} = useQuery<any, AxiosError>(
        ['getGPuValidationDevice'],
        () => DeviceService.getGPUValidationDevice(),
        {
            onSuccess: (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    setDeviceList(res.data)
                }
            }
        }
    )

    const getDeviceOption = (list:any[])=>{
        const deviceOption:{id:string, label:string}[] = []
        list.forEach(i => {
            deviceOption.push({
                id: i.id,
                label: i.name
            })
        })
        return deviceOption
    }


    const handleSubmit = (values: any, actions: any) => {
        let input = values.inputShape;
        if (input.startsWith("[")) {
            input = input.substring(1);
        }

        if (input.endsWith("]")) {
            input = input.substring(0, input.length - 1);
        }

        const allNumbers = input.split(',').every((el:any) => !isNaN(el) && el.trim() !== '');

        if(allNumbers){
            setFormValue(values);
            openConfirm(t("confirm.save"), "info");
        } else{
            setErrMsg(t("ERRORMESSAGE.FORM.VALID.INPUTSHAPE"))
            setOpenErrMsg(true)
        }
        console.log(input)
    }

    const handleYes = ()=>{
        if (!apiReq) {
            _modelExecution.id = location.state.model.id? location.state.model.id: undefined;
            _modelExecution.name = formValue.name
            _modelExecution.genType = location.state.genType
            _modelExecution.type = formValue.modelType
            _modelExecution.iterCnt = formValue.iterCnt
            _modelExecution.generateMethod = formValue.generateMethod
            _modelExecution.inputShape = (formValue.inputShape.startsWith('[') ? '' : '[') + formValue.inputShape + (formValue.inputShape.endsWith(']') ? '' : ']');
            _modelExecution.deviceId = formValue.targetDevice.id
            _modelExecution.deviceName = formValue.targetDevice.label
            _modelExecution.code = textValue
            setApiReq(true)
            saveModelExecution()
        }
    }

    const handleNo = () => {
        toggleDialog()
    }

    const goBack = () => {
        navigate(-1);
    }


    //수정일때 시작
    useEffect(() => {
        getGPuValidationDevice()
    }, [])

    const [textValue, setTextValue] = useState(location.state.model.codeScript || "");

    // 값이 변경될 때마다 업데이트
    const handleChange = (codeText:string) => {
        setTextValue(codeText);
    };


    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(100)

    function getStepContent(formData: any): JSX.Element {
        const {formField, values, errors, touched} = formData;
        const {name, inputShape, modelType, generateMethod, iterCnt, targetDevice} = formField;
        const {
            name: nameV,
            inputShape: inputShapeV,
            modelType: modelTypeV,
            generateMethod: generateMethodV,
            iterCnt: iterCntV,
            targetDevice: targetDeviceV
        } = values;

        return <MDBox>
            <MDBox lineHeight={0}>
                <MDTypography variant="h5">
                    {t(`title.path.${location.state.type}`, {title: location.state.menu_name})}
                </MDTypography>
            </MDBox>

            <Grid container spacing={2} mt={1.625}>
                <Grid container item={true} xs={6} display="flex" justifyContent="center" >
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={nameV}
                            placeholder={name.placeholder}
                            error={errors.name && touched.name}
                            success={nameV?.length > 0 && !errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Autocomplete
                            value={targetDeviceV}
                            options={getDeviceOption(deviceList)}
                            isOptionEqualToValue={(option, value) => value.id === option.id}
                            getOptionLabel={(option) => option.label || ""}
                            onChange={(event, newValue) => {
                                values.targetDevice = newValue
                            }}
                            size="medium"
                            fullWidth={true}
                            renderInput={(params) => (
                                <FormField {...params}
                                           type={targetDevice.type}
                                           label={targetDevice.label}
                                           name={targetDevice.name}
                                           value={targetDeviceV}
                                           placeholder={targetDevice.placeholder}
                                           error={errors.targetDevice && touched.targetDevice}
                                           success={targetDeviceV?.length > 0 && !errors.targetDevice}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>

                        <Autocomplete
                            value={{id:modelTypeV, label: getDetailByCode("GPU_MODEL_TYPE", modelTypeV)}}
                            options={getCodeListForInputByCategory("GPU_MODEL_TYPE", location.state.genType)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label || ""}
                            onChange={(event, newValue) => {
                                values.modelType = newValue.id
                            }}
                            size="medium"
                            fullWidth={true}
                            renderInput={(params) => (
                                <FormField {...params}
                                           type={modelType.type}
                                           label={modelType.label}
                                           name={modelType.name}
                                           value={modelTypeV}
                                           placeholder={modelType.placeholder}
                                           error={errors.modelType && touched.modelType}
                                           success={modelTypeV?.length > 0 && !errors.modelType}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container item={true} xs={6} display="flex" justifyContent="center" alignItems={"center"}>
                    <Grid item xs={12} sm={11}>
                        <Autocomplete
                            value={{id:generateMethodV, label:getDetailByCode("GPU_GENERATE_METHOD", generateMethodV)}}
                            options={getCodeListForInputByCategory("GPU_GENERATE_METHOD", location.state.genType)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label || ""}
                            onChange={(event, newValue) => {
                                values.generateMethod = newValue.id
                            }}
                            size="medium"
                            fullWidth={true}
                            renderInput={(params) => (
                                <FormField {...params}
                                           type={generateMethod.type}
                                           label={generateMethod.label}
                                           name={generateMethod.name}
                                           value={generateMethodV}
                                           placeholder={generateMethod.placeholder}
                                           error={errors.generateMethod && touched.generateMethod}
                                           success={generateMethodV?.length > 0 && !errors.generateMethod}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={inputShape.type}
                            label={inputShape.label}
                            name={inputShape.name}
                            value={inputShapeV}
                            placeholder={inputShape.placeholder}
                            error={errors.inputShape && touched.inputShape}
                            success={inputShapeV?.length > 0 && !errors.inputShape}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Autocomplete
                            isOptionEqualToValue={(option, value) => option === value}
                            value={iterCntV || null}
                            options={iterCntList}
                            getOptionLabel={(option) => option || 1}
                            onChange={(event, newValue) => {
                                values.iterCnt = newValue
                            }}
                            size="medium"
                            fullWidth={true}
                            renderInput={(params) => (
                                <FormField {...params}
                                           type={iterCnt.type}
                                           label={iterCnt.label}
                                           name={iterCnt.name}
                                           value={iterCntV}
                                           placeholder={iterCnt.placeholder}
                                           error={errors.iterCnt && touched.iterCnt}
                                           success={iterCntV?.length > 0 && !errors.iterCnt}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>;
    }

    return (
        <DetailLayout pageTitle={location.state.menu_name}>
            {/* 이름 입력 영역*/}
            <DetailContentsLayout>
                <Grid item>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({values, errors, touched, isSubmitting}) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{height: "100%"}}>
                                    <MDBox px={3} pt={3}>
                                        <MDBox>
                                            {getStepContent({
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                            })}
                                        </MDBox>
                                    </MDBox>
                                    <MDBox py={3} px={3} display={"flex"} justifyContent={"flex-end"}>
                                        <MDButton size={"medium"} variant={"outlined"} color={"secondary"}
                                                  onClick={() => {
                                                      goBack()
                                                  }} sx={{minWidth: buttonMinWidth}}>
                                            {t("button.cancel")}
                                        </MDButton>
                                        <MDBox display={"inline-block"} p={0.5}/>
                                        <MDButton type="submit" size={"medium"} variant={"outlined"} color={"info"}
                                                  onClick={() => {
                                                  }} sx={{minWidth: buttonMinWidth}}>
                                            {apiReq ? <Icon>loop</Icon> : t("button.save")}
                                        </MDButton>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </DetailContentsLayout>

            {
                location.state.genType === 'CG'?
                    <MDBox my={3} mx={3}>
                        <DetailContentsLayout >
                            <MDBox p={3} display="flex" flexDirection="column">
                                <Grid item xs={12} sm={6}>Code Template</Grid>
                                <Grid item sx={{fontSize:"medium"}} xs={12} sm={6}>Please enter the code of the model you want to create.</Grid>
                                <MDBox display={"inline-block"} p={0.5}/>
                                <CodeMirrorTextArea codeText={textValue} handleCodeText={handleChange}/>
                                {/*<TextareaAutosize aria-label={"maximum height"} value={textValue} onChange={handleChange} style={{ height: "50vh", resize: "none" }}/>*/}
                            </MDBox>
                        </DetailContentsLayout>
                    </MDBox> : <></>
            }

            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color="warning"
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
            />
            <Footer/>
        </DetailLayout>
    );
}

export default ModelGenView;