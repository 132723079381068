export const BRAND_NAME = "Tedworks"
export const ACCESS_TOKEN = "accessToken"
export const USER_NAME = "userName"
export const USER_ID = "userId"
export const USER_EMAIL = "userEmail"
export const PERMISSIONS = "permissions"
export const LANGUAGE = "language"

export const MESSAGE = {
    "ERROR": "오류가 발생하였습니다. 관리자에게 문의 하세요."
    , "LOGIN_FAil": "로그인에 실패하였습니다."
    , "LOGIN_EXPIRED": "로그인 정보가 만료되었습니다."
    , "AUTHENTICATION_FAIL": "인증에 실패하였습니다. 관리자에게 문의 하세요."
    , "LOGOUT": "로그아웃 되었습니다."
    , "DELETE_USER" : "계정 삭제가 완료되었습니다"
}

export const LOGIN_TYPE = {
    "LOGOUT": "logout"
    , "EXPIRED": "expired"
    , "DELETE_USER" : "deleteUser"
}

export const DATE_FORMAT = {
    "DAY":"YYYY-MM-DD",
    "DAY_DOT":"YY.MM.DD",
    "DATE_TIME":"YYYY-MM-DD HH:mm:ss",
    "DATE_TIME_UTC":"YYYY-MM-DDTHH:mm:ss"
}

export const ACCESS_TYPE = {
    "EXECUTE": "X",
    "REGISTER": "C",
    "UPDATE": "E",
    "DELETE": "D",
    "SELECT": "R"
}

export const ENUMS = [
    {type: "WORK", code: "F", codeDetail: "Fusing"},
    {type: "WORK", code: "T", codeDetail: "Test Case"},
    {type: "WORK", code: "RT", codeDetail: "Regular TestCase"},
    {type: "WORK", code: "RF", codeDetail: "Regular Fusing"},

    {type: "PLATFORM", code: "A", codeDetail: "android"},
    {type: "PLATFORM", code: "L", codeDetail: "linux"},
    {type: "PLATFORM", code: "W", codeDetail: "Window"},
    // {type: "PLATFORM", code: "P", codeDetail: "python"},
    // {type: "PLATFORM", code: "Q", codeDetail: "qnx"},
    // {type: "PLATFORM", code: "S", codeDetail: "stability"},

    // {type: "TEST_TYPE", code: "SSH", codeDetail: "BOARD_TEST_TYPE"},
    // {type: "TEST_TYPE", code: "SERIAL", codeDetail: "BOARD_TEST_TYPE"},
    // {type: "TEST_TYPE", code: "WEB", codeDetail: "PC_TEST_TYPE"},
    // {type: "TEST_TYPE", code: "MLPERF", codeDetail: "GPU_TEST_TYPE"},
    // {type: "TEST_TYPE", code: "POWER", codeDetail: "GPU_TEST_TYPE"},

    {type: "INTERFACE_TYPE", code: "SSH", codeDetail: "BOARD_INTERFACE_TYPE"},
    {type: "INTERFACE_TYPE", code: "SERIAL", codeDetail: "BOARD_INTERFACE_TYPE"},
    {type: "INTERFACE_TYPE", code: "PCIe", codeDetail: "GPU/NPU_INTERFACE_TYPE"},

    {type: "DEVICE_TEST_STATUS", code: "I", codeDetail: "대기"},
    {type: "DEVICE_TEST_STATUS", code: "A", codeDetail: "실행중"},

    {type: "RESERVATION", code: "U", codeDetail: "CONSTANT.RESERVATION.U"},
    {type: "RESERVATION", code: "P", codeDetail: "CONSTANT.RESERVATION.P"},
    {type: "RESERVATION", code: "AC", codeDetail: "CONSTANT.RESERVATION.AC"},
    {type: "RESERVATION", code: "MC", codeDetail: "CONSTANT.RESERVATION.MC"},

    {type: "RESERVATION.SEARCH.DSTATUS", code: "A", codeDetail: "CONSTANT.RESERVATION.DSTATUS.A"},
    {type: "RESERVATION.SEARCH.DSTATUS", code: "N", codeDetail: "CONSTANT.RESERVATION.DSTATUS.N"},
    {type: "RESERVATION.SEARCH.RSTATUS", code: "A", codeDetail: "CONSTANT.RESERVATION.RSTATUS.A"},
    {type: "RESERVATION.SEARCH.RSTATUS", code: "N", codeDetail: "CONSTANT.RESERVATION.RSTATUS.N"},

    {type: "BUILD", code: "I", codeDetail: "CONSTANT.BUILD.I"},
    {type: "BUILD", code: "R", codeDetail: "CONSTANT.BUILD.R"},

    {type: "DEVICE", code: "imx6", codeDetail: "imx6"},
    {type: "DEVICE", code: "imx8", codeDetail: "imx8"},
    {type: "DEVICE", code: "rasp", codeDetail: "rasp"},
    {type: "DEVICE", code: "PC", codeDetail: "PC"},

    {type: "POWER_DEVICE_TYPE", code: "GPU", codeDetail: "GPU"},

    {type: "TEST_EXECUTION_STATUS", code: "P", codeDetail: "CONSTANT.EXECUTION.STATUS.P"},
    {type: "TEST_EXECUTION_STATUS", code: "F", codeDetail: "CONSTANT.EXECUTION.STATUS.F"},
    {type: "TEST_EXECUTION_STATUS", code: "E", codeDetail: "CONSTANT.EXECUTION.STATUS.E"},
    {type: "TEST_EXECUTION_STATUS", code: "R", codeDetail: "CONSTANT.EXECUTION.STATUS.R"},
    {type: "TEST_EXECUTION_STATUS", code: "X", codeDetail: "CONSTANT.EXECUTION.STATUS.X"},

    {type: "DEVICE_TYPE", code: "B", codeDetail: "CONSTANT.DEVICE.TYPE.BOARD"},
    {type: "DEVICE_TYPE", code: "M", codeDetail: "CONSTANT.DEVICE.TYPE.MOBILE"},
    {type: "DEVICE_TYPE", code: "G", codeDetail: "CONSTANT.DEVICE.TYPE.GPU"},
    {type: "DEVICE_TYPE", code: "N", codeDetail: "CONSTANT.DEVICE.TYPE.NPU"},
    {type: "DEVICE_TYPE", code: "P", codeDetail: "CONSTANT.DEVICE.TYPE.PC"},

    {type: "NODE_TYPE", code: "B", codeDetail: "CONSTANT.NODE.TYPE.BOARD"},
    {type: "NODE_TYPE", code: "G", codeDetail: "CONSTANT.NODE.TYPE.GPU"},
    {type: "NODE_TYPE", code: "P", codeDetail: "CONSTANT.NODE.TYPE.PC"},

    {type: "AUTO_DEVICE_TYPE", code: "B", codeDetail: "CONSTANT.DEVICE.TYPE.BOARD"},

    {type: "AVAILABILITY", code: "A", codeDetail: "CONSTANT.DEVICE.STATUS.A"},
    {type: "AVAILABILITY", code: "U", codeDetail: "CONSTANT.DEVICE.STATUS.N"},

    {type: "DOMAIN_TYPE", code: "B", codeDetail: "board"},
    {type: "DOMAIN_TYPE", code: "G", codeDetail: "gpu"},
    {type: "DOMAIN_TYPE", code: "W", codeDetail: "web"},

    {type: "EXECUTION_TYPE", code: "UT", codeDetail: "User Test"},
    {type: "EXECUTION_TYPE", code: "IT", codeDetail: "Integration Test"},
    {type: "EXECUTION_TYPE", code: "F", codeDetail: "User Fusing"},
    {type: "EXECUTION_TYPE", code: "M", codeDetail: "MLPerf"},
    {type: "EXECUTION_TYPE", code: "P", codeDetail: "Power"},

    {type: "GPU_MODEL_TYPE", code: "2D", codeDetail: "2D Vision (image type)", category: "PG"},
    {type: "GPU_MODEL_TYPE", code: "3D", codeDetail: "3D Vision", category: "PG"},
    {type: "GPU_MODEL_TYPE", code: "NLP", codeDetail: "NLP", category: "PG"},
    {type: "GPU_MODEL_TYPE", code: "Custom", codeDetail: "Custom", category: "CG"},

    {type: "GPU_GENERATE_METHOD", code: "NASNet", codeDetail: "NASNet", category: "PG"},
    {type: "GPU_GENERATE_METHOD", code: "DARTS", codeDetail: "DARTS", category: "PG"},
    {type: "GPU_GENERATE_METHOD", code: "Custom", codeDetail: "Custom", category: "CG"},

    {type: "GPU_GEN_TYPE", code: "PG", codeDetail: "predefined"},
    {type: "GPU_GEN_TYPE", code: "CG", codeDetail: "custom"},



]

export const getDetailByCode = (type: string, code: string) => {
    return ENUMS.filter( i => i.type === type && i.code === code)[0]?.codeDetail
}

export const getCodeByDetail = (type: string, codeDetail: string) => {
    return ENUMS.filter( i => i.type === type && i.codeDetail === codeDetail)[0]?.code
}

export const getCodeListForSearch = (_type: string) => {
    const typeList = [{label: "options.all", id: ""}]
    ENUMS.filter( i => i.type === _type).forEach( j => {
        typeList.push({
            label: j.codeDetail,
            id: j.code
        })
    })
    return typeList
}

export const getCodeListForInsert = (_type: string) => {
    const typeList:string[] = []
    ENUMS.filter( i => i.type === _type).forEach( j => {
        typeList.push(j.codeDetail)
    })
    return typeList
}

export const getCodeListForInput = (_type: string) => {
    const typeList:{label:string, id:string}[] = []
    ENUMS.filter( i => i.type === _type).forEach( j => {
        typeList.push({
            label: j.codeDetail,
            id: j.code
        })
    })
    return typeList
}

export const getCodeListForInputByCategory = (_type: string, _category: string) => {
    const typeList:{label:string, id:string}[] = []
    ENUMS.filter( i => (i.type === _type)&&(i.category === _category) ).forEach( j => {
        typeList.push({
            label: j.codeDetail,
            id: j.code
        })
    })
    return typeList
}

export const RESERVATION_TYPE = {
    USING: "U",
    PENDING: "P",
    AUTOCOMPLETE: "A",
    MANUAL_COMPLETE: "M"
} as const

export const SSE = {
    ALARM: "alarm",
    NOTICE: "notice",
    ACTIVITY: "activity",
    TEST_EXECUTION: "testExecution",
    TEST_EXECUTION_HIST: "testExecutionHist",
    SEARCH_NEW_DUT:"newDUTData",
    MODEL_GEN_HIST: "modelGenerateHist",
    MODEL_GEN_FINISH: "modelGenerateFinish"
}