/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import MDDatePicker from "components/MDDatePicker";
import {Refresh} from "@mui/icons-material";
import MDInput from "components/MDInput";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import {useLocation, useNavigate} from "react-router-dom";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {DefaultResponse, Device} from "common/Types";
import DateUtil from "common/DateUtil";
import Autocomplete from "@mui/material/Autocomplete";
import {BRAND_NAME, getCodeListForSearch, getDetailByCode} from "common/Constant";
import MDSnackbar from "components/MDSnackbar";
import {useTranslation} from "react-i18next";
import deviceConfigService from "../../../../service/DeviceConfigService";


import MDBadge from "../../../../components/MDBadge";
import ConfirmationDialog from "../../../component/ConfirmationDialog";
import CustomTableFooter from "../../../component/CustomTableFooter";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import DeviceSearchModal from "../details/DeviceSearchModal";

function DUTListView(): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const columns = [
        { Header: t("dut.thead.name"), accessor: "name", width: "10%", sortable: false},
        { Header: t("dut.thead.type"), accessor: "type", width: "8%", sortable: false },
        { Header: t("dut.thead.node"), accessor: "nodeName", width: "10%", sortable: false },
        { Header: t("dut.thead.buildType"), accessor: "buildType", width: "12%", sortable: false },
        { Header: t("dut.thead.pdu"), accessor: "pduName", width: "10%", sortable: false },
        { Header: t("dut.thead.executionStatus"), accessor: "executionStatus", width: "10%", sortable: false },
        { Header: t("dut.thead.interfaceType"), accessor: "interfaceType", width: "10%", sortable: false },
        { Header: t("dut.thead.regDate"), accessor: "regDt", width: "15%", sortable: false },
    ];
    const [deleteDeviceList, setDeleteDeviceList] = useState<Device[]>([]);

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setErrMsg(msg)
        setErrColor(color)
        setOpenErrMsg(true)
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/

    /*paging start*/
    const entries = ["10", "25", "50", "100"];
    const [entriesPerPage, setEntriesPerPage] = useState( 10 )
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [sort, setSort] = useState("")
    const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")

    // Setting the entries starting point
    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;

    // Setting the entries ending point
    let entriesEnd;
    const totalPageLength = Math.ceil(list.length / entriesPerPage);
    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength ) {
        entriesEnd = list.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }
    /*paging end*/

    /*searching start*/
    // Search input value state
    const [deviceName, setDeviceName] = useState("")
    const [node, setNode] = useState("")
    const [buildType, setBuildType] = useState(null)
    const [deviceType, setDeviceType] = useState(null)

    // 등록일 선택 검색
    const [ fromDate, setFromDate ] = useState<Date>(null)
    const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())

    // 등록일 handle function
    const handleFromDateChange = ( _fromDate: Date) => {
        setFromDate(_fromDate)
    }
    const handleToDateChange = ( _toDate: Date) => {
        setToDate(_toDate)
    }

    const {refetch: getDeviceList} = useQuery<any, AxiosError>(
        ['getDeviceList'],
        () => deviceConfigService.getDeviceList(page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), deviceName, node, buildType?buildType.id:"", deviceType?deviceType.id:""),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    console.log(res.data.content)
                    setList(res.data.content)
                    setTotal(res.data.total)
                }
            }
        }
    )

    const {refetch: deleteDevice} = useQuery<any, AxiosError>(
        ['deleteDevice'],
        () => deviceConfigService.deleteDevice(deleteDeviceList),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    navigate(0)
                }
            }
        }
    )

// 날짜 설정 부분
    useEffect(()=> {
        if(fromDate && toDate && fromDate > toDate){
            openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
            setFromDate(undefined)
            setToDate(undefined)
        }
    },[fromDate, toDate])

    // 페이지 변경시 전체 체크 해제
    useEffect( () => {
        setCheckList( new Set<any>([]) );
    }, [ page ] );

    useEffect( () => {
        getDeviceList()
    }, [ ] );

    useEffect(() => {
        getDeviceList()
    }, [ page ]);

    useEffect(() => {
        setPage(1)
        getDeviceList()
    }, [ entriesPerPage]);

    useEffect(() => {
        setPage(1)
        getDeviceList()
    }, [deviceType]);

    useEffect( () => {
        if(deleteDeviceList && deleteDeviceList.length > 0)
            deleteDevice()
    }, [deleteDeviceList]);

    const onClickHeader = (_accessor:string) => {
        if(sort === _accessor){
            if(direction && direction === "DESC"){
                setDirection("ASC")
            }else{
                setDirection("DESC")
            }
        }else{
            setDirection("DESC")
            setSort(_accessor)
        }
    }

    const activeEnter = (ev:KeyboardEvent) => {
        if(ev.key === "Enter") {
            getDeviceList();
        }
    }

    useEffect( () => {
        if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
            getDeviceList()
        }
    }, [sort, direction]);

    // 페이지 이동
    const goToDetail = (row: any | undefined, type: string)=>{
        const url = type === "Create" ? `/device/DUT` : `/device/DUT/${row.id}`;
        navigate({pathname:url}, {state:{menu_name: "Device", device: row, type: type}})
    }

    // 체크박스 체크리스트 선택
    const [ checkList, setCheckList ] = useState(new Set<any>([]));

    // 체크박스 단일 선택
    const handleSingleCheck = ((key: any) => {
        if ( checkList.has( key ) ) {
            checkList.delete( key )
            setCheckList( new Set<any>( [ ...checkList ] ) );
        } else {
            setCheckList( new Set<any>([ ...checkList.add( key ) ] ) );
        }
    });

    const onClickSave = () => {
        goToDetail({}, "Create");
    }

    const onClickDelete = () => {
        if(checkList.size === 0){
            openAlert(t("ERRORMESSAGE.CONFIRM.DELETE.DEVICE"), "warning")
        }
        else{
            openConfirm(t("confirm.delete"), "warning")
        }
    }

    const onClickCustom = () => {
        setModalOpen(true)
    }

    const handleYes = ()=>{
        const _deleteDeviceList:Device[] = []
        checkList.forEach(value => {
            _deleteDeviceList.push({
                deviceId: value
            })
        })
        setDeleteDeviceList(_deleteDeviceList)
    }

    const handleNo = () => {
        toggleDialog()
    }

    // 체크박스 전체 선택
    const handleAllCheck =  (( checked: boolean ) => {
        if( checked ) {
            // 전체 선택 클릭 시 모든 로우의 키들 담음
            const set = new Set<any>([]);
            for (let i = 0; i < Math.min(entriesPerPage, list.length); i++ ) {
                set.add(list[i].id)
            }
            setCheckList( set );
        } else {
            setCheckList ( new Set<any>([]) );
        }
    });

    // 모달 창 관리
    const [modalOpen, setModalOpen] = useState(false);

    function handleClose() {
        setModalOpen(false)
    }

    function renderStatusIcon(status : string, usable:boolean) {
        let content;
        let color : "warning" | "success" | "error" | "primary" | "secondary" | "info" | "light" | "dark"

        if ( status === "A" ) {
            content = t("device.status.active")
            color  = "warning"
        } else {
            content = t("device.status.inactive")
            color = "secondary"
        }

        if(!usable){
            content = t("device.status.broken")
            color = "error"
        }
        return (
            <MDBadge container badgeContent={content} size="sm" color={color} variant={"contained"} minWidth={80}/>
        )
    }

    function renderBuildType(buildType: string) {
        let content
        let color : "warning" | "success" | "error" | "primary" | "secondary" | "info" | "light" | "dark"
        if (buildType === "R") {
            content = t(getDetailByCode("BUILD", buildType))
            color = "success"
        } else if (buildType === "I")  {
            content = t(getDetailByCode("BUILD", buildType))
            color = "info"
        } else {
            return null
        }
        return <MDBadge container badgeContent={content} size="sm" color={color} variant={"contained"} minWidth={110}/>
    }

    return (
        /* list, detail 화면에서 공용으로 사용하는 최상위 layout 컴포넌트 */
        <TableLayout pageTitle={location.state?.menu_name}>
            <SearchLayout>
                <MDBox  display={"flex"} justifyContent={"space-between"}>
                    <MDBox display={"flex"} width="90%" alignItems="center">
                        <MDBox display={"flex"} alignItems="center">
                            <MDDatePicker
                                value={ fromDate }
                                input = {
                                    {
                                        size : "small",
                                        height: "37px",
                                        placeholder : t("search.fromDate"),
                                    }
                                }
                                options = {
                                    {
                                        maxDate : "today",
                                        // locale : Korean,
                                    }
                                }
                                onChange = { ( currentTarget: any ) => {
                                    const target = currentTarget[0];
                                    handleFromDateChange( target );
                                }}
                            />
                            <MDBox px={0.25}>~</MDBox>
                            <MDDatePicker
                                value={ toDate }
                                input = {
                                    {
                                        size : "small",
                                        height: "37px",
                                        placeholder : t("search.toDate"),
                                    }
                                }
                                options = {
                                    {
                                        maxDate : "today",
                                        // locale : Korean,
                                    }
                                }
                                onChange = { ( currentTarget: any ) => {
                                    const target = currentTarget[0];
                                    handleToDateChange( target );
                                }}
                            />
                            <MDBox px={0.25}/>
                            <MDButton iconOnly={true} onClick={ () => { setFromDate( undefined ); setToDate( undefined ); } }>
                                <Refresh/>
                            </MDButton>
                        </MDBox>
                        <MDBox alignItems="center" width="15%" pl={3}>
                            <Autocomplete
                                fullWidth
                                disableClearable
                                value={deviceType}
                                options={getCodeListForSearch("DEVICE_TYPE").map((e: {label: string, id: string}) => {
                                    e.label = t(e.label)
                                    return e
                                })}
                                onChange={(event, newValue) => {
                                    setDeviceType(newValue)
                                }}
                                size="small"
                                renderInput={(params) => <MDInput {...params} placeholder={t("dut.search.deviceType")} onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}/>}
                            />
                        </MDBox>
                        <MDBox alignItems="center" width="15%" pl={3}>
                            <Autocomplete
                                fullWidth
                                disableClearable
                                value={buildType}
                                options={getCodeListForSearch("BUILD").map((e: {label: string, id: string}) => {
                                    e.label = t(e.label)
                                    return e
                                })}
                                onChange={(event, newValue) => {
                                    setBuildType(newValue)
                                }}
                                size="small"
                                renderInput={(params) => <MDInput {...params} placeholder={t("dut.search.buildType")} onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}/>}
                            />
                        </MDBox>
                        <MDBox alignItems="center" width="20%" pl={3}>
                            <MDInput
                                placeholder={t("search.deviceName")}
                                value={deviceName}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }: any) => {
                                    setDeviceName(currentTarget.value)
                                }}
                                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                            />
                        </MDBox>
                        <MDBox alignItems="center" width="20%" pl={3}>
                            <MDInput
                                placeholder={t("search.nodeName")}
                                value={node}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }: any) => {
                                    setNode(currentTarget.value)
                                }}
                                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox display={"flex"}>
                        <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getDeviceList()}>
                            {t("search.button.search")}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </SearchLayout>
            <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />
            {/* 테이블 전체 layout */}
            <TableContainer
                sx={{
                    boxShadow : "none", overflow : "auto",
                    "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                    "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                    ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                }}
            >
                {/* 테이블 contents */}
                <Table>
                    <MDBox component={"thead"}>
                        <TableRow>
                            <DataTableHeadCell
                                width={ "1%" }
                                align={ "center" }
                                direction={ "none" }
                                sortable={false}
                            >
                                <Checkbox size={"small"}
                                          onChange={( e) => {
                                              handleAllCheck(e.target.checked);
                                          }}
                                          checked={ checkList.size === Math.min(entriesPerPage,list.length) && list.length>0 } />

                            </DataTableHeadCell>
                            <DataTableHeadCell
                                width={"5%"}
                                align={"left"}
                                onClick={onClickHeader}
                                accessor={"no"}
                                direction={direction}
                                sort={sort}
                            >
                                {t("dut.thead.no")}
                            </DataTableHeadCell>

                            {columns.map(( column : any, index : number ) => (
                                <DataTableHeadCell
                                    key={index}
                                    width={column.width}
                                    align={"left"}
                                    onClick={onClickHeader}
                                    accessor={column.accessor}
                                    direction={direction}
                                    sort={sort}
                                    sortable={column.sortable}
                                >
                                    {column.Header}
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    </MDBox>
                    <TableBody>
                        {list.map(( row : any, index : number ) => (
                            <TableRow key={index} >
                                <DataTableBodyCell
                                    align={ "center" }
                                >
                                    <Checkbox
                                        checked={ checkList.has( row.id ) }
                                        onChange={ (e) => {
                                            handleSingleCheck( row.id );
                                        }}
                                    />
                                </DataTableBodyCell>
                                <DataTableBodyCell
                                    key={index}
                                    align={ "left" }
                                    row={row}
                                >
                                    {entriesPerPage*(page-1)+index+1}

                                </DataTableBodyCell>
                                {columns.map(( column : any, index) => (
                                    <DataTableBodyCell
                                        key={index}
                                        align={ "left" }
                                        goToDetail={goToDetail}
                                        row={row}
                                    >
                                        { column.accessor === "executionStatus" ? renderStatusIcon(row["executionStatus"], row["usable"]) :
                                            column.accessor === "buildType" ? renderBuildType(row["buildType"]) :
                                                column.accessor === "type"? t(getDetailByCode("DEVICE_TYPE", row["type"])) : row[column.accessor]
                                        }

                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {/* 테이블 footer */}
                <CustomTableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page} customBtnName={"button.deviceSearch"} onClickCustom={onClickCustom}
                                 setEntriesPerPage={setEntriesPerPage} onClickSave={onClickSave} onClickDelete={onClickDelete} onChangePage={setPage}/>

            </TableContainer>
            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color={errColor}
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
                />
            <Footer />
            <Modal
                open={modalOpen} onClose={handleClose}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={modalOpen}>
                    <MDBox
                        display="flex"
                        width="95vw" minHeight="85vh" maxHeight="80vh" shadow="xl"
                        position="fixed" top="50%" left="50%"
                        sx={{transform: "translate(-50%, -50%)"}}
                    >
                        <DeviceSearchModal closeModalBtn={handleClose}/>
                    </MDBox>
                </Fade>
            </Modal>
        </TableLayout>
    );
}

export default DUTListView;
