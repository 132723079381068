import { useEffect, ReactNode } from "react";
import DashboardNavbar from "../../../Navbars/DashboardNavbar";
import DashboardLayout from "../../DashboardLayout";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Declaring props types for PageLayout
interface Props {
  children: ReactNode;
  pageTitle: string;
}

function DetailLayout({children, pageTitle}: Props): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar  pageTitle={pageTitle}/>
      <MDBox py={3} mb={3}>
        {children}
      </MDBox>
    </DashboardLayout>
  );
}

export default DetailLayout;
