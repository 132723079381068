import StringUtil from "./StringUtil";
import StorageUtil from "./StorageUtil";
import {PERMISSIONS} from "./Constant";

const isAccessible = (menuId: number, type: string) => {
    const permissions = StorageUtil.getLocalStorage(PERMISSIONS)
    let permissionsArr = StringUtil.object2jsonString(permissions)
    permissionsArr = permissionsArr.filter((permission: any) => {
        return permission.indexOf(type) > -1 && String(permission.slice(1)) === String(menuId)
    })
    return permissionsArr.length > 0;
}

export default {
    isAccessible,
}