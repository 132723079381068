import MDBox from "components/MDBox";
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import React, {useEffect, useState} from "react";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import DetailLayout from "examples/LayoutContainers/CustomLayout/detail/DetailLayout";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import DetailContentsLayout from "examples/LayoutContainers/CustomLayout/detail/DetailContentsLayout";
import FormField from "../../component/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import {DefaultResponse, ModelExecution,} from "common/Types";
import {CurrentModelTestConstructor} from "common/Constructors";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import {BRAND_NAME, LANGUAGE} from "common/Constant";
import pxToRem from "assets/theme/functions/pxToRem";
import {useTranslation} from "react-i18next";
import ConfirmationDialog from "../../component/ConfirmationDialog";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import DeviceService from "../../../service/DeviceService";
import GPUValidationService from "../../../service/GPUValidationService";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import ModelGenerateAllHistoryModal from "../gen/history/ModelGenerateAllHistoryModal";

function CurrentModelTestView(...rest: any): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation();
    const navigate = useNavigate()
    const [formValue, setFormValue] = useState(null);
    const [apiReq, setApiReq] = useState<Boolean>(false);

    // input parameter
    const [deviceList, setDeviceList] = useState([]);

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/

    //수정 일때 list에서 전달 받은 데이터
    const [modalOpen, setModalOpen] = useState(false);

    /* from validation start*/
    const form = {
        formId: "create-model_execution-form",
        formField: {
            selectedModel: {
                name: "selectedModel",
                label: t("selectedModel"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.SELECTEDMODEL"),
            },
            refDevice: {
                name: "refDevice",
                label: t("Reference Device"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.REFERENCEDEVICE"),
            }
        },
    }

    const {
        formField: {selectedModel, refDevice},
    } = form;

    const validations = Yup.object().shape({
        [selectedModel.name]: Yup.object().required(selectedModel.errorMsg),
        [refDevice.name]: Yup.object().required(refDevice.errorMsg)
    })

    const [initialValues, setInitialValues] = useState({
        [selectedModel.name]: null,
        [refDevice.name]: null
    })


    const {formId, formField} = form;
    const currentValidation = validations;
    /* from validation end*/

    const _testExecution = new CurrentModelTestConstructor(
        undefined,
        undefined
    )

    const {refetch: executeCurrentTest} = useQuery<any, AxiosError>(
        ['executeCurrentTest'],
        () => GPUValidationService.executeCurrentTest(_testExecution),
        {
            onSuccess: (res: DefaultResponse) => {
                setApiReq(false)
                if (res.code === 200 && res.subCode === 0) {
                    goBack()
                }
            }
        }
    )

    const {refetch: getGPuValidationDevice} = useQuery<any, AxiosError>(
        ['getGPuValidationDevice'],
        () => DeviceService.getGPUValidationDevice(),
        {
            onSuccess: (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    setDeviceList(res.data)
                }
            }
        }
    )

    const getDeviceOption = (list:any[])=>{
        const deviceOption:{id:string, label:string}[] = []
        list.forEach(i => {
            deviceOption.push({
                id: i.id,
                label: i.name
            })
        })
        return deviceOption
    }


    const handleSubmit = (values: any, actions: any) => {
        setFormValue(values);
        openConfirm(t("confirm.execute"), "info");
    }

    const handleYes = ()=>{
        if (!apiReq) {
            _testExecution.modelId = formValue.selectedModel.id
            _testExecution.refDeviceId = formValue.refDevice.id
            setApiReq(true)
            executeCurrentTest()
        }
    }

    const handleNo = () => {
        toggleDialog()
    }

    const goBack = () => {
        navigate(-1);
    }


    useEffect(() => {
        getGPuValidationDevice()
    }, [])


    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(100)

    function getStepContent(formData: any): JSX.Element {
        const {formField, values, errors, touched} = formData;
        const {selectedModel, refDevice} = formField;
        const {
            selectedModel: selectedModelV,
            refDevice: refDeviceV
        } = values;

        const handleModelValue = (value:any)=>{
            console.log(value)
            values.selectedModel = value
        }

        function handleClose() {
            setModalOpen(false)
        }

        return <MDBox>
            <MDBox lineHeight={0}>
                <MDTypography variant="h5">
                    {t(`title.path.${location.state.type}`, {title: location.state.menu_name})}
                </MDTypography>
            </MDBox>

            <Grid container spacing={2} mt={1.625}>
                <Grid container item={true} display="flex" justifyContent="space-around" >
                    <Grid item xs={6} sm={6} sx={{width:"40%"}}>
                        <FormField
                            label={selectedModel.label}
                            name={selectedModel.name}
                            value={selectedModelV?.name || ""}
                            placeholder={selectedModel.placeholder}
                            error={errors.selectedModel && touched.selectedModel}
                            success={selectedModelV && !errors.selectedModel}
                            onClick={()=> setModalOpen(true)}
                        />
                    </Grid>

                    <Grid item xs={5} sm={5} sx={{width:"40%"}}>
                        <Autocomplete
                            value={refDeviceV}
                            options={getDeviceOption(deviceList)}
                            isOptionEqualToValue={(option, value) => value.id === option.id}
                            getOptionLabel={(option) => option.label || ""}
                            onChange={(event, newValue) => {
                                values.refDevice = newValue
                            }}
                            size="medium"
                            fullWidth={true}
                            renderInput={(params) => (
                                <FormField {...params}
                                           type={refDevice.type}
                                           label={refDevice.label}
                                           name={refDevice.name}
                                           value={refDeviceV}
                                           placeholder={refDevice.placeholder}
                                           error={errors.refDevice && touched.refDevice}
                                           success={refDeviceV?.length > 0 && !errors.refDevice}
                                />
                            )}
                        />
                    </Grid>

                </Grid>
            </Grid>
            <Modal
                open={modalOpen} onClose={handleClose}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={modalOpen}>
                    <MDBox
                        display="flex"
                        width="95vw" minHeight="85vh" maxHeight="80vh" shadow="xl"
                        position="fixed" top="50%" left="50%"
                        sx={{transform: "translate(-50%, -50%)"}}
                    >
                        <ModelGenerateAllHistoryModal handleModelValue={handleModelValue} closeModalBtn={handleClose}/>
                    </MDBox>
                </Fade>
            </Modal>
        </MDBox>;
    }



    return (
        <DetailLayout pageTitle={location.state.menu_name}>
            {/* 이름 입력 영역*/}
            <DetailContentsLayout>
                <Grid item>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({values, errors, touched, isSubmitting}) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{height: "100%"}}>
                                    <MDBox px={3} pt={3}>
                                        <MDBox>
                                            {getStepContent({
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                            })}
                                        </MDBox>
                                    </MDBox>
                                    <MDBox py={3} px={3} display={"flex"} justifyContent={"flex-end"}>
                                        <MDButton size={"medium"} variant={"outlined"} color={"secondary"}
                                                  onClick={() => {
                                                      goBack()
                                                  }} sx={{minWidth: buttonMinWidth}}>
                                            {t("button.cancel")}
                                        </MDButton>
                                        <MDBox display={"inline-block"} p={0.5}/>
                                        <MDButton type="submit" size={"medium"} variant={"outlined"} color={"info"}
                                                  onClick={() => {
                                                  }} sx={{minWidth: buttonMinWidth}}>
                                            {apiReq ? <Icon>loop</Icon> : t("button.execute")}
                                        </MDButton>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </DetailContentsLayout>

            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color="warning"
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
            />
            <Footer/>
        </DetailLayout>
    );
}

export default CurrentModelTestView;