import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {useQuery} from "@tanstack/react-query";
import {DefaultResponse, LineChartData, MlPerfExecutionStats} from "common/Types";
import {AxiosError} from "axios";
import React, {useEffect, useMemo, useState} from "react";
import MlPerfService from "service/MlPerfService";
import Grid from "@mui/material/Grid";
import MDDatePicker from "components/MDDatePicker";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import {Skeleton} from "@mui/material";
import DateUtil from "common/DateUtil";
import MDSnackbar from "components/MDSnackbar";
import {useTranslation} from "react-i18next";
import {BRAND_NAME} from "common/Constant";
import pxToRem from "assets/theme/functions/pxToRem";
import Icon from "@mui/material/Icon";


interface Props {
  executionId: number
  closeModalBtn: ()=>void
}

function MlPerfExecutionHistoryDetailsModal({executionId, closeModalBtn}: Props): JSX.Element {

  const {t} = useTranslation();

  const [data, setData] = useState<MlPerfExecutionStats[]>(undefined)
  const [selectedBuildName, setSelectedBuildName] = useState("")

  // 등록일 선택 검색
  const [ fromDate, setFromDate ] = useState<Date>(null)
  const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())

  // 등록일 handle function
  const handleFromDateChange = ( _fromDate: Date) => {
    setFromDate(_fromDate)
  }

  const handleToDateChange = ( _toDate: Date) => {
    setToDate(_toDate)
  }

  const [errMsg, setErrMsg] = useState<string>('');
  const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
  const [openErrMsg, setOpenErrMsg] = useState(false);
  const toggleSnackbar = () => {
    setOpenErrMsg(!openErrMsg);
  }

  const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
    setErrMsg(msg)
    setErrColor(color)
    setOpenErrMsg(true)
  }

  useEffect(()=> {
    if(fromDate && toDate && fromDate > toDate){
      openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
      setFromDate(undefined)
      setToDate(undefined)
    }
  },[fromDate, toDate])


  const {refetch: getMlPerfExecutionStats} = useQuery<DefaultResponse, AxiosError>(
    ["getMlPerfExecutionStats"],
    () => MlPerfService.getMlPerfExecutionStats(executionId, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), selectedBuildName),
    {
      onSuccess: (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          setData(res.data)
        }
      }
    }
  )

  function transformToLineChartData(rawData: MlPerfExecutionStats[]) {
    if (!rawData) {
      return null
    }
    const chartData: LineChartData = {
      labels : [],
      datasets : [
        {
          label : "Accuracy",
          color : "primary",
          data : []
        },
        {
          label : "qps",
          color : "info",
          data : []
        }
      ]
    }
    for (const data of rawData.reverse()) {
      chartData.labels.push(data.buildNo.toString())
      chartData.datasets[0].data.push(data.accuracy)
      chartData.datasets[1].data.push(data.qps)
    }
    return chartData
  }

  useEffect(() => {
    getMlPerfExecutionStats()
  }, [selectedBuildName, fromDate, toDate]);

  const buildNames: string[] = useMemo(() => {
    if (!data) {
      return [t("options.all")]
    }
    const temp: string[] = []
    for (const datum of data) {
      temp.push(datum.buildName)
    }
    temp.sort()
    return temp
  }, [data])

  const chartData = useMemo(() => (
    transformToLineChartData(data)
  ), [data]);


  return (

    <Card sx={{width : "100%", px : 3, py : 3,}}>
      <Grid container gap={3} direction={"column"}
        sx={{
        minHeight : pxToRem(200), boxShadow : "none", overflow : "auto",
        "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
        "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
        ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
      }}>

        <Grid item borderBottom={"1px solid lightgrey"} display={"flex"} justifyContent={"space-between"} pb={1}>
          <MDTypography variant={"h5"}>
            MLPerf Report
          </MDTypography>
          <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
            <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
          </MDBox>
        </Grid>

        <Grid item>
          <MDBox display={"flex"} flexDirection={"column"} p={1} gap={1.5}>
            {/*<MDBox display={"flex"} flexDirection={"row"} gap={3} alignItems={"center"}>*/}
            {/*  <MDBox display={"block"} width={"30%"}>*/}
            {/*    <MDTypography variant={"h6"}>*/}
            {/*      {t("Build Name")}*/}
            {/*    </MDTypography>*/}
            {/*  </MDBox>*/}
            {/*  <MDBox display={"flex"} flexGrow={1} justifyContent={"flex-start"}>*/}
            {/*    <Autocomplete sx={{width : "50%"}}*/}
            {/*                  inputValue={selectedBuildName}*/}
            {/*                  onInputChange={(event: any, newName: string) => {*/}
            {/*                    setSelectedBuildName(newName)*/}
            {/*                  }}*/}
            {/*                  options={buildNames}*/}
            {/*                  noOptionsText={t("options.nothing")}*/}
            {/*                  renderInput={(params) =>*/}
            {/*                    <TextField {...params} label={t("Build Name")}/>*/}
            {/*    }/>*/}
            {/*  </MDBox>*/}
            {/*</MDBox>*/}
            <MDBox display={"flex"} flexDirection={"row"} gap={3} alignItems={"center"}>
              <MDBox display={"block"} width={"30%"}>
                <MDTypography variant={"h6"}>
                  {t("Execution Date")}
                </MDTypography>
              </MDBox>
              <MDBox display={"flex"} flexGrow={1}>
                <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
                  <MDDatePicker
                    value={ fromDate }
                    input = {
                      {
                        size : "small",
                        height: "37px",
                        placeholder : t("search.fromDate"),
                      }
                    }
                    options = {
                      {
                        maxDate : "today",
                        // locale : Korean,
                      }
                    }
                    onChange = { ( currentTarget: any ) => {
                      const target = currentTarget[0];
                      handleFromDateChange( target );
                    }}
                  />
                  <MDBox px={0.25}>~</MDBox>
                  <MDDatePicker
                    value={ toDate }
                    input = {
                      {
                        size : "small",
                        height: "37px",
                        placeholder : t("search.toDate"),
                      }
                    }
                    options = {
                      {
                        maxDate : "today",
                        // locale : Korean,
                      }
                    }
                    onChange = { ( currentTarget: any ) => {
                      const target = currentTarget[0];
                      handleToDateChange( target );
                    }}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>

        <Grid item
              sx={{
                boxShadow : "none", overflow : "auto",
                "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
              }}
        >
          {data?
          <DefaultLineChart chart={chartData} />:
            <Card>
              <MDBox py={2} pr={2} pl={2}>
                <MDBox display="flex" px={1} pt={1}>
                  <MDBox width={"100%"}>
                    <Skeleton variant={"text"} width={"100%"} height={"2rem"}/>
                    <MDBox mb={2}>
                      <Skeleton variant={"text"} width={"100%"} height={"1rem"}/>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox height={"100%"}>
                  <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}/>
                </MDBox>
              </MDBox>
            </Card>
          }
        </Grid>

      </Grid>

      <MDSnackbar
        anchorOrigin={{horizontal: 'center', vertical: 'top'}}
        color={errColor}
        icon="notifications"
        title={BRAND_NAME}
        content={errMsg}
        dateTime=""
        open={openErrMsg}
        close={toggleSnackbar}
      />

    </Card>

  )
}

export default MlPerfExecutionHistoryDetailsModal