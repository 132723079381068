import {apiClient} from "service/ApiClient";
import {DefaultResponse} from "common/Types";

const getMenuList = async function () {
    return await apiClient.get<DefaultResponse>("/menu/list").then((res:any) => res.data)
}


const MenuService = {
    getMenuList
}

export default MenuService;