import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import React, {useRef} from "react";
import {XTerm} from "xterm-for-react";
import MDButton from "../../../components/MDButton";
import {useTranslation} from "react-i18next";
import Icon from "@mui/material/Icon";
import MDBadge from "../../../components/MDBadge";
import UITestScreenView from "./conponent/UiTestScreenView";
import {styled} from "@mui/material/styles";

interface Props {
    deviceId: number
    deviceName: string
    handleModal: any
}
function UserRemoteAccessModal({deviceId, deviceName, handleModal}: Props): JSX.Element {

    const {t} = useTranslation()
    const xtermRef = useRef(null)
    const socket = new WebSocket(process.env.REACT_APP_WS_URI)
    socket.onmessage = function(e){
        xtermRef.current.terminal.write(e.data)
    }


    const connect = (c:number) => {
        disconnect();
        console.log(c)

        socket.send("OPEN CONTAINER CONSOLE:" + c);
        xtermRef.current.terminal.focus()
    }

    function disconnect() {
        socket.send("DISCONNECT CONSOLE");
    }

    function picocom() {
        socket.send("PICOCOM_$");
        xtermRef.current.terminal.focus()
    }
    function simulateCtrlAX() {
        socket.send('\x01\x18');
        socket.close();
        handleModal(false)
    }

    // function coldReset() {
    //     $('#coldResetModal').modal();
    //     s.send("COLD_RESET_$");
    //     t.focus();
    //
    //     setTimeout(() => {
    //         $('#coldResetModal').modal('hide');
    //     }, 3000);
    // }

    const onData = (data:string) => {
        const c = data.charCodeAt(0);

        if (c === 3) {
            socket.send('SIGINT');
            return;
        }/*ctrl+c*/
        if (c === 26) {
            socket.send('SIGTSTP');
            return;
        }/*ctrl+z*/
        if (c === 127) {
            socket.send('\b');
            return;
        }/*backspace*/
        if (c === 27 && data.length === 1) {
            socket.send('\x1B');
            return;
        }/*esc key*/
        if (c === 27 && data.includes('[A')) {
            socket.send('\x1b[A');
            return;
        }/*up key*/
        if (c === 27 && data.includes('[B')) {
            socket.send('\x1b[B');
            return;
        }/*down key*/
        if (c === 27 && data.includes('[C')) {
            socket.send('\x1b[C');
            return;
        }/*right key*/
        if (c === 27 && data.includes('[D')) {
            socket.send('\x1b[D');
            return;
        }/*left key*/
        if (c === 27 && data.includes('OA')) {
            socket.send('\x1bOA');
            return;
        }/*vi up key*/
        if (c === 27 && data.includes('OB')) {
            socket.send('\x1bOB');
            return;
        }/*vi down key*/
        if (c === 27 && data.includes('OC')) {
            socket.send('\x1bOC');
            return;
        }/*vi right key */
        if (c === 27 && data.includes('OD')) {
            socket.send('\x1bOD');
            return;
        }/*vi left key*/
        if (c === 9) {
            socket.send('\t');
            return;
        }/*tab*/
        if (c === 13) {
            socket.send("\r");
        } else {
            socket.send(data);
        }/*enter*/
    };

    const StyledXTerm = styled(XTerm)({
        height: '100%',
        width: '100%',
        '& .terminal': {
            height: '63vh',
        },
    });


    return (
        <Card sx={{width : "100%", px : 4, py : 3}}>
            <MDBox display={"flex"} justifyContent={"space-between"} pb={1}>
                <MDBox>CONTAINER NAME : {deviceName}</MDBox>
                <MDBox style={{cursor: "pointer"}} onClick={() => simulateCtrlAX()} display="flex">
                    <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
                </MDBox>
            </MDBox>
            <MDBox display={"inline-block"} p={0.5} />
            <MDBox display={"flex"} height={"100%"}>
                <MDBox width={"60vw"} display={"flex"} flexDirection={"column"}>
                    <MDBox display={"flex"} minHeight={"40px"}>
                        <MDBadge container badgeContent={"SCREEN"} size="md" color={"info"} variant="gradient"/>
                    </MDBox>
                    <MDBox display={"inline-block"} p={0.5} />
                    <UITestScreenView/>
                </MDBox>
                <MDBox width={"40vw"}>
                    <MDBox display={"flex"} justifyContent={"space-between"}>
                        <MDBadge container badgeContent={"CONSOLE"} size="md" color={"success"} variant="gradient"/>
                        <MDBox>
                            <MDButton size={"medium"} variant={"outlined"} color={ "info"}  onClick={(e) => {connect(deviceId)}}>{t("remoteAccess.button.connect")}</MDButton>
                            <MDBox display={"inline-block"} p={0.5} />
                            <MDButton size={"medium"} variant={"outlined"} color={"warning"}  onClick={(e) => {picocom()}}>{t("remoteAccess.button.picocom")}</MDButton>
                            <MDBox display={"inline-block"} p={0.5} />
                            <MDButton size={"medium"} variant={"outlined"} color={"error"}  onClick={(e) => {simulateCtrlAX()}}>{t("remoteAccess.button.exit")}</MDButton>
                        </MDBox>
                    </MDBox>

                    <MDBox display={"inline-block"} p={0.5} />

                    <StyledXTerm ref={xtermRef} onData={onData} />
                </MDBox>
            </MDBox>

        </Card>
    )
}

export default UserRemoteAccessModal