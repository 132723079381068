import { useEffect, ReactNode } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

// Declaring props types for PageLayout
interface Props {
  children: ReactNode;
}

function SearchLayout({children}: Props): JSX.Element {
  return (
    <Card>
      <MDBox px={3} pt={2} pb={2}>
          {children}
      </MDBox>
    </Card>

  );
}

export default SearchLayout;
