import { useMemo, ReactNode } from "react";

import { Pie } from "react-chartjs-2";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// PieChart configurations
import configs from "./configs";

interface Props {
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  chart: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColors?: string[];
      data: number[];
      borderWidth? : number
    };
  };
  [key: string]: any;
}

function CustomPieChart({ title, description, height, chart }: Props): JSX.Element {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  const renderChart = (
    <MDBox py={2} pr={2} pl={2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          <MDBox>
            {title && <MDTypography variant={visualViewport.height > 1024 ? "h4" : "h6"}>{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            <Pie data={data} options={options} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

CustomPieChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
  failed : false,
};

export default CustomPieChart;