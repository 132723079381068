import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {DefaultResponse, Device, MlPerfExecution, Select} from "common/Types";
import {AxiosError} from "axios";
import MlPerfService from "service/MlPerfService";
import DateUtil from "common/DateUtil";
import {BRAND_NAME, getCodeByDetail, getDetailByCode, LANGUAGE, SSE, USER_ID} from "common/Constant";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";
import {Refresh} from "@mui/icons-material";
import MDInput from "components/MDInput";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import TableBody from "@mui/material/TableBody";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import MDSnackbar from "components/MDSnackbar";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import MlPerfExecutionHistoryStats from "../history/stats/MlPerfExecutionHistoryStats";
import Footer from "examples/Footer";
import MlPerfExecutionHistoryModal from "../history/MlPerfExecutionHistoryModal";
import {useTranslation} from "react-i18next";
import pxToRem from "assets/theme/functions/pxToRem";
import MDBadge from "components/MDBadge";
import Checkbox from "@mui/material/Checkbox";
import TestService from "../../../../service/TestService";
import ReservationService from "../../../../service/ReservationService";
import StorageUtil from "../../../../common/StorageUtil";
import Autocomplete from "@mui/material/Autocomplete";
import {TestExecutionConstructor} from "../../../../common/Constructors";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sseForUserRecoilState} from "../../../../store/recoilState";
import StringUtil from "../../../../common/StringUtil";
import MDBadgeDot from "../../../../components/MDBadgeDot";
import ConfirmationDialog from "../../../component/ConfirmationDialog";

function MlPerfExecutionListView(): JSX.Element {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const location = useLocation()
  const [list, setList] = useState<MlPerfExecution[]>([])

  const columns = [
    { Header: t("mlPerfExecution.thead.name"), accessor: "name", width: "20%", sortable: true},
    { Header: t("mlPerfExecution.thead.category"), accessor: "category", width: "10%", sortable: true},
    { Header: t("mlPerfExecution.thead.description"), accessor: "description", width: "25%", sortable: false},
    { Header: t("mlPerfExecution.thead.regUserName"), accessor: "userName", width: "10%", sortable: false },
    { Header: t("mlPerfExecution.thead.regDt"), accessor: "regDt", width: "10%", sortable: true },
    { Header: t("mlPerfExecution.thead.deviceName"), accessor: "deviceName", width: "10%", sortable: false},
  ];

  const [deleteTestExecutionList, setDeleteTestExecutionList] = useState<MlPerfExecution[]>([])

  /*paging start*/
  const entries = ["10", "25", "50", "100"];
  const [entriesPerPage, setEntriesPerPage] = useState( 10 )
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [sort, setSort] = useState("")
  const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")

  /*snackbar start*/
  const [errMsg, setErrMsg] = useState<string>('');
  const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
  const [openErrMsg, setOpenErrMsg] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState<string>('');
  const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
  const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
  const toggleSnackbar = () => {
    setOpenErrMsg(!openErrMsg);
  }
  const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
    setErrMsg(msg)
    setErrColor(color)
    setOpenErrMsg(true)
  }
  const toggleDialog = () => {
    setOpenConfirmMsg(!openConfirmMsg);
  }
  const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
    setConfirmMsg(msg)
    setConfirmColor(color)
    setOpenConfirmMsg(true)
  }
  /*snackbar end*/

  // Setting the entries starting point
  const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;

  // Setting the entries ending point
  let entriesEnd;
  const totalPageLength = Math.ceil(list.length / entriesPerPage);

  if (page === 1) {
    entriesEnd = entriesPerPage;
  } else if (page === totalPageLength ) {
    entriesEnd = list.length;
  } else {
    entriesEnd = entriesPerPage * page;
  }
  /*paging end*/

  /*searching start*/
  // Search input value state
  const [testExecutionName, setTestExecutionName] = useState("")
  const [category, setCategory] = useState("")
  const [userName, setUserName] = useState("")
  const [deviceName, setDeviceName] = useState("")
  const [reservationList, setReservationList] = useState([])
  const [deviceList, setDeviceList] = useState([])
  // const [ fromDate, setFromDate ] = useState<Date>(DateUtil.getDefaultFromDate())
  const [ fromDate, setFromDate ] = useState<Date>(null)
  const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())


  const _testExecution = new TestExecutionConstructor(
      undefined,
      undefined,
  )
  // 등록일 handle function
  const handleFromDateChange = ( _fromDate: Date) => {
    setFromDate(_fromDate)
  }

  const handleToDateChange = ( _toDate: Date) => {
    setToDate(_toDate)
  }

  useEffect(()=> {
    if(fromDate && toDate && fromDate > toDate){
      openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
      setFromDate(undefined)
      setToDate(undefined)
    }
  },[fromDate, toDate])
  /*searching end*/

  const goToDetail = (row: any | undefined, type: string) => {
    const url = type === 'Create'? `/mlperf/execution`: `/mlperf/execution/${row.id}`;
    navigate({pathname: url}, {state: {menu_name: "Mlperf Execution", mlperf_execution: row, type: type, domain: getCodeByDetail("DOMAIN_TYPE",location.pathname.split('/')[2])}})
  }

  const onClickSave = () => {
    goToDetail({},'Create')
  }

  const onClickDelete = () => {
    if (checkList.size === 0) {
      openAlert(t("ERRORMESSAGE.CONFIRM.DELETE.TESTEXECUTION"), "warning")
    } else {
      openConfirm(t("confirm.delete"), "warning")
    }
  }

  const handleYes = () => {
    const _deleteTestExecutionList:MlPerfExecution[] = []
    checkList.forEach(value => {
      _deleteTestExecutionList.push({
        id: value
      })
    })
    setDeleteTestExecutionList(_deleteTestExecutionList)
  }

  const handleNo = () => {
    toggleDialog()
  }

  const {refetch: getMlPerfExecutionList} = useQuery<DefaultResponse, AxiosError>(
    ["getMlPerfExecutionList"],
    () => MlPerfService.getMlPerfExecutionList(page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), getCodeByDetail("DOMAIN_TYPE",location.pathname.split('/')[2]),testExecutionName, category, userName, deviceName),
    {
      onSuccess: (res: DefaultResponse) => {
        if(res.code === 200 && res.subCode === 0) {
          const _list:MlPerfExecution[] = []
          res.data.content.forEach((i:MlPerfExecution)=>{
            _list.push({
              id: i.id,
              name: i.name,
              regDt: i.regDt,
              category: i.category,
              description: i.description,
              userName: i.userName,
              projectId: i.projectId,
              projectName: i.projectName,
              deviceName: i.deviceName?i.deviceName:"",
              device: null,
            })
          })
          setList(_list)
          setTotal(res.data.total)
          getMlPerfReservationListByUserId()
        }
      }
    }
  )
  const {refetch: deleteTestExecution} = useQuery<any, AxiosError>(
      ['deleteTestSuite'],
      () => TestService.deleteTestExecution(deleteTestExecutionList),
      {
        onSuccess: (res: DefaultResponse ) => {
          if(res.code === 200 && res.subCode === 0){
            navigate(0)
          }
        }
      }
  )

  const {refetch: getMlPerfReservationListByUserId} = useQuery<any, AxiosError>(
      ['getReservationListByUserId'],
      () => ReservationService.getReservationListByUserId(StorageUtil.getLocalStorage(USER_ID), "USING", getCodeByDetail("DOMAIN_TYPE", location.pathname.split("/")[2])),
      {
        onSuccess: (res: DefaultResponse ) => {
          if(res.code === 200 && res.subCode === 0){
            console.log(res.data)
            setReservationList(res.data.content)
          }
        }
      }
  )

  const {refetch: executeTest} = useQuery<any, AxiosError>(
      ['executeTest'],
      () => MlPerfService.executeMlPerfTest(_testExecution),
      {
        onSuccess: (res: DefaultResponse ) => {
          if(res.code === 200 && res.subCode === 0){
            openAlert("작업이 요청 되었습니다.", "info")
          }else{
            openAlert(res.error, "error")
          }
          getMlPerfExecutionList()
        }
      }
  )

  useEffect(() => {
    getMlPerfExecutionList()
  }, [page]);

  useEffect( () => {
    if(deleteTestExecutionList && deleteTestExecutionList.length > 0)
      deleteTestExecution()
  }, [deleteTestExecutionList]);

  useEffect(() => {
    setPage(1)
    getMlPerfExecutionList()
  }, [entriesPerPage]);

  const onClickHeader = (_accessor:string) => {
    if(sort === _accessor){
      if(direction && direction === "DESC"){
        setDirection("ASC")
      }else{
        setDirection("DESC")
      }
    }else{
      setDirection("DESC")
      setSort(_accessor)
    }
  }

  const activeEnter = (ev:KeyboardEvent) => {
    if(ev.key === "Enter") {
      getMlPerfExecutionList();
    }
  }

  useEffect( () => {
    if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
      getMlPerfExecutionList()
    }
  }, [sort, direction]);

  /* SSE 설정 */
  const setSseForUser = useSetRecoilState(sseForUserRecoilState);
  const sseForUser = useRecoilValue(sseForUserRecoilState);

  const handleConnectedEvent = (e: any) => {
    const { data: receivedData } = e;
  };

  const handleTestExecutionEvent = (e: any) => {
    const result = StringUtil.object2jsonString(e.data);
    switch (result.status) {
      case "p":
        openAlert("작업이 완료(성공)되었습니다.", "success")
        getMlPerfExecutionList()
        break
      case "f":
        openAlert("작업이 완료(실패)되었습니다.", "primary")
        getMlPerfExecutionList()
        break
    }

  };

  useEffect(()=>{
    const baseURI = process.env.REACT_APP_BACKEND_URI+process.env.REACT_APP_API_VERSION

    if(!sseForUser.sse){
      setSseForUser({sse: new EventSource(baseURI + "/sse/connect/" + StorageUtil.getLocalStorage(USER_ID))})
    }else{
      sseForUser.sse.addEventListener("Connected", handleConnectedEvent)
      sseForUser.sse.addEventListener(SSE.TEST_EXECUTION, handleTestExecutionEvent)
    }
  }, [sseForUser.sse])

  useEffect(()=>{
    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      if (sseForUser.sse) {
        sseForUser.sse.removeEventListener('Connected', handleConnectedEvent);
        sseForUser.sse.removeEventListener(SSE.TEST_EXECUTION, handleTestExecutionEvent);
      }
    };
  },[]);

  /* SSE 설정 끝 */

  function renderTestExecutionStatus(status: string): JSX.Element {
    let color: "success" | "primary" | "secondary" | "warning"
    const content = getDetailByCode("TEST_EXECUTION_STATUS", status)
    if (status === "P") {
      color = "success"
    } else if (status === "F") {
      color = "primary"
    } else if (status === "E") {
      color = "secondary"
    } else {
      color = "warning"
    }

    return (
      <MDBadge container badgeContent={content} size="sm" color={color} variant="contained" font={{color: "text"}}/>
    )
  }

  const [resultModalOpen, setResultModalOpen] = useState(false)
  const [statsModalOpen, setStatsModalOpen] = useState(false)
  const [propsId, setPropsId] = useState(undefined)

  function handleResultClick(executionId: number) {
    setPropsId(executionId)
    setResultModalOpen(true)
  }

  function handleStatsClick(executionId: number) {
    setPropsId(executionId)
    setStatsModalOpen(true)
  }

  function handleClose() {
    setResultModalOpen(false)
    setStatsModalOpen(false)
    setTimeout(() => {
      setPropsId(undefined)
    }, 150)
  }

  // 체크박스 체크리스트 선택
  const [ checkList, setCheckList ] = useState(new Set<any>([]));

  // 체크박스 단일 선택
  const handleSingleCheck = ((key: any) => {
    if ( checkList.has( key ) ) {
      checkList.delete( key )
      setCheckList( new Set<any>( [ ...checkList ] ) );
    } else {
      setCheckList( new Set<any>([ ...checkList.add( key ) ] ) );
    }
  });


  // 체크박스 전체 선택
  const handleAllCheck =  (( checked: boolean ) => {
    if( checked ) {
      // 전체 선택 클릭 시 모든 로우의 키들 담음
      const set = new Set<any>([]);
      for (let i = 0; i < Math.min(entriesPerPage, list.length); i++ ) {
        set.add( list[i].id );
      }
      setCheckList( set );
    } else {
      setCheckList ( new Set<any>([]) );
    }
  });

  useEffect( () => {
    if(reservationList && reservationList.length > 0){
      console.log(reservationList)
      const _deviceList:Select[] = []
      reservationList.forEach((i:Device) => {
        _deviceList.push({label:i.deviceName, id:i.deviceId})
      })
      setDeviceList(_deviceList)
    }
  }, [reservationList] );

  useEffect( () => {
    getMlPerfExecutionList()
  }, [] );

  useEffect(() => {
    getMlPerfExecutionList()
  }, [ page ]);

  useEffect(() => {
    setPage(1)
    getMlPerfExecutionList()
  }, [ entriesPerPage]);

  useEffect( () => {
    if(deleteTestExecutionList && deleteTestExecutionList.length > 0)
      deleteTestExecution()
  }, [deleteTestExecutionList]);

  const changeDevice = (row: MlPerfExecution, _device:any) => {
    const _list:MlPerfExecution[] = []
    list.forEach((i:MlPerfExecution) => {
      if(i.id === row.id){
        _list.push({
          id: i.id,
          name: i.name,
          regDt: i.regDt,
          category: i.category,
          description: i.description,
          userName: i.userName,
          projectId: i.projectId,
          projectName: i.projectName,
          deviceName: "",
          device: _device,
        })
      }else{
        _list.push(i)
      }
    })
    setList(_list)
  }

  const handleExecuteClick = (row:MlPerfExecution) => {
    _testExecution.id = undefined
    _testExecution.deviceId = undefined

    if(row.id && row.device && row.device.id) {
      _testExecution.id = row.id
      _testExecution.deviceId = Number(row.device.id)
      executeTest()
    }else{
      openAlert(t("ERRORMESSAGE.DEVICE.SELECT"), "warning")
    }
  }


  // URL 변경 감지를 통해 getExecutionList 호출
  useEffect(() => {
    getMlPerfExecutionList();
  }, [location.pathname]); // location.pathname이 변경될 때마다 호출

  const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(90)

  return (
    /* list, detail 화면에서 공용으로 사용하는 최상위 layout 컴포넌트 */
    <TableLayout pageTitle={location.state.menu_name}>

      {/* 검색 영역 layout */}
      <SearchLayout>
        <MDBox  display={"flex"} justifyContent={"space-between"}>
          <MDBox display={"flex"} width="90%" alignItems="center">
            <MDBox display={"flex"} alignItems="center" >
              <MDDatePicker
                value={ fromDate }
                input = {
                  {
                    size : "small",
                    height: "37px",
                    placeholder : t("search.fromDate"),
                  }
                }
                options = {
                  {
                    maxDate : "today",
                    // locale : Korean,
                  }
                }
                onChange = { ( currentTarget: any ) => {
                  const target = currentTarget[0];
                  handleFromDateChange( target );
                }}
              />
              <MDBox px={0.25}>~</MDBox>
              <MDDatePicker
                value={ toDate }
                input = {
                  {
                    size : "small",
                    height: "37px",
                    placeholder : t("search.fromDate"),
                  }
                }
                options = {
                  {
                    maxDate : "today",
                    // locale : Korean,
                  }
                }
                onChange = { ( currentTarget: any ) => {
                  const target = currentTarget[0];
                  handleToDateChange( target );
                }}
              />
              <MDBox px={0.25}/>
              <MDButton iconOnly={true} onClick={ () => { setFromDate( undefined ); setToDate( undefined ); } }>
                <Refresh/>
              </MDButton>
            </MDBox>
            <MDBox alignItems="center" width="15%" pl={3}>
              <MDInput
                placeholder={t("search.name")}
                value={testExecutionName}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setTestExecutionName(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
            <MDBox alignItems="center" width="15%" pl={3}>
              <MDInput
                placeholder={t("search.category")}
                value={category}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setCategory(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
            <MDBox alignItems="center" width="15%" minWidth={"100px"} pl={3}>
              <MDInput
                placeholder={t("search.userName")}
                value={userName}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setUserName(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
            <MDBox alignItems="center" width="15%" minWidth={"100px"} pl={3}>
              <MDInput
                placeholder={t("search.deviceName")}
                value={deviceName}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setDeviceName(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
          </MDBox>
          <MDBox display={"flex"}>
            <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getMlPerfExecutionList()}>
              {t("search.button.search")}
            </MDButton>
          </MDBox>
        </MDBox>
      </SearchLayout>

      {/* 테이블 헤더 */}
      <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />

      {/* 테이블 전체 layout */}
      <Card>
        <TableContainer
          sx={{
            boxShadow : "none", overflow : "auto",
            "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
            "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
            ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
          }}
        >
          {/* 테이블 contents */}
          <Table>
            <MDBox component={"thead"}>
              <TableRow>
                <DataTableHeadCell
                    width={ "1%" }
                    align={ "center" }
                    direction={ "none" }
                    sortable={false}
                >
                  <Checkbox size={"small"}
                            onChange={( e) => {
                              handleAllCheck(e.target.checked);
                            }}
                            checked={ checkList.size === Math.min(entriesPerPage, list.length) && list.length > 0 } />

                </DataTableHeadCell>

                {columns.map(( column : any, index : number ) => (
                  <DataTableHeadCell
                    key={index}
                    width={column.width}
                    align={"left"}
                    onClick={onClickHeader}
                    accessor={column.accessor}
                    direction={direction}
                    sort={sort}
                    sortable={column.sortable}
                  >
                    {column.Header}
                  </DataTableHeadCell>
                ))}
                <DataTableHeadCell align={"left"}>
                  {}
                </DataTableHeadCell>
              </TableRow>
            </MDBox>
            <TableBody>
              {list.map(( row : any, index : number ) => (
                <TableRow key={index} >
                  <DataTableBodyCell
                      align={ "center" }
                  >
                    <Checkbox
                        checked={ checkList.has( row.id ) }
                        onChange={ (e) => {
                          handleSingleCheck( row.id );
                        }}
                    />
                  </DataTableBodyCell>

                  {columns.map(( column : any, index) => (
                    <DataTableBodyCell
                      key={index}
                      align={ "left" }
                      goToDetail={["status","deviceName"].indexOf(column.accessor) > -1 ?null:goToDetail}
                      row={row}
                    >
                      {
                        column.accessor === "status" ? renderTestExecutionStatus(row["status"]):
                        column.accessor === "description" && row["description"] ? row["description"].split(".")[0]:
                            column.accessor === "deviceName" && row.deviceName === "" ?
                                <MDBox minWidth={"150px"}>
                                  <Autocomplete
                                      fullWidth
                                      disableClearable
                                      value={row.device}
                                      options={deviceList}
                                      onChange={(event, newValue) => {
                                        changeDevice(row, newValue)
                                      }}
                                      size="small"
                                      renderInput={(params) => <MDInput {...params} placeholder={"DEVICE"} onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}/>}
                                  />
                                </MDBox>:
                                column.accessor === "deviceName" && row.deviceName !== "" ?
                                    <MDBadgeDot size="md" color="warning" variant="gradient"
                                                badgeContent={row.deviceName} /> :
                                    row[ column.accessor ]
                      }
                    </DataTableBodyCell>
                  ))}
                  <DataTableBodyCell row={row} align={"center"}>
                    <MDBox display={"flex"} gap={1} justifyContent={"flex-end"}>
                      <MDBox>
                        <MDButton variant={"outlined"} onClick={() => handleExecuteClick(row)} size="small" color={"info"} sx={{minWidth : buttonMinWidth}}>
                          {t("button.execute")}
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <MDButton variant={"outlined"} size="small" color={"dark"} onClick={() => {handleResultClick(row["id"])}} sx={{minWidth : buttonMinWidth}}>
                        {/*  <MDButton variant={"outlined"} size="small" color={"dark"} onClick={() =>{}} sx={{minWidth : buttonMinWidth}}>*/}

                          {t("button.history")}
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <MDButton variant={"outlined"} size="small" color={"success"} onClick={() => {handleStatsClick(row["id"])}} sx={{minWidth : buttonMinWidth}}>
                          {t("button.statistics")}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </DataTableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* 테이블 footer */}
        <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page}
                     setEntriesPerPage={setEntriesPerPage} onClickSave={onClickSave} onClickDelete={onClickDelete} onChangePage={setPage}/>
      </Card>

      <MDSnackbar
        anchorOrigin={{horizontal: 'center', vertical: 'top'}}
        color={errColor}
        icon="notifications"
        title={BRAND_NAME}
        content={errMsg}
        dateTime=""
        open={openErrMsg}
        close={toggleSnackbar}
      />
      <ConfirmationDialog
          anchorOrigin={{horizontal: 'center', vertical: 'top'}}
          open={openConfirmMsg}
          close={toggleDialog}
          content={confirmMsg}
          color={confirmColor}
          handleYes={handleYes}
          handleNo={handleNo}
      />

      <Modal
        open={resultModalOpen} onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 200,
            sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
          },
        }}
        sx={{display:"flex"}}
      >
        <Fade in={resultModalOpen}>
          <MDBox
            display="flex"
            width="95vw" minHeight="70vh" maxHeight="95vh" shadow="xl"
            position="fixed" top="50%" left="50%"
            sx={{transform: "translate(-50%, -50%)"}}
          >
            <MlPerfExecutionHistoryModal executionId={propsId} closeModalBtn={handleClose}/>
          </MDBox>
        </Fade>
      </Modal>

      <Modal
        open={statsModalOpen} onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 200,
            sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
          },
        }}
      >
        <Fade in={statsModalOpen}>
          <MDBox
              display="flex"
              width="70vw" minHeight="50vh" maxHeight="80vh" shadow="xl"
              position="fixed" top="50%" left="50%"
              sx={{transform: "translate(-50%, -50%)"}}
          >
            <MlPerfExecutionHistoryStats executionId={propsId} closeModalBtn={handleClose}/>
          </MDBox>
        </Fade>
      </Modal>

      <Footer />
    </TableLayout>
  )
}

export default MlPerfExecutionListView