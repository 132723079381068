import {apiClient, jenkinsLoginApiClient} from "service/ApiClient";
import {DefaultResponse, TestExecution, TestSuite} from "common/Types";
import {TestExecutionConstructor, TestExecutionSaveConstructor, TestSuiteConstructor} from "../common/Constructors";

const getTestCaseList = async function () {
    return await apiClient.get<DefaultResponse>("/test/test-case").then((res:any) => res.data)
}

const getTestSuiteList = async function (page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, testSuiteName: string, userName: string, projectId: number) {
    return await apiClient.get<DefaultResponse>
                (`/test/test-suite?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&testSuiteName=${testSuiteName}&userName=${userName}&projectId=${projectId}`)
                .then((res:any) => res.data)
}

const getExecutionList = async function (page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, domainType: string, testExecutionName: string, testSuiteName: string, userName: string, executionType: string) {
    return await apiClient.get<DefaultResponse>
    (`/test/test-execution?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&domainType=${domainType}&testExecutionName=${testExecutionName}&testSuiteName=${testSuiteName}&userName=${userName}&executionType=${executionType}`)
      .then((res:any) => res.data)
}

const getExecutionListForRegular = async function (page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, domainType: string, testExecutionName: string, testSuiteName: string, userName: string, executionType: string) {
    return await apiClient.get<DefaultResponse>
    (`/test/test-execution-for-regular?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&domainType=${domainType}&testExecutionName=${testExecutionName}&testSuiteName=${testSuiteName}&userName=${userName}&executionType=${executionType}`)
      .then((res:any) => res.data)
}

const getExecutionHistoryList = async function (page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, domainType:string, executionType: string, name: string, buildName: string, buildNo: string, userName: string, deviceName: string) {
    return await apiClient.get<DefaultResponse>
    (`/test/test-execution-history?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}`
      +`&fromDate=${fromDate}&toDate=${toDate}&domainType=${domainType}&executionType=${executionType}&name=${name}&buildName=${buildName}&buildNo=${buildNo}&userName=${userName}&deviceName=${deviceName}`)
      .then((res:any) => res.data)
}

const getExecutionHistoryListByExecutionId = async function (executionId: number ,page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, name: string, buildName: string, buildNo: string, userName: string, deviceName: string) {
    return await apiClient.get<DefaultResponse>
    (`/test/test-execution-history/${executionId}?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}`
      +`&fromDate=${fromDate}&toDate=${toDate}&name=${name}&buildName=${buildName}&buildNo=${buildNo}&userName=${userName}&deviceName=${deviceName}`)
      .then((res:any) => res.data)
}



const getHistoryDetails = async function (historyId: number) {
    return await apiClient.get<DefaultResponse>(`/test/test-execution-history/details/${historyId}`).then((res: any) => res.data)
}

const getHistoryResults = async function (executionId: number) {
    return await apiClient.get<DefaultResponse>(`/test/test-execution-history/results/${executionId}`).then((res: any) => res.data)
}

const createTestSuite = async function (testSuite: TestSuiteConstructor) {
    return await apiClient.post<DefaultResponse>("/test/test-suite", testSuite).then((res:any) => res.data)
}

const deleteTestSuite = async function (testSuiteList: TestSuite[]) {
    return await apiClient.patch<DefaultResponse>("/test/test-suite/delete", testSuiteList).then((res:any) => res.data)
}

const getTestSuiteTestCaseList = async function (testSuiteId: number) {
    return await apiClient.get<DefaultResponse>(`/test/test-suite-test-case?testSuiteId=${testSuiteId}`).then((res:any) => res.data)
}

const deleteTestExecution = async function (testExecutionList: TestExecution[]) {
    return await apiClient.patch<DefaultResponse>("/test/test-execution/delete", testExecutionList).then((res:any) => res.data)
}

const executeTest = async function (req: TestExecutionConstructor) {
    return await apiClient.post<DefaultResponse>("/test/test-execution/execute", req).then((res:any) => res.data)
}

const startRegularTest = async function (req: TestExecutionConstructor) {
    return await apiClient.post<DefaultResponse>("/test/test-execution/startRegularTest", req).then((res:any) => res.data)
}

const stopRegularTest = async function (regularTestExecutionId: number) {
    return await apiClient.post<DefaultResponse>(`/test/test-execution/stopRegularTest`,{id: regularTestExecutionId}).then((res:any) => res.data)
}

const saveTestExecution = async function (testExecution: TestExecutionSaveConstructor) {
    return await apiClient.post<DefaultResponse>("/test/test-execution", testExecution).then((res:any) => res.data)
}

const getTestExecutionTestCaseList = async function (testExecutionId: number) {
    return await apiClient.get<DefaultResponse>(`/test/test-execution-test-case?testExecutionId=${testExecutionId}`).then((res:any) => res.data)
}


const TestService = {
    getTestCaseList,
    createTestSuite,
    getTestSuiteList,
    deleteTestSuite,
    getTestSuiteTestCaseList,
    getExecutionList,
    deleteTestExecution,
    executeTest,
    getExecutionHistoryList,
    getExecutionHistoryListByExecutionId,
    getHistoryDetails,
    getHistoryResults,
    saveTestExecution,
    getTestExecutionTestCaseList,
    startRegularTest,
    stopRegularTest,
    getExecutionListForRegular
}

export default TestService;