import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {BRAND_NAME, LANGUAGE} from "common/Constant";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Footer from "../../examples/Footer";
import DetailLayout from "../../examples/LayoutContainers/CustomLayout/detail/DetailLayout";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import pxToRem from "../../assets/theme/functions/pxToRem";
import {useQuery} from "@tanstack/react-query";
import LogAnalysisService from "../../service/LogAnalysisService";
import {DefaultResponse} from "../../common/Types";
import {AxiosError} from "axios";
import CustomBubbleChart from "./CustomMixedChart/CustomMixedChart";
import {Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import MDTypography from "../../components/MDTypography";

// import LogAnalysisService from "../../service/LogAnalysisService";

function LogAnalysisDetailView(): JSX.Element {

    const listRef = useRef(null);
    const location = useLocation();
    const [file, setFile] = useState<FormData>(null);
    const [result, setResult] = useState(undefined);
    const [allLines, setAllLines] = useState([]);
    const [selectedLines, setSelectedLines] = useState([]);
    const [execute, setExecute] = useState<boolean>(false)
    const [labels, setLabels] = useState(undefined)
    const [dataSetNormal, setDataSetNormal] = useState(undefined)
    const [dataSetWarning, setDataSetWarning] = useState(undefined)
    const [dataSetAll, setDataSetAll] = useState(undefined)

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    /*snackbar end*/

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(110)

    const onChangeFile = (file: any) => {
        const formData = new FormData();
        formData.append("analysisFile", file)
        setFile(formData)

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // 읽은 파일 내용은 reader.result에 있음
                const content: string = String(reader.result); // 파일 내용 전체
                const linesArray = content.split('\r\n').map((line, index) => ({index, text: line})); // index: 로그번호
                setAllLines(linesArray);
                setSelectedLines(linesArray);
            };
            reader.readAsText(file); // 또는 readAsDataURL 등을 사용할 수 있음
        }
    }

    const {refetch: uploadFile} = useQuery<DefaultResponse, AxiosError>(
        ["uploadFile"],
        () => LogAnalysisService.uploadFile(file),
        {
            onSuccess: (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    setResult(res.data)
                }
            }
        }
    )

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const viewAllLogs = () => {
        setSelectedLines(allLines)
    }

    const executeAnalysis = () => {
        if (file && file !== null) {
            setExecute(true)
            uploadFile()
        }

    }

    const pointClick = useCallback((elements: any, event: any) => {
        if (event[0] && event[0].element) {
            const _startIndex = event[0].element.$context.raw.x-1
            const _selectedLines = allLines.filter((data: any, index) => {
                return index >= _startIndex && index < _startIndex + 40;
            })
            setSelectedLines(_selectedLines)

            if (listRef.current) {
                listRef.current.scrollTop = 0;
            }
        }
    }, [allLines]);

    const initLogAnalysis = () => {
        setExecute(false)
        setFile(null)
        setResult(null)
        setAllLines([])
        setLabels(undefined)
        setDataSetNormal(undefined)
        setDataSetWarning(undefined)
    }

    useEffect(() => {
        const _labels: Number[] = []
        const _dataSetNormal: any = []
        const _dataSetWarning: any = []
        const _dataSetAll: any = []

        if (result) {
            result.value.forEach((data: any) => {
                const startIndex = Number(data.split(",")[0])
                const endIndex = Number(data.split(",")[1])
                const warningValue = Number(data.split(",")[2])

                _labels.push(startIndex)
                _labels.push(endIndex)
                _dataSetAll.push({x: startIndex, y: warningValue})
                if (warningValue < 0.5) {
                    _dataSetNormal.push({x: startIndex, y: warningValue, r: 2})
                } else {
                    _dataSetWarning.push({x: startIndex, y: warningValue, r: 3})
                }
            })
        }

        setLabels(_labels)
        setDataSetAll(_dataSetAll)
        setDataSetNormal(_dataSetNormal)
        setDataSetWarning(_dataSetWarning)
    }, [result]);


    return (
        <DetailLayout pageTitle={location.state.menu_name}>
            <Grid sx={{width: "100%", alignItems: "center", justifyContent: "center"}} display="flex">
                <Grid width={"85%"} display="flex" flexDirection="column">
                    <MDBox pt={3} pb={2} gap={1} sx={{height: "50%"}}>
                        <Grid item xs={12} md={12} lg={3}>
                            <Grid container gap={1}>
                                <Grid item xs={12} md={12} lg={12}>
                                    {file ?
                                        <MDBox mb={2} display={"flex"} justifyContent={"space-between"}>
                                            <MDBox display={"flex"}>
                                            </MDBox>
                                            <MDBox display={"flex"} alignItems={"center"}>
                                                <MDButton size={"medium"} variant={"outlined"} color={"info"}
                                                          onClick={viewAllLogs} sx={{minWidth: buttonMinWidth}}>
                                                    전체 로그
                                                </MDButton>
                                                <MDBox display={"inline-block"} p={0.5}/>
                                                <MDButton size={"medium"} variant={"outlined"} color={"success"}
                                                          onClick={executeAnalysis} sx={{minWidth: buttonMinWidth}}>
                                                    분석
                                                </MDButton>
                                                <MDBox display={"inline-block"} p={0.5}/>
                                                <MDButton size={"medium"} variant={"outlined"} color={"primary"}
                                                          onClick={initLogAnalysis} sx={{minWidth: buttonMinWidth}}>
                                                    초기화
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                        : <></>
                                    }
                                    {
                                        file ?
                                            <Card sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                minHeight: "300px",
                                                maxHeight: "400px"
                                            }}>
                                                <List ref={listRef}
                                                      sx={{width: "99%", overflow: "auto", margin: "10px"}}
                                                      component="nav" aria-label="mailbox folders">
                                                    {selectedLines.map((line, index) => (
                                                        <ListItem key={index}>
                                                            <MDBox display="flex">
                                                                <MDTypography color={'info'} fontWeight="medium"
                                                                              fontSize={16}>
                                                                    {line.index+1}
                                                                </MDTypography>
                                                                <MDBox display={"inline-block"} p={0.5}/>
                                                                <MDTypography color={'dark'} fontWeight="medium"
                                                                              fontSize={16}>
                                                                    {line.text}
                                                                </MDTypography>
                                                            </MDBox>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Card>
                                            :
                                            // , paddingTop: "200px"
                                            <Card sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                minHeight: "400px",
                                                justifyContent: "center"
                                            }}>
                                                <Button component="label" startIcon={<CloudUploadIcon/>}
                                                        sx={{width: "400px"}}>
                                                    <Typography variant="body1"
                                                                sx={{fontSize: "1.5rem", fontWeight: "bold"}}>
                                                        Click here to upload
                                                    </Typography>
                                                    <VisuallyHiddenInput type="file" accept={".log"}
                                                                         onChange={(e) => onChangeFile(e.target.files?.[0])}/>
                                                </Button>
                                            </Card>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    </MDBox>
                    {
                        execute ?
                            <MDBox pt={3} pb={1} gap={1}>
                                <Grid item xs={12} md={12} lg={3}>
                                    <Grid container gap={1.5}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            {
                                                result && labels && dataSetNormal && dataSetWarning ?
                                                    <CustomBubbleChart
                                                        title="Bubble Chart"
                                                        description="Users divided by regions"
                                                        chart={{
                                                            labels: labels,
                                                            datasets: [
                                                                {
                                                                    type: "bubble",
                                                                    label: "Dataset Normal",
                                                                    color: "info",
                                                                    data: dataSetNormal,
                                                                },
                                                                {
                                                                    type: 'bubble',
                                                                    label: "Dataset Warning",
                                                                    color: "primary",
                                                                    data: dataSetWarning,
                                                                },
                                                                {
                                                                    type: 'line',
                                                                    label: "Dataset All",
                                                                    color: "info",
                                                                    data: dataSetAll,
                                                                },
                                                            ],
                                                        }}
                                                        height={visualViewport.height > 1024 ? "40rem" : "30rem"}
                                                        pointClick={pointClick}

                                                    />
                                                    :
                                                    <Card>
                                                        <Skeleton variant={"text"} width={"98%"} height={"400px"}
                                                                  style={{margin: "15px"}}/>
                                                    </Card>
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </MDBox>
                            :
                            <>
                            </>
                    }
                </Grid>
            </Grid>

            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color="warning"
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
            <Footer/>
        </DetailLayout>
    );
}

export default LogAnalysisDetailView;