import {apiClient} from "service/ApiClient";
import {DefaultResponse, Node} from "../common/Types";
import {NodeConstructor} from "../common/Constructors";


const getNodeList = async function (page:number,
                                      size:number,
                                      sort:string,
                                      direction:"none" | "ASC" | "DESC",
                                      fromDate: string,
                                      toDate: string,
                                      name: string) {
    return await apiClient.get<DefaultResponse>(`/node?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&name=${name}`)
        .then((res:any) => res.data)
}
const deleteNode = async function (nodeList: Node[]) {
    return await apiClient.patch<DefaultResponse>("/node/delete", nodeList).then((res:any) => res.data)
}

const getNodeListForDeviceConfig = async function (page:number,
                                    size:number,
                                    sort:string,
                                    direction:"none" | "ASC" | "DESC",
                                    fromDate: string,
                                    toDate: string,
                                    name: string) {
    return await apiClient.get<DefaultResponse>(`/node/deviceConfig?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&name=${name}`)
        .then((res:any) => res.data)
}

const getNodeListForAuto = async function (){
    return await apiClient.get<DefaultResponse>("node/nodeListForAuto").then((res:any) => res.data)
}

const saveNode = async function (node:NodeConstructor){
    return await apiClient.post<DefaultResponse>("node", node).then((res:any)=>res.data)
}

const NodeService = {
    getNodeList,
    deleteNode,
    saveNode,
    getNodeListForDeviceConfig,
    getNodeListForAuto
}

export default NodeService;