import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import MDDatePicker from "components/MDDatePicker";
import {Refresh} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import TestService from "service/TestService";
import {DefaultResponse,} from "common/Types";
import DateUtil from "common/DateUtil";
import {BRAND_NAME, getDetailByCode, LANGUAGE, SSE, USER_ID} from "common/Constant";
import MDSnackbar from "components/MDSnackbar";
import Card from "@mui/material/Card";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sseForUserRecoilState} from "store/recoilState";
import StorageUtil from "common/StorageUtil";
import {useTranslation} from "react-i18next";
import MDTypography from "components/MDTypography";
import MDBadge from "../../../../components/MDBadge";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import Icon from "@mui/material/Icon";
import ModelGenerateHistoryDetailsModal from "./ModelGenerateHistoryDetailsModal";
import GPUValidationService from "../../../../service/GPUValidationService";
import ModelGenerateImageModal from "./ModelGenerateImageModal";

interface Props {
    validationId: number
    closeModalBtn: () => void
}
function ModelGenerateHistoryModal({validationId, closeModalBtn}: Props): JSX.Element {

    const {t} = useTranslation();


    const [list, setList] = useState([])

    const [resultModalOpen, setResultModalOpen] = useState(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [historyId, setHistoryId] = useState(undefined)
    const [historyStatus, setHistoryStatus] = useState(undefined)
    const [fileName , setFileName] = useState("");
    const [downloadState , setDownloadState] = useState(false);

    const columns = [
        { Header: t("genHistory.thead.name"), accessor: "name", sortable: false},
        { Header: t("genHistory.thead.deviceName"), accessor: "deviceName", sortable: false},
        { Header: t("genHistory.thead.genNo"), accessor: "genNo", sortable: false},
        { Header: t("genHistory.thead.iterNo"), accessor: "iterNo", sortable: false },
        { Header: t("genHistory.thead.providers"), accessor: "providers", sortable: false },
        { Header: t("genHistory.thead.status"), accessor: "status", sortable: false },
        { Header: t("genHistory.thead.startDt"), accessor: "startDt", sortable: false },
        { Header: t("genHistory.thead.endDt"), accessor: "endDt", sortable: false },
    ];
    /*paging start*/
    const entries = ["10", "25", "50", "100"];
    const [entriesPerPage, setEntriesPerPage] = useState( 10 )
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [sort, setSort] = useState("")

    const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")
    const [errMsg, setErrMsg] = useState<string>('');
    const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);

    }
    const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setErrMsg(msg)
        setErrColor(color)
        setOpenErrMsg(true)

    }
    // Setting the entries starting point

    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;
    // Setting the entries ending point
    let entriesEnd;

    const totalPageLength = Math.ceil(list.length / entriesPerPage);
    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength ) {
        entriesEnd = list.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }

    /*paging end*/
    /*searching start*/
    // 등록일 선택 검색
    const [ fromDate, setFromDate ] = useState<Date>(null);

    const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())
    // 등록일 handle function
    const handleFromDateChange = ( _fromDate: Date) => {
        setFromDate(_fromDate)

    }
    const handleToDateChange = ( _toDate: Date) => {
        setToDate(_toDate)

    }
    useEffect(()=> {
        if(fromDate && toDate && fromDate > toDate){
            openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
            setFromDate(undefined)
            setToDate(undefined)
        }
    },[fromDate, toDate])

    /*searching end*/
    function onHandleDownload(row: any){
        const fileName = row.name+'.onnx';
        setFileName(fileName)
        setDownloadState(true)
        setHistoryId(row.id)
    }

    const {refetch: getGenerateHistoryListById} = useQuery<DefaultResponse, AxiosError>(
        ["getGenerateHistoryListById"],
        () => GPUValidationService.getGenerateHistoryListByValidationId(validationId, page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate)),
        {
            onSuccess: (res: DefaultResponse) => {
                if(res.code === 200 && res.subCode === 0) {
                    console.log(res.data)
                    setList(res.data.content)
                    setTotal(res.data.total)
                }
            }
        }
    )

    const {refetch: getModelFileDownload} = useQuery<DefaultResponse, AxiosError>(
        ["getNModelFileDownload"],
        () => GPUValidationService.getNModelFileDownload(historyId),
        {
            onSuccess: (res: DefaultResponse) => {
                if(res.code === 200 && res.subCode === 0) {
                    const model = res.data
                    const byteCharacters = atob(model);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click();

                    // 다운로드 후 링크 제거
                    document.body.removeChild(link);

                    setHistoryId(undefined);
                    setDownloadState(false);
                }
            }
        }
    )


    useEffect(() => {
        if(historyId != undefined && downloadState){
            getModelFileDownload()
        }
    }, [historyId]);

    /* SSE 설정 */
    const setSseForUser = useSetRecoilState(sseForUserRecoilState);

    const sseForUser = useRecoilValue(sseForUserRecoilState);
    const handleConnectedEvent = (e: any) => {
        const { data: receivedData } = e;
        console.log(receivedData);

    };
    const handleGenerateHistoryEvent = (e: any) => {
        getGenerateHistoryListById()

    };

    useEffect(()=>{
        const baseURI = process.env.REACT_APP_BACKEND_URI+process.env.REACT_APP_API_VERSION

        if(!sseForUser.sse){
            setSseForUser({sse: new EventSource(baseURI + "/sse/connect/" + StorageUtil.getLocalStorage(USER_ID))})
        }else{
            sseForUser.sse.addEventListener("Connected", handleConnectedEvent)
            sseForUser.sse.addEventListener(SSE.MODEL_GEN_HIST, handleGenerateHistoryEvent)
        }
    }, [sseForUser.sse])

    useEffect(()=>{
        return () => {
            // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
            if (sseForUser.sse) {
                sseForUser.sse.removeEventListener('Connected', handleConnectedEvent);
                sseForUser.sse.removeEventListener(SSE.MODEL_GEN_HIST, handleGenerateHistoryEvent);
            }
        };
    },[]);

    /* SSE 설정 끝 */

    useEffect(() => {
        getGenerateHistoryListById()
    }, [page]);

    useEffect(() => {
        setPage(1)
        getGenerateHistoryListById()
    }, [entriesPerPage]);
    const onClickHeader = (_accessor:string) => {
        if(sort === _accessor){
            if(direction && direction === "DESC"){
                setDirection("ASC")
            }else{
                setDirection("DESC")
            }
        }else{
            setDirection("DESC")
            setSort(_accessor)
        }

    }
    const activeEnter = (ev:KeyboardEvent) => {
        if(ev.key === "Enter") {
            getGenerateHistoryListById();
        }

    }

    useEffect( () => {
        if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
            getGenerateHistoryListById()
        }
    }, [sort, direction]);
    function renderModelGenerateStatus(status: string): JSX.Element {
        let color: "success" | "primary" | "secondary" | "warning" | "error"
        const content = t(getDetailByCode("TEST_EXECUTION_STATUS", status))
        if (status === "P") {
            color = "success"
        } else if (status === "F") {
            color = "primary"
        } else if (status === "E") {
            color = "secondary"
        } else if (status === "X") {
            color = "error"
        } else {
            color = "warning"

        }
        return (
            <MDBadge badgeContent={content}
                     container color={color} size="sm" variant="contained"/>
        )
    }


    function onHandleImageClick(historyId: number, historyStatus: string) {
        setHistoryId(historyId)
        setHistoryStatus(historyStatus)
        setImageModalOpen(true)
    }
    function onHandleResultClick(historyId: number, historyStatus: string) {
        setHistoryId(historyId)
        setHistoryStatus(historyStatus)
        setResultModalOpen(true)
    }

    function handleClose() {
        setImageModalOpen(false)
        setResultModalOpen(false)
        setTimeout(() => {
            setHistoryId(undefined)
            setHistoryStatus(undefined)
        }, 150)
    }

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(85)

    return (

        <Card sx={{width : "100%", px : 4, py : 2,}}>
            <MDBox gap={2}
                   sx={{
                       overflow : "auto",
                       "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                       "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                       ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                   }}>

                <MDBox display={"flex"} justifyContent={"space-between"} pb={1}>
                    <MDTypography variant={"h5"}>
                        Model Generate History List
                    </MDTypography>
                    <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
                        <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
                    </MDBox>
                </MDBox>

                {/* 검색 영역 layout */}
                <SearchLayout>
                    <MDBox  display={"flex"} justifyContent={"space-between"}>
                        <MDBox display={"flex"} width="90%" alignItems="center">
                            <MDBox display={"flex"} alignItems="center" >
                                <MDDatePicker
                                    value={ fromDate }
                                    input = {
                                        {
                                            size : "small",
                                            height: "37px",
                                            placeholder : t("search.fromDate"),
                                        }
                                    }
                                    options = {
                                        {
                                            maxDate : "today",
                                            // locale : Korean,
                                        }
                                    }
                                    onChange = { ( currentTarget: any ) => {
                                        const target = currentTarget[0];
                                        handleFromDateChange( target );
                                    }}
                                />
                                <MDBox px={0.25}>~</MDBox>
                                <MDDatePicker
                                    value={ toDate }
                                    input = {
                                        {
                                            size : "small",
                                            height: "37px",
                                            placeholder : t("search.toDate"),
                                        }
                                    }
                                    options = {
                                        {
                                            maxDate : "today",
                                            // locale : Korean,
                                        }
                                    }
                                    onChange = { ( currentTarget: any ) => {
                                        const target = currentTarget[0];
                                        handleToDateChange( target );
                                    }}
                                />
                                <MDBox px={0.25}/>
                                <MDButton iconOnly={true} onClick={ () => { setFromDate( undefined ); setToDate( undefined ); } }>
                                    <Refresh/>
                                </MDButton>
                            </MDBox>

                        </MDBox>
                        <MDBox display={"flex"}>
                            <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getGenerateHistoryListById()}>
                                {t("search.button.search")}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </SearchLayout>

                {/* 테이블 헤더 */}
                <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />

                {/* 테이블 전체 layout */}
                <Card>
                    <TableContainer
                        sx={{
                            boxShadow : "none", overflow : "auto",
                            "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                            "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                            ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                        }}
                    >
                        {/* 테이블 contents */}
                        <Table>
                            <MDBox component={"thead"}>
                                <TableRow>

                                    {columns.map(( column : any, index : number ) => (
                                        <DataTableHeadCell
                                            key={index}
                                            width={column.width}
                                            align={"left"}
                                            onClick={onClickHeader}
                                            accessor={column.accessor}
                                            direction={direction}
                                            sort={sort}
                                            sortable={column.sortable}
                                        >
                                            {column.Header}
                                        </DataTableHeadCell>
                                    ))}
                                    <DataTableHeadCell align={"left"}>
                                        {}
                                    </DataTableHeadCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>
                                {list.map(( row : any, index : number ) => {
                                    return(
                                        <TableRow key={index} >

                                            {columns.map((column, colIndex) => {
                                                if (column.accessor === 'name' && (index === 0 || list[index - 1].genNo !== row.genNo)) {
                                                    return (
                                                        <DataTableBodyCell
                                                            key={`${index}_${colIndex}`}
                                                            align="left"
                                                            rowSpan={list.filter(item => (item.name === row.name && item.deviceName === row.deviceName && item.genNo === row.genNo)).length}
                                                        >
                                                            {row[column.accessor]}
                                                        </DataTableBodyCell>
                                                    );
                                                } else if (column.accessor === 'deviceName' && (index === 0 || list[index - 1].genNo !== row.genNo)) {
                                                    return (
                                                        <DataTableBodyCell
                                                            key={`${index}_${colIndex}`}
                                                            align="left"
                                                            rowSpan={list.filter(item => (item.name === row.name && item.deviceName === row.deviceName && item.genNo === row.genNo)).length}
                                                        >
                                                            {row[column.accessor]}
                                                        </DataTableBodyCell>
                                                    );
                                                } else if (column.accessor === 'genNo' && (index === 0 || list[index - 1].genNo !== row.genNo)) {
                                                    return (
                                                        <DataTableBodyCell
                                                            key={`${index}_${colIndex}`}
                                                            align="left"
                                                            rowSpan={list.filter(item => (item.name === row.name && item.deviceName === row.deviceName && item.genNo === row.genNo)).length}
                                                        >
                                                            {"Gen "+row[column.accessor]}
                                                        </DataTableBodyCell>
                                                    );
                                                } else if (column.accessor === 'genNo' || column.accessor === 'deviceName' || column.accessor === 'name') {
                                                    return null; // 이전 행과 genNo가 같으면 rowspan 적용하지 않고 빈 셀로 처리
                                                } else if (column.accessor === "iterNo") {
                                                    const iterNo = list.filter(item => item.genNo === row.genNo).indexOf(row) + 1;
                                                    return (
                                                        <DataTableBodyCell
                                                            key={`${colIndex}`}
                                                            align="left"
                                                        >
                                                            {"#" + iterNo}
                                                        </DataTableBodyCell>
                                                    );
                                                } else if (column.accessor === "providers"){
                                                    return(
                                                        <DataTableBodyCell
                                                            key={`${colIndex}`}
                                                            align="left"
                                                        >
                                                            {"CUDAExecutionProvider"}
                                                        </DataTableBodyCell>
                                                    )
                                                }
                                                return (
                                                    <DataTableBodyCell
                                                        key={`${index}_${colIndex}`}
                                                        align="left"
                                                        row={row}
                                                    >
                                                        {
                                                            column.accessor === "status" ? renderModelGenerateStatus(row[column.accessor]):
                                                                row[column.accessor]
                                                        }
                                                    </DataTableBodyCell>
                                                );
                                            })}
                                            <DataTableBodyCell row={row}>
                                                {
                                                    row["status"] === "P"?
                                                        <MDBox display={"flex"}>
                                                            <MDButton variant={"outlined"} size="small" color={"info"} onClick={() => {onHandleImageClick(row.id, row.status)}} sx={{minWidth : buttonMinWidth}}>
                                                                {t("button.graph")}
                                                            </MDButton>
                                                            <MDBox display={"inline-block"} p={0.5}/>
                                                            <MDButton variant={"outlined"} size="small" color={"dark"} onClick={() => {onHandleResultClick(row.id, row.status)}} sx={{minWidth : buttonMinWidth}}>
                                                                {t("button.result")}
                                                            </MDButton>
                                                            <MDBox display={"inline-block"} p={0.5}/>
                                                            <MDButton variant={"outlined"} size="small" color={"success"} onClick={() => {onHandleDownload(row)}} sx={{minWidth : buttonMinWidth}}>
                                                                {t("button.modelDownload")}
                                                            </MDButton>
                                                        </MDBox>:<></>
                                                }
                                            </DataTableBodyCell>
                                        </TableRow>
                                        )
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* 테이블 footer */}
                    <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page} buttonDisabled
                                 setEntriesPerPage={setEntriesPerPage} onClickSave={() => {}} onClickDelete={() => {}} onChangePage={setPage}/>
                </Card>

                <MDSnackbar
                    anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                    color={errColor}
                    icon="notifications"
                    title={BRAND_NAME}
                    content={errMsg}
                    dateTime=""
                    open={openErrMsg}
                    close={toggleSnackbar}
                />

                <Modal
                    open={resultModalOpen} onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 200,
                            sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
                        },
                    }}
                >
                    <Fade in={resultModalOpen}>
                        <MDBox
                            display="flex"
                            width="80vw" minHeight="55vh" maxHeight="95vh" shadow="xl"
                            position="fixed" top="50%" left="50%"
                            sx={{transform: "translate(-50%, -50%)"}}
                        >
                            <ModelGenerateHistoryDetailsModal historyId={historyId} historyStatus={historyStatus} closeModalBtn={handleClose}/>
                        </MDBox>
                    </Fade>
                </Modal>

                <Modal
                    open={imageModalOpen} onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 200,
                            sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
                        },
                    }}
                >
                    <Fade in={imageModalOpen}>
                        <MDBox
                            display="flex"
                            width="80vw" minHeight="55vh" maxHeight="95vh" shadow="xl"
                            position="fixed" top="50%" left="50%"
                            sx={{transform: "translate(-50%, -50%)"}}
                        >
                            <ModelGenerateImageModal historyId={historyId} historyStatus={historyStatus} closeModalBtn={handleClose}/>
                        </MDBox>
                    </Fade>
                </Modal>

            </MDBox>
        </Card>
    )
}

export default ModelGenerateHistoryModal