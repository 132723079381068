/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import React, {useEffect, useState} from "react";
import MDTypography from "components/MDTypography";
import DetailLayout from "examples/LayoutContainers/CustomLayout/detail/DetailLayout";
import DetailContentsLayout from "examples/LayoutContainers/CustomLayout/detail/DetailContentsLayout";
import {DefaultResponse} from "common/Types";
import {DeviceConfigConstructor} from "common/Constructors";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import {BRAND_NAME, getCodeListForInput,} from "common/Constant";
import {useTranslation} from "react-i18next";
import DeviceConfigService from "../../../service/DeviceConfigService";
import {LinearProgress, FormControlLabel, Radio, RadioGroup, Backdrop} from "@mui/material";
import MDInput from "../../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import DeviceInputView from "./details/DeviceInputView";
import PduService from "../../../service/PduService";
import ConfirmationDialog from "../../component/ConfirmationDialog";

function DUTConfigView(...rest: any): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    const [device, setDevice] = useState(null)
    const [formValue, setFormValue] = useState(null);
    const [pduList, setPduList] = useState([]);
    const [pduSlotList, setPduSlotList] = useState([])
    const [apiReq, setApiReq] = useState<Boolean>(false);

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }

    /*snackbar end*/
    //수정 일때 list에서 전달 받은 데이터
    const [deviceType, setDeviceType] = useState("B");
    const [pdu, setPdu] = useState({value:"", label:""});
    const [pduSlot, setPduSlot] = useState(null);

    const _deviceConfig = new DeviceConfigConstructor(
        location.state.device.id?location.state.device.id:undefined,
        "",
        undefined,
        "",
        "",
        "",
        false,
        0,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    )

    const {refetch: saveDevice} = useQuery<any, AxiosError>(
        ['saveDevice'],
        () => DeviceConfigService.saveDevice(_deviceConfig),
        {
            onSuccess: (res: DefaultResponse ) => {
                setApiReq(false)
                if(res.code === 200 && res.subCode === 0){
                    goBack()
                } else if(res.code === 200 && res.subCode === 1001){
                    setErrMsg(res.error)
                    setOpenErrMsg(true)
                }
            }
        }
    )

    const {refetch: autoSaveDevice} = useQuery<any, AxiosError>(
        ['autoSaveDevice'],
        () => DeviceConfigService.autoSaveDevice(_deviceConfig),
        {
            onSuccess: (res: DefaultResponse ) => {
                setApiReq(false)
                if(res.code === 200 && res.subCode === 0){
                    goBack()
                }
            }
        }
    )

    const {refetch: getValidPduList} = useQuery<any, AxiosError>(
        ['getValidPduList'],
        () => PduService.getValidPduList(),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    setPduList(res.data)
                }
            }
        }
    )

    const {refetch: getPduSlotList} = useQuery<any, AxiosError>(
        ['getPduSlotList'],
        () => PduService.getPduSlotList(pdu.value),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    if(location.state.type === "Edit"){
                        res.data.push(device.pduSlot)
                        const data = res.data.sort(function(a:any, b:any)  {
                            return a - b;
                        })
                        setPduSlotList(data)

                    }else{
                        setPduSlotList(res.data)
                    }
                }
            }
        }
    )

    const {refetch: getDevice} = useQuery<any, AxiosError>(
        ['getDevice'],
        () => DeviceConfigService.getDevice(location.state.device.id),
        {
            onSuccess: (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    setDevice(res.data)
                    setPdu({label:res.data.pduName, value:res.data.pduId})
                    setDeviceType(res.data.type)
                    setPduSlot(res.data.pduSlot)
                    console.log(res.data)
                }
            }
        }
    )

    const handleYes = ()=>{
        submitForm()
        toggleDialog()
    }

    const handleNo = () => {
        toggleDialog()
    }

    const setErrorDialog = (message:string) => {
        setErrMsg(t(message))
        setOpenErrMsg(true)
    }

    const handleForm = (values: any, actions: any) => {
        if(deviceType === ""){
            setErrorDialog("ERRORMESSAGE.FORM.REQUIRED.DEVICE.TYPE")
        } else if(pdu.value === "" || pduSlot === null){
            setErrorDialog("ERRORMESSAGE.FORM.REQUIRED.PDU");
        } else if(values.buildType === ""){
            setErrorDialog("ERRORMESSAGE.FORM.REQUIRED.BUILD.TYPE");
        } else if(values.node === ""){
            setErrorDialog("ERRORMESSAGE.FORM.REQUIRED.NODE");
        } else if(values.interfaceType === ""){
            setErrorDialog("ERRORMESSAGE.TESTCASE.SELECT");
        } else if(values.containerIp.split(".").some((item:any) => item === "" || isNaN(item))){
            setErrorDialog("ERRORMESSAGE.DEVICE.CONFIG.VAILDIP")
        } else{
            setFormValue(values)
            openConfirm(t("confirm.save"), "info")
        }
    }

    const submitForm = () => {
        if(deviceType === "G" || deviceType ==="N"){
            _deviceConfig.acceleratorActive = true
        }

        _deviceConfig.deviceType = deviceType
        _deviceConfig.deviceName = formValue.name
        _deviceConfig.nodeId = formValue.node.id
        _deviceConfig.buildType = formValue.buildType
        _deviceConfig.containerIp = formValue.containerIp
        _deviceConfig.usable = formValue.usable
        _deviceConfig.acceleratorCnt = formValue.acceleratorCnt
        _deviceConfig.pduSlot = pduSlot
        _deviceConfig.pduId = pdu.value
        _deviceConfig.serialNo = formValue.serialNo
        _deviceConfig.interfaceType = formValue.interfaceType
        _deviceConfig.serialCableKeyNo = formValue.serialCableKeyNo
        _deviceConfig.dutIp = formValue.dutIp
        _deviceConfig.ttyUSBSyLink = formValue.ttyUSBSyLink
        _deviceConfig.sshExternalPort = formValue.sshExternalPort
        _deviceConfig.deviceProductName = formValue.deviceProductName
        _deviceConfig.vendorId = formValue.vendorId
        _deviceConfig.productId = formValue.productId
        // console.log(_deviceConfig)
        location.state.type === "AutoCreate" ? autoSaveDevice(): saveDevice()
        setApiReq(true)
    }

    const goBack = () => {
        navigate(-1);
    }

    useEffect(()=>{
        getValidPduList();
        //수정 화면 일때
        if(location.state.type === "Edit"){
            getDevice();
        } else if(location.state.type == "AutoCreate"){
            setDevice(location.state.device);
        }
    }, [])

    useEffect(()=>{
        if(pdu.value != "") {
            getPduSlotList()
        }
    }, [pdu])

    const getPduOption = (list:any[])=>{
        const pduList:{value:string, label:string}[] = []
        list.forEach(i => {
            pduList.push({
                value: i.id,
                label: i.name
            })
        })
        return pduList
    }

    return (
        <>
            {device || location.state.type === "Create" ? <DetailLayout  pageTitle={location.state.menu_name}>
                {/* 이름 입력 영역*/}
                <DetailContentsLayout>
                    <Grid item >
                        <Card sx={{ height: "100%" }}>
                            <MDBox p={3}>
                                <MDBox >
                                    <MDBox lineHeight={0}>
                                        <MDTypography variant="h5">{t(`title.path.${location.state.type}`, {title : location.state.menu_name})}</MDTypography>
                                    </MDBox>
                                    <MDBox mt={1.625} display="flex" flexDirection="column">
                                        <Grid item xs={12} sm={6}>
                                            <RadioGroup onChange={(event, value)=>{setDeviceType(value)}} defaultValue={device?.type || "B"} row={true} sx={{ justifyContent: "space-between" }}>
                                                {
                                                    getCodeListForInput(location.state.type === "AutoCreate"? "AUTO_DEVICE_TYPE" : "DEVICE_TYPE").map((e) => (
                                                        e.id != 'P' ? <FormControlLabel key={e.id} value={e.id} control={<Radio />} label={t(e.label)}/> : <></>
                                                    ))
                                                }
                                            </RadioGroup>
                                        </Grid>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </DetailContentsLayout>

                {/* 디바이스 정보 입력 영역*/}
                <MDBox my={3} mx={3}>
                    <DetailContentsLayout>
                        <DeviceInputView deviceType={deviceType} actionType={location.state.type} device={device} handleForm={handleForm}/>
                    </DetailContentsLayout>
                </MDBox>
                {/*{pdu 정보입력}*/}
                <MDBox my={3} mx={3}>
                    <DetailContentsLayout >
                        <MDBox p={3} display="flex" flexDirection="column">
                            <Grid item xs={12} sm={6}>PDU</Grid>
                            <Grid  display={"flex"} justifyContent={"space-around"} >
                                <Autocomplete
                                    value={location.state.type==="Edit" || location.state.type==="AutoCreate" || pdu.value != ""?{value:pdu.value, label:pdu.label}:null}
                                    options={getPduOption(pduList)}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    getOptionLabel={(option) => option.label || ""}
                                    onChange={(event, newValue) => {
                                        setPdu({ value: newValue.value, label: newValue.label });
                                    }}
                                    size="small"
                                    fullWidth={true}
                                    renderInput={(params) => <MDInput {...params} placeholder={"PDU"} />}
                                />
                                <MDBox display={"inline-block"} p={0.5} />
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) => option === value}
                                    value={pduSlot || null}
                                    options={pduSlotList}
                                    getOptionLabel={(option) => option || ""}
                                    onChange={(event, newValue) => {
                                        setPduSlot(newValue)
                                    }}
                                    size="small"
                                    fullWidth={true}
                                    renderInput={(params) => <MDInput {...params} placeholder={"PDU SLOT"} />}
                                />
                            </Grid>
                        </MDBox>
                    </DetailContentsLayout>

                </MDBox>
                <MDSnackbar
                    anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                    color="warning"
                    icon="notifications"
                    title={BRAND_NAME}
                    content={errMsg}
                    dateTime=""
                    open={openErrMsg}
                    close={toggleSnackbar}
                />
                <ConfirmationDialog
                    anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                    open={openConfirmMsg}
                    close={toggleDialog}
                    content={confirmMsg}
                    color={confirmColor}
                    handleYes={handleYes}
                    handleNo={handleNo}
                />
                <Footer />
                    { apiReq?
                        <Backdrop
                            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', alignItems: 'flex-start', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={true}
                        >
                            <MDBox width={"100%"}>
                                <LinearProgress variant={"indeterminate"} color={"info"}/>
                            </MDBox>
                        </Backdrop>:<></>
                    }
            </DetailLayout>:
                <></>}
        </>
    );
}

export default DUTConfigView;
