import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import MDDatePicker from "components/MDDatePicker";
import {Refresh} from "@mui/icons-material";
import MDInput from "components/MDInput";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {useLocation} from "react-router-dom";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import TestService from "service/TestService";
import {DefaultResponse,} from "common/Types";
import DateUtil from "common/DateUtil";
import {
  BRAND_NAME,
  getCodeByDetail,
  getCodeListForSearch,
  getDetailByCode,
  LANGUAGE,
  SSE,
  USER_ID
} from "common/Constant";
import MDSnackbar from "components/MDSnackbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import TestExecutionHistoryDetailsModal from "../details/TestExecutionHistoryDetailsModal";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sseForUserRecoilState} from "store/recoilState";
import StorageUtil from "common/StorageUtil";
import {useTranslation} from "react-i18next";
import MDBadge from "../../../../../components/MDBadge";
import pxToRem from "../../../../../assets/theme/functions/pxToRem";
import MlPerfExecutionHistoryStats from "../../../../mlperf/execution/history/stats/MlPerfExecutionHistoryStats";
import MlPerfExecutionHistoryDetailsModal
  from "../../../../mlperf/execution/history/details/MlPerfExecutionHistoryDetailsModal";
import PowerExecutionHistoryDetailsModal
  from "../../../../power/execution/history/details/PowerExecutionHistoryDetailsModal";
import Autocomplete from "@mui/material/Autocomplete";

function TestExecutionHistoryListView(): JSX.Element {

  const {t} = useTranslation();

  const location = useLocation()
  const [list, setList] = useState([])

  const columns = [
    { Header: t("testHistory.thead.executionType"), accessor: "executionType", sortable: true},
    { Header: t("testHistory.thead.name"), accessor: "name", sortable: true},
    { Header: t("testHistory.thead.buildName"), accessor: "buildName", sortable: true},
    { Header: t("testHistory.thead.buildNo"), accessor: "buildNo", sortable: true},
    { Header: t("testHistory.thead.userName"), accessor: "userName", sortable: true },
    { Header: t("testHistory.thead.deviceName"), accessor: "deviceName", sortable: true },
    { Header: t("testHistory.thead.status"), accessor: "status", sortable: true },
    { Header: t("testHistory.thead.startDt"), accessor: "startDt", sortable: true },
    { Header: t("testHistory.thead.endDt"), accessor: "endDt", sortable: true },
  ];

  /*paging start*/
  const entries = ["10", "25", "50", "100"];
  const [entriesPerPage, setEntriesPerPage] = useState( 10 )
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [sort, setSort] = useState("")
  const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")

  const [errMsg, setErrMsg] = useState<string>('');
  const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
  const [openErrMsg, setOpenErrMsg] = useState(false);
  const toggleSnackbar = () => {
    setOpenErrMsg(!openErrMsg);
  }

  const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
    setErrMsg(msg)
    setErrColor(color)
    setOpenErrMsg(true)
  }

  // Setting the entries starting point
  const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;

  // Setting the entries ending point
  let entriesEnd;
  const totalPageLength = Math.ceil(list.length / entriesPerPage);

  if (page === 1) {
    entriesEnd = entriesPerPage;
  } else if (page === totalPageLength ) {
    entriesEnd = list.length;
  } else {
    entriesEnd = entriesPerPage * page;
  }
  /*paging end*/

  /*searching start*/
  // Search input value state
  const [executionType, setExecutionType] = useState(null)
  const [name, setName] = useState("")
  const [buildName, setBuildName] = useState("")
  const [buildNo, setBuildNo] = useState("")
  const [userName, setUserName] = useState("")
  const [deviceName, setDeviceName] = useState("")

  // 등록일 선택 검색
  // const [ fromDate, setFromDate ] = useState<Date>(DateUtil.getDefaultFromDate())
  const [ fromDate, setFromDate ] = useState<Date>(null)
  const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())
  // 등록일 handle function
  const handleFromDateChange = ( _fromDate: Date) => {
    setFromDate(_fromDate)
  }

  /* SSE 설정 */
  const setSseForUser = useSetRecoilState(sseForUserRecoilState);
  const sseForUser = useRecoilValue(sseForUserRecoilState);

  const handleConnectedEvent = (e: any) => {
    const { data: receivedData } = e;
    console.log(receivedData);
  };

  const handleTestExecutionHistoryEvent = (e: any) => {
    const { data: receivedData } = e;
    console.log(receivedData);
    getExecutionHistoryList();
  };

  useEffect(()=>{
    const baseURI = process.env.REACT_APP_BACKEND_URI+process.env.REACT_APP_API_VERSION
    if(!sseForUser.sse){
      setSseForUser({sse: new EventSource(baseURI + "/sse/connect/" + StorageUtil.getLocalStorage(USER_ID))})
    }else{
      sseForUser.sse.addEventListener("Connected", handleConnectedEvent)
      sseForUser.sse.addEventListener(SSE.TEST_EXECUTION_HIST, handleTestExecutionHistoryEvent)
    }
  }, [sseForUser.sse])

  useEffect(()=>{
    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      if (sseForUser.sse) {
        sseForUser.sse.removeEventListener('Connected', handleConnectedEvent);
        sseForUser.sse.removeEventListener(SSE.TEST_EXECUTION_HIST, handleTestExecutionHistoryEvent);
      }
    };
  },[]);

  /* SSE 설정 끝 */

  const handleToDateChange = ( _toDate: Date) => {
    setToDate(_toDate)
  }

  useEffect(()=> {
    if(fromDate && toDate && fromDate > toDate){
      openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
      setFromDate(undefined)
      setToDate(undefined)
    }
  },[fromDate, toDate])
  /*searching end*/

  const {refetch: getExecutionHistoryList} = useQuery<DefaultResponse, AxiosError>(
    ["getExecutionHistoryList"],
    () => TestService.getExecutionHistoryList(page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), getCodeByDetail("DOMAIN_TYPE",location.pathname.split('/')[2]), executionType?executionType.id:"", name, buildName, buildNo, userName, deviceName),
    {
      onSuccess: (res: DefaultResponse) => {
        if(res.code === 200 && res.subCode === 0) {
          console.log(res.data)
          setList(res.data.content)
          setTotal(res.data.total)
        }
      }
    }
  )

  useEffect(() => {
    getExecutionHistoryList()
  }, [page]);

  useEffect(() => {
    setPage(1)
    getExecutionHistoryList()
  }, [entriesPerPage]);

  const onClickHeader = (_accessor:string) => {
    if(sort === _accessor){
      if(direction && direction === "DESC"){
        setDirection("ASC")
      }else{
        setDirection("DESC")
      }
    }else{
      setDirection("DESC")
      setSort(_accessor)
    }
  }

  const activeEnter = (ev:KeyboardEvent) => {
    if(ev.key === "Enter") {
      getExecutionHistoryList();
    }
  }

  useEffect( () => {
    if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
      getExecutionHistoryList()
    }
  }, [sort, direction]);

  function renderTestExecutionStatus(status: string): JSX.Element {
    let color: "success" | "primary" | "secondary" | "warning" | "error"
    const content = t(getDetailByCode("TEST_EXECUTION_STATUS", status))
    if (status === "P") {
      color = "success"
    } else if (status === "F") {
      color = "primary"
    } else if (status === "E") {
      color = "secondary"
    }else if (status === "X") {
      color = "error"
    } else {
      color = "warning"
    }

    return (
      <MDBadge badgeContent={content}
               container color={color} size="sm" variant="contained"/>
    )
  }

  // 모달 상태 처리 변수
  const [testModalOpen, setTestModalOpen] = useState(false);
  const [mlperfModalOpen, setMlperfModalOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [powerModalOpen, setPowerModalOpen] = useState(false);
  const [historyId, setHistoryId] = useState(undefined)
  const [historyStatus, setHistoryStatus] = useState(undefined)
  const [buildNameProps, setBuildNameProps] = useState(undefined)

  function onHandleClick(historyId: number, historyStatus: string, buildName: string, executionType: string) {
    if(historyStatus === "X"){
      setErrMsg(t("ERRORMESSAGE.TEST.RESULT.ERROR"))
      setOpenErrMsg(true)
    } else if(historyStatus === "F" && (['M','P'].includes(executionType))){
      setErrMsg(t("ERRORMESSAGE.TEST.RESULT.FAIL"))
      setOpenErrMsg(true)
    } else{
      setHistoryId(historyId)
      setHistoryStatus(historyStatus)
      setBuildNameProps(buildName)
      if (executionType === "P") setPowerModalOpen(true)
      else if (executionType === "M") setMlperfModalOpen(true)
      else setTestModalOpen(true)
    }
  }

  function handleStatsClick(executionId: number, buildName: string) {
    setHistoryId(executionId)
    setBuildNameProps(buildName)
    setStatusModalOpen(true)
  }

  function onHandleClickLog(row: any){
    const popupWidth = 1400;
    const popupHeight = 800;
    const popupX = (window.screen.width / 2) - (popupWidth / 2);
    const popupY= (window.screen.height / 2) - (popupHeight / 2);
    let hostname = window.location.hostname
    if(hostname !== '192.168.0.11'){
      hostname = process.env.REACT_APP_JENKINS
    }

    window.open(`http://${hostname}:8100/job/${row.buildName}/${row.buildNo}/console`, 'JENKINS_CONSOLE', 'status=no, height=' + popupHeight  + ', width=' + popupWidth  + ', left='+ popupX + ', top='+ popupY);
  }

  function handleClose() {
    setPowerModalOpen(false)
    setMlperfModalOpen(false)
    setTestModalOpen(false)
    setStatusModalOpen(false)
    setTimeout(() => {
      setHistoryId(undefined)
      setHistoryStatus(undefined)
    }, 150)
  }

  const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(80)

  // URL 변경 감지를 통해 getExecutionList 호출
  useEffect(() => {
    setPage(1);
    getExecutionHistoryList();
  }, [location.pathname]); // location.pathname이 변경될 때마다 호출

  return (
    /* list, detail 화면에서 공용으로 사용하는 최상위 layout 컴포넌트 */
<TableLayout pageTitle={location.state?.menu_name}>

      {/* 검색 영역 layout */}
      <SearchLayout>
        <MDBox  display={"flex"} justifyContent={"space-between"}>
          <MDBox display={"flex"} width="90%" alignItems="center">
            <MDBox display={"flex"} alignItems="center" >
              <MDDatePicker
                value={ fromDate }
                input = {
                  {
                    size : "small",
                    height: "37px",
                    placeholder : t("search.fromDate"),
                  }
                }
                options = {
                  {
                    maxDate : "today",
                    // locale : Korean,
                  }
                }
                onChange = { ( currentTarget: any ) => {
                  const target = currentTarget[0];
                  handleFromDateChange( target );
                }}
              />
              <MDBox px={0.25}>~</MDBox>
              <MDDatePicker
                value={ toDate }
                input = {
                  {
                    size : "small",
                    height: "37px",
                    placeholder : t("search.fromDate"),
                  }
                }
                options = {
                  {
                    maxDate : "today",
                    // locale : Korean,
                  }
                }
                onChange = { ( currentTarget: any ) => {
                  const target = currentTarget[0];
                  handleToDateChange( target );
                }}
              />
              <MDBox px={0.25}/>
              <MDButton iconOnly={true} onClick={ () => { setFromDate( undefined ); setToDate( undefined ); } }>
                <Refresh/>
              </MDButton>
            </MDBox>
            <MDBox alignItems="center" width="15%" pl={3}>
              <Autocomplete
                  fullWidth
                  disableClearable
                  value={executionType}
                  options={getCodeListForSearch("EXECUTION_TYPE").map((e: {label: string, id: string}) => {
                    if(!e.id) e.label = t(e.label)
                    return e
                  })}
                  onChange={(event, newValue) => {
                    setExecutionType(newValue)
                  }}
                  size="small"
                  renderInput={(params) => <MDInput {...params} placeholder={t("search.executionType")} onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}/>}
              />
            </MDBox>
            <MDBox alignItems="center" width="20%" pl={3}>
              <MDInput
                placeholder={t("search.name")}
                value={name}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setName(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
            <MDBox alignItems="center" width="15%" pl={3}>
              <MDInput
                placeholder={t("search.buildName")}
                value={buildName}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setBuildName(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
            <MDBox alignItems="center" width="15%" minWidth={"100px"} pl={3}>
              <MDInput
                placeholder={t("search.buildNo")}
                value={buildNo}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setBuildNo(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
            <MDBox alignItems="center" width="15%" minWidth={"100px"} pl={3}>
              <MDInput
                placeholder={t("search.userName")}
                value={userName}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setUserName(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
            <MDBox alignItems="center" width="15%" minWidth={"100px"} pl={3}>
              <MDInput
                placeholder={t("search.deviceName")}
                value={deviceName}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setDeviceName(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
          </MDBox>
          <MDBox display={"flex"}>
            <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getExecutionHistoryList()}>
              {t("search.button.search")}
            </MDButton>
          </MDBox>
        </MDBox>
      </SearchLayout>

      {/* 테이블 헤더 */}
      <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />

      {/* 테이블 전체 layout */}
      <Card>
        <TableContainer
          sx={{
            boxShadow : "none", overflow : "auto",
            "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
            "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
            ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
          }}
        >
          {/* 테이블 contents */}
          <Table>
            <MDBox component={"thead"}>
              <TableRow>

                {columns.map(( column : any, index : number ) => (
                  <DataTableHeadCell
                    key={index}
                    width={column.width}
                    align={"left"}
                    onClick={onClickHeader}
                    accessor={column.accessor}
                    direction={direction}
                    sort={sort}
                    sortable={column.sortable}
                  >
                    {column.Header}
                  </DataTableHeadCell>
                ))}
                <DataTableHeadCell align={"left"}>
                  {}
                </DataTableHeadCell>
              </TableRow>
            </MDBox>
            <TableBody>
              {list.map(( row : any, index : number ) => (
                <TableRow key={index} >

                  {columns.map(( column : any, index) => (
                    <DataTableBodyCell
                      key={index}
                      align={ "left" }
                      row={row}
                    >
                      {
                        column.accessor === "workType" ? getDetailByCode("WORK", row["workType"]):
                          column.accessor === "platformType" ? getDetailByCode("PLATFORM", row["platformType"]):
                            column.accessor === "status" ? renderTestExecutionStatus(row[column.accessor]):
                                column.accessor == "executionType" ? getDetailByCode("EXECUTION_TYPE", row["executionType"]):
                                    row[ column.accessor ]
                      }
                    </DataTableBodyCell>
                  ))}
                  <DataTableBodyCell row={row}>
                    <MDBox display={"flex"}>
                      <MDButton variant={"outlined"} size="small" color={"dark"} onClick={() => {onHandleClick(row["id"], row["status"], row["buildName"], row["executionType"])}} sx={{minWidth : buttonMinWidth}}>
                        {t("button.result")}
                      </MDButton>
                      <MDBox display={"inline-block"} p={0.5}/>
                      {
                        row["executionType"] === "M"?
                            <MDButton variant={"outlined"} size="small" color={"success"} onClick={() => {handleStatsClick(row["executionId"], row["buildName"])}} sx={{minWidth : buttonMinWidth}}>
                              {t("button.statistics")}
                            </MDButton>
                            :<MDButton variant={"outlined"} size="small" color={"success"} onClick={() => {onHandleClickLog(row)}} sx={{minWidth : buttonMinWidth}}>
                              {t("button.log")}
                            </MDButton>
                      }
                    </MDBox>
                  </DataTableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* 테이블 footer */}
        <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page} buttonDisabled
                     setEntriesPerPage={setEntriesPerPage} onClickSave={() => {}} onClickDelete={() => {}} onChangePage={setPage}/>
      </Card>

      <MDSnackbar
        anchorOrigin={{horizontal: 'center', vertical: 'top'}}
        color={errColor}
        icon="notifications"
        title={BRAND_NAME}
        content={errMsg}
        dateTime=""
        open={openErrMsg}
        close={toggleSnackbar}
      />

      <Modal
        open={testModalOpen} onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 200,
            sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
          },
        }}
      >
        <Fade in={testModalOpen}>
          <MDBox
              display="flex"
              width="85vw" minHeight="85vh" maxHeight="90vh" shadow="xl"
              position="fixed" top="50%" left="50%"
              sx={{transform: "translate(-50%, -50%)"}}
          >
            <TestExecutionHistoryDetailsModal historyId={historyId} historyStatus={historyStatus} closeModalBtn={handleClose}/>
          </MDBox>
        </Fade>
      </Modal>
      <Modal
          open={mlperfModalOpen} onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 200,
              sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
            },
          }}
      >
        <Fade in={mlperfModalOpen}>
          <MDBox
              display="flex"
              width="70vw" minHeight="55vh" maxHeight="95vh" shadow="xl"
              position="fixed" top="50%" left="50%"
              sx={{transform: "translate(-50%, -50%)"}}
          >
            <MlPerfExecutionHistoryDetailsModal historyId={historyId} historyStatus={historyStatus} buildName={buildNameProps} closeModalBtn={handleClose}/>
          </MDBox>
        </Fade>
      </Modal>
      <Modal
          open={statusModalOpen} onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 200,
              sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
            },
          }}
      >
        <Fade in={statusModalOpen}>
          <MDBox
              display="flex"
              width="70vw" minHeight="50vh" maxHeight="90vh" shadow="xl"
              position="fixed" top="50%" left="50%"
              sx={{transform: "translate(-50%, -50%)"}}
          >
            <MlPerfExecutionHistoryStats executionId={historyId} closeModalBtn={handleClose}/>
          </MDBox>
        </Fade>
      </Modal>
      <Modal
          open={powerModalOpen} onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 200,
              sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
            },
          }}
      >
        <Fade in={powerModalOpen}>
          <MDBox
              display="flex"
              width="70vw" minHeight="55vh" maxHeight="95vh" shadow="xl"
              position="fixed" top="50%" left="50%"
              sx={{transform: "translate(-50%, -50%)"}}
          >
            <PowerExecutionHistoryDetailsModal historyId={historyId} historyStatus={historyStatus} buildName={buildNameProps} closeModalBtn={handleClose}/>
          </MDBox>
        </Fade>
      </Modal>

      <Footer />
    </TableLayout>
  )
}

export default TestExecutionHistoryListView