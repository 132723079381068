import {apiClient} from "service/ApiClient";
import {DefaultResponse, Pdu} from "../common/Types";
import {PduConstructor} from "../common/Constructors";


const getPduList = async function (page:number,
                                    size:number,
                                    sort:string,
                                    direction:"none" | "ASC" | "DESC",
                                    fromDate: string,
                                    toDate: string,
                                    name: string) {
    return await apiClient.get<DefaultResponse>(`device/pdu?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&name=${name}`)
        .then((res:any) => res.data)
}
const deletePdu = async function (pduList: Pdu[]) {
    return await apiClient.patch<DefaultResponse>("device/pdu/delete", pduList).then((res:any) => res.data)
}


const savePdu = async function (pdu:PduConstructor){
    return await apiClient.post<DefaultResponse>("device/pdu", pdu).then((res:any)=>res.data)
}

const getValidPduList = async function (){
    return await apiClient.get<DefaultResponse>('device/pdu/valid').then((res:any) => res.data)
}

const getPduSlotList = async function (pduId:any){
    return await apiClient.get<DefaultResponse>(`device/pdu/slot/${pduId}`).then((res:any) => res.data)
}


const PduService = {
    getPduList,
    deletePdu,
    savePdu,
    getValidPduList,
    getPduSlotList
}

export default PduService;