import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import {useQuery} from "@tanstack/react-query";
import {DashboardToday, DashboardTotal, DefaultResponse} from "common/Types";
import {AxiosError} from "axios";
import {useEffect, useState} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MDButton from "components/MDButton";
import {Refresh} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import DashboardService from "service/DashboardService";
import {Skeleton} from "@mui/material";
import {useTranslation} from "react-i18next";

function Today(): JSX.Element{

  const {t} = useTranslation();

  const [today, setToday]= useState<DashboardToday>({
                                                                                      today : null,
                                                                                      todayEvaluation : null,
                                                                                      todayExecuteRatio : null
                                                                                    })

  const [total, setTotal] = useState<DashboardTotal>({
                                                                                      totalTestSuite : null,
                                                                                      totalDevice : null,
                                                                                      idleDevice : null,
                                                                                      popularDevice : null
                                                                                    })

  const {refetch: getToday} = useQuery<DefaultResponse, AxiosError>(
    ["getToday"],
    () => DashboardService.getToday(),
    {
      onSuccess : (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          const dashboardToday: DashboardToday = res.data
          setToday(dashboardToday)
        }
      }
    }
  )

  const {refetch: getTotal} = useQuery<DefaultResponse, AxiosError>(
    ["getTotal"],
    () => DashboardService.getTotal(),
    {
      onSuccess : (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          const dashboardTotal: DashboardTotal = res.data
          console.log(dashboardTotal)
          setTotal(dashboardTotal)
        }
      }
    }
  )

  useEffect(() => {
    getToday()
    getTotal()
  }, []);

  return (
    <Grid container gap={1.5} sx={{height : "100%"}}>
      <Grid item xs={12} md={12} lg={12}>
        <Card sx={{width : "100%"}}>
          <MDBox display={"flex"} justifyContent={"space-between"} p={1}>
            <MDBox
              display={"inline-flex"}
              my={"auto"}
              justifyContent={"center"}
              alignItems={"center"}
              bgColor={"info"}
              color="white"
              width="3.5rem"
              height="3.5rem"
              shadow="md"
              borderRadius="lg"
              variant="gradient"
            >
              <Icon fontSize={"large"}>{"event_available"}</Icon>
            </MDBox>
            <MDBox
              display={"inline-flex"}
              flexDirection={"column"}
              my={"auto"}
              px={1}
              justifyContent={"right"}
              alignItems={"right"}
            >
              <MDTypography variant={visualViewport.height > 1024 ? "body1" :"body2"} align={"right"}>
                {t("qaSummary.today.evaluation")}
              </MDTypography>
              {today.todayEvaluation === null ?
                <Skeleton variant={"text"} width={"100%"} height={"3rem"} />:
              <MDTypography variant={visualViewport.height > 1024 ? "h2" :"h3"} align={"right"} height={visualViewport.height > 1024 ? "3rem" : ""}>
                {today.todayEvaluation}
              </MDTypography>}
            </MDBox>
          </MDBox>
        </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Card sx={{width : "100%"}}>
          <MDBox display={"flex"} justifyContent={"space-between"} p={1}>
            <MDBox
              display={"inline-flex"}
              justifyContent={"center"}
              my={"auto"}
              alignItems="center"
              bgColor={"info"}
              color="white"
              width="3.5rem"
              height="3.5rem"
              shadow="md"
              borderRadius="lg"
              variant="gradient"
            >
              <Icon fontSize={"large"}>{"query_stats"}</Icon>
            </MDBox>
            <MDBox
              display={"inline-flex"}
              flexDirection={"column"}
              px={1} my={"auto"}
              justifyContent={"right"}
              alignItems={"right"}
            >
              <MDTypography variant={visualViewport.height > 1024 ? "body1" :"body2"} align={"right"}>
                {t("qaSummary.today.ratio")}
              </MDTypography>
              {today.todayExecuteRatio === null ?
                <Skeleton variant={"text"} width={"100%"} height={"3rem"} />:
                <MDTypography variant={visualViewport.height > 1024 ? "h2" :"h3"} align={"right"} height={visualViewport.height > 1024 ? "3rem" : ""}>
                {today.todayExecuteRatio + "%"}
              </MDTypography>}
            </MDBox>
          </MDBox>
        </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Card sx={{height : "100%"}}>
          <List>
            <ListItem>
              <MDBox
                width={"50%"} px={2} py={visualViewport.height > 1024 ? 4 : 2}
                display={"flex"} flexDirection={"column"}
                alignItems={"left"}
              >
                <MDTypography variant={visualViewport.height > 1024 ? "h2" :"h3"}>
                  {t("qaSummary.total.total")}
                </MDTypography>
                {today.today === null ?
                  <Skeleton variant={"text"} width={"100%"} height={"2rem"} />:
                  <MDTypography variant={visualViewport.height > 1024 ? "body1" :"body2"} height={visualViewport.height > 1024 ? "2rem" : ""}>
                  {today.today}
                </MDTypography>}
              </MDBox>
              <MDBox
                width={"50%"} px={2} pt={1} pb={3}
                display={"flex"}
                justifyContent={"right"}
              >
                <MDButton iconOnly onClick={()=> {
                  getToday()
                  getTotal()
                }}>
                  <Refresh />
                </MDButton>
              </MDBox>
            </ListItem>

            <ListItem>
              <MDBox
                display={"flex"} flexDirection={"column"}
                px={2} pt={visualViewport.height > 1024 ? 5 : 2}
              >
                <MDTypography variant={visualViewport.height > 1024 ? "h4" :"inherit"}>
                  {t("qaSummary.total.suites")}
                </MDTypography>
                {total.totalTestSuite === null ?
                  <Skeleton variant={"text"} width={"100%"} height={"3rem"} />:
                  <MDTypography variant={visualViewport.height > 1024 ? "h1" :"h2"} color={"info"} height={visualViewport.height > 1024 ? "3rem" : ""}>
                  {total.totalTestSuite}
                </MDTypography>}
              </MDBox>
            </ListItem>
              <Divider variant={"middle"} sx={{my : visualViewport.height > 1024 ? 2 : 1}}/>
            <ListItem>
              <MDBox
                display={"flex"} flexDirection={"column"}
                px={2}
              >
                <MDTypography variant={visualViewport.height > 1024 ? "h4" :"inherit"}>
                  {t("qaSummary.total.device")}
                </MDTypography>
                {total.totalDevice === null ?
                  <Skeleton variant={"text"} width={"100%"} height={"3rem"} />:
                  <MDTypography variant={visualViewport.height > 1024 ? "h1" :"h2"} color={"info"} height={visualViewport.height > 1024 ? "3rem" : ""}>
                  {total.totalDevice}
                </MDTypography>}
              </MDBox>
            </ListItem>
            <Divider variant={"middle"} sx={{my : visualViewport.height > 1024 ? 2 : 1}}/>
            <ListItem>
              <MDBox
                display={"flex"} flexDirection={"column"}
                px={2}
              >
                <MDTypography variant={visualViewport.height > 1024 ? "h4" :"inherit"}>
                  {t("qaSummary.total.idle")}
                </MDTypography>
                {total.idleDevice === null ?
                  <Skeleton variant={"text"} width={"100%"} height={"3rem"} />:
                  <MDTypography variant={visualViewport.height > 1024 ? "h1" :"h2"} color={"info"} height={visualViewport.height > 1024 ? "3rem" : ""}>
                  {total.idleDevice}
                </MDTypography>}
              </MDBox>
            </ListItem>
            <Divider variant={"middle"} sx={{my : visualViewport.height > 1024 ? 2 : 1}}/>
            <ListItem>
              <MDBox
                display={"flex"} flexDirection={"column"}
                px={2} pb={visualViewport.height > 1024 ? 3 : 2}
              >
                <MDTypography variant={visualViewport.height > 1024 ? "h4" :"inherit"}>
                  {t("qaSummary.total.popular")}
                </MDTypography>
                {total.popularDevice === null ?
                  <Skeleton variant={"text"} width={"100%"} height={"3rem"} />:
                  <MDTypography variant={visualViewport.height > 1024 ? "h1" :"h2"} color={"info"} height={visualViewport.height > 1024 ? "3rem" : ""}>
                  {total.popularDevice ? total.popularDevice.name : "N/A"}
                </MDTypography>}
              </MDBox>
            </ListItem>

          </List>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Today