import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import Card from "@mui/material/Card";
import MDBox from "../../../../components/MDBox";
import {createBrowserHistory} from "history";
import {RateLimitedMouse} from "../../../../common/MouseUtil";

const myStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',  // 필요에 따라 추가
    width: '100%',  // 필요에 따라 추가
    height: 'auto',  // 필요에 따라 추가
    marginTop: '24px',
    paddingRight: '10px',
};

const useWebSocket = () => {
    const ws = useRef<WebSocket | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [username, setUsername] = useState<string>('admin');
    const [password, setPassword] = useState<string>('admin');
    const loginUrl = 'http://192.168.12.54/login_api';

    const handleLogin = async () => {
        const requestBody = {
            username,
            password,
        };

        try {
            const response = await fetch(loginUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data: LoginResponse = await response.json();

            if (data.status === 1) {
                console.log('Login successful:', data.info);
                setToken(data.data.token);
            } else {
                console.error('Login failed:', data.info);
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    useEffect(() => {
        if(!token){
            handleLogin();
        }
    },[])

    useEffect(() => {
        if(token){
            // 함수로 WebSocket 연결을 설정
            const connectWebSocket = () => {
                ws.current = new WebSocket(`ws://192.168.12.54/kme?token=${token}`);

                ws.current.onopen = () => {
                    console.log('WebSocket connection established');
                };

                ws.current.onmessage = (event) => {
                    console.log('Received:', event.data);
                };

                ws.current.onclose = () => {
                    console.log('WebSocket connection closed');
                    // WebSocket이 닫히면 다시 연결 시도
                    setTimeout(connectWebSocket, 1000); // 1초 후에 재연결 시도
                };

                ws.current.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };
            };

            // WebSocket 연결 설정
            connectWebSocket();

            // 컴포넌트 언마운트 시 WebSocket 연결 해제
            return () => {
                if (ws.current) {
                    ws.current.close();
                }
            };
        }

    }, [token]);

    // WebSocket 인스턴스를 반환하여 외부에서 메시지를 보낼 수 있도록 함
    return ws;
};

interface LoginResponse {
    status: number;
    info: string;
    data: {
        token: string;
    };
}

function UITestScreenView(): JSX.Element {

    const {t} = useTranslation();
const [inputKey, setInputKey] = useState<string>('');
    const mjpegUrl = 'http://192.168.12.54:8008/stream?advance_headers=1';


    const rateLimitedMouse = useRef<RateLimitedMouse | null>(null);


    const ws = useWebSocket();

    useEffect(() => {
        if(!ws){
            return
        }

        const limitTime = 100;

        rateLimitedMouse.current = new RateLimitedMouse(limitTime, (mouseEvent) => {
            const obj = { m: mouseEvent };
            if (ws && ws.current && ws.current.readyState && ws.current.readyState === WebSocket.OPEN) {
                ws.current.send(JSON.stringify(obj));
            }
        }, true);

        const handleKeyDown = (event: KeyboardEvent) => {
            event.preventDefault();
            const code = event.code;
            setInputKey(code);
            ws.current.send(`{"k": ["${code}"]}`)
        }

        const handleKeyUp = (event: KeyboardEvent) => {
            event.preventDefault();
            ws.current.send(`{"k": [""]}`)
        }

        const handleMouseMove = (event: MouseEvent) => {
            event.preventDefault();
            if(event.buttons){
                rateLimitedMouse.current?.onMouseMove(event);
            } else {
                rateLimitedMouse.current?.onMouseDown(event);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        document.getElementById('kvm').addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            document.getElementById('kvm')?.removeEventListener('mousemove', handleMouseMove);
        };
    }, [ws]);

    const handleMouseDown = (event: any) => {
        event.preventDefault();
        rateLimitedMouse.current?.onMouseDown(event);
    }

    useEffect(()=> {
        // project list를 조회하고 있을때와 없을때, init 화면 분기 처리
    },[])


    return (
            <MDBox sx={{ height: "100%" }}>
                <div id="kvm" className="kvm-area">
                    <img
                        id="image"
                        draggable="false"
                        src={mjpegUrl}
                        // onMouseMove={handleMouseMove}
                        onMouseDown={handleMouseDown}
                        // onMouseUp={handleMouseUp}
                        // onWheel={handleWheel}
                        style={myStyle}
                    />
                </div>
            </MDBox>
    );
}

export default UITestScreenView;