/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


import React, {useState} from "react";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import DetailLayout from "examples/LayoutContainers/CustomLayout/detail/DetailLayout";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import DetailContentsLayout from "examples/LayoutContainers/CustomLayout/detail/DetailContentsLayout";
import FormField from "../../component/FormField";
import {DefaultResponse} from "common/Types";
import {NoticeConstructor} from "common/Constructors";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import Footer from "examples/Footer";
import {LANGUAGE} from "common/Constant";
import {useTranslation} from "react-i18next";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import MDEditor from "../../../components/MDEditor";
import NoticeService from "../../../service/NoticeService";
import ConfirmationDialog from "../../component/ConfirmationDialog";

function NoticeView(...rest: any): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation();
    const navigate = useNavigate()
    const [content, setContent] = useState(location.state.notice.content)
    const [formValue, setFormValue] = useState(null);
    const [apiReq, setApiReq] = useState<Boolean>(false);

    /*snackbar start*/
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);

    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/

    /* from validation start*/
    const form = {
        formId: "create-notice-form",
        formField: {
            title: {
                name: "title",
                label: "Title",
                type: "text",
                errorMsg: "Title is required.",
            },
        },
    }

    const {
        formField: { title},
    } = form;

    const validations = Yup.object().shape({
        [title.name]: Yup.string().required(title.errorMsg),
    })

    const [initialValues, setInitialValues] = useState({
        [title.name]: location.state.notice.title?location.state.notice.title:"",
    })


    const { formId, formField } = form;
    const currentValidation = validations;
    /* from validation end*/


    const _notice = new NoticeConstructor(
        location.state.notice.id?location.state.notice.id:undefined,
        '',
        '',
        location.state.notice.type?location.state.notice.type:"N",
        location.state.notice.type?location.state.notice.type:false,
    )

    // 공지사항 추가/수정 axios
    const {refetch:  createNotice} = useQuery<any, AxiosError>(
        ['createNotice'],
        () => NoticeService.createNotice(_notice),
        {
            onSuccess: (res: DefaultResponse ) => {
                setApiReq(false)
                if(res.code === 200 && res.subCode === 0){
                    goBack()
                }
            }
        }
    )

    const submitForm = (values: any, actions: any) => {
        setFormValue(values);
        openConfirm(t("confirm.save"), "info");
    }

    const handleYes = ()=>{
        _notice.title = formValue.title
        _notice.content = content

        setApiReq(true)
        createNotice()
    }

    const handleNo = () => {
        toggleDialog()
    }

    const goBack = () => {
        navigate(-1);
    }

    // 공지내용 수정 함수
    const changeNoticeContent = (text: any)=> {
        setContent(text)
    }


    function getStepContent(formData: any): JSX.Element {
        const { formField, values, errors, touched } = formData;
        const { title} = formField;
        const {
            title: titleV
        } = values;
        return  <MDBox>
            <MDBox lineHeight={0}>
                <MDTypography variant="h5">{t(`title.path.${location.state.type}`, {title : location.state.menu_name})}</MDTypography>
            </MDBox>

            <MDBox mt={1.625} height="70vh" display="flex" flexDirection="column">
                <Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={title.type}
                            label={title.label}
                            name={title.name}
                            value={titleV}
                            placeholder={title.placeholder}
                            error={errors.title && touched.title}
                        />
                    </Grid>
                    <Grid item  sm={12} sx={()=>({height:"70vh"})}>
                        <MDEditor content={content} value={changeNoticeContent}/>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>;
    }

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(100)

    return (
        <DetailLayout  pageTitle={location.state.menu_name}>
            {/* 이름 입력 영역*/}
            <DetailContentsLayout>
                <Grid item >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={submitForm}
                    >
                        {({ values, errors, touched, isSubmitting }) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{ height: "100%" }}>
                                    <MDBox p={3}>
                                        <MDBox>
                                            {getStepContent({
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                            })}
                                        </MDBox>
                                    </MDBox>
                                    <MDBox py={3} px={3} display={"flex"} justifyContent={"flex-end"}>
                                        <MDButton size={"medium"} variant={"outlined"} color={"secondary"} onClick={() => {goBack()}} sx={{minWidth : buttonMinWidth}}>
                                            {t("button.cancel")}
                                        </MDButton>
                                        <MDBox display={"inline-block"} p={0.5} />
                                        <MDButton type="submit" size={"medium"} variant={"outlined"} color={"info"} onClick={() => {}} sx={{minWidth : buttonMinWidth}}>
                                            {apiReq?<Icon>loop</Icon>:t("button.save")}
                                        </MDButton>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </DetailContentsLayout>
            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
            />
            <Footer />
        </DetailLayout>
    );
}

export default NoticeView;