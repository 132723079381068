import {Form, Formik} from "formik";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import FormField from "../../../component/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import {getCodeListForInput, LANGUAGE} from "../../../../common/Constant";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import {Backdrop, FormControlLabel, Modal, Radio, RadioGroup} from "@mui/material";
import MDInput from "../../../../components/MDInput";
import * as Yup from "yup";
import Fade from "@mui/material/Fade";
import NodeListModal from "../../tedNode/list/NodeListModal";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {DefaultResponse} from "../../../../common/Types";
import DeviceConfigService from "../../../../service/DeviceConfigService";


interface Props{
    deviceType: string;
    actionType: string;
    device: any;
    handleForm: any;
}


function DeviceInputView({deviceType, actionType, device, handleForm} : Props): JSX.Element {

    const {t} = useTranslation();
    const navigate = useNavigate()

    const [interfaceTypeCk, setInterfaceTypeCk] = useState(device?.interfaceType || "")
    // const [node, setNode] = useState({label:"", value:""})

    const form = {
        formId: "create-device_board-form",
        formField: {
            name: {
                name: "name",
                label: t("Name"),
                type: "text",
                errorMsg: "name is required.",
            },
            node: {
                name: "node",
                label: t("Node"),
                type: "text",
                errorMsg: "Node is required.",
            },
            buildType: {
                name: "buildType",
                label: t("BuildType"),
                type: "text",
                errorMsg: "Build Type is required.",
            },
            usable: {
                name: "usable",
                label: t("Usable"),
                type: "text",
                errorMsg: "Usable is required.",
            },
            serialNo: {
                name: "serialNo",
                label: t("ProductSerialNo"),
                type: "text",
                errorMsg: "Product Serial No is required.",
            },
            ttyUSBSyLink: {
                name: "ttyUSBSyLink",
                label: t("ttyUSBSyLink"),
                type: "text",
                errorMsg: "ttyUSB Symbolic Link is required.",
            },
            fusingImage: {
                name: "fusingImage",
                label: t("FusingImage"),
                type: "text",
                errorMsg: "Fusing Image is required.",
            },
            serialCableKeyNo: {
                name: "serialCableKeyNo",
                label: t("SerialCableKeyNo"),
                type: "text",
                errorMsg: "Serial Cable Key No Type is required.",
            },
            containerIp: {
                name: "containerIp",
                label: t("ContainerIp"),
                type: "text",
                errorMsg: "Container IP is required.",
            },
            dutIp: {
                name: "dutIp",
                label: t("DUTIp"),
                type: "text",
                errorMsg: "DUT IP is required.",
            },
            interfaceType: {
                name: "interfaceType",
                label: t("InterfaceType"),
                type: "text",
                errorMsg: "Interface Type is required.",
            },
            acceleratorCnt: {
                name: "acceleratorCnt",
                label: t("AcceleratorCount"),
                type: "number",
                errorMsg: "Accelerator Count is required.",
            },
            executionState: {
                name: "executionState",
                label: t("ExecutionState"),
                type: "text",
                errorMsg: "Execution State is required.",
            },
            sshExternalPort: {
                name: "sshExternalPort",
                label: t("SSHExternalPort"),
                type: "text",
                errorMsg: "SSH External Port is required.",
            },
            deviceProductName: {
                name: "deviceProductName",
                label: t("DeviceProductName"),
                type: "text",
                errorMsg: "Device Product Name is required.",
            },
            vendorId: {
                name: "vendorId",
                label: t("VendorId"),
                type: "text",
                errorMsg: "Device Vendor ID is required.",
            },
            productId: {
                name: "productId",
                label: t("ProductId"),
                type: "text",
                errorMsg: "Device product ID is required.",
            },
        },
    }

    const {
        formField: { name,node, buildType, serialCableKeyNo, serialNo, usable, ttyUSBSyLink, fusingImage, containerIp, dutIp, interfaceType, acceleratorCnt, executionState, sshExternalPort, deviceProductName, vendorId, productId},
    } = form;

    const validations = Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [serialNo.name]: Yup.string().required(serialNo.errorMsg),
        [containerIp.name]: Yup.string().required(containerIp.errorMsg),
        [dutIp.name]: Yup.string().required(dutIp.errorMsg),
        [deviceProductName.name]: Yup.string().required(deviceProductName.errorMsg),
    });

    const [initialValues, setInitialValues] = useState({
        [name.name]: device?.name || "",
        [node.name]: device ? { id: device.nodeId, name: device.nodeName } : null,
        [buildType.name]: device?.buildType || "I",
        [usable.name]: device === null ? "A" : (device.usable ? "A" : "U"),
        [serialNo.name]: device?.serialNo || "",
        [ttyUSBSyLink.name]: device?.syLink || "",
        [fusingImage.name]: device?.fusingImage || "",
        [serialCableKeyNo.name]: device?.serialCableKeyNo || "",
        [containerIp.name]: device?.containerIp || "",
        [dutIp.name]: device?.dutIp || "",
        [interfaceType.name]: device?.interfaceType || "SERIAL",
        [acceleratorCnt.name]: device?.acceleratorCnt || 0,
        [executionState.name]: device?.executionState || "I",
        [sshExternalPort.name]: device?.sshExternalPort || "",
        [deviceProductName.name]: device?.deviceProductName || "",
        [vendorId.name]: device?.vendorId || "",
        [productId.name]: device?.productId || ""
    });

    const { formId, formField } = form;
    const currentValidation = validations;

    function getStepContent(formData: any): JSX.Element {
        const { formField, values, errors, touched } = formData;
        const { name, node, serialNo, containerIp, ttyUSBSyLink, fusingImage, serialCableKeyNo, dutIp, acceleratorCnt, sshExternalPort, deviceProductName, vendorId, productId} = formField;
        const {
            name: nameV,
            node:nodeV,
            buildType: buildTypeV,
            serialNo: serialNoV,
            containerIp: containerIpV,
            ttyUSBSyLink: ttyUSBSyLinkV,
            fusingImage: fusingImageV,
            serialCableKeyNo: serialCableKeyNoV,
            usable: usableV,
            dutIp: dutIpV,
            interfaceType: interfaceTypeV,
            acceleratorCnt: acceleratorCntV,
            sshExternalPort: sshExternalPortV,
            deviceProductName: deviceProductNameV,
            vendorId: vendorIdV,
            productId: productIdV
        } = values;

        const [containerIpList, setContainerIpList ] = useState([]);
        const [deviceTypeCk, setDeviceTypeCk ] = useState('B');

        function renderCustomForm(deviceType: string){
            if(deviceType === "B" && deviceType != deviceTypeCk){
                values.acceleratorCnt = 0
                setInterfaceTypeCk("SERIAL")
                values.interfaceType = "SERIAL"
                setDeviceTypeCk("B")

                return(
                    <Grid item xs={12} sm={11}>
                        <FormField
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            type={ttyUSBSyLink.type}
                            label={ttyUSBSyLink.label}
                            name={ttyUSBSyLink.name}
                            value={ttyUSBSyLinkV}
                            placeholder={ttyUSBSyLink.placeholder}
                            error={errors.ttyUSBSyLink && touched.ttyUSBSyLink}
                            success={ttyUSBSyLinkV?.length > 0 && !errors.ttyUSBSyLink}
                        />
                    </Grid>
                )
            } else if((deviceType === "G" || deviceType ==="N") && deviceType != deviceTypeCk){
                values.serialCableKeyNo = ""
                values.ttyUSBSyLink = ""
                values.vendorId = ""
                values.productId = ""
                setDeviceTypeCk(deviceType)
                setInterfaceTypeCk("PCIe")
                values.interfaceType = "PCIe"

                return(
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={acceleratorCnt.type}
                            label={acceleratorCnt.label}
                            name={acceleratorCnt.name}
                            value={acceleratorCntV}
                            placeholder={acceleratorCnt.placeholder}
                            error={errors.acceleratorCnt && touched.acceleratorCnt}
                            success={acceleratorCntV?.length > 0 && !errors.acceleratorCnt}
                        />
                    </Grid>
                )
            } else if(deviceType === "M" && deviceType != deviceTypeCk){
                values.acceleratorCnt = 0
                values.serialCableKeyNo = ""
                values.vendorId = ""
                values.productId = ""
                setDeviceTypeCk("M")
                setInterfaceTypeCk("SERIAL")
                values.interfaceType = "SERIAL"

                return(
                    <Grid item xs={12} sm={11}>
                        <FormField
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            type={ttyUSBSyLink.type}
                            label={ttyUSBSyLink.label}
                            name={ttyUSBSyLink.name}
                            value={ttyUSBSyLinkV}
                            placeholder={ttyUSBSyLink.placeholder}
                            error={errors.ttyUSBSyLink && touched.ttyUSBSyLink}
                            success={ttyUSBSyLinkV?.length > 0 && !errors.ttyUSBSyLink}
                        />
                    </Grid>
                )
            }
        }

        const handleNodeValue = (value:any)=>{
            values.node = value
        }

        function handleClose() {
            setModalOpen(false)
        }

        const {refetch: getContainerIpList} = useQuery<any, AxiosError>(
            ['getContainerIpList'],
            () => DeviceConfigService.getContainerIpList(values.node.id),
            {
                onSuccess: (res: DefaultResponse ) => {
                    if(res.code === 200 && res.subCode === 0){
                        const usedContaineerIp = res.data
                        const containerIpForm = "172.18.0."
                        const selectContainerIpList = values.containerIp?[values.containerIp]:[]
                        for(let i=101; i<255; i++){
                            if(selectContainerIpList.length === 5){
                                break
                            } else if(!usedContaineerIp.includes(containerIpForm+i)){
                                selectContainerIpList.push(containerIpForm+i)
                            }
                        }
                        setContainerIpList(selectContainerIpList)
                    }
                }
            }
        )

        useEffect(()=>{
            if(values.name && (deviceType === "B" || deviceType === "M")){
                values.ttyUSBSyLink = "tty_"+values.name
            }
        },[values.name])

        useEffect(()=>{
            const ipList = values.containerIp?.split(".").filter((item:any) => item !== "");
            if(values.containerIp && ipList.length === 4){
                const portNum = Number(ipList[3])
                values.sshExternalPort = !isNaN(portNum)?5000+portNum:"Unsolved Value";
                values.dutIp = "192.168."+ipList[2]+"."+ipList[3]
            }else{
                values.sshExternalPort = "";
                values.dutIp = "";
            }
        },[values.containerIp])

        useEffect(()=>{
            if(values.node ){
                getContainerIpList()
            }
        },[values.node])

        return  <Grid>
            <Grid container spacing={2} mt={1.625}>
                <Grid container item={true} xs={6} display="flex" justifyContent="center" >
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={nameV}
                            placeholder={name.placeholder}
                            error={errors.name && touched.name}
                            success={nameV?.length > 0 && !errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormField
                            disabled={actionType === 'AutoCreate'}
                            inputProps={ actionType === 'AutoCreate' ? {tabIndex: -1} : {} }
                            label={node.label}
                            name={node.name}
                            value={nodeV?.name || ""}
                            placeholder={node.placeholder}
                            error={errors.node && touched.node}
                            success={nodeV && !errors.node}
                            onClick={()=> setModalOpen(true)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <RadioGroup onChange={(event, value)=>{values.buildType = value}} defaultValue={buildTypeV} row={true} sx={{ justifyContent: "space-between" }}>
                            {
                                getCodeListForInput("BUILD").map((e) => (
                                    <FormControlLabel key={e.id} value={e.id} control={<Radio />} label={t(e.label)}/>
                                ))
                            }
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={deviceProductName.type}
                            label={deviceProductName.label}
                            name={deviceProductName.name}
                            value={deviceProductNameV}
                            placeholder={deviceProductName.placeholder}
                            error={errors.deviceProductName && touched.deviceProductName}
                            success={deviceProductNameV?.length > 0 && !errors.deviceProductName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={serialNo.type}
                            label={serialNo.label}
                            name={serialNo.name}
                            value={serialNoV}
                            placeholder={serialNo.placeholder}
                            error={errors.serialNo && touched.serialNo}
                            success={serialNoV?.length > 0 && !errors.serialNo}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Autocomplete
                            disabled={nodeV === undefined}
                            value={containerIpV || null}
                            options={containerIpList}
                            isOptionEqualToValue={(option, value) => value === option}
                            getOptionLabel={(option) => option || ""}
                            onChange={(event, newValue) => {
                                values.containerIp = newValue
                            }}
                            size="small"
                            fullWidth={true}
                            renderInput={(params) => (
                                <FormField {...params}
                                           type={containerIp.type}
                                           label={containerIp.label}
                                           name={containerIp.name}
                                           value={containerIpV}
                                           placeholder={containerIp.placeholder}
                                           error={errors.containerIp && touched.containerIp}
                                           success={containerIpV?.length > 0 && !errors.containerIp}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormField
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            type={dutIp.type}
                            label={dutIp.label}
                            name={dutIp.name}
                            value={dutIpV}
                            placeholder={dutIp.placeholder}
                            error={errors.dutIp && touched.dutIp}
                            success={dutIpV?.length > 0 && !errors.dutIp}
                        />
                    </Grid>
                </Grid>

                <Grid container item={true} xs={6} display="flex" justifyContent="center" alignItems={"center"}>
                    <Grid item xs={12} sm={11} sx={{border:"1px solid black", borderRadius:"10px", padding:"10px", marginBottom:"10px"}}>
                        <Grid item xs={10} sm={6}>INTERFACE TYPE</Grid>
                        <Autocomplete
                            value={
                                device || getCodeListForInput("INTERFACE_TYPE").some((option) => option.id === interfaceTypeV)
                                    ? { id: interfaceTypeV, label: interfaceTypeV }
                                    : null
                            }
                            options={getCodeListForInput("INTERFACE_TYPE").filter((option) => {
                                return ((deviceType === "G" || deviceType === "N") && option.label === "GPU/NPU_INTERFACE_TYPE") || ((deviceType === "B" || deviceType === "M") && option.label === "BOARD_INTERFACE_TYPE");
                            })}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.id || ""}
                            onChange={(event, newValue) => {
                                values.interfaceType =  newValue.id
                                values.serialCableKeyNo = ""
                                values.vendorId = ""
                                values.productId = ""
                                setInterfaceTypeCk(newValue.id)
                            }}
                            size="small"
                            renderInput={(params) => <MDInput {...params} placeholder={t("dut.input.interfaceType")} />}
                        />
                        {deviceType === "B" && interfaceTypeV == "SERIAL" ?
                            <Grid  display={"flex"} justifyContent={"space-around"} marginTop={"10px"}>
                                <Grid item xs={6} sm={6}>
                                    <FormField
                                        disabled={actionType === "AutoCreate"}
                                        inputProps={ actionType === 'AutoCreate' ? {tabIndex: -1} : {} }
                                        type={vendorId.type}
                                        label={vendorId.label}
                                        name={vendorId.name}
                                        value={vendorIdV}
                                        placeholder={vendorId.placeholder}
                                        error={errors.vendorId && touched.vendorId}
                                        success={vendorIdV?.length > 0 && !errors.vendorId}
                                    />
                                </Grid>
                                <MDBox display={"inline-block"} p={0.5} />
                                <Grid item xs={6} sm={6}>
                                    <FormField
                                        disabled={actionType === "AutoCreate"}
                                        inputProps={ actionType === 'AutoCreate' ? {tabIndex: -1} : {} }
                                        type={productId.type}
                                        label={productId.label}
                                        name={productId.name}
                                        value={productIdV}
                                        placeholder={productId.placeholder}
                                        error={errors.productId && touched.productId}
                                        success={productIdV?.length > 0 && !errors.productId}
                                    />
                                </Grid>
                            </Grid>
                            :<></>
                        }
                        <Grid item xs={12} sm={12} sx={{marginTop:"10px"}}>
                            <FormField
                                disabled={interfaceTypeV != "SERIAL" || actionType === "AutoCreate"}
                                inputProps={ (interfaceTypeCk != "SERIAL" || actionType === "AutoCreate") ? {tabIndex: -1} : {} }
                                type={serialCableKeyNo.type}
                                label={serialCableKeyNo.label}
                                name={serialCableKeyNo.name}
                                value={serialCableKeyNoV}
                                placeholder={serialCableKeyNo.placeholder}
                                error={errors.serialCableKeyNo && touched.serialCableKeyNo}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <RadioGroup onChange={(event, value)=>{values.usable = value}} defaultValue={usableV} row={true} sx={{ justifyContent: "space-between" }}>
                            {
                                getCodeListForInput("AVAILABILITY").map((e) => (
                                    <FormControlLabel key={e.id} value={e.id} control={<Radio />} label={t(e.label)}/>
                                ))
                            }
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormField
                            disabled
                            inputProps={{tabIndex: -1}}
                            type={sshExternalPort.type}
                            label={sshExternalPort.label}
                            name={sshExternalPort.name}
                            value={sshExternalPortV}
                            placeholder={sshExternalPort.placeholder}
                            error={errors.sshExternalPort && touched.sshExternalPort}
                            success={sshExternalPortV?.length > 0 && !errors.sshExternalPort}
                        />
                    </Grid>
                    { deviceType? renderCustomForm(deviceType) : null }
                </Grid>
            </Grid>
            <Modal
                open={modalOpen} onClose={handleClose}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={modalOpen}>
                    <MDBox
                        display="flex"
                        width="95vw" minHeight="85vh" maxHeight="80vh" shadow="xl"
                        position="fixed" top="50%" left="50%"
                        sx={{transform: "translate(-50%, -50%)"}}
                    >
                        <NodeListModal handleNodeValue={handleNodeValue} closeModalBtn={handleClose}/>
                    </MDBox>
                </Fade>
            </Modal>
        </Grid>;
    }

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(100)

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <Grid item >
            <Formik initialValues={initialValues} onSubmit={handleForm} validationSchema={currentValidation}>
                {({ values, errors, touched, isSubmitting }) => (
                    <Form id={formId} autoComplete="off">
                        <Card sx={{ height: "100%" }}>
                            <MDBox p={3}>
                                <MDBox>
                                    {getStepContent({
                                        values,
                                        touched,
                                        formField,
                                        errors,
                                    })}
                                </MDBox>
                            </MDBox>
                            <MDBox py={3} px={3} display={"flex"} justifyContent={"flex-end"}>
                                <MDButton size={"medium"} variant={"outlined"} color={"secondary"} onClick={() => {navigate(-1)}} sx={{minWidth : buttonMinWidth}}>
                                    {t("button.cancel")}
                                </MDButton>
                                <MDBox display={"inline-block"} p={0.5} />
                                <MDButton type="submit" size={"medium"} variant={"outlined"} color={"info"} onClick={() => {}} sx={{minWidth : buttonMinWidth}}>
                                    {t("button.save")}
                                </MDButton>
                            </MDBox>
                        </Card>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
}

export default DeviceInputView;
