/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import MDButton from "components/MDButton";
import TableContainer from "@mui/material/TableContainer";
import {useEffect, useState} from "react";
import MDDatePicker from "components/MDDatePicker";
import {Refresh} from "@mui/icons-material";
import MDInput from "components/MDInput";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import {useLocation, useNavigate} from "react-router-dom";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {DefaultResponse, Farm} from "common/Types";
import DateUtil from "common/DateUtil";
import {useTranslation} from "react-i18next";
import FarmService from "../../../../service/FarmService";
import MDSnackbar from "../../../../components/MDSnackbar";
import {BRAND_NAME} from "../../../../common/Constant";
import Footer from "../../../../examples/Footer";
import ConfirmationDialog from "../../../component/ConfirmationDialog";

function FarmListView(): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const columns = [
        {Header: t("farm.thead.name"), accessor: "name", width: "15%", sortable: false},
        {Header: t("farm.thead.description"), accessor: "description", width: "40%", sortable: false},
        {Header: t("farm.thead.unitCnt"), accessor: "unitCnt", width: "15%", sortable: false},
        {Header: t("farm.thead.regDt"), accessor: "regDt", width: "20%", sortable: false},
    ];

    const [deleteFarmList, setDeleteFarmList] = useState<Farm[]>([])

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setErrMsg(msg)
        setErrColor(color)
        setOpenErrMsg(true)
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/

    /*paging start*/
    const entries = ["10", "25", "50", "100"];
    const [entriesPerPage, setEntriesPerPage] = useState( 10 )
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [sort, setSort] = useState("")
    const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")

    // Setting the entries starting point
    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;

    // Setting the entries ending point
    let entriesEnd;
    const totalPageLength = Math.ceil(list.length / entriesPerPage);

    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength) {
        entriesEnd = list.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }
    /*paging end*/

    /*searching start*/
    // Search input value state
    const [name, setName] = useState("")

    // 등록일 선택 검색
    const [fromDate, setFromDate] = useState<Date>(null)
    const [toDate, setToDate] = useState<Date>(DateUtil.getDefaultToDate())

    // 등록일 handle function
    const handleFromDateChange = (_fromDate: Date) => {
        setFromDate(_fromDate)
    }

    const handleToDateChange = (_toDate: Date) => {
        setToDate(_toDate)
    }

    // 날짜 설정 부분
    useEffect(() => {
        if (fromDate && toDate && fromDate > toDate) {
            openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
            setFromDate(undefined)
            setToDate(undefined)
        }
    }, [fromDate, toDate])

    const goToDetail = (row: any | undefined, type: string) => {
        const url = type === 'Create' ? `/device/farm` : `/device/farm/${row.id}`;
        navigate({pathname: url}, {state: {menu_name: "Farm", farm: row, type: type}})
    }

    const {refetch: getFarmList} = useQuery<any, AxiosError>(
        ['getFarmList'],
        () => FarmService.getFarmList(page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), name),
        {
            onSuccess: (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    setList(res.data.content)
                    setTotal(res.data.total)
                }
            }
        }
    )

    const {refetch: deleteFarm} = useQuery<any, AxiosError>(
        ['deleteFarm'],
        () => FarmService.deleteFarm(deleteFarmList),
        {
            onSuccess: (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    if (res.data) {
                        navigate(0)
                    } else {
                        setOpenConfirmMsg(false)
                        openAlert(t("ERRORMESSAGE.DELETE.FARM"), "warning")
                    }
                }
            }
        }
    )

    const onClickSave = () => {
        goToDetail({}, 'Create')
    }

    const onClickDelete = () => {
        if (checkList.size === 0) {
            openAlert(t("ERRORMESSAGE.CONFIRM.DELETE.FARM"), "warning")
        } else {
            openConfirm(t("confirm.delete"), "warning")
        }
    }

    const handleYes = () => {
        const _deleteFarmList: Farm[] = []
        checkList.forEach(value => {
            _deleteFarmList.push({
                id: value
            })
        })
        setDeleteFarmList(_deleteFarmList)
    }

    const handleNo = () => {
        toggleDialog()
    }
    // 체크박스 체크리스트 선택

    const [checkList, setCheckList] = useState(new Set<any>([]));
    // 체크박스 단일 선택

    const handleSingleCheck = ((key: any) => {
        if (checkList.has(key)) {
            checkList.delete(key)
            setCheckList(new Set<any>([...checkList]));
        } else {
            setCheckList(new Set<any>([...checkList.add(key)]));
        }
    });

    // 체크박스 전체 선택
    const handleAllCheck = ((checked: boolean) => {
        if (checked) {
            // 전체 선택 클릭 시 모든 로우의 키들 담음
            const set = new Set<any>([]);
            for (let i = 0; i < Math.min(entriesPerPage, list.length); i++) {
                set.add(list[i].id);
            }
            setCheckList(set);
        } else {
            setCheckList(new Set<any>([]));
        }
    });

    // 페이지 변경시 전체 체크 해제
    useEffect(() => {
        setCheckList(new Set<any>([]));
    }, [page]);

    useEffect(() => {
        getFarmList()
    }, []);

    useEffect(() => {
        getFarmList()
    }, [page]);

    useEffect(() => {
        setPage(1)
        getFarmList()
    }, [entriesPerPage]);

    useEffect(() => {
        if (deleteFarmList && deleteFarmList.length > 0)
            deleteFarm()
    }, [deleteFarmList]);

    const onClickHeader = (_accessor: string) => {
        if (sort === _accessor) {
            if (direction && direction === "DESC") {
                setDirection("ASC")
            } else {
                setDirection("DESC")
            }
        } else {
            setDirection("DESC")
            setSort(_accessor)
        }
    }

    const activeEnter = (ev: KeyboardEvent) => {
        if (ev.key === "Enter") {
            getFarmList();
        }
    }


    useEffect(() => {
        if (sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')) {
            getFarmList()
        }
    }, [sort, direction]);

    return (
        /* list, detail 화면에서 공용으로 사용하는 최상위 layout 컴포넌트 */
        <TableLayout pageTitle={location.state?.menu_name}>
            <SearchLayout>
                <MDBox display={"flex"} justifyContent={"space-between"}>
                    <MDBox display={"flex"} width="90%" alignItems="center">
                        <MDBox display={"flex"} alignItems="center">
                            <MDDatePicker
                                value={fromDate}
                                input={
                                    {
                                        size: "small",
                                        height: "37px",
                                        placeholder: t("search.fromDate"),
                                    }
                                }
                                options={
                                    {
                                        maxDate: "today",
                                        // locale : Korean,
                                    }
                                }
                                onChange={(currentTarget: any) => {
                                    const target = currentTarget[0];
                                    handleFromDateChange(target);
                                }}
                            />
                            <MDBox px={0.25}>~</MDBox>
                            <MDDatePicker
                                value={toDate}
                                input={
                                    {
                                        size: "small",
                                        height: "37px",
                                        placeholder: t("search.toDate"),
                                    }
                                }
                                options={
                                    {
                                        maxDate: "today",
                                        // locale : Korean,
                                    }
                                }
                                onChange={(currentTarget: any) => {
                                    const target = currentTarget[0];
                                    handleToDateChange(target);
                                }}
                            />
                            <MDBox px={0.25}/>
                            <MDButton iconOnly={true} onClick={() => {
                                setFromDate(undefined);
                                setToDate(undefined);
                            }}>
                                <Refresh/>
                            </MDButton>
                        </MDBox>

                        <MDBox alignItems="center" width="40%" pl={3}>
                            <MDInput
                                placeholder={t("search.name")}
                                value={name}
                                size="small"
                                fullWidth
                                onChange={({currentTarget}: any) => {
                                    setName(currentTarget.value)
                                }}
                                onKeyDown={(ev: KeyboardEvent) => activeEnter(ev)}
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox display={"flex"}>
                        <MDButton aria-autocomplete={"none"} variant={"outlined"} color={"dark"} onClick={() => {
                        }}>
                            {t("search.button.search")}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </SearchLayout>
            <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total}
                         total={total}/>
            {/* 테이블 전체 layout */}
            <TableContainer
                sx={{
                    boxShadow: "none", overflow: "auto",
                    "&::-webkit-scrollbar": {
                        opacity: 0,
                        background: "transparent",
                        borderRadius: 12,
                        height: 5,
                        width: 4,
                    },
                    "&::-webkit-scrollbar:hover": {opacity: 0.1,},
                    ":hover::-webkit-scrollbar-thumb": {background: "lightgrey", borderRadius: 12, opacity: 0.1}
                }}
            >
                {/* 테이블 contents */}
                <Table>
                    <MDBox component={"thead"}>
                        <TableRow>
                            <DataTableHeadCell
                                width={"1%"}
                                align={"center"}
                                direction={"none"}
                                sortable={false}
                            >
                                <Checkbox size={"small"}
                                          onChange={(e) => {
                                              handleAllCheck(e.target.checked);
                                          }}
                                          checked={checkList.size === Math.min(entriesPerPage, list.length) && list.length > 0}/>

                            </DataTableHeadCell>
                            <DataTableHeadCell
                                width={"10%"}
                                align={"left"}
                                onClick={onClickHeader}
                                accessor={"no"}
                                direction={direction}
                                sort={sort}
                            >
                                {t("farm.thead.no")}
                            </DataTableHeadCell>

                            {columns.map((column: any, index: number) => (
                                <DataTableHeadCell
                                    key={index}
                                    width={column.width}
                                    align={"left"}
                                    onClick={onClickHeader}
                                    accessor={column.accessor}
                                    direction={direction}
                                    sort={sort}
                                    sortable={column.sortable}
                                >
                                    {column.Header}
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    </MDBox>
                    <TableBody>
                        {list.map((row: any, index: number) => (
                            <TableRow key={index}>
                                <DataTableBodyCell
                                    align={"center"}
                                >
                                    <Checkbox
                                        checked={checkList.has(row.id)}
                                        onChange={(e) => {
                                            handleSingleCheck(row.id);
                                        }}
                                    />
                                </DataTableBodyCell>
                                <DataTableBodyCell
                                    key={index}
                                    align={"left"}
                                    row={row}
                                >
                                    {entriesPerPage * (page - 1) + index + 1}

                                </DataTableBodyCell>
                                {columns.map((column: any, index) => (
                                    <DataTableBodyCell
                                        key={index}
                                        align={"left"}
                                        goToDetail={goToDetail}
                                        row={row}
                                    >
                                        {row[column.accessor]}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {/* 테이블 footer */}
                <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page}
                             setEntriesPerPage={setEntriesPerPage} onClickSave={onClickSave}
                             onClickDelete={onClickDelete} onChangePage={setPage}/>
            </TableContainer>
            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color={errColor}
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
            />
            <Footer/>
        </TableLayout>
    );
}

export default FarmListView;