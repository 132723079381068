/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import React, {useEffect, useState} from "react";
import MDTypography from "components/MDTypography";
import DetailLayout from "examples/LayoutContainers/CustomLayout/detail/DetailLayout";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import DetailContentsLayout from "examples/LayoutContainers/CustomLayout/detail/DetailContentsLayout";
import {DefaultResponse, Pdu} from "common/Types";
import {PduConstructor} from "common/Constructors";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import {BRAND_NAME, LANGUAGE,} from "common/Constant";
import {useTranslation} from "react-i18next";
import MDInput from "../../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "../../component/FormField";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import Icon from "@mui/material/Icon";
import PduService from "../../../service/PduService";
import FarmService from "../../../service/FarmService";
import ConfirmationDialog from "../../component/ConfirmationDialog";

function PduDetailView(...rest: any): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    const [formValue, setFormValue] = useState(null);
    const [farmList, setFarmList] = useState([]);
    const [apiReq, setApiReq] = useState<Boolean>(false);

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/

    //수정 일때 list에서 전달 받은 데이터
    const [farm, setFarm] = useState(null);
    const [Pdu, setPdu] = useState<Pdu>();

    const _pduConstructor = new PduConstructor(
        location.state.pdu.id?location.state.pdu.id:undefined,
        "",
        "",
        undefined,
        "",
        undefined
    )

    const form = {
        formId: "create-Pdu-form",
        formField: {
            name: {
                name: "name",
                label: t("Name"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.NAME"),
            },
            description: {
                name: "description",
                label: t("DESCRIPTION"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.DESCRIPTION"),
            },
            ip: {
                name: "ip",
                label: t("IP"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.IP"),
            },
            slotCnt: {
                name: "slotCnt",
                label: t("SLOT CNT"),
                type: "number",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.SLOTCNT"),
            },
        },
    }

    const {
        formField: { name, description, ip, slotCnt},
    } = form;

    const validations = Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [description.name]: Yup.string().required(description.errorMsg),
        [ip.name]: Yup.string().required(ip.errorMsg),
        [slotCnt.name]: Yup.number().required(slotCnt.errorMsg).min(0, t("ERRORMESSAGE.FORM.REQUIRED.PDU.SLOT.CNT")),
    })

    const [initialValues, setInitialValues] = useState({
        [name.name]: location.state.pdu?.name?location.state.pdu.name:"",
        [description.name]: location.state.pdu.description?location.state.pdu.description:"",
        [ip.name]: location.state.pdu.ip?location.state.pdu.ip:"",
        [slotCnt.name]: location.state.pdu.slotCnt?location.state.pdu.slotCnt:0,
    })

    const { formId, formField } = form;
    const currentValidation = validations;

    const {refetch: savePdu} = useQuery<any, AxiosError>(
        ['savePdu'],
        () => PduService.savePdu(_pduConstructor),
        {
            onSuccess: (res: DefaultResponse ) => {
                setApiReq(false)
                if(res.code === 200 && res.subCode === 0){
                    goBack()
                }
            }
        }
    )

    const {refetch: getFarmList} = useQuery<any, AxiosError>(
        ['getFarmList'],
        () => FarmService.getValidFarm(),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    setFarmList(res.data)
                }
            }
        }
    )

    // handle submit form start
    const handleSubmit = (values: any, actions: any) => {
        if(farm.value === "" || farm.value  === undefined){
            setErrMsg(t("ERRORMESSAGE.FORM.REQUIRED.FARM"))
            setOpenErrMsg(true)
        }else{
            setFormValue(values);
            openConfirm(t("confirm.save"), "info");
        }
    }

    const handleYes = ()=>{
        _pduConstructor.pduName = formValue.name
        _pduConstructor.farmId = farm.value
        _pduConstructor.description = formValue.description
        _pduConstructor.pduIp = formValue.ip
        _pduConstructor.slotCnt = formValue.slotCnt
        setApiReq(true)
        savePdu()
    }

    const handleNo = () => {
        toggleDialog()
    }
    // handle submit form end

    const goBack = () => {
        navigate(-1);
    }

    useEffect(()=>{
        getFarmList()
        if(location.state.type === "Edit"){
            setPdu(location.state.pdu)
            setFarm({label:location.state.pdu.farmName, value:location.state.pdu.farmId})
        }
    }, [])

    const getFarmOption = (list:any[])=>{
        const farmList:{value:string, label:string}[] = []
        list.forEach(i => {
            farmList.push({
                value: i.id,
                label: i.name
            })
        })
        return farmList
    }

    function getStepContent(formData: any): JSX.Element {
        const { formField, values, errors, touched } = formData;
        const { name, description, ip, slotCnt} = formField;
        const {
            name: nameV,
            description: descriptionV,
            ip: ipV,
            slotCnt: slotCntV,
        } = values;
        return  <MDBox>
            <MDBox lineHeight={0}>
                <MDTypography variant="h5">
                    {t(`title.path.${location.state.type}`, { title : location.state.menu_name })}
                </MDTypography>
            </MDBox>

            <Grid container spacing={2} mt={1.625}>
                <Grid container item={true} xs={6} display="flex" justifyContent="center" >
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={nameV}
                            placeholder={name.placeholder}
                            error={errors.name && touched.name}
                            success={nameV?.length > 0 && !errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={description.type}
                            label={description.label}
                            name={description.name}
                            value={descriptionV}
                            placeholder={description.placeholder}
                            error={errors.description && touched.description}
                            success={descriptionV?.length > 0 && !errors.description}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={ip.type}
                            label={ip.label}
                            name={ip.name}
                            value={ipV}
                            placeholder={ip.placeholder}
                            error={errors.ip && touched.ip}
                            success={ipV?.length > 0 && !errors.ip}
                        />
                    </Grid>
                </Grid>
                <Grid container item={true} xs={6} display="flex" justifyContent="center" alignItems={"center"}>
                    <Grid item xs={12} sm={11} sx={{border:"1px solid black", borderRadius:"10px", padding:"10px"}}>
                        <Grid item xs={10} sm={6}>FARM</Grid>
                        <Autocomplete
                            disableClearable
                            value={farm}
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            options={getFarmOption(farmList)}
                            getOptionLabel={(option) => option.label || ""}
                            onChange={(event, newValue) => {
                                setFarm(newValue)
                            }}
                            size="small"
                            fullWidth={true}
                            renderInput={(params) => <MDInput {...params} placeholder={"FARM"} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormField
                            type={slotCnt.type}
                            label={slotCnt.label}
                            name={slotCnt.name}
                            value={slotCntV}
                            placeholder={slotCnt.placeholder}
                            error={errors.slotCnt && touched.slotCnt}
                            success={slotCntV?.length > 0 && !errors.slotCnt}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>;
    }

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(100)

    return (
        <DetailLayout  pageTitle={location.state.menu_name}>
            {/* 이름 입력 영역*/}
            <DetailContentsLayout>
                <Grid item >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting}) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{ height: "100%" }}>
                                    <MDBox px={3} pt={3}>
                                        <MDBox>
                                            {getStepContent({
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                            })}
                                        </MDBox>
                                    </MDBox>
                                    <MDBox py={3} px={3} display={"flex"} justifyContent={"flex-end"}>
                                        <MDButton size={"medium"} variant={"outlined"} color={"secondary"} onClick={() => {goBack()}} sx={{minWidth : buttonMinWidth}}>
                                            {t("button.cancel")}
                                        </MDButton>
                                        <MDBox display={"inline-block"} p={0.5} />
                                        <MDButton type="submit" size={"medium"} variant={"outlined"} color={"info"} onClick={() => {}} sx={{minWidth : buttonMinWidth}}>
                                            {apiReq?<Icon>loop</Icon>:t("button.save")}
                                        </MDButton>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </DetailContentsLayout>
            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color="warning"
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
            />
            <Footer />
        </DetailLayout>
    );
}

export default PduDetailView;
