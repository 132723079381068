import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import MDDatePicker from "components/MDDatePicker";
import {Refresh} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import TestService from "service/TestService";
import {DefaultResponse,} from "common/Types";
import DateUtil from "common/DateUtil";
import {BRAND_NAME, getDetailByCode, LANGUAGE} from "common/Constant";
import MDSnackbar from "components/MDSnackbar";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";
import MDTypography from "components/MDTypography";
import MDBadge from "../../../../components/MDBadge";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import Icon from "@mui/material/Icon";
import GPUValidationService from "../../../../service/GPUValidationService";

interface Props {
    handleModelValue: (value:any)=>void
    closeModalBtn:()=>void
}
function ModelGenerateHistoryModal({handleModelValue, closeModalBtn}: Props): JSX.Element {

    const {t} = useTranslation();


    const [list, setList] = useState([])


    const columns = [
        { Header: t("genHistory.thead.name"), accessor: "name", sortable: false},
        { Header: t("genHistory.thead.deviceName"), accessor: "deviceName", sortable: false},
        { Header: t("genHistory.thead.genNo"), accessor: "genNo", sortable: false},
        { Header: t("genHistory.thead.iterNo"), accessor: "iterNo", sortable: false },
        { Header: t("genHistory.thead.status"), accessor: "status", sortable: false },
        { Header: t("genHistory.thead.startDt"), accessor: "startDt", sortable: false },
        { Header: t("genHistory.thead.endDt"), accessor: "endDt", sortable: false },
    ];
    /*paging start*/
    const entries = ["10", "25", "50", "100"];
    const [entriesPerPage, setEntriesPerPage] = useState( 10 )
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [sort, setSort] = useState("")

    const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")
    const [errMsg, setErrMsg] = useState<string>('');
    const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);

    }
    const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setErrMsg(msg)
        setErrColor(color)
        setOpenErrMsg(true)

    }
    // Setting the entries starting point

    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;
    // Setting the entries ending point
    let entriesEnd;

    const totalPageLength = Math.ceil(list.length / entriesPerPage);
    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength ) {
        entriesEnd = list.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }

    const [ fromDate, setFromDate ] = useState<Date>(null);

    const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())
    // 등록일 handle function
    const handleFromDateChange = ( _fromDate: Date) => {
        setFromDate(_fromDate)

    }
    const handleToDateChange = ( _toDate: Date) => {
        setToDate(_toDate)

    }
    useEffect(()=> {
        if(fromDate && toDate && fromDate > toDate){
            openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
            setFromDate(undefined)
            setToDate(undefined)
        }
    },[fromDate, toDate])


    const {refetch: getGenerateHistoryList} = useQuery<DefaultResponse, AxiosError>(
        ["getGenerateHistoryList"],
        () => GPUValidationService.getGenerateHistoryList( page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate)),
        {
            onSuccess: (res: DefaultResponse) => {
                if(res.code === 200 && res.subCode === 0) {
                    console.log(res.data.content)
                    setList(res.data.content)
                    setTotal(res.data.total)
                }
            }
        }
    )


    useEffect(() => {
        getGenerateHistoryList()
    }, [page]);

    useEffect(() => {
        setPage(1)
        getGenerateHistoryList()
    }, [entriesPerPage]);
    const onClickHeader = (_accessor:string) => {
        if(sort === _accessor){
            if(direction && direction === "DESC"){
                setDirection("ASC")
            }else{
                setDirection("DESC")
            }
        }else{
            setDirection("DESC")
            setSort(_accessor)
        }

    }
    const activeEnter = (ev:KeyboardEvent) => {
        if(ev.key === "Enter") {
            getGenerateHistoryList();
        }

    }

    useEffect( () => {
        if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
            getGenerateHistoryList()
        }
    }, [sort, direction]);
    function renderModelGenerateStatus(status: string): JSX.Element {
        let color: "success" | "primary" | "secondary" | "warning" | "error"
        const content = t(getDetailByCode("TEST_EXECUTION_STATUS", status))
        if (status === "P") {
            color = "success"
        } else if (status === "F") {
            color = "primary"
        } else if (status === "E") {
            color = "secondary"
        } else if (status === "X") {
            color = "error"
        } else {
            color = "warning"

        }
        return (
            <MDBadge badgeContent={content}
                     container color={color} size="sm" variant="contained"/>
        )
    }

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(85)

    return (

        <Card sx={{width : "100%", px : 4, py : 2,}}>
            <MDBox gap={2}
                   sx={{
                       overflow : "auto",
                       "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                       "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                       ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                   }}>

                <MDBox display={"flex"} justifyContent={"space-between"} pb={1}>
                    <MDTypography variant={"h5"}>
                        Model Generate History List
                    </MDTypography>
                    <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
                        <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
                    </MDBox>
                </MDBox>

                {/* 검색 영역 layout */}
                <SearchLayout>
                    <MDBox  display={"flex"} justifyContent={"space-between"}>
                        <MDBox display={"flex"} width="90%" alignItems="center">
                            <MDBox display={"flex"} alignItems="center" >
                                <MDDatePicker
                                    value={ fromDate }
                                    input = {
                                        {
                                            size : "small",
                                            height: "37px",
                                            placeholder : t("search.fromDate"),
                                        }
                                    }
                                    options = {
                                        {
                                            maxDate : "today",
                                            // locale : Korean,
                                        }
                                    }
                                    onChange = { ( currentTarget: any ) => {
                                        const target = currentTarget[0];
                                        handleFromDateChange( target );
                                    }}
                                />
                                <MDBox px={0.25}>~</MDBox>
                                <MDDatePicker
                                    value={ toDate }
                                    input = {
                                        {
                                            size : "small",
                                            height: "37px",
                                            placeholder : t("search.toDate"),
                                        }
                                    }
                                    options = {
                                        {
                                            maxDate : "today",
                                            // locale : Korean,
                                        }
                                    }
                                    onChange = { ( currentTarget: any ) => {
                                        const target = currentTarget[0];
                                        handleToDateChange( target );
                                    }}
                                />
                                <MDBox px={0.25}/>
                                <MDButton iconOnly={true} onClick={ () => { setFromDate( undefined ); setToDate( undefined ); } }>
                                    <Refresh/>
                                </MDButton>
                            </MDBox>

                        </MDBox>
                        <MDBox display={"flex"}>
                            <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getGenerateHistoryList()}>
                                {t("search.button.search")}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </SearchLayout>

                {/* 테이블 헤더 */}
                <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />

                {/* 테이블 전체 layout */}
                <Card>
                    <TableContainer
                        sx={{
                            boxShadow : "none", overflow : "auto",
                            "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                            "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                            ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                        }}
                    >
                        {/* 테이블 contents */}
                        <Table>
                            <MDBox component={"thead"}>
                                <TableRow>

                                    {columns.map(( column : any, index : number ) => (
                                        <DataTableHeadCell
                                            key={index}
                                            width={column.width}
                                            align={"left"}
                                            onClick={onClickHeader}
                                            accessor={column.accessor}
                                            direction={direction}
                                            sort={sort}
                                            sortable={column.sortable}
                                        >
                                            {column.Header}
                                        </DataTableHeadCell>
                                    ))}
                                    <DataTableHeadCell align={"left"}>
                                        {}
                                    </DataTableHeadCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>
                                {list.map(( row : any, index : number ) => {
                                    return(
                                        <TableRow key={index} >

                                            {columns.map((column, colIndex) => {
                                                if (column.accessor === 'name' && (index === 0 || ((list[index - 1].name !== row.name) || (list[index - 1].deviceName !== row.deviceName) || (list[index - 1].genNo !== row.genNo)))){
                                                    return (
                                                        <DataTableBodyCell
                                                            key={`${index}_${colIndex}`}
                                                            align="left"
                                                            rowSpan={list.filter(item => (item.name === row.name && item.deviceName === row.deviceName && item.genNo === row.genNo)).length}
                                                        >
                                                            {row[column.accessor]}
                                                        </DataTableBodyCell>
                                                    );
                                                } else if (column.accessor === 'deviceName' && (index === 0 || ((list[index - 1].name !== row.name) || (list[index - 1].deviceName !== row.deviceName) || (list[index - 1].genNo !== row.genNo)))) {
                                                    return (
                                                        <DataTableBodyCell
                                                            key={`${index}_${colIndex}`}
                                                            align="left"
                                                            rowSpan={list.filter(item => (item.name === row.name && item.deviceName === row.deviceName && item.genNo === row.genNo)).length}
                                                        >
                                                            {row[column.accessor]}
                                                        </DataTableBodyCell>
                                                    );
                                                } else if (column.accessor === 'genNo' && (index === 0 || ((list[index - 1].name !== row.name) || (list[index - 1].deviceName !== row.deviceName) || (list[index - 1].genNo !== row.genNo)))) {
                                                    return (
                                                        <DataTableBodyCell
                                                            key={`${index}_${colIndex}`}
                                                            align="left"
                                                            rowSpan={list.filter(item => (item.deviceName === row.deviceName && row.name === item.name && item.genNo === row.genNo)).length}
                                                        >
                                                            {"Gen "+row[column.accessor]}
                                                        </DataTableBodyCell>
                                                    );
                                                } else if (column.accessor === 'genNo' || column.accessor === 'deviceName' || column.accessor === 'name') {
                                                    return null; // 이전 행과 genNo가 같으면 rowspan 적용하지 않고 빈 셀로 처리
                                                } else if (column.accessor === "iterNo") {
                                                    return (
                                                        <DataTableBodyCell
                                                            key={`${colIndex}`}
                                                            align="left"
                                                        >
                                                            {"#" + row[column.accessor]}
                                                        </DataTableBodyCell>
                                                    );
                                                }
                                                return (
                                                    <DataTableBodyCell
                                                        key={`${index}_${colIndex}`}
                                                        align="left"
                                                        row={row}
                                                    >
                                                        {
                                                            column.accessor === "status" ? renderModelGenerateStatus(row[column.accessor]):
                                                                row[column.accessor]
                                                        }
                                                    </DataTableBodyCell>
                                                );
                                            })}
                                            <DataTableBodyCell row={row}>
                                                <MDBox display={"flex"}>
                                                    <MDButton variant={"outlined"} size="small" color={"info"} onClick={() => {
                                                        console.log(row.id)
                                                        handleModelValue({id:row.id, name:row.name+"/GEN "+row.genNo+"/#"+row.iterNo})
                                                        closeModalBtn()
                                                    }} sx={{minWidth : buttonMinWidth}}>
                                                        {t("button.select")}
                                                    </MDButton>
                                                </MDBox>
                                            </DataTableBodyCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* 테이블 footer */}
                    <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page} buttonDisabled
                                 setEntriesPerPage={setEntriesPerPage} onClickSave={() => {}} onClickDelete={() => {}} onChangePage={setPage}/>
                </Card>

                <MDSnackbar
                    anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                    color={errColor}
                    icon="notifications"
                    title={BRAND_NAME}
                    content={errMsg}
                    dateTime=""
                    open={openErrMsg}
                    close={toggleSnackbar}
                />


            </MDBox>
        </Card>
    )
}

export default ModelGenerateHistoryModal