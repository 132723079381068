import MDBox from "components/MDBox";
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import React, {useEffect, useRef, useState} from "react";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import {Theme} from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import DetailLayout from "examples/LayoutContainers/CustomLayout/detail/DetailLayout";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import DetailContentsLayout from "examples/LayoutContainers/CustomLayout/detail/DetailContentsLayout";
import FormField from "../../component/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import {Category, DefaultResponse, TestCase, TestExecution, TestExecutionTestCase} from "common/Types";
import {MLPerfExecutionSaveConstructor} from "common/Constructors";
import {useQuery} from "@tanstack/react-query";
import TestService from "service/TestService";
import {AxiosError} from "axios";
import StringUtil from "common/StringUtil";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import {BRAND_NAME, LANGUAGE} from "common/Constant";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import pxToRem from "assets/theme/functions/pxToRem";
import {useTranslation} from "react-i18next";
import ModalTestSuiteListView from "../../test/suite/modal/ModalTestSuiteListView";
import MlPerfService from "../../../service/MlPerfService";
import ConfirmationDialog from "../../component/ConfirmationDialog";
import ProjectService from "../../../service/ProjectService";

function MlPerfExecutionView(...rest: any): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation();
    const navigate = useNavigate()
    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [projectList, setProjectList] = useState(null);

    const [testCaseList, setTestCaseList] = useState<TestCase[]>([]);
    const [testCaseListAll, setTestCaseListAll] = useState<TestCase[] | undefined>(undefined);
    const [resultList, setResultList] = useState<TestCase[]>([]);
    const [apiReq, setApiReq] = useState<Boolean>(false);
    const [formValue, setFormValue] = useState(null);
    const [_project, setProject] = useState(location.state.mlperf_execution.projectId?{label:location.state.mlperf_execution.projectName, id:location.state.mlperf_execution.projectId}:null)


    const inputRef = useRef(null);

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/

    /*modal start*/
    const [modalOpen, setModalOpen] = useState(false);

    function onHandleClick() {
        if (_project?.id) {
            setModalOpen(true)
        } else {
            setErrMsg(t("ERRORMESSAGE.PROJECT.NAME.SELECT"))
            setOpenErrMsg(true)
        }

    }

    function handleClose() {
        setModalOpen(false)
    }

    /*modal end*/

    //수정 일때 list에서 전달 받은 데이터
    const [testExecution, setTestExecution] = useState<TestExecution>();

    /* from validation start*/
    const form = {
        formId: "create-test_execution-form",
        formField: {
            name: {
                name: "name",
                label: t("Name"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.NAME"),
            },
            projectName: {
                name: "projectName",
                label: t("Project Name"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.PROJECT.NAME"),
            },
        },
    }

    const {
        formField: {name, projectName},
    } = form;

    const validations = Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [projectName.name]: Yup.string().required(projectName.errorMsg),
    })

    const [initialValues, setInitialValues] = useState({
        [name.name]: location.state.mlperf_execution?.name ? location.state.mlperf_execution.name : "",
        [projectName.name]: location.state.mlperf_execution.projectName ? location.state.mlperf_execution.projectName : null,
    })


    const {formId, formField} = form;
    const currentValidation = validations;
    /* from validation end*/

    const _mlPerfExecution = new MLPerfExecutionSaveConstructor(
        undefined,
        '',
        '',
        undefined,
        undefined,
    )

    const {refetch: saveTestExecution} = useQuery<any, AxiosError>(
        ['saveTestExecution'],
        () => MlPerfService.saveMLPerfExecution(_mlPerfExecution),
        {
            onSuccess: (res: DefaultResponse) => {
                setApiReq(false)
                if (res.code === 200 && res.subCode === 0) {
                    goBack()
                }
            }
        }
    )

    const {refetch: getTestCaseList} = useQuery<any, AxiosError>(
        ['getTestCaseList'],
        () => MlPerfService.getMlPerfExecutionTestCaseList(),
        {
            onSuccess: (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    setTestCaseListAll(res.data)
                }
            }
        }
    )

    const {refetch: getTestExecutionTestCaseList} = useQuery<any, AxiosError>(
        ['getTestExecutionTestCaseList'],
        () => TestService.getTestExecutionTestCaseList(testExecution.id),
        {
            onSuccess: (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    const _testCaseList: TestCase[] = []
                    const _resultList: TestCase[] = []
                    for (let i = 0; i < testCaseList.length; i++) {
                        const _testCase: TestCase = testCaseList[i]
                        res.data.filter((testExecutionTestCase: TestExecutionTestCase) => testExecutionTestCase.testCaseId === testCaseList[i].id).forEach((i: TestExecutionTestCase) => {
                            _testCase.isSelected = true
                            _testCase.orderSeq = i.orderSeq
                            _resultList.push(_testCase)
                        })
                        _testCaseList.push(_testCase)
                    }

                    //저장된 순서대로 정렬
                    _resultList.sort(function (a, b) {
                        return a.orderSeq - b.orderSeq;
                    })

                    //TestCaseList checkbox 선택
                    setTestCaseList(_testCaseList)
                    //ResultList 데이터 입력
                    setResultList(_resultList)
                }
            }
        }
    )

    const {refetch: getProjectListByDomainType} = useQuery<any, AxiosError>(
        ['getProjectListByDomainType'],
        () => ProjectService.getProjectListByDomainType(location.state.domain),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    console.log(res.data)
                    setProjectList(res.data)
                }
            }
        }
    )

    const getProjectOption = (list:any[])=>{
        const projectList:{id:string, label:string}[] = []
        list?.forEach(i => {
            projectList.push({
                id : i.id,
                label:i.name
            })
        })
        return projectList
    }

    const categoryClick = (_category: any) => {
        const _categoryList: any[] = []
        categoryList.forEach(category => {
            if (category.name === _category.name) {
                // 클릭한 친구는 접었다 폈다.
                category.isOpen = !category.isOpen
            }
            _categoryList.push(category)
        })
        setCategoryList(_categoryList)
    }

    const testCaseClick = (_testCase: any) => {
        setTestCaseList(handleTestCase(_testCase))
    }

    const handleTestCase = (_testCase: any) => {
        const _testCaseList: any[] = []
        testCaseList.forEach(testCase => {
            if (testCase.category === _testCase.category && testCase.fileName === _testCase.fileName) {
                if (testCase.isSelected) {
                    setResultList(handleResult(_testCase, "remove"))
                    testCase.isSelected = !testCase.isSelected
                } else {
                    setResultList(handleResult(_testCase, "add"))
                    if (resultList.length < 1) testCase.isSelected = !testCase.isSelected
                }
            }
            _testCaseList.push(testCase)
        })
        return _testCaseList
    }

    const handleResult = (_testCase: any, type: string) => {
        const _resultList: any[] = []
        switch (type) {
            case "add":
                resultList.forEach(testCase => {
                    _resultList.push(testCase)
                })
                if (resultList.length === 0) {
                    _resultList.push(_testCase)
                } else {
                    setErrMsg(t("ERRORMESSAGE.MLPERF.TESTCASE.SELECT"))
                    setOpenErrMsg(true)
                }
                break;
            case "remove":
                resultList.forEach(testCase => {
                    if (!(testCase.category === _testCase.category && testCase.fileName === _testCase.fileName)) {
                        _resultList.push(testCase)
                    }
                })
                break;
        }

        return _resultList
    }


    const handleSubmit = (values: any, actions: any) => {
        if(resultList && resultList.length > 0){
            setFormValue(values);
            openConfirm(t("confirm.save"), "info");
        }else{
            setErrMsg(t("ERRORMESSAGE.TESTCASE.SELECT"))
            setOpenErrMsg(true)
        }
    }

    const handleYes = ()=>{
        if (!apiReq) {
            const _resultList: TestCase[] = [];
            resultList.forEach((i, index) => {
                _resultList.push({
                    id: i.id,
                    orderSeq: index
                })
            })

            _mlPerfExecution.id = testExecution?.id ? testExecution.id : undefined;
            _mlPerfExecution.name = formValue.name
            _mlPerfExecution.domainType = location.state.domain
            _mlPerfExecution.projectId = _project.id
            _mlPerfExecution.testCase = _resultList.pop()
            // console.log(_mlPerfExecution)
            setApiReq(true)
            saveTestExecution()
        }
    }

    const handleNo = () => {
        toggleDialog()
    }


    const goBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (!testCaseListAll) {
            getTestCaseList()
        } else {
            //수정 화면 일때
            // if(testExecution?.projectName){
            //   renderTestCaseList(testExecution.projectName)
            // }
        }
    }, [testCaseListAll])

    useEffect(() => {
        //수정 화면 일때
        if (testExecution && testCaseListAll) {
            renderTestCaseList(testExecution.projectId)
            getTestExecutionTestCaseList()
        }
    }, [testExecution, testCaseListAll])

    // useEffect(() => {
    //     if (testCaseList && testCaseList.length > 0) {
    //         if (testExecution) { //수정이면 호츌
    //             getTestExecutionTestCaseList()
    //         }
    //     }
    // }, [testCaseList])

    const renderTestCaseList = (_projectId: any) => {
        if (!StringUtil.isEmpty(_projectId)) {
            const testCaseList: TestCase[] = []
            const categoryList: string[] = []
            const _categoryList: Category[] = [] // 중복 제거된 categoryList
            testCaseListAll.forEach(i => {
                if (i.projectId === _projectId) {
                    testCaseList.push({
                        id: i.id,
                        fileName: i.fileName?.toLowerCase(),
                        category: i.category,
                        isSelected: false
                    })
                    categoryList.push(i.category)
                }
            });

            StringUtil.deleteDuplicate(categoryList).forEach(i => {
                _categoryList.push({
                    name: String(i),
                    isOpen: false
                })
            })
            setTestCaseList(testCaseList)
            setCategoryList(_categoryList)
            setResultList([])

        }
    }

    //수정일때 시작
    useEffect(() => {
        getProjectListByDomainType()
        if (location.state.type === "Edit") {
            console.log(location.state.mlperf_execution)
            setTestExecution(location.state.mlperf_execution)
        }
    }, [])

    useEffect(() => {
        if (testExecution) {
            getTestCaseList()
        }
    }, [testExecution])


    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(100)


    function getStepContent(formData: any): JSX.Element {
        const {formField, values, errors, touched} = formData;
        const {name, projectName} = formField;
        const {
            name: nameV,
            projectName: projectNameV,
        } = values;
        return <MDBox>
            <MDBox lineHeight={0}>
                <MDTypography variant="h5">
                    {t(`title.path.${location.state.type}`, {title: location.state.menu_name})}
                </MDTypography>
            </MDBox>

            <Grid mt={1.625} display="flex" flexDirection="column">
                <Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={nameV}
                            placeholder={name.placeholder}
                            error={errors.name && touched.name}
                            success={nameV?.length > 0 && !errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            value={_project}
                            options={getProjectOption(projectList)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label || ""}
                            onChange={(event, newValue) => {
                                values.projectName = newValue.label
                                setProject(newValue)
                                renderTestCaseList(newValue.id)
                            }}
                            renderInput={(params) => (
                                <FormField {...params}
                                           type={projectName.type}
                                           label={projectName.label}
                                           name={projectName.name}
                                           value={projectNameV}
                                           placeholder={projectName.placeholder}
                                           error={errors.projectName && touched.projectName}
                                           success={projectNameV?.length > 0 && !errors.projectName}
                                           onChange={() => {
                                           }}  //text 입력후에 selectbox에 값이 없어도 성공으로 나오는데 onchange 넣으니 해결되어서 추가함. 이유는 모르겠음
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>;
    }

    return (
        <DetailLayout pageTitle={location.state.menu_name}>
            {/* 이름 입력 영역*/}
            <DetailContentsLayout>
                <Grid item>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({values, errors, touched, isSubmitting}) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{height: "100%"}}>
                                    <MDBox px={3} pt={3}>
                                        <MDBox>
                                            {getStepContent({
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                            })}
                                        </MDBox>
                                    </MDBox>
                                    <MDBox py={3} px={3} display={"flex"} justifyContent={"flex-end"}>
                                        <MDButton size={"medium"} variant={"outlined"} color={"secondary"}
                                                  onClick={() => {
                                                      goBack()
                                                  }} sx={{minWidth: buttonMinWidth}}>
                                            {t("button.cancel")}
                                        </MDButton>
                                        <MDBox display={"inline-block"} p={0.5}/>
                                        <MDButton type="submit" size={"medium"} variant={"outlined"} color={"info"}
                                                  onClick={() => {
                                                  }} sx={{minWidth: buttonMinWidth}}>
                                            {apiReq ? <Icon>loop</Icon> : t("button.save")}
                                        </MDButton>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </DetailContentsLayout>

            {/* 테스트 케이스 입력 영역*/}
            <MDBox my={3} mx={3}>
                <DetailContentsLayout>
                    <Grid display={"flex"} justifyContent="center" alignItems="center">
                        <MDBox px={3} py={3} sx={{width: '100%'}} display={"flex"}>
                            {/*왼쪽*/}
                            <MDBox sx={{width: '47.5%', maxWidth: 500}}>
                                <MDTypography variant="button" color={'dark'} fontWeight="medium" gutterBottom>
                                    {t("testSuite.prepared")} ( {testCaseList.length} )
                                </MDTypography>
                                <Grid item sx={({
                                                    palette: {light},
                                                    borders: {borderWidth}
                                                }: Theme) => ({
                                    border: `${borderWidth[1]} solid ${light.main}`,
                                    height: "50vh",
                                    overflow: "auto",
                                    marginTop: "10px"
                                })}>
                                    <List>
                                        {
                                            categoryList?.length > 0 && categoryList.map(function (category) {
                                                return <MDBox key={category.name}>
                                                    <ListItemButton onClick={() => categoryClick(category)} sx={({
                                                                                                                     palette: {light},
                                                                                                                     typography: {size},
                                                                                                                     borders: {borderWidth}
                                                                                                                 }: Theme) => ({
                                                        pl: 4,
                                                        border: `${borderWidth[1]} solid ${light.main}`
                                                    })}>
                                                        <ListItemText>
                                                            <MDBox display="flex">
                                                                <MDTypography variant="button" color={'dark'}
                                                                              fontWeight="medium" gutterBottom>
                                                                    {category.name}
                                                                </MDTypography>
                                                                <Icon fontSize="medium" onClick={() => {
                                                                }}>keyboard_double_arrow_right</Icon>
                                                            </MDBox>
                                                        </ListItemText>
                                                        {category.isOpen ? <Icon fontSize="medium">remove</Icon> :
                                                            <Icon fontSize="medium">add</Icon>}
                                                    </ListItemButton>
                                                    <Collapse in={category.isOpen} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding sx={({
                                                                                                      palette: {light},
                                                                                                      borders: {borderWidth}
                                                                                                  }: Theme) => ({
                                                            border: `${borderWidth[1]} solid ${light.main}`,
                                                            paddingTop: "3px"
                                                        })}>
                                                            {
                                                                testCaseList?.filter(i => i.category === category.name).length > 0 && testCaseList.filter(i => i.category === category.name).map(function (testCase) {
                                                                    return <ListItemButton key={testCase.fileName}
                                                                                           onClick={() => testCaseClick(testCase)}
                                                                                           sx={{height: "30px"}}>
                                                                        <ListItemText>
                                                                            <MDBox display={"flex"}
                                                                                   justifyContent={"space-between"}>
                                                                                <MDBox color="text"
                                                                                       sx={{pl: 3, verticalAlign: "middle"}}>
                                                                                    <MDTypography variant="button"
                                                                                                  sx={{lineHeight: "2"}}>{testCase.fileName}</MDTypography>
                                                                                </MDBox>
                                                                                <MDBox>
                                                                                    <Checkbox
                                                                                        checked={testCase.isSelected}></Checkbox>
                                                                                </MDBox>
                                                                            </MDBox>
                                                                        </ListItemText>
                                                                    </ListItemButton>;
                                                                })
                                                            }
                                                        </List>
                                                    </Collapse>
                                                </MDBox>;
                                            })
                                        }
                                    </List>
                                </Grid>
                            </MDBox>

                            {/*중간*/}
                            <Grid item sx={{width: '5%', maxWidth: 200}}>
                                <Grid container direction="column" alignItems="center">
                                </Grid>
                            </Grid>

                            {/*오른쪽*/}
                            <MDBox sx={{width: '47.5%', maxWidth: 500}}>
                                <MDTypography pb={1} variant="button" color={'dark'} fontWeight="medium" gutterBottom>
                                    {t("testExecution.testcases")} ( {resultList.length} )
                                </MDTypography>
                                <Grid item sx={({
                                                    palette: {light},
                                                    borders: {borderWidth}
                                                }: Theme) => ({
                                    border: `${borderWidth[1]} solid ${light.main}`,
                                    height: "50vh",
                                    overflow: "auto",
                                    marginTop: "10px"
                                })}>
                                    <List component="div" disablePadding>
                                        {
                                            resultList?.filter(i => i.isSelected).length > 0 && resultList?.filter(i => i.isSelected).map((testCase, index) => {
                                                return <ListItemButton key={testCase.fileName}>
                                                    <ListItemText>
                                                        <MDBox display={"flex"} justifyContent={"space-between"}>
                                                            <MDBox display="flex">
                                                                <MDBox color="text" sx={{
                                                                    pl: 1,
                                                                    verticalAlign: "middle",
                                                                    fontSize: "1rem"
                                                                }}>
                                                                    <MDTypography
                                                                        variant="button">{testCase.fileName}</MDTypography>
                                                                </MDBox>
                                                            </MDBox>
                                                            <Icon fontSize="medium"
                                                                  onClick={() => handleTestCase(testCase)}>clear</Icon>
                                                        </MDBox>
                                                    </ListItemText>
                                                </ListItemButton>;
                                            })
                                        }
                                    </List>
                                </Grid>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </DetailContentsLayout>
            </MDBox>
            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color="warning"
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
            />
            <Modal
                open={modalOpen} onClose={handleClose}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={modalOpen}>
                    <MDBox
                        display="flex"
                        width="95vw" minHeight="85vh" maxHeight="80vh" shadow="xl"
                        position="fixed" top="50%" left="50%"
                        sx={{transform: "translate(-50%, -50%)"}}
                    >
                        <ModalTestSuiteListView selectTestSuite={null} _projectName={_project} closeModalBtn={handleClose}/>
                    </MDBox>
                </Fade>
            </Modal>
            <Footer/>
        </DetailLayout>
    );
}

export default MlPerfExecutionView;