import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import DateUtil from "common/DateUtil";
import {AxiosError} from "axios";
import {DefaultResponse} from "common/Types";
import StorageUtil from "common/StorageUtil";
import {getCodeListForSearch, getDetailByCode, USER_ID} from "common/Constant";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import ReservationService from "service/ReservationService";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import MDSnackbar from "components/MDSnackbar";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import Checkbox from "@mui/material/Checkbox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";


function ReservationView(): JSX.Element {

    const {t} = useTranslation();
    const [msg, setMsg] = useState<string>("");
    const [isOpenMsg, setIsOpenMsg] = useState(false);
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [deviceId, setDeviceId] = useState<number>(0)
    const [returnReservationId, setReturnReservationId] = useState<number>()
    const [cancelReservationId, setCancelReservationId] = useState<number>()
    const [deviceName, setDeviceName] = useState<string>("")
    const [deviceStatus, setDeviceStatus] = useState(null)
    const [reserveStatus, setReservationStatus] = useState(null)
    const [isMine, setIsMine] = useState<boolean>(false)
    const location = useLocation()
    const columns = [
        {Header: t("reservation.thead.deviceName"), accessor: "deviceName", sortable: true},
        {Header: t("reservation.thead.deviceType"), accessor: "deviceType", sortable: true},
        {Header: t("reservation.thead.ip"), accessor: "containerIp", sortable: false},
        {Header: t("reservation.thead.deviceStatus"), accessor: "deviceId", sortable: false},
        {Header: t("reservation.thead.user"), accessor: "onUserName", sortable: true},
        {Header: t("reservation.thead.startAt"), accessor: "startDt", sortable: true},
        {Header: t("reservation.thead.expiredAt"), accessor: "expiredDt", sortable: true},
        {Header: t("reservation.thead.reserveStatus"), accessor: "queueUserId", sortable: false},
        {Header: t("reservation.thead.reserveUser"), accessor: "queueUserName", sortable: false},
        {Header: t("reservation.thead.reserve"), accessor: "onReservationId", sortable: false},
    ]

    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("deviceName")
    const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")
    const entries = ["10", "25", "50", "100"];
    const [entriesPerPage, setEntriesPerPage] = useState( 10 );
    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1 ;
    const totalPageLength = Math.ceil(data.length / entriesPerPage);
    let entriesEnd;
    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength ) {
        entriesEnd = data.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }

    const {refetch: insertReservation} = useQuery<DefaultResponse, AxiosError>(
        ["insertReservation"],
        () => ReservationService.insertReservation(deviceId),
        {
            onSuccess : (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    getDeviceList()
                } else {
                    setMsg(res.error)
                    setIsOpenMsg(true)
                }
            }
        }
    )

    const {refetch: getDeviceList} = useQuery<DefaultResponse, AxiosError>(
        ["getDeviceList"],
        () => ReservationService
            .getReservationList(page,
                entriesPerPage,
                sort,
                direction,
                deviceName,
                deviceStatus?deviceStatus.id:"",
                reserveStatus?reserveStatus.id:"",
                isMine),
        {
            onSuccess : (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    setData(res.data.content)
                    setTotal(res.data.total)
                }
            }
        }
    )

    const {refetch: returnDevice} = useQuery<DefaultResponse, AxiosError>(
        ["returnDevice"],
        () => ReservationService.returnDevice(returnReservationId),
        {
            onSuccess : (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    getDeviceList()
                } else {
                    setMsg(res.error)
                    setIsOpenMsg(true)
                }
            }
        }
    )

    const {refetch: cancelReservation} = useQuery<DefaultResponse, AxiosError>(
        ["cancelReservation"],
        () => ReservationService.cancelReservation(cancelReservationId),
        {
            onSuccess : (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    getDeviceList()
                } else {
                    setMsg(res.error)
                    setIsOpenMsg(true)
                }
            }
        }
    )

    useEffect(() => {
        setPage(1)
        getDeviceList()
    }, [entriesPerPage])
    useEffect(() => {
        getDeviceList()
    }, [page])

    const onClickHeader = (_accessor:string) => {
        if(sort === _accessor){
            if(direction && direction === "DESC"){
                setDirection("ASC")
            } else {
                setDirection("DESC")
            }
        }else{
            setDirection("DESC")
            setSort(_accessor)
        }
    }
    useEffect( () => {
        if(sort && sort !== "" && direction && (direction === "DESC" || direction === "ASC")){
            getDeviceList()
        }
    },[sort, direction])
    useEffect( () => {
        getDeviceList()
    },[isMine])
    useEffect( () => {
        if(deviceStatus){
            setPage(1)
            getDeviceList()
        }
    },[deviceStatus])
    useEffect( () => {
        if(reserveStatus){
            setPage(1)
            getDeviceList()
        }
    },[reserveStatus])

    function renderReserveStatus(row:any) {
        const onId = row["onReservationId"]
        const queueId = row["queueReservationId"]

        if(onId && !queueId) {
            return (
                <MDBadge badgeContent={t("reservation.rstatus.available")}
                         container color="warning" size="sm" variant="contained" minWidth={100}/>
            )
        }

        if(onId && queueId) {
            return (
                <MDBadge badgeContent={t("reservation.rstatus.unavailable")}
                         container color="secondary" size="sm" variant="contained" minWidth={100}/>
            )
        }

        return ""
    }

    function renderDeviceStatus(row:any) {
        const onId = row["onReservationId"]
        const queueId = row["queueReservationId"]

        if(onId || queueId) {
            return (
                <MDBadge badgeContent={t("reservation.dstatus.disable")}
                         container color="secondary" size="sm" variant="contained" minWidth={70}/>
            )
        } else {
            return (
                <MDBadge badgeContent={t("reservation.dstatus.idle")}
                         container color="info" size="sm" variant="contained" minWidth={70}/>
            )
        }

    }

    const toggleSnackbar = () => {
        setIsOpenMsg(false)
        setMsg("")
    }

    function renderReservationButton(row:any) {
        const minWidth = "90px"
        const deviceId = row["deviceId"]
        const onUserId = row["onUserId"]
        const queueUserId = row["queueUserId"]
        const queueReservationId = row["queueReservationId"]
        const onReservationId = row["onReservationId"]
        const viewerId = Number(StorageUtil.getLocalStorage(USER_ID))

        if(queueReservationId) {
            if(queueUserId === viewerId) {
                return( <MDButton color="warning" size="small" variant="contained"
                                  onClick={(e) => {onCancelReservation(e, queueReservationId)}}
                                  sx={{minWidth:minWidth}}>
                            {t("reservation.cancel")}
                        </MDButton>)
            }
            if(onUserId !== viewerId) {
                return( <MDButton color="secondary" size="small" variant="outlined" sx={{minWidth:minWidth}} disabled={true} >
                            {t("reservation.disable")}
                        </MDButton>)
            }
        }

        if(onReservationId) {
            if(onUserId === viewerId) {
                return( <MDButton color="info" size="small" variant="contained"
                                  onClick={(e)=> {onReturnDevice(e, onReservationId)}}
                                  sx={{minWidth:minWidth}}>
                            {t("reservation.return")}
                        </MDButton>)
            }
            return( <MDButton color="warning" size="small" variant="outlined"
                              onClick={(e) => {onUseDevice(e, deviceId)}}
                              sx={{minWidth:minWidth}}>
                        {t("reservation.able")}
                    </MDButton>)
        }

        return( <MDButton color="info" size="small" variant="outlined"
                          onClick={(e) => {onUseDevice(e, deviceId)}}
                          sx={{minWidth:minWidth}}>
                    {t("reservation.use")}
                </MDButton>)
    }

    const onUseDevice = (e:any, deviceId:number) => {
        setDeviceId(deviceId)
    }
    useEffect(() => {
        if(deviceId) {
            insertReservation()
        }
    }, [deviceId])

    const onCancelReservation = (e:any, reservationId:number) => {
        setCancelReservationId(reservationId)
    }
    useEffect(() => {
        if(cancelReservationId) {
            cancelReservation()
        }
    },[cancelReservationId])

    const onReturnDevice = (e:any, reservationId:number) => {
        setReturnReservationId(reservationId)
    }
    useEffect(() => {
        if(returnReservationId) {
            returnDevice()
        }
    },[returnReservationId])

    const activeEnter = (ev:KeyboardEvent) => {
        if(ev.key === "Enter") {
            setPage(1)
            getDeviceList();
        }
    }

    return (
    <TableLayout pageTitle={location.state?.menu_name}>
            <SearchLayout>
                <MDBox  display={"flex"} justifyContent={"space-between"}>
                    <MDBox display={"flex"} width="90%" alignItems="center">
                        <MDBox alignItems="center" width="20%" minWidth={"100px"}>
                            <Autocomplete
                                fullWidth
                                disableClearable
                                value={deviceStatus}
                                options={getCodeListForSearch("RESERVATION.SEARCH.DSTATUS").map((e: {label: string, id: string}) => {
                                    e.label = t(e.label)
                                    return e
                                  })}
                                onChange={(event, newValue) => {
                                    setDeviceStatus(newValue)
                                }}
                                size="small"
                                renderInput={(params) => <MDInput {...params} placeholder={t("reservation.search.dstatus")} onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}/>}
                            />
                        </MDBox>
                        <MDBox alignItems="center" width="20%" minWidth={"100px"} pl={3}>
                            <Autocomplete
                                fullWidth
                                disableClearable
                                value={reserveStatus}
                                options={getCodeListForSearch("RESERVATION.SEARCH.RSTATUS").map((e: {label: string, id: string}) => {
                                    e.label = t(e.label)
                                    return e
                                })}
                                onChange={(event, newValue) => {
                                    setReservationStatus(newValue)
                                }}
                                size="small"
                                renderInput={(params) => <MDInput {...params} placeholder={t("reservation.search.rstatus")} onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}/>}
                            />
                        </MDBox>
                        <MDBox alignItems="center" width="30%" pl={3}>
                            <MDInput
                                placeholder={t("reservation.search.name")}
                                value={deviceName}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }: any) => {
                                    setDeviceName(currentTarget.value)
                                }}
                                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                            />
                        </MDBox>
                        <MDBox display="flex" alignItems="center" pl={3}>
                            <Checkbox size={"small"}
                                  onChange={( e) => {
                                      setIsMine(e.target.checked)
                                  }}/>
                            <MDTypography variant="caption" color="inherit">
                                {t("reservation.search.isMine")}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox display={"flex"}>
                        <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getDeviceList()}>
                            {t("search.button.search")}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </SearchLayout>

            <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < data.length ? entriesEnd : total} total={total} />
            <Card>
                <TableContainer
                    sx={{
                        boxShadow : "none", overflow : "auto",
                        "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                        "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                        ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                    }}
                >
                    <Table>
                        <MDBox component={"thead"}>
                            <TableRow>
                                {columns.map(( column : any, key : number ) => (
                                    <DataTableHeadCell
                                        key={key}
                                        width={"auto"}
                                        align={"left"}
                                        onClick={onClickHeader}
                                        accessor={column.accessor}
                                        sort={sort}
                                        direction={direction}
                                        sortable={column.sortable}
                                    >
                                        {column.Header}
                                    </DataTableHeadCell>
                                ))}
                            </TableRow>
                        </MDBox>
                        <TableBody>
                            {data.map((row :any, key: number) => (
                                <TableRow key={row.deviceId} hover={false}>
                                    {columns.map(( column : any, key : number) => (
                                        <DataTableBodyCell
                                            key={key}
                                            align={"left"}
                                            goToDetail={null}
                                        >
                                            {
                                                column.accessor === "deviceId" ? renderDeviceStatus(row) :
                                                column.accessor === "queueUserId" ? renderReserveStatus(row) :
                                                column.accessor === "onReservationId" ? renderReservationButton(row) :
                                                ["expiredDt"].includes(column.accessor) ? DateUtil.formattedDate(row[column.accessor]) :
                                                    column.accessor === "deviceType"? t(getDetailByCode("DEVICE_TYPE", row["deviceType"])) : row[column.accessor]
                                            }
                                        </DataTableBodyCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page} buttonDisabled={true}
                             setEntriesPerPage={setEntriesPerPage} onClickSave={() => {}} onClickDelete={() => {}} onChangePage={setPage}/>
            </Card>
            <MDSnackbar
                anchorOrigin={{horizontal: "center", vertical: "top"}}
                color="dark"
                bgWhite={true}
                icon="notifications"
                title="Ted Manager"
                content={msg}
                dateTime=""
                open={isOpenMsg}
                close={toggleSnackbar}
            />
        </TableLayout>
    );
}

export default ReservationView;