/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import React, {useEffect, useState} from "react";
import MDTypography from "components/MDTypography";
import DetailLayout from "examples/LayoutContainers/CustomLayout/detail/DetailLayout";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import DetailContentsLayout from "examples/LayoutContainers/CustomLayout/detail/DetailContentsLayout";
import {DefaultResponse, Farm,} from "common/Types";
import {FarmConstructor} from "common/Constructors";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import {BRAND_NAME, LANGUAGE,} from "common/Constant";
import {useTranslation} from "react-i18next";
import FormField from "../../component/FormField";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import Icon from "@mui/material/Icon";
import FarmService from "../../../service/FarmService";
import ConfirmationDialog from "../../component/ConfirmationDialog";

function FarmDetailView(...rest: any): JSX.Element {

    const {t} = useTranslation();

    const [formValue, setFormValue] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [apiReq, setApiReq] = useState<Boolean>(false);

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/

    //수정 일때 list에서 전달 받은 데이터
    const [farm, setFarm] = useState<Farm>();

    const _farmConstructor = new FarmConstructor(
        location.state.farm.id?location.state.farm.id:undefined,
        "",
        "",
        undefined,
    )

    const form = {
        formId: "create-farm-form",
        formField: {
            name: {
                name: "name",
                label: t("Name"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.NAME"),
            },
            description: {
                name: "description",
                label: t("DESCRIPTION"),
                type: "text",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.DESCRIPTION"),
            },
            unitCnt: {
                name: "unitCnt",
                label: t("UNIT CNT"),
                type: "number",
                errorMsg: t("ERRORMESSAGE.FORM.REQUIRED.FARM.UNIT.CNT"),
            },
        },
    }

    const {
        formField: { name, description, unitCnt},
    } = form;

    const validations = Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [description.name]: Yup.string().required(description.errorMsg),
        [unitCnt.name]: Yup.number().required(unitCnt.errorMsg).min(0, t("ERRORMESSAGE.FORM.REQUIRED.FARM.UNIT.CNT")),
    })

    const [initialValues, setInitialValues] = useState({
        [name.name]: location.state.farm?.name?location.state.farm.name:"",
        [description.name]: location.state.farm.description?location.state.farm.description:"",
        [unitCnt.name]: location.state.farm.unitCnt?location.state.farm.unitCnt:0,
    })

    const { formId, formField } = form;
    const currentValidation = validations;

    const {refetch: saveFarm} = useQuery<any, AxiosError>(
        ['saveFarm'],
        () => FarmService.saveFarm(_farmConstructor),
        {
            onSuccess: (res: DefaultResponse ) => {
                setApiReq(false)
                if(res.code === 200 && res.subCode === 0){
                    goBack()
                }
            }
        }
    )

    const handleSubmit = (values: any, actions: any) => {
        setFormValue(values);
        openConfirm(t("confirm.save"), "info");
    }

    const handleYes = ()=>{
        _farmConstructor.name = formValue.name
        _farmConstructor.description = formValue.description
        _farmConstructor.unitCnt  = formValue.unitCnt
        setApiReq(true)
        saveFarm()
    }

    const handleNo = () => {
        toggleDialog()
    }

    const goBack = () => {
        navigate(-1);
    }

    useEffect(()=>{
        if(location.state.type === "Edit"){
            setFarm(location.state.farm)
        }
    }, [])

    function getStepContent(formData: any): JSX.Element {
        const { formField, values, errors, touched } = formData;
        const { name, description, unitCnt} = formField;
        const {
            name: nameV,
            description: descriptionV,
            unitCnt: unitCntV,
        } = values;
        return  <MDBox>
            <MDBox lineHeight={0}>
                <MDTypography variant="h5">
                    {t(`title.path.${location.state.type}`, { title : location.state.menu_name })}
                </MDTypography>
            </MDBox>

            <Grid mt={1.625} display="flex" flexDirection="column">
                <Grid >
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={name.type}
                            label={name.label}
                            name={name.name}
                            value={nameV}
                            placeholder={name.placeholder}
                            error={errors.name && touched.name}
                            success={nameV?.length > 0 && !errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={description.type}
                            label={description.label}
                            name={description.name}
                            value={descriptionV}
                            placeholder={description.placeholder}
                            error={errors.description && touched.description}
                            success={descriptionV?.length > 0 && !errors.description}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={unitCnt.type}
                            label={unitCnt.label}
                            name={unitCnt.name}
                            value={unitCntV}
                            placeholder={unitCnt.placeholder}
                            error={errors.unitCnt && touched.unitCnt}
                            success={unitCntV?.length > 0 && !errors.unitCnt}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>;
    }

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(100)

    return (
        <DetailLayout  pageTitle={location.state.menu_name}>
            {/* 이름 입력 영역*/}
            <DetailContentsLayout>
                <Grid item >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting}) => (
                            <Form id={formId} autoComplete="off">
                                <Card sx={{ height: "100%" }}>
                                    <MDBox px={3} pt={3}>
                                        <MDBox>
                                            {getStepContent({
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                            })}
                                        </MDBox>
                                    </MDBox>
                                    <MDBox py={3} px={3} display={"flex"} justifyContent={"flex-end"}>
                                        <MDButton size={"medium"} variant={"outlined"} color={"secondary"} onClick={() => {goBack()}} sx={{minWidth : buttonMinWidth}}>
                                            {t("button.cancel")}
                                        </MDButton>
                                        <MDBox display={"inline-block"} p={0.5} />
                                        <MDButton type="submit" size={"medium"} variant={"outlined"} color={"info"} onClick={() => {}} sx={{minWidth : buttonMinWidth}}>
                                            {apiReq?<Icon>loop</Icon>:t("button.save")}
                                        </MDButton>
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </DetailContentsLayout>
            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color="warning"
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
            />
            <Footer />
        </DetailLayout>
    );
}

export default FarmDetailView;
