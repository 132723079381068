import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {useQuery} from "@tanstack/react-query";
import {DefaultResponse, ModelGenReport, selectedModel} from "common/Types";
import {AxiosError} from "axios";
import React, {Fragment, useEffect, useState} from "react";
import {getDetailByCode} from "common/Constant";
import TableCell from "@mui/material/TableCell";
import MDBadge from "components/MDBadge";
import {useTranslation} from "react-i18next";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import {CircularProgress} from "@mui/material";
import GPUValidationService from "../../../../service/GPUValidationService";


interface Props {
    selectedModel: selectedModel
    historyId: number
    historyStatus: "P" | "F" | "R" | "E"
    closeModalBtn: ()=>void
}

function CurrentModelTestHistoryDetailModal({selectedModel, historyId, historyStatus, closeModalBtn}: Props): JSX.Element {

    const {t} = useTranslation();

    const [modelData, setModelData] = useState<ModelGenReport>(undefined)
    const [testData, setTestData] = useState<ModelGenReport>(undefined)

    type TRequestColumn = "name" | "type" | "method"

    type Tcolumn = {Header: string,
        stringAccessor1? : "deviceName" | "inShape" | 'outShape' | 'startDt' | 'endDt' | 'inferenceTime' | "providers",
        stringAccessor2? : "request"
    }

    const columns: Tcolumn[] = [
        {Header : "Device", stringAccessor1 : "deviceName"},
        {Header : "Start Date", stringAccessor1 : "startDt"},
        {Header : "End Date", stringAccessor1 : "endDt"},
        {Header : "Request", stringAccessor2 : "request"},
        {Header : "Input Shape", stringAccessor1 : "inShape"},
        {Header : "Out Shape", stringAccessor1 : "outShape"},
        {Header : "Providers", stringAccessor1 : "providers"},
        {Header : "Inference Time (s)", stringAccessor1 : "inferenceTime"}
    ]

    const requestHeader: TRequestColumn[] = ["name", "type", "method"]


    const {refetch: getCurrentModelTestReport} = useQuery<DefaultResponse, AxiosError>(
        ["getCurrentModelTestReport"],
        () => GPUValidationService.getCurrentModelTestReport(selectedModel.modelId, selectedModel.genType, selectedModel.genNo, selectedModel.iterationNo, historyId ),
        {
            onSuccess: (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    console.log(res.data)
                    const _modelData = res.data[0];
                    const _testData = res.data[1];
                    setModelData({
                        deviceName: _modelData.deviceName,
                        inShape: _modelData.inShape,
                        outShape: _modelData.outShape,
                        startDt: _modelData.startDt,
                        endDt: _modelData.endDt,
                        providers: _modelData.providers,
                        inferenceTime : Math.round(Number(_modelData.inferenceTime) * (10**6))/(10**6),
                        request: {
                            name: _modelData.name,
                            type: getDetailByCode("GPU_MODEL_TYPE", _modelData.modelType),
                            method: getDetailByCode("GPU_GENERATE_METHOD", _modelData.method),
                            deviceName: _modelData.deviceName
                        },
                    })
                    setTestData({
                        image:  _testData.image,
                        deviceName: _testData.deviceName,
                        inShape: _testData.inShape,
                        outShape: _testData.outShape,
                        startDt: _testData.startDt,
                        endDt: _testData.endDt,
                        providers: _testData.providers,
                        inferenceTime : Math.round(Number(_testData.inferenceTime) * (10**6))/(10**6),
                        request: {
                            name: _testData.name,
                            type: getDetailByCode("GPU_MODEL_TYPE",_testData.modelType),
                            method: getDetailByCode("GPU_GENERATE_METHOD",_testData.method),
                            deviceName:_testData.deviceName
                        },
                    })
                }
            }
        }
    )


    useEffect(() => {
        getCurrentModelTestReport()
    }, []);


    function renderStatus(status: "P" | "F" | "R" | "E", size?: "xs" | "sm" | "md" | "lg") {
        let color : "success" | "primary" | "warning" | "secondary"
        if (status === "P") {
            color = "success"
        } else if (status === "F") {
            color = "primary"
        } else if (status === "R") {
            color = "warning"
        } else {
            color = "secondary"
        }

        return (
            <MDBadge container badgeContent={t(getDetailByCode("TEST_EXECUTION_STATUS", status))} size="xs" color={color} variant="contained"/>
        )
    }
    return (
        <Card sx={{width : "100%", px : 4, py : 3}}>

            {
                modelData?
                    <Grid container spacing={3}
                          sx={{
                              overflow : "auto",
                              "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                              "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                              ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                          }}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction={"column"}>
                                <Grid item display={"flex"} justifyContent={"space-between"}>
                                    <Grid container direction={"row"}>
                                        <MDTypography variant={"h5"} sx={{ marginRight: "10px" }}>
                                            Model Compatibility Test Report
                                        </MDTypography>
                                        {renderStatus(historyStatus, "lg")}
                                    </Grid>
                                    <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
                                        <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={2} direction={"row"}>
                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Card>
                                        <MDBox px={3} pt={2} pb={2}>
                                            Target Model Report
                                        </MDBox>
                                    </Card>
                                    <TableContainer sx={{
                                        height:"70vh", overflowY: "scroll", "&::-webkit-scrollbar" : { opacity : 0.2}}}>
                                        <Table sx={{ height: '100%' }}>
                                            <TableBody sx={{height: '100%'}}>
                                                {columns.map((column: Tcolumn, index: number) => (
                                                    column.Header !== "Request" ?
                                                        <TableRow key={index}>
                                                            <TableCell variant={"head"} key={"head" + index} style={(column.Header === 'Providers' || column.Header === 'Inference Time (s)')?{ color: 'blue' }:{}}>{column.Header}</TableCell>
                                                            <TableCell variant={"body"} key={"body" + index} align={"right"} colSpan={2} style={(column.Header === 'Providers' || column.Header === 'Inference Time (s)')?{ color: 'blue' }:{}}>
                                                                {modelData
                                                                    &&
                                                                    (
                                                                        ( column.stringAccessor1 && modelData[column.stringAccessor1])
                                                                    )
                                                                    || "-"}
                                                            </TableCell>
                                                        </TableRow> :
                                                        <Fragment key={"request"}>
                                                            <TableRow key={index}>
                                                                <TableCell variant={"head"} key={"head" + index} rowSpan={4}>{column.Header}</TableCell>
                                                            </TableRow>
                                                            {requestHeader.map((header: TRequestColumn, subIndex: number) => (
                                                                <TableRow key={"body" + index + "-" + subIndex}>
                                                                    <TableCell variant={"head"} key={"head" + subIndex}>{header.charAt(0).toUpperCase() + header.slice(1)}</TableCell>
                                                                    <TableCell variant={"body"} key={"body" + subIndex}  align={"right"}>{modelData && modelData.request[header] || "-"}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </Fragment>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Card>
                                        <MDBox px={3} pt={2} pb={2}>
                                            Model Image
                                        </MDBox>
                                    </Card>
                                    <Card sx={{marginTop:"0.5rem", border:"10px", maxHeight:"70vh", maxWidth:"90vh", overflowY: "scroll", "&::-webkit-scrollbar" : { opacity : 0.8}}}>
                                        <img src={testData?.image} alt={"생성 모델"} style={location.pathname.split('/')[2] === "custom" ? {width:"30%", margin:"auto"}:null}/>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Card>
                                        <MDBox px={3} pt={2} pb={2}>
                                            Reference Model Report
                                        </MDBox>
                                    </Card>
                                    <TableContainer sx={{
                                        height:"70vh", overflowY: "scroll", "&::-webkit-scrollbar" : { opacity : 0.2}}}>
                                        <Table sx={{ height: '100%' }}>
                                            <TableBody sx={{height: '100%'}}>
                                                {columns.map((column: Tcolumn, index: number) => (
                                                    column.Header !== "Request" ?
                                                        <TableRow key={index}>
                                                            <TableCell variant={"head"} key={"head" + index} style={(column.Header === 'Providers' || column.Header === 'Inference Time (s)')?{ color: 'blue' }:{}}>{column.Header}</TableCell>
                                                            <TableCell variant={"body"} key={"body" + index} align={"right"} colSpan={2} style={(column.Header === 'Providers' || column.Header === 'Inference Time (s)')?{ color: 'blue' }:{}}>
                                                                {testData
                                                                    &&
                                                                    (
                                                                        ( column.stringAccessor1 && testData[column.stringAccessor1])
                                                                    )
                                                                    || "-"}
                                                            </TableCell>
                                                        </TableRow> :
                                                        <Fragment key={"request"}>
                                                            <TableRow key={index}>
                                                                <TableCell variant={"head"} key={"head" + index} rowSpan={4}>{column.Header}</TableCell>
                                                            </TableRow>
                                                            {requestHeader.map((header: TRequestColumn, subIndex: number) => (
                                                                <TableRow key={"body" + index + "-" + subIndex}>
                                                                    <TableCell variant={"head"} key={"head" + subIndex}>{header.charAt(0).toUpperCase() + header.slice(1)}</TableCell>
                                                                    <TableCell variant={"body"} key={"body" + subIndex}  align={"right"}>{testData && testData.request[header] || "-"}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </Fragment>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    :<MDBox display={"flex"} justifyContent={"center"} alignItems={"center"} height={"80vh"}>
                        <CircularProgress color={"info"}/>
                        <span style={{ marginLeft: '10px' }}>LOADING...</span>
                    </MDBox>
            }
        </Card>
    )
}

export default CurrentModelTestHistoryDetailModal