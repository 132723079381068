import { useEffect, ReactNode } from "react";
import DashboardNavbar from "../../../Navbars/DashboardNavbar";
import DashboardLayout from "../../DashboardLayout";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Declaring props types for PageLayout
interface Props {
  children: ReactNode;
}

function DetailContentsLayout({children}: Props): JSX.Element {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Card sx={{ width: "100%", maxWidth: "1024px", minWidth: "720px"}}>
        {children}
      </Card>
    </Grid>
  );
}

export default DetailContentsLayout;
