import { atom } from "recoil";
import {MenuTypes} from "common/Types";

//recoil state 생성
export const menuRecoilState = atom<MenuTypes>({
  key: "menu",
  default: {
    list: undefined
  }
});

export const sseForAllRecoilState = atom<any>({
  key: "sseForAll",
  default: {
    sse: undefined
  }
});

export const sseForUserRecoilState = atom<any>({
  key: "sseForUser",
  default: {
    sse: undefined
  }
});