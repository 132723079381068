import {apiClient} from "service/ApiClient";
import {DefaultResponse, Device, Notice} from "../common/Types";
import {DeviceConfigConstructor} from "../common/Constructors";


const getDeviceList = async function (page:number,
                                      size:number,
                                      sort:string,
                                      direction:"none" | "ASC" | "DESC",
                                      fromDate: string,
                                      toDate: string,
                                      name: string,
                                      node: string,
                                      buildType: string,
                                      type:string) {
    return await apiClient.get<DefaultResponse>(`/device?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&name=${name}&node=${node}&buildType=${buildType}&type=${type}`)
        .then((res:any) => res.data)
}

const deleteDevice = async function (deviceList:Device[]){
    return await apiClient.patch<DefaultResponse>("device/delete", deviceList).then((res:any)=>res.data)
}

const saveDevice = async function (device:DeviceConfigConstructor){
    return await apiClient.post<DefaultResponse>("device", device).then((res:any)=>res.data)
}

const getDevice = async function (deviceId:any){
    return await apiClient.get<DefaultResponse>(`device/${deviceId}`).then((res:any)=>res.data)
}

const getContainerIpList = async function (nodeId:any){
    return await apiClient.get<DefaultResponse>(`device/containerIpListByNode/${nodeId}`).then((res:any) => res.data)
}

const searchNewDevice = async function (){
    return await apiClient.get<DefaultResponse>("device/search/newDUT").then((res:any)=>res.data)
}

const autoSaveDevice = async function (device:DeviceConfigConstructor){
    return await apiClient.post<DefaultResponse>("device/autoSave", device).then((res:any)=>res.data)
}

const deviceConfigService = {
    getDeviceList,
    deleteDevice,
    saveDevice,
    getDevice,
    getContainerIpList,
    searchNewDevice,
    autoSaveDevice
}

export default deviceConfigService;