import React from 'react';
import MDButton from "../../components/MDButton";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MDBox from "../../components/MDBox";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";

interface Props {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
    content: string;
    close: () => void;
    [key: string]: any;
}

export default function ConfirmationDialog({color,content,close,handleYes, handleNo,...rest}: Props): JSX.Element {
    const {t} = useTranslation();

    return (
        <Snackbar
            TransitionComponent={Fade}
            autoHideDuration={5000}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            {...rest}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
                    <Icon fontSize="small">close</Icon>
                </IconButton>
            }
        >
            <MDBox
                variant={"gradient"}
                bgColor={color}
                minWidth="21.875rem"
                maxWidth="100%"
                shadow="md"
                borderRadius="md"
                p={1}
                sx={{
                    backgroundColor: ({ palette }: { palette: any }) => palette[color] || palette.white.main,
                }}
            >
                <MDBox
                    p={1.5}
                    sx={{
                        fontSize: ({ typography: { size } }) => size.sm,
                        color: ({ palette }: { palette: any }) => {
                            return palette.white.main;
                        },
                    }}
                >
                    {content}
                </MDBox>
                <Divider sx={{ margin: 0 }} light={true} />
                <MDBox
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    color="dark"
                    p={1.5}
                >
                        <MDButton color={"error"} onClick={handleYes}>
                            {t("confirm.yes")}
                        </MDButton>
                    <MDBox display={"inline-block"} p={0.5} />

                        <MDButton color={"dark"} onClick={handleNo}>
                            {t("confirm.no")}
                        </MDButton>
                </MDBox>
            </MDBox>
        </Snackbar>
    );
}
