import {apiClient} from "service/ApiClient";
import {DefaultResponse} from "common/Types";

const getAlarms = async function (userId: string) {
  return await apiClient.get<DefaultResponse>("/alarm/" + userId).then((res) => res.data)
}

const readAlarm = async function (alarmId: number) {
  return await apiClient.patch<DefaultResponse>("/alarm/" + alarmId).then((res) => res.data)
}

const AlarmService ={
  getAlarms,
  readAlarm,
}

export default AlarmService