import {apiClient} from "service/ApiClient";
import {DefaultResponse, TestExecution, TestSuite} from "common/Types";
import {PowerExecutionSaveConstructor, TestExecutionConstructor} from "../common/Constructors";

const getPowerExecutionList = async function (page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, domainType: string, testExecutionName: string, userName: string, deviceName: string) {
    return await apiClient.get<DefaultResponse>
    (`/power/execution/list?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&domainType=${domainType}&testExecutionName=${testExecutionName}&&userName=${userName}&deviceName=${deviceName}`)
        .then((res:any) => res.data)
}

const savePowerExecution = async function (_powerExecution:PowerExecutionSaveConstructor){
    return await apiClient.post<DefaultResponse>("/power", _powerExecution).then((res:any) => res.data)
}

const getPowerExecutionHistoryListByExecutionId = async function(executionId: number, page: number,
                                                                 entriesPerPage: number, sort: string,
                                                                 direction:"none" | "ASC" | "DESC",
                                                                 fromDate: string, toDate: string,
                                                                 name: string, buildName: string, buildNo: string, userName: string, deviceName: string){
    return await apiClient.get<DefaultResponse>(`/power/history/${executionId}?page=${page}&entriesPerPage=${entriesPerPage}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}`
        +`&fromDate=${fromDate}&toDate=${toDate}&name=${name}&buildName=${buildName}&buildNo=${buildNo}&userName=${userName}&deviceName=${deviceName}`).then((res: any) => res.data)
}

const executePowerTest = async function (req: TestExecutionConstructor) {
    return await apiClient.post<DefaultResponse>("/power/execute", req).then((res:any) => res.data)
}

const getPowerExecutionHistoryReport = async function (historyId:number){
    return await apiClient.get<DefaultResponse>(`/power/execution/history/report/${historyId}`).then((res:any)=>res.data)
}

const getPowerExecutionHistoryList = async function(page: number,
                                                    entriesPerPage: number, sort: string,
                                                    direction:"none" | "ASC" | "DESC",
                                                    fromDate: string, toDate: string,
                                                    name: string, buildName: string, buildNo: string, userName: string, deviceName: string){
    return await apiClient.get<DefaultResponse>(`/power/history?page=${page}&entriesPerPage=${entriesPerPage}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}`
        +`&fromDate=${fromDate}&toDate=${toDate}&name=${name}&buildName=${buildName}&buildNo=${buildNo}&userName=${userName}&deviceName=${deviceName}`).then((res: any) => res.data)
}

const PowerService = {
    getPowerExecutionHistoryListByExecutionId,
    getPowerExecutionList,
    savePowerExecution,
    executePowerTest,
    getPowerExecutionHistoryReport,
    getPowerExecutionHistoryList
}

export default PowerService