/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {DefaultResponse} from "common/Types";
import DateUtil from "common/DateUtil";
import {BRAND_NAME, getDetailByCode} from "common/Constant";
import MDSnackbar from "components/MDSnackbar";
import {useTranslation} from "react-i18next";
import NodeService from "../../../../service/NodeService";
import MDBadge from "../../../../components/MDBadge";
import MDTypography from "../../../../components/MDTypography";
import Icon from "@mui/material/Icon";
import {Card} from "@mui/material";

interface Props {
    handleNodeValue: (value:any)=>void
    closeModalBtn:()=>void
}
function NodeListModal({handleNodeValue, closeModalBtn}:Props): JSX.Element {

    const {t} = useTranslation();

    const [list, setList] = useState([])
    const columns = [
        { Header: t("node.thead.nodeName"), accessor: "name", width: "15%", sortable: false},
        { Header: t("node.thead.farmName"), accessor: "farm", width: "15%", sortable: false },
        { Header: t("node.thead.ip"), accessor: "ip", width: "20%", sortable: false },
        { Header: t("node.thead.status"), accessor: "availability", width: "10%", sortable: false},
        { Header: t("node.thead.DUTCnt"), accessor: "dutCnt", width: "15%", sortable: false },
        { Header: t("node.thead.regDate"), accessor: "regDt", width: "15%", sortable: false },

    ];

    /*paging start*/
    const entries = ["10", "25", "50", "100"];
    const [entriesPerPage, setEntriesPerPage] = useState( 10 )
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [sort, setSort] = useState("")
    const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")

    // Setting the entries starting point
    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;

    // Setting the entries ending point
    let entriesEnd;
    const totalPageLength = Math.ceil(list.length / entriesPerPage);

    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength ) {
        entriesEnd = list.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }
    /*paging end*/

    /*searching start*/
    // Search input value state
    const [name, setName] = useState("")

    // 등록일 선택 검색
    const [ fromDate, setFromDate ] = useState<Date>(null)
    const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())

    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setErrMsg(msg)
        setErrColor(color)
        setOpenErrMsg(true)
    }
    /*snackbar end*/


    const {refetch: getNodeListForDeviceConfig} = useQuery<any, AxiosError>(
        ['getNodeListForDeviceConfig'],
        () => NodeService.getNodeListForDeviceConfig(page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), name),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    setList(res.data.content)
                    setTotal(res.data.total)
                }
            }
        }
    )



    // 페이지 변경시 전체 체크
    useEffect( () => {
        getNodeListForDeviceConfig()
    }, [ ] );

    useEffect(() => {
        getNodeListForDeviceConfig()
    }, [ page ]);

    useEffect(() => {
        setPage(1)
        getNodeListForDeviceConfig()
    }, [ entriesPerPage]);




    useEffect( () => {
        if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
            getNodeListForDeviceConfig()
        }
    }, [sort, direction]);

    function renderStatusIcon(status: string) {
        let content
        let color : "warning" | "success" | "error" | "primary" | "secondary" | "info" | "light" | "dark"
        if (status == "A") {
            content = t(getDetailByCode("AVAILABILITY", status))
            color = "success"
        } else if (status == "U")  {
            content = t(getDetailByCode("AVAILABILITY", status))
            color = "info"
        } else {
            return null
        }
        return <MDBadge container badgeContent={content} size="sm" color={color} variant={"contained"} minWidth={110}/>
    }

    const selectNode = (row: any | undefined, type: string)=>{
        handleNodeValue({id :row.id, name:row.name})
        closeModalBtn()
    }

    return (
        <Card sx={{width : "100%", px : 4, py : 2,
            overflow : "auto",
            "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
            "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
            ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
        }}>
            <MDBox gap={2}>
                <MDBox display={"flex"} justifyContent={"space-between"} pb={1}>
                    <MDTypography variant={"h5"}>
                        Node List
                    </MDTypography>
                    <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
                        <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
                    </MDBox>
                </MDBox>
                <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />
                {/* 테이블 전체 layout */}
                <TableContainer
                    sx={{
                        boxShadow : "none", overflow : "auto",
                        "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                        "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                        ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                    }}
                >
                    {/* 테이블 contents */}
                    <Table>
                        <MDBox component={"thead"}>
                            <TableRow>
                                <DataTableHeadCell
                                    width={"10%"}
                                    align={"left"}
                                    accessor={"no"}
                                    direction={direction}
                                    sort={sort}
                                >
                                    {t("node.thead.no")}
                                </DataTableHeadCell>

                                {columns.map(( column : any, index : number ) => (
                                    <DataTableHeadCell
                                        key={index}
                                        width={column.width}
                                        align={"left"}
                                        accessor={column.accessor}
                                        direction={direction}
                                        sort={sort}
                                        sortable={column.sortable}
                                    >
                                        {column.Header}
                                    </DataTableHeadCell>
                                ))}
                            </TableRow>
                        </MDBox>
                        <TableBody>
                            {list.map(( row : any, index : number ) => (
                                <TableRow key={index} >
                                    <DataTableBodyCell
                                        key={index}
                                        align={ "left" }
                                        row={row}
                                    >
                                        {entriesPerPage*(page-1)+index+1}

                                    </DataTableBodyCell>
                                    {columns.map(( column : any, index) => (
                                        <DataTableBodyCell
                                            key={index}
                                            align={ "left" }
                                            row={row}
                                            goToDetail={selectNode}
                                        >
                                            { column.accessor === "availability" ? renderStatusIcon(row["availability"]) : row[column.accessor]
                                            }

                                        </DataTableBodyCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>
            </MDBox>
            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color={errColor}
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
        </Card>
    );
}

export default NodeListModal;