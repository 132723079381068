import {DefaultResponse, ModelExecution} from "../common/Types";
import {apiClient} from "./ApiClient";
import {CurrentModelTestConstructor, ModelGenExecutionConstructor} from "../common/Constructors";

const saveModelExecution = async function ( model : ModelGenExecutionConstructor ){
    console.log(model)
    return await apiClient.post<DefaultResponse>("/validation/modelSave", model).then((res:any) => res.data)
}

const executeCurrentTest = async function ( test : CurrentModelTestConstructor ){
    return await apiClient.post<DefaultResponse>("/validation/currentTest/execute", test).then((res:any) => res.data)
}

async function generateModel(id : number){
    return await apiClient.get<DefaultResponse>(`/validation/model/generate/${id}`).then((res:any) => res.data)
}

const getModelGenList = async function (page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, name: string, type: string) {
    return await apiClient.get<DefaultResponse>
    (`/validation/modelgen/list?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&name=${name}&type=${type}`)
        .then((res:any) => res.data)
}
const getCurrentModelTestList = async function (page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, name: string) {
    return await apiClient.get<DefaultResponse>
    (`/validation/currentTest/list?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&name=${name}`)
        .then((res:any) => res.data)
}

const getModelGenerateHistoryReport = async function (historyId:number){
    return await apiClient.get<DefaultResponse>(`/validation/modelGen/report/${historyId}`).then((res:any)=>res.data)
}
const getCurrentModelTestReport = async function (modelId:number, genType:string, genNo: number, iterationNo: number,  historyId:number){
    return await apiClient.get<DefaultResponse>(`/validation/currentTest/report?modelId=${modelId}&genType=${genType}&genNo=${genNo}&iterationNo=${iterationNo}&historyId=${historyId}`).then((res:any)=>res.data)
}

const getNModelFileDownload = async function (historyId:number){
    return await apiClient.get<DefaultResponse>(`/validation/modelGen/download/${historyId}`).then((res:any)=>res.data)
}

const getGenerateHistoryListByValidationId = async function (validationId: number, page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string) {
    return await apiClient.get<DefaultResponse>
    (`/validation/model-generate-history/${validationId}?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}`
        +`&fromDate=${fromDate}&toDate=${toDate}`)
        .then((res:any) => res.data)
}
const getGenerateHistoryList = async function (page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string) {
    return await apiClient.get<DefaultResponse>
    (`/validation/model-generate-history?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}`
        +`&fromDate=${fromDate}&toDate=${toDate}`)
        .then((res:any) => res.data)
}

const deleteModelGenConfig = async function (deleteList : ModelExecution[]){
    return await apiClient.patch<DefaultResponse>('/validation/delete', deleteList).then((res:any) => res.data)
}

const GPUValidationService = {
    saveModelExecution,
    generateModel,
    getModelGenList,
    getModelGenerateHistoryReport,
    getNModelFileDownload,
    getCurrentModelTestList,
    executeCurrentTest,
    getCurrentModelTestReport,
    getGenerateHistoryListByValidationId,
    getGenerateHistoryList,
    deleteModelGenConfig
}

export default GPUValidationService;