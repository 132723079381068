import React, {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import TestService from "../../../../service/TestService";
import {DefaultResponse} from "../../../../common/Types";
import {AxiosError} from "axios";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import SearchLayout from "../../../../examples/LayoutContainers/CustomLayout/list/SearchLayout";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import Card from "@mui/material/Card";
import TableHeader from "../../../../examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import DataTableHeadCell from "../../../../examples/Tables/DataTable/DataTableHeadCell";
import TableBody from "@mui/material/TableBody";
import DataTableBodyCell from "../../../../examples/Tables/DataTable/DataTableBodyCell";
import TableFooter from "../../../../examples/LayoutContainers/CustomLayout/list/TableFooter";
import {useTranslation} from "react-i18next";
import Icon from "@mui/material/Icon";

interface Props {
  selectTestSuite: (data: any) => void
  _projectName: any
  closeModalBtn:()=>void
}
function ModalTestSuiteListView({selectTestSuite, _projectName, closeModalBtn}:Props): JSX.Element {

  const {t} = useTranslation();

  const [testSuiteName, setTestSuiteName] = useState("")
  const [list, setList] = useState([])
  const [projectName, setProjectName] = useState(_projectName)
  const columns = [
    { Header: "Project Name", accessor: "projectName", width: "40%", sortable: false},
    { Header: "name", accessor: "name", width: "50%", sortable: false},
    { Header: "", accessor: "execution", width: "10%", sortable: false},
  ];


  /*paging start*/
  const entries = ["10", "25", "50", "100"];
  const [entriesPerPage, setEntriesPerPage] = useState( 10 )
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [sort, setSort] = useState("")
  const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")

  // Setting the entries starting point
  const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;

  // Setting the entries ending point
  let entriesEnd;
  const totalPageLength = Math.ceil(list.length / entriesPerPage);

  if (page === 1) {
    entriesEnd = entriesPerPage;
  } else if (page === totalPageLength ) {
    entriesEnd = list.length;
  } else {
    entriesEnd = entriesPerPage * page;
  }
  /*paging end*/

  const {refetch: getTestSuiteList} = useQuery<any, AxiosError>(
    ['getTestSuiteList'],
    () => TestService.getTestSuiteList(page, entriesPerPage, sort, direction, "", "", testSuiteName, "", projectName?projectName.id:undefined),
    {
      onSuccess: (res: DefaultResponse ) => {
        if(res.code === 200 && res.subCode === 0){
          console.log(res.data)
          setList(res.data.content)
          setTotal(res.data.total)
        }
      }
    }
  )

  const activeEnter = (ev:KeyboardEvent) => {
    if(ev.key === "Enter") {
      getTestSuiteList();
    }
  }

  useEffect( () => {
    if(projectName.label){
      getTestSuiteList()
    }
  }, [ projectName] );

  useEffect(() => {
    if(projectName.label) {
      getTestSuiteList()
    }
  }, [ page ]);

  useEffect(() => {
    if(projectName.label) {
      setPage(1)
      getTestSuiteList()
    }
  }, [ entriesPerPage]);

  return (
    <Card sx={{width : "100%", px : 4, py : 2,}}>
      <MDBox display={"flex"} flexDirection={"column"} gap={1}>
        <MDBox display={"flex"} justifyContent={"space-between"} pb={1}>
          <MDTypography variant={"h5"}>
            Test Suite List ( Device Type: {projectName.label} )
          </MDTypography>
          <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
            <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
          </MDBox>
        </MDBox>

        <SearchLayout>
          <MDBox  display={"flex"} justifyContent={"space-between"}>
            <MDBox display={"flex"} width="90%" alignItems="center">
              {/*<MDBox alignItems="center" width="30%" minWidth={"100px"} pl={3}>*/}
              {/*  <Autocomplete*/}
              {/*    fullWidth*/}
              {/*    disableClearable*/}
              {/*    value={projectName.id}*/}
              {/*    options={getCodeListForSearch("DEVICE")}*/}
              {/*    onChange={(event, newValue) => {*/}
              {/*      setProjectName(newValue)*/}
              {/*    }}*/}
              {/*    size="small"*/}
              {/*    renderInput={(params) => <MDInput {...params} placeholder={"DEVICE TYPE"} onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}/>}*/}
              {/*  />*/}
              {/*</MDBox>*/}
              <MDBox alignItems="center" width="50%" >
                <MDInput
                  placeholder="NAME"
                  value={testSuiteName}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }: any) => {
                    setTestSuiteName(currentTarget.value)
                  }}
                  onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                />
              </MDBox>
            </MDBox>
            <MDBox display={"flex"}>
              <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getTestSuiteList()}>
                {t("search.button.search")}
              </MDButton>
            </MDBox>
          </MDBox>
        </SearchLayout>

        <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />

        {/* 테이블 전체 layout */}
        <TableContainer
          sx={{
            boxShadow : "none", overflow : "auto",
            "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
            "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
            ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
          }}
        >
          {/* 테이블 contents */}
          <Table>
            <MDBox component={"thead"}>
              <TableRow>
                {columns.map(( column : any, index : number ) => (
                  <DataTableHeadCell
                    key={index}
                    width={column.width}
                    align={"left"}
                    accessor={column.accessor}
                    sortable={column.sortable}
                  >
                    {column.Header}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            </MDBox>
            <TableBody>
              {list.map(( row : any, index : number ) => (
                <TableRow key={index} >
                  {columns.map(( column : any, index) => (
                    <DataTableBodyCell
                      key={index}
                      align={ "left" }
                      row={row}
                      goToDetail={selectTestSuite}
                    >
                      { column.accessor === "execution" ?
                        <MDBox>
                          <MDButton color="gray" size="small" variant="outlined" >
                            선택
                          </MDButton>
                        </MDBox>:
                        row[ column.accessor ] }

                    </DataTableBodyCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* 테이블 footer */}
          <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page}
                       setEntriesPerPage={setEntriesPerPage} buttonDisabled={true} onChangePage={setPage}/>
        </TableContainer>

      </MDBox>
    </Card>
  );
}

export default ModalTestSuiteListView;