/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, ReactNode } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

//state, recoil import
import { menuRecoilState } from 'store/recoilState';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import MenuService from "service/MenuService";
import StringUtil from "common/StringUtil";
import {ACCESS_TYPE, USER_NAME} from "common/Constant";
import AuthUtil from "common/AuthUtil";
import {DefaultResponse, Menu, SubMenu} from "common/Types";
import StorageUtil from "common/StorageUtil";
import MDButton from "components/MDButton";
import Fade from "@mui/material/Fade";
import {Image} from "@mui/icons-material";

import logo from "assets/images/logos/Tedworks-logo_W.png"

// Declaring props types for Sidenav
interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  brand?: string;
  brandName: string;
  routes: {
    [key: string]:
      | ReactNode
      | string
      | {
          [key: string]:
            | ReactNode
            | string
            | {
                [key: string]: ReactNode | string;
              }[];
        }[];
  }[];
  [key: string]: any;
}

function Sidenav({ color, brand, brandName, routes, ...rest }: Props): JSX.Element {
  const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string>(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const [userEmail] = useState<string>(StorageUtil.getLocalStorage("userEmail"))

  //recoil 사용 선언부
  const setMenuList = useSetRecoilState(menuRecoilState);
  const menu = useRecoilValue(menuRecoilState);

  let textColor:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "white"
    | "inherit"
    | "text"
    | "light" = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const {refetch: getMenuList} = useQuery<any, AxiosError>(
    ['getMenuList'],
    () => MenuService.getMenuList(),
    {
      onSuccess: (res: DefaultResponse ) => {
        if(res.code === 200 && res.subCode === 0){
          const accessMenuList: any = []
          const accessMainMenuList = res.data.menuList.filter((menu: Menu) => AuthUtil.isAccessible(menu.id, ACCESS_TYPE.SELECT) ) // 접근 가능한 main menu list 조회
          accessMainMenuList.forEach(function(accessMenu: Menu){
            const accessSubMenuList = accessMenu.subMenu.filter((subMenu: SubMenu) => AuthUtil.isAccessible(subMenu.id, ACCESS_TYPE.SELECT)) // 접근 가능한 sub menu list 조회
            accessMenuList.push({
              id: accessMenu.id,
              name: accessMenu.name,
              menuOrder: accessMenu.menuOrder,
              url: accessMenu.url,
              iconName: accessMenu.iconName,
              subMenu: accessSubMenuList
            })
          })

          setMenuList({list: accessMenuList})
        }
      }
    }
  )

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    if(!menu.list){
      getMenuList()
    }
  }, [menu]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);


  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center" gap={1}>
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem"/>}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme: any) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDBox display={"flex"} component={"img"} src={logo} alt={brandName} height={"2rem"}/>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <MDBox px={4} py={1} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {miniSidenav &&
            <Fade in={miniSidenav}>
              <MDButton iconOnly circular sx={{fontSize : "1rem"}}>{userEmail ? localStorage.getItem(USER_NAME)[0] : "?"}</MDButton>
            </Fade>}
            <MDBox sx={(theme: any) => sidenavLogoLabel(theme, { miniSidenav })}>
              <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
                {userEmail ? userEmail : ""}
              </MDTypography>
            </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>
      {
        menu?.list?.length > 0 && menu.list.map(function (menu) {
          // 1depth && url 없음, submenu 있을때
          if(StringUtil.isEmpty(menu.url)) {
            return <SidenavCollapse
              key={String(menu.id)}
              name={menu.name}
              icon={<Icon fontSize="medium">{menu.iconName}</Icon>}
              active={String(menu.id) === openCollapse}
              open={openCollapse === String(menu.id)}
              onClick={() => (openCollapse === String(menu.id) ? setOpenCollapse(false) : setOpenCollapse(String(menu.id)))}
            >
              <SidenavList key={String(menu.id)}>
              { menu.subMenu.map(function (subMenu) {
                  return <NavLink to={subMenu.url} key={String(subMenu.id)} state={{menuId: subMenu.id, menu_name: subMenu.name}} style={{ textDecoration: "none" }}>
                           <SidenavItem name={subMenu.name} active={subMenu.url === pathname} nested />
                         </NavLink>
                })
              }
              </SidenavList>
            </SidenavCollapse>;
          }else{
            return <NavLink to={menu.url} key={String(menu.id)} state={{menu_id: menu.id, menu_name: menu.name}}>
              <SidenavCollapse
               name={menu.name}
               icon={<Icon fontSize="medium">{menu.iconName}</Icon>}
               noCollapse={true}
               open={openCollapse === String(menu.id)}
               active={String(menu.id) === openCollapse}
               onClick={() => (openCollapse === String(menu.id) ? setOpenCollapse(false) : setOpenCollapse(String(menu.id)))}
              >
              </SidenavCollapse>
            </NavLink>;
          }
        })
      }
      </List>
    </SidenavRoot>
  );
}

// Declaring default props for Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

export default Sidenav;
