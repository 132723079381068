import MDBox from "components/MDBox";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import MDDatePicker from "components/MDDatePicker";
import {Refresh} from "@mui/icons-material";
import MDInput from "components/MDInput";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {useLocation, useNavigate} from "react-router-dom";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {CurrentTest, DefaultResponse, selectedModel} from "common/Types";
import DateUtil from "common/DateUtil";
import {BRAND_NAME, getDetailByCode, LANGUAGE, SSE, USER_ID} from "common/Constant";
import MDSnackbar from "components/MDSnackbar";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import {useTranslation} from "react-i18next";
import Card from "@mui/material/Card";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import GPUValidationService from "../../../../service/GPUValidationService";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sseForUserRecoilState} from "../../../../store/recoilState";
import StorageUtil from "../../../../common/StorageUtil";
import CurrentModelTestHistoryDetailModal from "../history/CurrentModelTestHistoryDetailModal";

function CurrentModelTestList(): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const columns = [
        { Header: t("currentTest.thead.name"), accessor: "name", width: "10%", sortable: true},
        { Header: t("currentTest.thead.type"), accessor: "type", width: "10%", sortable: true},
        { Header: t("currentTest.thead.modelType"), accessor: "modelType", width: "10%", sortable: true},
        { Header: t("currentTest.thead.generateMethod"), accessor: "generateMethod", width: "10%", sortable: true},
        { Header: t("currentTest.thead.genNo"), accessor: "genNo", width: "10%", sortable: false},
        { Header: t("currentTest.thead.targetDevice"), accessor: "targetDevice", width: "10%", sortable: false },
        { Header: t("currentTest.thead.referenceDevice"), accessor: "referenceDevice", width: "10%", sortable: true },
        { Header: "", accessor: "execution", width: "5%", sortable: false },
    ];
    const [selectedModel, setSelectedModel] = useState<selectedModel>(undefined);
    const [historyId, setHistoryId] = useState(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const [historyStatus, setHistoryStatus] = useState(undefined)

    /*paging start*/
    const entries = ["10", "25", "50", "100"];
    const [entriesPerPage, setEntriesPerPage] = useState( 10 )
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [sort, setSort] = useState("")

    const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")
    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setErrMsg(msg)
        setErrColor(color)
        setOpenErrMsg(true)
    }
    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;
    // Setting the entries ending point
    let entriesEnd;

    const totalPageLength = Math.ceil(list.length / entriesPerPage);
    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength ) {
        entriesEnd = list.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }

    /*paging end*/
    /*searching start*/
    // Search input value state

    const [modelGenName, setModelGenName] = useState("");
    // 등록일 선택 검색
    // const [ fromDate, setFromDate ] = useState<Date>(DateUtil.getDefaultFromDate())
    const [ fromDate, setFromDate ] = useState<Date>(null)


    const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())
    // 등록일 handle function
    const handleFromDateChange = ( _fromDate: Date) => {
        setFromDate(_fromDate)

    }
    const handleToDateChange = ( _toDate: Date) => {
        setToDate(_toDate)
    }

    /* SSE 설정 */
    const setSseForUser = useSetRecoilState(sseForUserRecoilState);
    const sseForUser = useRecoilValue(sseForUserRecoilState);

    const handleConnectedEvent = (e: any) => {
        const { data: receivedData } = e;
        console.log(receivedData);
    };

    const handleGenerateFinishEvent = (e: any) => {
        getCurrentModelTestList()
        openAlert("생성이 완료되었습니다.", "success")
    };

    useEffect(()=>{
        const baseURI = process.env.REACT_APP_BACKEND_URI+process.env.REACT_APP_API_VERSION

        if(!sseForUser.sse){
            setSseForUser({sse: new EventSource(baseURI + "/sse/connect/" + StorageUtil.getLocalStorage(USER_ID))})
        }else{
            sseForUser.sse.addEventListener("Connected", handleConnectedEvent)
            sseForUser.sse.addEventListener(SSE.MODEL_GEN_FINISH, handleGenerateFinishEvent)
        }
    }, [sseForUser.sse])

    useEffect(()=>{
        return () => {
            // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
            if (sseForUser.sse) {
                sseForUser.sse.removeEventListener('Connected', handleConnectedEvent);
                sseForUser.sse.removeEventListener(SSE.MODEL_GEN_FINISH, handleGenerateFinishEvent);
            }
        };
    },[]);

    /* SSE 설정 끝 */

    useEffect(()=> {
        if(fromDate && toDate && fromDate > toDate){
            openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
            setFromDate(undefined)
            setToDate(undefined)
        }
    },[fromDate, toDate])
    /*searching end*/
    const goToDetail = (row: any | undefined, type: string) => {
        const url = `/validation/compatibilitytest/execute`;
        navigate({pathname: url}, {state: {menu_name: "Current Test", model: row, type: type}})

    }

    const {refetch: getCurrentModelTestList} = useQuery<any, AxiosError>(
        ['getCurrentModelTestList'],
        () => GPUValidationService.getCurrentModelTestList(page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), modelGenName),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    setList(res.data.content)
                    setTotal(res.data.total)
                }
            }
        }
    )




    const onClickTest = () => {
        goToDetail({},'Create')

    }


    useEffect( () => {
        getCurrentModelTestList()
    }, [] );

    useEffect(() => {
        getCurrentModelTestList()
    }, [ page ]);

    useEffect(() => {
        setPage(1)
        getCurrentModelTestList()
    }, [ entriesPerPage]);

    const onClickHeader = (_accessor:string) => {
        if(sort === _accessor){
            if(direction && direction === "DESC"){
                setDirection("ASC")
            }else{
                setDirection("DESC")
            }
        }else{
            setDirection("DESC")
            setSort(_accessor)
        }

    }
    const activeEnter = (ev:KeyboardEvent) => {
        if(ev.key === "Enter") {
            // getExecutionList();
        }

    }

    useEffect( () => {
        if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
            getCurrentModelTestList()
        }
    }, [sort, direction]);



    const clickHistory = (row: any) => {
        console.log(row)
        if(row.status === 'R'){
            openAlert("현재 테스트 진행 중입니다. 나중에 다시 시도해주십시오.", "dark")
        } else{
            console.log(row)
            setHistoryId(row.id)
            setSelectedModel({
                modelId: row.modelId,
                genType: row.type,
                genNo: row.genNo,
                iterationNo: row.iterationNo
            })
            setHistoryStatus(row.status)
            setModalOpen(true)
        }
    }


    function handleClose() {
        setModalOpen(false)
        setTimeout(() => {
            setHistoryId(undefined)
            setSelectedModel(undefined)
        }, 150)
    }


    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(100) : pxToRem(100)

    return (
        /* list, detail 화면에서 공용으로 사용하는 최상위 layout 컴포넌트 */
        <TableLayout pageTitle={location.state?.menu_name}>

            {/* 검색 영역 layout */}
            <SearchLayout>
                <MDBox  display={"flex"} justifyContent={"space-between"}>
                    <MDBox display={"flex"} width="90%" alignItems="center">
                        <MDBox display={"flex"} alignItems="center" >
                            <MDDatePicker
                                value={ fromDate }
                                input = {
                                    {
                                        size : "small",
                                        height: "37px",
                                        placeholder : t("search.fromDate"),
                                    }
                                }
                                options = {
                                    {
                                        maxDate : "today",
                                        // locale : Korean,
                                    }
                                }
                                onChange = { ( currentTarget: any ) => {
                                    const target = currentTarget[0];
                                    handleFromDateChange( target );
                                }}
                            />
                            <MDBox px={0.25}>~</MDBox>
                            <MDDatePicker
                                value={ toDate }
                                input = {
                                    {
                                        size : "small",
                                        height: "37px",
                                        placeholder : t("search.toDate"),
                                    }
                                }
                                options = {
                                    {
                                        maxDate : "today",
                                        // locale : Korean,
                                    }
                                }
                                onChange = { ( currentTarget: any ) => {
                                    const target = currentTarget[0];
                                    handleToDateChange( target );
                                }}
                            />
                            <MDBox px={0.25}/>
                            <MDButton iconOnly={true} onClick={ () => { setFromDate( undefined ); setToDate( undefined ); } }>
                                <Refresh/>
                            </MDButton>
                        </MDBox>
                        <MDBox alignItems="center" width="15%" pl={3}>
                            <MDInput
                                placeholder={t("search.name")}
                                value={modelGenName}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }: any) => {
                                    setModelGenName(currentTarget.value)
                                }}
                                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox display={"flex"}>
                        <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getCurrentModelTestList()}>
                            {t("search.button.search")}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </SearchLayout>

            {/* 테이블 헤더 */}
            <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />

            {/* 테이블 전체 layout */}
            <Card>
                <TableContainer
                    sx={{
                        boxShadow : "none", overflow : "auto",
                        "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                        "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                        ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                    }}
                >
                    {/* 테이블 contents */}
                    <Table>
                        <MDBox component={"thead"}>
                            <TableRow>

                                {columns.map(( column : any, index : number ) => (
                                    <DataTableHeadCell
                                        key={index}
                                        width={column.width}
                                        align={"left"}
                                        onClick={onClickHeader}
                                        accessor={column.accessor}
                                        direction={direction}
                                        sort={sort}
                                        sortable={column.sortable}
                                    >
                                        {column.Header}
                                    </DataTableHeadCell>
                                ))}
                            </TableRow>
                        </MDBox>
                        <TableBody>
                            {list.map(( row : any, index : number ) => (
                                <TableRow key={index} >

                                    {columns.map(( column : any, index) => (
                                        <DataTableBodyCell
                                            key={index}
                                            align={ "left" }
                                            goToDetail={null}
                                            row={row}
                                        >
                                            {
                                                column.accessor === "execution"?
                                                    <MDBox>
                                                        {
                                                            row["status"] === 'P'?
                                                                <MDButton color="success" size="small" variant="outlined" onClick={(e) => {clickHistory(row)}} sx={{minWidth : buttonMinWidth}}>
                                                                    {t("button.result")}
                                                                </MDButton>
                                                                :
                                                                <MDButton color="gray" size="small" variant="outlined" onClick={(e) => {openAlert("현재 테스트가 실행중입니다.","dark")}} sx={{minWidth : buttonMinWidth}}>
                                                                    {t("button.running")}
                                                                </MDButton>
                                                        }
                                                    </MDBox>:
                                                    column.accessor === "type"?
                                                        getDetailByCode("GPU_GEN_TYPE", row[column.accessor]).toUpperCase():
                                                        column.accessor === "modelType"? getDetailByCode("GPU_MODEL_TYPE", row[column.accessor]):
                                                            column.accessor === "generateMethod"? getDetailByCode("GPU_GENERATE_METHOD", row[column.accessor]):
                                                                column.accessor === "genNo"? "Gen"+row["genNo"]+" #"+row["iterationNo"]:
                                                                    row[ column.accessor]
                                            }

                                        </DataTableBodyCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* 테이블 footer */}
                <MDBox display={"flex"} justifyContent={"flex-end"}>
                    <TableFooter buttonDisabled={true} entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page}
                                 setEntriesPerPage={setEntriesPerPage} onClickSave={null} onClickDelete={null} onChangePage={setPage}/>
                    <MDBox width={"30vw"} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                        <MDButton size={"medium"} variant={"outlined"} color={"info"} onClick={onClickTest} sx={{marginRight:"2vw",maxHeight : "20vh" ,minWidth : buttonMinWidth}}>
                            {t("button.newTest")}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </Card>

            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color={errColor}
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />


            <Modal
                open={modalOpen} onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 200,
                        sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
                    },
                }}
            >
                <Fade in={modalOpen}>
                    <MDBox
                        display="flex"
                        width="90vw" minHeight="60vh" maxHeight="100vh" shadow="xl"
                        position="fixed" top="50%" left="50%"
                        sx={{transform: "translate(-50%, -50%)"}}
                    >
                        <CurrentModelTestHistoryDetailModal selectedModel={selectedModel} historyId={historyId} historyStatus={historyStatus} closeModalBtn={handleClose}/>
                    </MDBox>
                </Fade>
            </Modal>

            <Footer />
        </TableLayout>
    );
}

export default CurrentModelTestList;