export class RateLimitedMouse {
    static isAbsoluteMode: boolean = true;
    static sensitivity: number = 1.0;

    private _msBetweenEvents: number;
    private _sendEventFn: (mouseInfo: MouseInfo) => void;
    private _queuedEvent: MouseInfo | null;
    private _eventTimer: NodeJS.Timeout | null;

    /**
     * @param msBetweenEvents Number of milliseconds to wait between sending low-priority mouse events to the backend.
     * @param sendEventFn Function that sends a parsed mouse event to the backend server.
     * @param mode Optional mode parameter to set absolute mode.
     */
    constructor(msBetweenEvents: number, sendEventFn: (mouseInfo: MouseInfo) => void, mode: boolean = true) {
        this._msBetweenEvents = msBetweenEvents;
        this._sendEventFn = sendEventFn;
        this._queuedEvent = null;
        this._eventTimer = null;
        RateLimitedMouse.isAbsoluteMode = mode;
    }

    onMouseDown(jsMouseEvt: MouseEvent): void {
        this._processHighPriorityEvent(this._parseMouseEvent(jsMouseEvt));
    }

    onMouseUp(jsMouseEvt: MouseEvent): void {
        this._processHighPriorityEvent(this._parseMouseEvent(jsMouseEvt));
    }

    onMouseMove(jsMouseEvt: MouseEvent): void {
        this._processLowPriorityEvent(this._parseMouseEvent(jsMouseEvt));
    }

    onWheel(jsMouseEvt: WheelEvent): void {
        this._processLowPriorityEvent(this._parseMouseEvent(jsMouseEvt));
    }

    setTimeoutWindow(msBetweenEvents: number): void {
        this._msBetweenEvents = msBetweenEvents;
    }

    static setMode(mode: boolean): void {
        RateLimitedMouse.isAbsoluteMode = mode;
    }

    static setSensitivity(value: number): void {
        RateLimitedMouse.sensitivity = value;
    }

    private _processHighPriorityEvent(mouseInfo: MouseInfo): void {
        // Cancel pending event, if one exists.
        this._queuedEvent = null;
        this._emitEvent(mouseInfo);
    }

    private _processLowPriorityEvent(mouseInfo: MouseInfo): void {
        if (this._isInTimeoutWindow() && RateLimitedMouse.isAbsoluteMode === true) {
            this._queuedEvent = mouseInfo;
        } else {
            this._emitEvent(mouseInfo);
        }
    }

    /**
     * Emit a mouse event immediately and start a timeout window to gate the next mouse event to send.
     *
     * @param mouseInfo Mouse information object, parsed from parseMouseEvent.
     */
    private _emitEvent(mouseInfo: MouseInfo): void {
        this._sendEventFn(mouseInfo);
        this._startTimeoutWindow();
    }

    private _startTimeoutWindow(): void {
        // Clear any existing timeout window, if one is set.
        if (this._eventTimer) {
            clearTimeout(this._eventTimer);
        }
        this._eventTimer = null;

        // Start the timeout window to gate subsequent low-priority events.
        this._eventTimer = setTimeout(() => {
            this._eventTimer = null;
            if (this._queuedEvent) {
                this._emitEvent(this._queuedEvent);
            }
            this._queuedEvent = null;
        }, this._msBetweenEvents);
    }

    private _isInTimeoutWindow(): boolean {
        return this._eventTimer !== null;
    }

    /**
     * Parses a standard JavaScript mouse event into a TinyPilot-specific object containing information about the mouse event.
     *
     * @param evt A standard JavaScript mouse event, such as mousedown or mousemove.
     * @returns The mouse event data in TinyPilot-specific format with properties like buttons, relativeX, relativeY, etc.
     */
    private _parseMouseEvent(evt: MouseEvent): MouseInfo {
        if (!RateLimitedMouse.isAbsoluteMode) {
            return {
                isAbsoluteMode: false,
                buttons: evt.buttons,
                relativeX: evt.movementX,
                relativeY: evt.movementY,
                verticalWheelDelta: normalizeWheelDelta((evt as WheelEvent).deltaY),
                horizontalWheelDelta: normalizeWheelDelta((evt as WheelEvent).deltaX),
                sensitivity: RateLimitedMouse.sensitivity,
            };
        } else {
            const boundingRect = (evt.target as HTMLElement).getBoundingClientRect();
            const cursorX = Math.max(0, evt.clientX - boundingRect.left);
            const cursorY = Math.max(0, evt.clientY - boundingRect.top);
            const width = boundingRect.right - boundingRect.left;
            const height = boundingRect.bottom - boundingRect.top;

            return {
                isAbsoluteMode: true,
                buttons: evt.buttons,
                relativeX: Math.min(1.0, Math.max(0.0, cursorX / width)),
                relativeY: Math.min(1.0, Math.max(0.0, cursorY / height)),
                verticalWheelDelta: normalizeWheelDelta((evt as WheelEvent).deltaY),
                horizontalWheelDelta: normalizeWheelDelta((evt as WheelEvent).deltaX),
            };
        }
    }
}

/**
 * Normalize mouse wheel delta to a value that's consistent across browsers.
 * Different browsers use different values for the delta, so we reduce it to a simple -1, 0, or 1.
 *
 * @param delta The mouse wheel delta value from the browser's mouse event.
 * @returns A value of -1, 0, or 1 representing whether the delta is negative, zero, or positive, respectively.
 */
function normalizeWheelDelta(delta: number): number {
    if (!delta) {
        return 0;
    }
    return Math.sign(delta);
}

// MouseInfo 인터페이스 정의
interface MouseInfo {
    isAbsoluteMode: boolean;
    buttons: number;
    relativeX: number;
    relativeY: number;
    verticalWheelDelta: number;
    horizontalWheelDelta: number;
    sensitivity?: number;
}