import React, { useState, useEffect, useRef } from 'react';
import { Grid, Paper } from '@mui/material';
import * as monaco from 'monaco-editor';


interface Props{
    codeText: string
    handleCodeText: (str:string)=>void
}
function CodeMirrorTextArea({codeText, handleCodeText }: Props): JSX.Element {


    const [code, setCode] = useState(codeText);
    const editorRef = useRef(null);

    useEffect(() => {
        editorRef.current = monaco.editor.create(document.getElementById('editor'), {
            value: code,
            language: 'python',
            theme: 'vs-dark',
            automaticLayout: true,
            fontSize:18
        });

        const subscription = editorRef.current.onDidChangeModelContent(() => {
            // handleCodeChange();
            handleCodeText(editorRef.current.getValue())
        });

        return () => {
            editorRef.current.dispose();
            subscription.dispose();
        };
    }, []);


    return (
        <Grid item height={"50vh"} justifyContent="center">
            <div id="editor" style={{ width: '100%', height:"100%"}} />
        </Grid>
    );
}

export default CodeMirrorTextArea;