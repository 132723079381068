import { useEffect, ReactNode } from "react";
import DashboardNavbar from "../../../Navbars/DashboardNavbar";
import DashboardLayout from "../../DashboardLayout";
import MDBox from "components/MDBox";

// Declaring props types for PageLayout
interface Props {
  children: ReactNode;
  pageTitle: string;
}

function TableLayout({children, pageTitle}: Props): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={pageTitle}/>
      <MDBox pt={3} pb={3}>
        {children}
      </MDBox>
    </DashboardLayout>

  );
}

export default TableLayout;
