import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import TableBody from "@mui/material/TableBody";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {DefaultResponse, HistoryDetailsResult} from "common/Types";
import {AxiosError} from "axios";
import React, {useEffect, useMemo, useState} from "react";
import TestService from "service/TestService";
import {getDetailByCode} from "common/Constant";
import TableCell from "@mui/material/TableCell";
import MDBadgeDot from "components/MDBadgeDot";
import Divider from "@mui/material/Divider";
import MDBadge from "components/MDBadge";
import pxToRem from "assets/theme/functions/pxToRem";
import {useTranslation} from "react-i18next";
import DateUtil from "../../../../../common/DateUtil";
import Icon from "@mui/material/Icon";


interface Props {
  historyId: number
  historyStatus: "P" | "F" | "R" | "E"
  closeModalBtn: ()=>void
}
function TestExecutionHistoryDetailsModal({historyId, historyStatus, closeModalBtn}: Props): JSX.Element {

  const {t} = useTranslation();

  const firstColumns = [
    { Header: t("testHistory.details.thead.category"), accessor: "category", width : "20%"},
    { Header: t("testHistory.details.thead.time"), accessor: "time", width: "20"},
    { Header: t("testHistory.details.thead.pass"), accessor: "pass", width : "20%"},
    { Header: t("testHistory.details.thead.fail"), accessor: "fail", width : "20%"},
    { Header: t("testHistory.details.thead.total"), accessor: "total", width : "20%"},
  ];

  const secondColumns = [
    { Header: t("testHistory.details.thead.category"), accessor: "category", width : "20%"},
    { Header: t("testHistory.details.thead.testCase"), accessor: "testCaseName", width : "15%"},
    { Header: t("testHistory.details.thead.startDt"), accessor: "startDt", width : "15%"},
    { Header: t("testHistory.details.thead.endDt"), accessor: "endDt", width : "15%"},
    { Header: t("testHistory.details.thead.time"), accessor: "time", width: "20"},
    { Header: t("testHistory.details.thead.result"), accessor: "status", width : "15%"},
  ];

  const [list, setList] = useState([])
  const [results, setResults] = useState([])

  const {refetch: getHistoryDetails} = useQuery<DefaultResponse, AxiosError>(
    ["getHistoryDetails"],
    () => TestService.getHistoryDetails(historyId),
    {
      onSuccess: (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          setList(res.data)
        }
      }
    }
  )

  const {refetch: getHistoryResults} = useQuery<DefaultResponse, AxiosError>(
    ["getHistoryResults"],
    () => TestService.getHistoryResults(historyId),
    {
      onSuccess: (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          setResults(res.data)
        }
      }
    }
  )

  useEffect(() => {
    getHistoryDetails()
    getHistoryResults()
  }, []);

  const resultTotal = useMemo(() => {
    const resultTotal = {time : 0, pass : 0, fail : 0, total : 0}
    for (const result of results) {
      resultTotal.time += result.time || 0
      resultTotal.pass += result.pass || 0
      resultTotal.fail += result.fail || 0
      resultTotal.total += result.total || 0
    }

    return resultTotal
  }, [results]);


  const data = useMemo(() => {

    const map = new Map()

    for (const result of results) {
      map.set(result["category"], [])
    }

    for (const item of list) {
      const category = item["category"]
      const dataList = map.get(category)
      dataList?.push({...item})

      map.set(category, dataList)
    }

    return map

  }, [list, results]);

  function renderResult(result: HistoryDetailsResult) {
    return (
      <MDBox display={"flex"} justifyContent={"flex-end"}>
        <MDBadgeDot badgeContent={String(result.pass)} color={"success"} size={"lg"} />
        <Divider orientation={"vertical"}/>
        <MDBadgeDot badgeContent={String(result.fail)} color={"primary"} size={"lg"} />
        <Divider orientation={"vertical"}/>
        <MDBadgeDot badgeContent={String(result.total)} color={"info"} size={"lg"} />
      </MDBox>
    )
  }

  function renderStatus(status: "P" | "F" | "R" | "E" | "X", size?: "xs" | "sm" | "md" | "lg") {
    let color : "success" | "primary" | "warning" | "secondary" | "error"
    if (status === "P") {
      color = "success"
    } else if (status === "F") {
      color = "primary"
    } else if (status === "R") {
      color = "warning"
    } else if (status === "X") {
      color = "error"
    } else {
      color = "secondary"
    }

    return <MDBadge container badgeContent={t(getDetailByCode("TEST_EXECUTION_STATUS", status))} size="sm" color={color} variant={"contained"}/>
  }

  function translateTimeString(timeString: string): string {

    const times = timeString.split(":")
    let hour
    let minute
    const second = t("DateUtil.timeString.second", {Second : times.pop()})

    if (times.length === 0) {
      return second
    } else if (times.length === 2) {
      hour = t("DateUtil.timeString.hour", {Hour : times[0]}) + " "
      minute = t("DateUtil.timeString.minute", {Minute : times[1]}) + " "
    } else {
      hour = ""
      minute = t("DateUtil.timeString.minute", {Minute : times[0]}) + " "
    }

    return hour + minute + second
  }

  return (
    <Card sx={{width : "100%", px : 4, py : 2,}}>
      <MDBox display={"flex"} flexDirection={"column"} gap={2}
             sx={{
               boxShadow : "none", overflow : "auto",
               "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
               "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
               ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
             }}
      >

        <MDBox display={"flex"} justifyContent={"space-between"} pb={1}>
          <MDTypography variant={"h5"}>
            History Details {renderStatus(historyStatus, "lg")}
          </MDTypography>
          <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
            <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
          </MDBox>
        </MDBox>

        <MDBox display={"flex"} flexDirection={"column"} gap={1}>

          <MDBox display={"flex"} flexDirection={"column"} gap={1}>
            <MDBox display={"flex"}>
              <TableContainer>
                <Table>
                  <MDBox component={"thead"}>
                    <TableRow>
                      {firstColumns.map((column: any, index: number) => (
                      <DataTableHeadCell
                        key={index}
                        width={column.width}
                        align={"center"}
                      >
                        {column.Header}
                      </DataTableHeadCell>
                      ))}
                    </TableRow>
                  </MDBox>

                  <TableBody>
                    {results.map((result: any, rowIndex: number) => (
                    <TableRow key={rowIndex}>
                      {firstColumns.map((column: any, index: number) => (
                      <DataTableBodyCell
                        key={index}
                        align={"center"}
                      >
                        {column.accessor === "time" ? translateTimeString(DateUtil.secondsToTimeString(result.time)) : result[column.accessor]}
                      </DataTableBodyCell>
                      ))}
                    </TableRow>
                    ))}
                    <TableRow>
                      <DataTableBodyCell
                        key={0}
                        align={"center"}
                      >
                        Total
                      </DataTableBodyCell>
                      <DataTableBodyCell
                        key={1}
                        align={"center"}
                      >
                        {translateTimeString(DateUtil.secondsToTimeString(resultTotal.time))}
                      </DataTableBodyCell>
                      <DataTableBodyCell
                        key={2}
                        align={"center"}
                      >
                        {resultTotal.pass}
                      </DataTableBodyCell>
                      <DataTableBodyCell
                        key={3}
                        align={"center"}
                      >
                        {resultTotal.fail}
                      </DataTableBodyCell>
                      <DataTableBodyCell
                        key={4}
                        align={"center"}
                      >
                        {resultTotal.total}
                      </DataTableBodyCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </MDBox>

            <MDBox display={"flex"} pt={2} justifyContent={"flex-end"}>
              <MDBadgeDot badgeContent={t("CONSTANT.EXECUTION.STATUS.P")} color={"success"} size={"sm"} />
              <Divider orientation={"vertical"}/>
              <MDBadgeDot badgeContent={t("CONSTANT.EXECUTION.STATUS.F")} color={"primary"} size={"sm"} />
              <Divider orientation={"vertical"}/>
              <MDBadgeDot badgeContent={t("Total")} color={"info"} size={"sm"} />
            </MDBox>

            <MDBox display={"flex"}>
              <TableContainer
                sx={{
                minHeight : pxToRem(200), boxShadow : "none", overflow : "auto",
                "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
              }}
              >
                <Table>
                  <MDBox component={"thead"}>
                    <TableRow>
                      {secondColumns.map((column: any, index: number) => (
                      <DataTableHeadCell
                        key={index}
                        width={column.width}
                        align={"center"}
                      >
                        {column.Header}
                      </DataTableHeadCell>
                      ))}
                    </TableRow>
                  </MDBox>

                  <TableBody>
                    {[...data.keys()].map((category: string, categoryIndex: number) => (
                    <>
                      <TableRow>
                        <DataTableBodyCell
                          key={categoryIndex}
                          align={"center"}
                        >
                          {category} Result
                        </DataTableBodyCell>
                        <TableCell colSpan={5} align={"center"}>
                          {renderResult(results.find((result) => result.category === category))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell rowSpan={data.get(category).length + 1} align={"center"}>
                          {category}
                        </TableCell>
                      </TableRow>
                      {data.get(category).map((row: any, rowIndex: number) => (
                      <TableRow key={rowIndex}>
                        {secondColumns.slice(1, 6).map((column: any, index: number) => (
                        <DataTableBodyCell
                          key={index}
                          align={column.accessor === "testCaseName" ? "left" : "center"}
                        >
                          {column.accessor === "status" ? renderStatus(row["status"], "lg"):
                            column.accessor === "time" ? translateTimeString(DateUtil.timeDiffToTimeString(row["startDt"], row["endDt"])) :
                            row[column.accessor]}
                        </DataTableBodyCell>
                        ))}
                      </TableRow>
                      ))}
                    </>
                    ))}
                  </TableBody>

                </Table>
              </TableContainer>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default TestExecutionHistoryDetailsModal