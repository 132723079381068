import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Footer from "examples/Footer";
import Notice from "./components/Notice/Notice";
import Today from "./components/Today/Today";
import {
  DashboardLineCharts,
  DashboardPieCharts,
  DefaultResponse,
  LineChartData,
  PieChartData, TodayTestCase,
} from "common/Types";
import {useEffect, useMemo, useState} from "react";
import CustomPieChart from "./components/CustomPieChart/CustomPieChart";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import DashboardService from "service/DashboardService";
import DateUtil from "common/DateUtil";
import CustomLineChart from "./components/CustomLineChart/CustomLineChart";
import ActivityTimeLine from "./components/ActivityTimeLine/ActivityTimeLine";
import {Skeleton} from "@mui/material";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";

function QaSummaryView():JSX.Element {

  const {t} = useTranslation();

  const [pieCharts, setPieCharts] = useState<DashboardPieCharts>({
                                                                                          todayFailTestSuite : null,
                                                                                          todayTestResult : null,
                                                                                        })

  const [lineCharts, setLineCharts] = useState<DashboardLineCharts>({
                                                                                          weekOfUse : null,
                                                                                          lastWeekOfUse : null,
                                                                                        })

  const today = useMemo(() => (
    DateUtil.getNowTime().substring(0, 10)
  ), []);

  const weekOfMonth = useMemo(() => (
    DateUtil.getWeekOfMonth()
  ), [])

  const {refetch: getPieChart} = useQuery<DefaultResponse, AxiosError>(
    ["getPieChart"],
    () => DashboardService.getPieChart(),
    {
      onSuccess : (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          const dashboardPieCharts: DashboardPieCharts = res.data
          setPieCharts(dashboardPieCharts)
        }
      }
    }
  )

  function transformToPieChartData(rawData: TodayTestCase[]) {
    if (!rawData) {
      return null
    }
    const chartData: PieChartData = {
      labels: [],
      datasets: {
        label: "Today's failed Test Suites",
        borderWidth : 0,
        backgroundColors : ["primary", "secondary", "info", "success", "warning", "error", "light", "dark"],
        data: [],
      }
    }

    if(rawData.length === 0) {
      chartData.labels.push("N/A")
      chartData.datasets.data.push(1)
    } else {
      for (const data of rawData) {
        chartData.labels.push(data.category)
        chartData.datasets.data.push(parseInt(data.cnt))
      }
    }
    if (chartData.labels.length > 1) {
      chartData.datasets.borderWidth = 2
    }
    return chartData
  }

  const {refetch: getLineChart} = useQuery<DefaultResponse, AxiosError>(
    ["getLineChart"],
    () => DashboardService.getLineChart(),
    {
      onSuccess : (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          const dashboardLineCharts: DashboardLineCharts = res.data
          setLineCharts(dashboardLineCharts)
        }
      }
    }
  )
  function transformToLineChartData(rawData: DashboardLineCharts) {
    if (!rawData.lastWeekOfUse || !rawData.weekOfUse) {
      return null
    }
    const chartData: LineChartData = {
      labels : ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN",],
      datasets : [
        {
          label : "This Week",
          color : "info",
          data : []
        },
        {
          label : "Last Week",
          color : "secondary",
          data : []
        }
      ]
    }
    for (const data of rawData.weekOfUse) {
      chartData.datasets[0].data.push(data.totalHours)
    }
    for (const data of rawData.lastWeekOfUse) {
      chartData.datasets[1].data.push(data.totalHours)
    }
    return chartData
  }

  useEffect(() => {
    getPieChart()
    getLineChart()
  }, []);

  const todaysFailureChartData = useMemo(() => (
    transformToPieChartData(pieCharts.todayFailTestSuite)
  ), [pieCharts])

  const todayTestResultChartData = useMemo(() => (
    transformToPieChartData(pieCharts.todayTestResult)
  ), [pieCharts]);

  const usageOfWeekData = useMemo(() => (
    transformToLineChartData(lineCharts)
  ), [lineCharts])

  return (

    <DashboardLayout>
      <DashboardNavbar pageTitle={"QA Summary"}/>
      <MDBox pt={3} pb={1} gap={1}>
        <Grid container pb={visualViewport.height > 1024 ? 1 : 0}>
          <Notice />
        </Grid>
        <Grid container pt={2} spacing={3}>
          <Grid item xs={12} md={12} lg={3}>
            <Today/>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Grid container spacing={1.5}>
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={6}>
                    {pieCharts.todayTestResult !== null ?
                      <CustomPieChart
                        title={t("qaSummary.pie.today")}
                        description={today}
                        chart={todayTestResultChartData}
                        height={visualViewport.height > 1024 ? "20rem" : "12rem"}
                      /> :
                      <Card>
                        <MDBox py={2} pr={2} pl={2}>
                          <MDBox display="flex" px={1} pt={1}>
                            <MDBox width={"100%"}>
                              <Skeleton variant={"text"} width={"100%"} height={"2rem"}/>
                              <MDBox mb={2}>
                                <Skeleton variant={"text"} width={"100%"} height={"1rem"}/>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                          <MDBox height={visualViewport.height > 1024 ? "20rem" : "12rem"}>
                            <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}/>
                          </MDBox>
                        </MDBox>
                      </Card>
                    }
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    {pieCharts.todayFailTestSuite !== null ?
                      <CustomPieChart
                        title={t("qaSummary.pie.failed")}
                        description={today}
                        chart={todaysFailureChartData}
                        height={visualViewport.height > 1024 ? "20rem" : "12rem"}
                      /> :
                      <Card>
                        <MDBox py={2} pr={2} pl={2}>
                          <MDBox display="flex" px={1} pt={1}>
                            <MDBox width={"100%"}>
                              <Skeleton variant={"text"} width={"100%"} height={"2rem"}/>
                              <MDBox mb={2}>
                                <Skeleton variant={"text"} width={"100%"} height={"1rem"}/>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                          <MDBox height={visualViewport.height > 1024 ? "20rem" : "12rem"}>
                            <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}/>
                          </MDBox>
                        </MDBox>
                      </Card>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                {lineCharts.weekOfUse === null || lineCharts.lastWeekOfUse === null ?
                  <Card>
                    <MDBox py={2} pr={2} pl={2}>
                      <MDBox display="flex" px={1} pt={1}>
                        <MDBox width={"100%"}>
                          <Skeleton variant={"text"} width={"100%"} height={"2rem"}/>
                          <MDBox mb={2}>
                            <Skeleton variant={"text"} width={"100%"} height={"1rem"}/>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox height={"18rem"}>
                        <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}/>
                      </MDBox>
                    </MDBox>
                  </Card> :
                  <CustomLineChart
                    title={t("qaSummary.line")}
                    description={weekOfMonth}
                    chart={usageOfWeekData}
                    height={visualViewport.height > 1024 ? "30rem" : "17.25rem"}
                  />}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <ActivityTimeLine />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default QaSummaryView