import React, {useEffect} from 'react'
import {createSearchParams, useNavigate} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import StorageUtil from "common/StorageUtil";
import {ACCESS_TOKEN, LOGIN_TYPE, USER_NAME, PERMISSIONS, USER_ID} from 'common/Constant'
import StringUtil from "common/StringUtil";

type LogoutMsg = {
    type : string
}

const AuthenticationView = () => {
    const navigate = useNavigate()
    const history = createBrowserHistory();
    const location = history.location
    const state = location.state as LogoutMsg
    const type = state?.type
    const data: LogoutMsg = { type: LOGIN_TYPE.EXPIRED};

    const initAuthentication = () => {
        if(type === LOGIN_TYPE.EXPIRED){
            StorageUtil.initStorage()
            navigate({pathname: '/authentication/sign-in', search: createSearchParams(data).toString()})
        }else if(StringUtil.isEmpty(StorageUtil.getLocalStorage(USER_NAME))
            || StringUtil.isEmpty(StorageUtil.getLocalStorage(PERMISSIONS))
            || StringUtil.isEmpty(StorageUtil.getLocalStorage(ACCESS_TOKEN))
            || StringUtil.isEmpty(StorageUtil.getLocalStorage(USER_ID))
        ) {
            StorageUtil.initStorage()
            goLogin()
        }else{
            goMain()
        }
    }

    const goMain = () => {
        navigate('/dashboards/qaSummary')
    }

    const goLogin = () => {
        navigate('/authentication/sign-in')
    }

    useEffect(()=> {
        initAuthentication()
    },[])

    return(
        <></>
    )
}

export default AuthenticationView