import {apiClient} from "service/ApiClient";
import {DefaultResponse} from "../common/Types";

const getProjectList = async function (){
    return await  apiClient.get<DefaultResponse>('project').then((res:any) => res.data)
}
const getProjectListByDomainType = async function (domainType:any){
    return await apiClient.get<DefaultResponse>(`project/${domainType}`).then((res:any) => res.data)
}


const ProjectService = {
    getProjectList,
    getProjectListByDomainType
}

export default ProjectService;