import MDBox from "components/MDBox";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import MDDatePicker from "components/MDDatePicker";
import {Refresh} from "@mui/icons-material";
import MDInput from "components/MDInput";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import {useLocation, useNavigate} from "react-router-dom";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import TestService from "service/TestService";
import {DefaultResponse, ModelExecution} from "common/Types";
import DateUtil from "common/DateUtil";
import {BRAND_NAME, getCodeByDetail, getDetailByCode, LANGUAGE, SSE, USER_ID} from "common/Constant";
import MDSnackbar from "components/MDSnackbar";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import {useTranslation} from "react-i18next";
import Card from "@mui/material/Card";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import ConfirmationDialog from "../../../component/ConfirmationDialog";
import GPUValidationService from "../../../../service/GPUValidationService";
import ModelGenerateHistoryModal from "../history/ModelGenerateHistoryModal";
import {ModelGenerateConstructor} from "../../../../common/Constructors";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sseForUserRecoilState} from "../../../../store/recoilState";
import StorageUtil from "../../../../common/StorageUtil";

function ModelGenList(): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const columns = [
        { Header: t("modelGen.thead.name"), accessor: "name", width: "15%", sortable: true},
        { Header: t("modelGen.thead.device"), accessor: "deviceName", width: "10%", sortable: true},
        { Header: t("modelGen.thead.type"), accessor: "modelType", width: "10%", sortable: true},
        { Header: t("modelGen.thead.generateMethod"), accessor: "generateMethod", width: "10%", sortable: true},
        { Header: t("modelGen.thead.inputShape"), accessor: "inputShape", width: "10%", sortable: false },
        { Header: t("modelGen.thead.iterateCnt"), accessor: "iterCnt", width: "10%", sortable: true },
        { Header: "", accessor: "execution", width: "5%", sortable: false },
    ];
    const [modalOpen, setModalOpen] = useState(false);
    const [validationId, setValidationId] = useState(undefined)
    const _modelGenerate = new ModelGenerateConstructor(
        undefined
    )
    // const [genState, setGenState] = useState(false);
    // const [genExecution, setGenExecution] = useState(undefined);

    const [deleteModelGenList, setDeleteModelGenList] = useState<ModelExecution[]>([])
    /*paging start*/
    const entries = ["10", "25", "50", "100"];
    const [entriesPerPage, setEntriesPerPage] = useState( 10 )
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [sort, setSort] = useState("")

    const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")
    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setErrMsg(msg)
        setErrColor(color)
        setOpenErrMsg(true)
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/

    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;
    // Setting the entries ending point
    let entriesEnd;

    const totalPageLength = Math.ceil(list.length / entriesPerPage);
    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength ) {
        entriesEnd = list.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }

    /*paging end*/
    /*searching start*/
    // Search input value state

    const [modelGenName, setModelGenName] = useState("");
    // 등록일 선택 검색
    // const [ fromDate, setFromDate ] = useState<Date>(DateUtil.getDefaultFromDate())
    const [ fromDate, setFromDate ] = useState<Date>(null)


    const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())
    // 등록일 handle function
    const handleFromDateChange = ( _fromDate: Date) => {
        setFromDate(_fromDate)

    }
    const handleToDateChange = ( _toDate: Date) => {
        setToDate(_toDate)
    }

    /* SSE 설정 */
    const setSseForUser = useSetRecoilState(sseForUserRecoilState);
    const sseForUser = useRecoilValue(sseForUserRecoilState);

    const handleConnectedEvent = (e: any) => {
        const { data: receivedData } = e;
        console.log(receivedData);
    };

    const handleGenerateFinishEvent = (e: any) => {
        getModelGenList()
        openAlert("생성이 완료되었습니다.", "success")
    };

    useEffect(()=>{
        const baseURI = process.env.REACT_APP_BACKEND_URI+process.env.REACT_APP_API_VERSION

        if(!sseForUser.sse){
            setSseForUser({sse: new EventSource(baseURI + "/sse/connect/" + StorageUtil.getLocalStorage(USER_ID))})
        }else{
            sseForUser.sse.addEventListener("Connected", handleConnectedEvent)
            sseForUser.sse.addEventListener(SSE.MODEL_GEN_FINISH, handleGenerateFinishEvent)
        }
    }, [sseForUser.sse])

    useEffect(()=>{
        return () => {
            // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
            if (sseForUser.sse) {
                sseForUser.sse.removeEventListener('Connected', handleConnectedEvent);
                sseForUser.sse.removeEventListener(SSE.MODEL_GEN_FINISH, handleGenerateFinishEvent);
            }
        };
    },[]);

    /* SSE 설정 끝 */

    useEffect(()=> {
        if(fromDate && toDate && fromDate > toDate){
            openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
            setFromDate(undefined)
            setToDate(undefined)
        }
    },[fromDate, toDate])
    /*searching end*/
    const goToDetail = (row: any | undefined, type: string) => {
        const url = type === 'Create'? `/validation/${location.pathname.split('/')[2]}/model`: `/validation/${location.pathname.split('/')[2]}/model/${row.id}`;
        navigate({pathname: url}, {state: {menu_name: location.pathname === '/validation/predefined' ? "Pre-Defined Model" : "Custom Model", model: row, type: type, genType: getCodeByDetail("GPU_GEN_TYPE", location.pathname.split('/')[2])}})

    }

    const {refetch: getModelGenList} = useQuery<any, AxiosError>(
        ['getModelGenList'],
        () => GPUValidationService.getModelGenList(page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), modelGenName,getCodeByDetail("GPU_GEN_TYPE", location.pathname.split('/')[2])),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    console.log(res.data)
                    setList(res.data.content)
                    setTotal(res.data.total)
                }
            }
        }
    )

    const {refetch: generatePreDefinedModel} = useQuery<any, AxiosError>(
        ['generatePreDefinedModel'],
        () => GPUValidationService.generateModel(_modelGenerate.id),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    openAlert("작업이 요청 되었습니다.", "info")
                }else{
                    openAlert(res.error, "error")
                }
                getModelGenList()
            }
        }
    )

    const {refetch: deleteModelGen} = useQuery<any, AxiosError>(
        ['deleteModelGen'],
        () => GPUValidationService.deleteModelGenConfig(deleteModelGenList),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    navigate(0)
                }
            }
        }
    )
    // 체크박스 체크리스트 선택

    const [ checkList, setCheckList ] = useState(new Set<any>([]));
    // 체크박스 단일 선택
    const handleSingleCheck = ((key: any) => {
        if ( checkList.has( key ) ) {
            checkList.delete( key )
            setCheckList( new Set<any>( [ ...checkList ] ) );
        } else {
            setCheckList( new Set<any>([ ...checkList.add( key ) ] ) );
        }

    });

    const onClickDelete = () => {
        if (checkList.size === 0) {
            openAlert(t("ERRORMESSAGE.CONFIRM.DELETE.MODELGEN"), "warning")
        } else {
            openConfirm(t("confirm.delete"), "warning")
        }
    }

    const onClickSave = () => {
        goToDetail({},'Create')

    }

    const handleYes = () => {
        const _deleteModelGenList:ModelExecution[] = []
        checkList.forEach(value => {
            _deleteModelGenList.push({
                id: value
            })
        })
        setDeleteModelGenList(_deleteModelGenList)

    }
    const handleNo = () => {
        toggleDialog()

    }
    // 체크박스 전체 선택
    const handleAllCheck =  (( checked: boolean ) => {
        if( checked ) {
            // 전체 선택 클릭 시 모든 로우의 키들 담음
            const set = new Set<any>([]);
            for (let i = 0; i < Math.min(entriesPerPage, list.length); i++ ) {
                set.add( list[i].id );
            }
            setCheckList( set );
        } else {
            setCheckList ( new Set<any>([]) );
        }

    });
    // 페이지 변경시 전체 체크 해제



    useEffect( () => {
        setCheckList( new Set<any>([]) );
    }, [page] );

    useEffect( () => {
        getModelGenList()
    }, [] );

    useEffect(() => {
        getModelGenList()
    }, [ page ]);

    useEffect(() => {
        setPage(1)
        getModelGenList()
    }, [ entriesPerPage]);

    useEffect( () => {
        if(deleteModelGenList && deleteModelGenList.length > 0)
            deleteModelGen()
    }, [deleteModelGenList]);
    const onClickHeader = (_accessor:string) => {
        if(sort === _accessor){
            if(direction && direction === "DESC"){
                setDirection("ASC")
            }else{
                setDirection("DESC")
            }
        }else{
            setDirection("DESC")
            setSort(_accessor)
        }

    }
    const activeEnter = (ev:KeyboardEvent) => {
        if(ev.key === "Enter") {
            // getExecutionList();
        }

    }

    useEffect( () => {
        if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
            getModelGenList()
        }
    }, [sort, direction]);

    const handleGenerateClick = (row:ModelExecution, status:Boolean) => {
        _modelGenerate.id = undefined

        if(!status){
            openAlert("현재 모델 생성 중입니다. 나중에 다시 시도해주십시오.", "dark")
        } else{
            _modelGenerate.id = row.id
            // setGenExecution(row.id)
            generatePreDefinedModel();

        }
    }


    const clickHistory = (row: ModelExecution) => {
        setValidationId(row.id)
        setModalOpen(true)
    }


    function handleClose() {
        setModalOpen(false)
        setTimeout(() => {
            setValidationId(undefined)
        }, 150)
    }


    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(90)

    useEffect(() => {
        getModelGenList();
    }, [location.pathname]); // location.pathname이 변경될 때마다 호출

    return (
        /* list, detail 화면에서 공용으로 사용하는 최상위 layout 컴포넌트 */
        <TableLayout pageTitle={location.state?.menu_name}>

            {/* 검색 영역 layout */}
            <SearchLayout>
                <MDBox  display={"flex"} justifyContent={"space-between"}>
                    <MDBox display={"flex"} width="90%" alignItems="center">
                        <MDBox display={"flex"} alignItems="center" >
                            <MDDatePicker
                                value={ fromDate }
                                input = {
                                    {
                                        size : "small",
                                        height: "37px",
                                        placeholder : t("search.fromDate"),
                                    }
                                }
                                options = {
                                    {
                                        maxDate : "today",
                                        // locale : Korean,
                                    }
                                }
                                onChange = { ( currentTarget: any ) => {
                                    const target = currentTarget[0];
                                    handleFromDateChange( target );
                                }}
                            />
                            <MDBox px={0.25}>~</MDBox>
                            <MDDatePicker
                                value={ toDate }
                                input = {
                                    {
                                        size : "small",
                                        height: "37px",
                                        placeholder : t("search.toDate"),
                                    }
                                }
                                options = {
                                    {
                                        maxDate : "today",
                                        // locale : Korean,
                                    }
                                }
                                onChange = { ( currentTarget: any ) => {
                                    const target = currentTarget[0];
                                    handleToDateChange( target );
                                }}
                            />
                            <MDBox px={0.25}/>
                            <MDButton iconOnly={true} onClick={ () => { setFromDate( undefined ); setToDate( undefined ); } }>
                                <Refresh/>
                            </MDButton>
                        </MDBox>
                        <MDBox alignItems="center" width="15%" pl={3}>
                            <MDInput
                                placeholder={t("search.name")}
                                value={modelGenName}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }: any) => {
                                    setModelGenName(currentTarget.value)
                                }}
                                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox display={"flex"}>
                        <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getModelGenList()}>
                            {t("search.button.search")}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </SearchLayout>

            {/* 테이블 헤더 */}
            <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />

            {/* 테이블 전체 layout */}
            <Card>
                <TableContainer
                    sx={{
                        boxShadow : "none", overflow : "auto",
                        "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                        "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                        ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                    }}
                >
                    {/* 테이블 contents */}
                    <Table>
                        <MDBox component={"thead"}>
                            <TableRow>
                                <DataTableHeadCell
                                    width={ "1%" }
                                    align={ "center" }
                                    direction={ "none" }
                                    sortable={false}
                                >
                                    <Checkbox size={"small"}
                                              onChange={( e) => {
                                                  handleAllCheck(e.target.checked);
                                              }}
                                              checked={ checkList.size === Math.min(entriesPerPage, list.length) && list.length > 0 } />

                                </DataTableHeadCell>

                                {columns.map(( column : any, index : number ) => (
                                    <DataTableHeadCell
                                        key={index}
                                        width={column.width}
                                        align={"left"}
                                        onClick={onClickHeader}
                                        accessor={column.accessor}
                                        direction={direction}
                                        sort={sort}
                                        sortable={column.sortable}
                                    >
                                        {column.Header}
                                    </DataTableHeadCell>
                                ))}
                            </TableRow>
                        </MDBox>
                        <TableBody>
                            {list.map(( row : any, index : number ) => (
                                <TableRow key={index} >
                                    <DataTableBodyCell
                                        align={ "center" }
                                    >
                                        <Checkbox
                                            checked={ checkList.has( row.id ) }
                                            onChange={ (e) => {
                                                handleSingleCheck( row.id );
                                            }}
                                        />
                                    </DataTableBodyCell>

                                    {columns.map(( column : any, index) => (
                                        <DataTableBodyCell
                                            key={index}
                                            align={ "left" }
                                            goToDetail={["execution"].indexOf(column.accessor) > -1 ?null:goToDetail}
                                            row={row}
                                        >
                                            {
                                                    column.accessor === "execution"?
                                                        <MDBox>
                                                            {
                                                                 !row["status"]?
                                                                    <MDButton color="dark" size="small" variant="outlined" onClick={(e) => {}} sx={{minWidth : buttonMinWidth}}>
                                                                        {t("button.generating")}
                                                                    </MDButton>:
                                                                    <MDButton color="info" size="small" variant="outlined" onClick={(e) => {handleGenerateClick(row, row["status"])}} sx={{minWidth : buttonMinWidth}}>
                                                                        {t("button.generate")}
                                                                    </MDButton>
                                                            }
                                                            <MDBox display={"inline-block"} p={0.5}/>
                                                            <MDButton color="gray" size="small" variant="outlined" onClick={(e) => {clickHistory(row)}} sx={{minWidth : buttonMinWidth}}>
                                                                {t("button.history")}
                                                            </MDButton>
                                                        </MDBox>:
                                                        column.accessor === "modelType"? getDetailByCode("GPU_MODEL_TYPE", row["modelType"]):row[ column.accessor]
                                            }

                                        </DataTableBodyCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* 테이블 footer */}
                <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page}
                             setEntriesPerPage={setEntriesPerPage} onClickSave={onClickSave} onClickDelete={onClickDelete} onChangePage={setPage}/>
            </Card>

            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color={errColor}
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />

            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
            />

            <Modal
                open={modalOpen} onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 200,
                        sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
                    },
                }}
            >
                <Fade in={modalOpen}>
                    <MDBox
                        display="flex"
                        width="95vw" minHeight="85vh" maxHeight="80vh" shadow="xl"
                        position="fixed" top="50%" left="50%"
                        sx={{transform: "translate(-50%, -50%)"}}
                    >
                        <ModelGenerateHistoryModal validationId={validationId} closeModalBtn={handleClose}/>
                    </MDBox>
                {/* 여기에 이력보기 모달창이 들어갈 예정 */}
                </Fade>
            </Modal>

            <Footer />
        </TableLayout>
    );
}

export default ModelGenList;