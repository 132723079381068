import MDBox from "components/MDBox";
import DeviceInfoCard from "./DeviceInfoCard";
import {useTranslation} from "react-i18next";

interface Props {
    data: {
      totalDevices: number,
      unusableDevices: number,
      regularDevices: number,
      idleDevices: number,
      userDevices: number
    }
}

function DeviceInfoCardsContainer({data} : Props) {

  const {t} = useTranslation();

  return (
    <MDBox display={"flex"} justifyContent={"space-between"} py={2} gap={1}>
      <DeviceInfoCard icon={"devices"} title={t("deviceSummary.total")} color={"error"} value={data.totalDevices}/>
      <DeviceInfoCard icon={"history"} title={t("deviceSummary.regular")} color={"success"} value={data.regularDevices}/>
      <DeviceInfoCard icon={"sensor_occupied"} title={t("deviceSummary.user")} value={data.userDevices}/>
      <DeviceInfoCard icon={"power_settings_new"} title={t("deviceSummary.idle")} color={"secondary"} value={data.idleDevices}/>
      <DeviceInfoCard icon={"do_not_disturb_on_outlined"} title={t("deviceSummary.unusable")} color={"dark"} value={data.unusableDevices}/>
    </MDBox>
  )
}

export default DeviceInfoCardsContainer;