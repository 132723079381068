import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

import React, {useEffect, useState} from "react";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import TableBody from "@mui/material/TableBody";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useLocation, useNavigate} from "react-router-dom";
import DeviceService from "service/DeviceService";
import {DefaultResponse, DeviceTotalData, } from "common/Types";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import TableContainer from "@mui/material/TableContainer";
import DeviceInfoCardsContainer from "./deviceInfoCards/DeviceInfoCardsContainer";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {getDetailByCode} from "common/Constant";
import {useTranslation} from "react-i18next";
import MDBadge from "components/MDBadge";

function DeviceSummaryView(): JSX.Element {

  const {t} = useTranslation();
  const navigate = useNavigate()
  const location = useLocation();

  const columns = [
    { Header: t("deviceSummary.thead.node"), accessor: "nodeName", sortable: true},
    { Header: t("deviceSummary.thead.nodeIp"), accessor: "nodeIp", sortable: true},
    { Header: t("deviceSummary.thead.device"), accessor: "deviceName", sortable: true},
    { Header: t("deviceSummary.thead.status"), accessor: "executionStatus", sortable: true},
    { Header: t("deviceSummary.thead.buildType"), accessor: "deviceBuildType", sortable: true},
    { Header: t("deviceSummary.thead.containerIp"), accessor: "deviceContainerIp", sortable: true},
    { Header: t("deviceSummary.thead.pdu"), accessor: "pduName", sortable: true},
    { Header: t("deviceSummary.thead.pduIp"), accessor: "pduIp", sortable: true},
    { Header: t("deviceSummary.thead.interfaceType"), accessor: "deviceInterfaceType", sortable: true},
  ];

  const [sort, setSort] = useState("")
  const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")

  const onClickHeader = (_accessor:string) => {
    if(sort === _accessor){
      if(direction && direction === "DESC"){
        setDirection("ASC")
      }else{
        setDirection("DESC")
      }
    }else{
      setDirection("DESC")
      setSort(_accessor)
    }
  }

  function renderStatusIcon(status : string, usable:boolean) {
    let content;
    let color : "warning" | "success" | "error" | "primary" | "secondary" | "info" | "light" | "dark"

    if ( status === "A" ) {
      content = t("device.status.active")
      color  = "warning"
    } else {
      content = t("device.status.inactive")
      color = "secondary"
    }

    if(!usable){
      content = t("device.status.broken")
      color = "error"
    }
    return (
        <MDBadge container badgeContent={content} size="sm" color={color} variant={"contained"} minWidth={80}/>
    )
  }

  function renderBuildType(buildType: string, using: string) {
    let content
    let color : "warning" | "success" | "error" | "primary" | "secondary" | "info" | "light" | "dark"
    if (buildType === "R") {
      content = t(getDetailByCode("BUILD", buildType))
      color = "success"
    } else if (using === 'U' || using === 'P') {
      content = t("CONSTANT.RESERVATION.U")
      color = "secondary"
    } else {
      content = t(getDetailByCode("BUILD", buildType))
      color = "info"
    }
    return <MDBadge container badgeContent={content} size="sm" color={color} variant={"contained"} minWidth={110}/>
  }

  const [ data, setData ] = useState([]);
  const [ total, setTotal ] = useState(0)

  const entries = ["10", "25", "50", "100"];
  const [ entriesPerPage, setEntriesPerPage ] = useState( 10 );
  const [ page, setPage ] = useState(1);


  const {refetch: getDeviceList} = useQuery<DefaultResponse, AxiosError>(
    ["getDeviceList"],
    () => DeviceService.getDeviceList(page, entriesPerPage, sort, direction),
    {
      onSuccess : (res: DefaultResponse) => {
          if ( res.code === 200 && res.subCode === 0 ) {
            setData(res.data.content)
            setTotal(res.data.total)
          }
      }
    }
  )

  useEffect(() => {
    setPage(1)
    getDeviceList()
  }, [entriesPerPage]);

  useEffect(() => {
    getDeviceList()
  }, [page]);

  useEffect( () => {
    if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
      getDeviceList()
    }
  }, [sort, direction]);

  const [ deviceTotalData, setDeviceTotalData ]
    = useState({
                      totalDevices : 0,
                      unusableDevices: 0,
                      regularDevices : 0,
                      idleDevices : 0,
                      userDevices : 0
                    })

  const {refetch: getDeviceTotalInfo} = useQuery<DefaultResponse, AxiosError>(
    ["getDeviceTotalInfo"],
    () => DeviceService.getDeviceTotalInfo(),
    {
      onSuccess : (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          const data: DeviceTotalData = res.data
          setDeviceTotalData(data)
        }
      }
    }
  )

  useEffect(() => {
    getDeviceTotalInfo()
  }, []);


  // Setting the entries starting point
  const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1 ;

  // Setting the entries ending point
  let entriesEnd;
  const totalPageLength = Math.ceil(data.length / entriesPerPage);

  if (page === 1) {
    entriesEnd = entriesPerPage;
  } else if (page === totalPageLength ) {
    entriesEnd = data.length;
  } else {
    entriesEnd = entriesPerPage * page;
  }


  const onClickSave = () => {
    alert("저장")
  }

  const onClickDelete = () => {
    alert("삭제")
  }

  // 디바이스 상세 페이지 이동
  const goToDetail = (row: any | undefined, type: string)=>{
    navigate({pathname:`/device/DUT/${row.deviceId}`}, {state:{menu_name: "Device", device: {id:row.deviceId}, type: "Edit"}})
  }

  return (
  <TableLayout pageTitle={location.state?.menu_name}>

    <DeviceInfoCardsContainer data={deviceTotalData}/>

    <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < data.length ? entriesEnd : total} total={total} />

    <Card>
    <TableContainer
      sx={{
        boxShadow : "none", overflow : "auto",
        "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
        "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
        ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
      }}
    >
      <Table>
        <MDBox component={"thead"}>
          <TableRow>
            {columns.map(( column : any, key : number ) => (
              <DataTableHeadCell
                key={key}
                width={"auto"}
                align={"left"}
                onClick={onClickHeader}
                accessor={column.accessor}
                direction={direction}
                sort={sort}
                sortable={column.sortable}
              >
                {column.Header}
              </DataTableHeadCell>
            ))}
          </TableRow>
        </MDBox>
        <TableBody>
          {data.map(( row : any, key : number ) => (
            <TableRow key={row.deviceId}>

              {columns.map(( column : any, key : number) => (
                <DataTableBodyCell
                  key={key}
                  align={ "left" }
                  goToDetail={goToDetail}
                  row={row}
                >
                  { column.accessor === "executionStatus" ? renderStatusIcon(row["executionStatus"], row["usable"]) :
                    column.accessor === "deviceBuildType" ? renderBuildType(row["deviceBuildType"], row["reservationStatus"]) : row[column.accessor]
                  }
                </DataTableBodyCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page} buttonDisabled={true}
                 setEntriesPerPage={setEntriesPerPage} onClickSave={onClickSave} onClickDelete={onClickDelete} onChangePage={setPage}/>
    </Card>
  </TableLayout>
  );
}

export default DeviceSummaryView;