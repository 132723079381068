import {useTranslation} from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "../../../../components/MDTypography";

// Declaring props types for PageLayout
interface Props {
  entriesStart: number,
  entriesEnd: number,
  total: number,
}

function SearchLayout({entriesStart, entriesEnd, total}: Props): JSX.Element {

  const {t} = useTranslation()

  return (
    <MDBox display={"flex"} justifyContent={"space-between"} pt={1} pb={1}>
      <MDBox display={"inline-block"} mb={{ xs: 3, sm: 0 }}>
        <MDTypography variant="button" color="secondary" fontWeight="regular">
          {
            t("tfoot.pagingInfo",
              {
                "total": total,
                "entriesStart": entriesStart,
                "entriesEnd": entriesEnd
              })
          }
          {/*Showing {entriesStart} to {entriesEnd} of {total} entries*/}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default SearchLayout;
