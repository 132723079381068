import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {DefaultResponse} from "common/Types";
import {getDetailByCode, LANGUAGE} from "common/Constant";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import ReservationService from "service/ReservationService";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import MDSnackbar from "components/MDSnackbar";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import UserRemoteAccessModal from "../UserRemoteAccessModal";

function UserRemoteAccessListView(): JSX.Element {

    const {t} = useTranslation();
    const [msg, setMsg] = useState<string>("");
    const [isOpenMsg, setIsOpenMsg] = useState(false);
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const location = useLocation()
    const columns = [
        {Header: t("remoteAccess.thead.nodeName"), accessor: "nodeName", sortable: true},
        {Header: t("remoteAccess.thead.nodeIp"), accessor: "nodeIp", sortable: false},
        {Header: t("remoteAccess.thead.deviceName"), accessor: "deviceName", sortable: true},
        {Header: t("remoteAccess.thead.type"), accessor: "type", sortable: false},
        {Header: t("remoteAccess.thead.serialNo"), accessor: "serialNo", sortable: false},
        {Header: t("remoteAccess.thead.dutIp"), accessor: "dutIp", sortable: false},
    ]

    const [remoteModalOpen, setRemoteModalOpen] = useState(false)
    const [propId, setPropId] = useState(undefined);
    const [deviceName, setDeviceName] = useState(undefined)
    const [nodeName, setNodeName] = useState(undefined)
    const [nodeId, setNodeId] = useState(undefined)

    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("")
    const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")
    const entries = ["10", "25", "50", "100"];
    const [entriesPerPage, setEntriesPerPage] = useState( 10 );
    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1 ;
    const totalPageLength = Math.ceil(data.length / entriesPerPage);
    let entriesEnd;
    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength ) {
        entriesEnd = data.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }

    const {refetch: getRemoteAccessDevice} = useQuery<DefaultResponse, AxiosError>(
        ["getRemoteAccessDevice"],
        () => ReservationService
            .getRemoteAccessDevice(page,
                entriesPerPage,
                sort,
                direction),
        {
            onSuccess : (res: DefaultResponse) => {
                if (res.code === 200 && res.subCode === 0) {
                    setData(res.data.content)
                    setTotal(res.data.total)
                }
            }
        }
    )


    useEffect(() => {
        setPage(1)
        getRemoteAccessDevice()
    }, [entriesPerPage])
    useEffect(() => {
        getRemoteAccessDevice()
    }, [page])

    const onClickHeader = (_accessor:string) => {
        if(sort === _accessor){
            if(direction && direction === "DESC"){
                setDirection("ASC")
            } else {
                setDirection("DESC")
            }
        }else{
            setDirection("DESC")
            setSort(_accessor)
        }
    }
    useEffect( () => {
        if(sort && sort !== "" && direction && (direction === "DESC" || direction === "ASC")){
            getRemoteAccessDevice()
        }
    },[sort, direction])


    const toggleSnackbar = () => {
        setIsOpenMsg(false)
        setMsg("")
    }

    function handleClose() {
        // setRemoteModalOpen(false)
        // console.log(12)
    }

    const handleRemoteClick = (deviceId: number, nodeId:number, deviceName: string, nodeName: string)=>{
        setPropId(deviceId)
        setDeviceName(deviceName)
        setNodeName(nodeName)
        setNodeId(nodeId)
        setRemoteModalOpen(true)
    }

    const activeEnter = (ev:KeyboardEvent) => {
        if(ev.key === "Enter") {
            setPage(1)
            getRemoteAccessDevice();
        }
    }

    const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(80)

    return (
        <TableLayout pageTitle={location.state?.menu_name}>

            <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < data.length ? entriesEnd : total} total={total} />
            <Card>
                <TableContainer
                    sx={{
                        boxShadow : "none", overflow : "auto",
                        "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                        "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                        ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                    }}
                >
                    <Table>
                        <MDBox component={"thead"}>
                            <TableRow>
                                {columns.map(( column : any, key : number ) => (
                                    <DataTableHeadCell
                                        key={key}
                                        width={"auto"}
                                        align={"left"}
                                        onClick={onClickHeader}
                                        accessor={column.accessor}
                                        sort={sort}
                                        direction={direction}
                                        sortable={column.sortable}
                                    >
                                        {column.Header}
                                    </DataTableHeadCell>
                                ))}
                            </TableRow>
                        </MDBox>
                        <TableBody>
                            {data.map((row :any, key: number) => (
                                <TableRow key={row.deviceId}>
                                    {columns.map(( column : any, key : number) => (
                                        <DataTableBodyCell
                                            key={key}
                                            align={"left"}
                                            goToDetail={null}
                                        >
                                            {column.accessor === "type"?
                                                t(getDetailByCode("DEVICE_TYPE", row["type"])):row[column.accessor]
                                            }
                                        </DataTableBodyCell>
                                    ))}
                                    <DataTableBodyCell row={row}>
                                        <MDButton variant={"outlined"} size="small" color={"info"} onClick={()=>{handleRemoteClick(row.deviceId, row.nodeId, row.deviceName, row.nodeName)}} sx={{minWidth : buttonMinWidth}}>
                                            {t("button.hostConsole")}
                                        </MDButton>
                                    </DataTableBodyCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page} buttonDisabled={true}
                             setEntriesPerPage={setEntriesPerPage} onClickSave={() => {}} onClickDelete={() => {}} onChangePage={setPage}/>
            </Card>
            <MDSnackbar
                anchorOrigin={{horizontal: "center", vertical: "top"}}
                color="dark"
                bgWhite={true}
                icon="notifications"
                title="Ted Manager"
                content={msg}
                dateTime=""
                open={isOpenMsg}
                close={toggleSnackbar}
            />


            <Modal
                open={remoteModalOpen} onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 200,
                        sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
                    },
                }}
                sx={{display:"flex"}}
            >
                <Fade in={remoteModalOpen}>
                    <MDBox
                        display="flex"
                        width="95vw" minHeight="85vh" shadow="xl"
                        position="fixed" top="50%" left="50%"
                        sx={{transform: "translate(-50%, -50%)"}}
                    >
                        <UserRemoteAccessModal deviceId={propId} deviceName={deviceName} handleModal={setRemoteModalOpen}/>
                    </MDBox>
                </Fade>
            </Modal>
        </TableLayout>
    );
}

export default UserRemoteAccessListView;