import {ReactNode} from "react";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  icon: ReactNode;
  title: string;
  value?: string | number;
  [key: string]: any;
}

function DeviceInfoCard({ color, icon, title, value }: Props) {
  return(
    <MDBox display={"grid"} width={"20%"}>
      <Card>
        <MDBox p={2} display="grid" justifyContent={"left"} alignItems={"center"} height={"100%"}>
          <MDBox display={"inline-grid"} gridRow={ window.innerWidth > 955 ? "1/2" : 0 } my={"auto"}>
            <MDBox
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor={color}
              color="white"
              width="4.5rem"
              height="4.5rem"
              shadow="md"
              borderRadius="lg"
              variant="contained"
            >
              <Icon fontSize="large">{icon}</Icon>
            </MDBox>
          </MDBox>
          <MDBox display={"inline-grid"} gridRow={ window.innerWidth > 955 ? "1/2" : 0 } flexDirection={"column"} px={2} textAlign="left" height={"100%"}>
            <MDBox display={"inline-grid"} gridRow={"1/7"} alignItems={"center"} pb="0">
              <MDTypography variant={"button"} fontWeight="medium" textTransform="capitalize">
                {title}
              </MDTypography>
            </MDBox>
            <MDBox display={"inline-grid"}>
            {value && (
              <MDTypography variant={"h3"} fontWeight="medium">
                {value}
              </MDTypography>
            )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
}

DeviceInfoCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
}

export default DeviceInfoCard