import {apiClient} from "service/ApiClient";
import {DefaultResponse} from "common/Types";

const getReservationList = async function (page: number,
                                           entriesPerPage: number,
                                           sort :string,
                                           direction:"none"|"ASC"|"DESC",
                                           deviceName:string,
                                           dstate:string,
                                           rstate:string,
                                           isMime:boolean) {
  return await apiClient.get<DefaultResponse>(`/reservation?page=${page}&size=${entriesPerPage}&sort=${sort}${(direction === "ASC" || direction === "DESC") ? ","+direction:""}&isMime=${isMime}${deviceName&&deviceName!=""?"&deviceName="+deviceName:""}${dstate&&dstate!=""?"&dstate="+dstate:""}${rstate&&rstate!=""?"&rstate="+rstate:""}`)
                        .then((res:any) => res.data)
}

const getReservationListByUserId = async function ( userId:string, status: string, type: string) {
  return await apiClient.get<DefaultResponse>(`/reservation/user/${userId}?sort=deviceName,ASC&status=${status}&type=${type}`)
    .then((res:any) => res.data)
}

const insertReservation = async function (deviceId: number) {
  return await apiClient.post<DefaultResponse>(`/reservation/${deviceId}`)
                        .then((res:any) => res.data)
}

const returnDevice = async function (reservationId: number) {
  return await apiClient.delete<DefaultResponse>(`/reservation/return/${reservationId}`)
                        .then((res:any) => res.data)
}

const cancelReservation = async function (reservationId: number) {
  return await apiClient.delete<DefaultResponse>(`/reservation/cancel/${reservationId}`)
                        .then((res:any) => res.data)
}

const getRemoteAccessDevice = async function (page: number,
                                           entriesPerPage: number,
                                           sort :string,
                                           direction:"none"|"ASC"|"DESC"){
  return await apiClient.get<DefaultResponse>(`/reservation/remoteAccess?page=${page}&size=${entriesPerPage}&sort=${sort}${(direction === "ASC" || direction === "DESC") ? ","+direction:""}`).then((res:any) => res.data)
}

const ReservationService = {
  getReservationList,
  insertReservation,
  returnDevice,
  cancelReservation,
  getReservationListByUserId,
  getRemoteAccessDevice
}

export default ReservationService;