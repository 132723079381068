/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {ReactNode, useMemo} from "react";

// react-chartjs-2 components
import {Line} from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
// BubbleChart configurations
// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme/base/colors";
import typography from "../../../assets/theme/base/typography";
import Grid from "@mui/material/Grid";

// Declaring props types for BubbleChart
interface Props {
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  width?: string | number;
  chart: {
    labels: string[];
    datasets: {
      type?: string;
      label: string;
      color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
      data: {
        x: number;
        y: number;
        r: number;
      }[];
    }[];
  };
  [key: string]: any;
  pointClick?: (elements: any, event: any) => void
}



function MixedChart({title, description, height, width, chart, pointClick }: Props): JSX.Element {
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset, index) => ({
        ...dataset,
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 2,
        backgroundColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        borderColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        maxBarThickness: 6,
      }))
    : [];


  const configs = (labels: any, datasets: any) => {
    return {
      data: {
        labels,
        datasets: [...datasets],
      },
      options: {
        onClick: pointClick,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            filter: ({ datasetIndex, dataIndex }: any) => {
              // Only show tooltips for datasets with type 'bubble'
              return datasets[datasetIndex].type === 'bubble';
            },
            callbacks: {
              title: () => {
                return "";
              },
              label: ({ datasetIndex, dataIndex, dataset }: any) => {
                const data = dataset.data[dataIndex];
                return `start line: ${data.x}, value: ${data.y}, status: ${data.r === 2?"Normal":"Warning"}`;
              },
            },
          },
        },
        scales: {
          y: {
            max:1.2,
            beginAtZero: true,
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              //stepSize: 0.5,
              display: true,
              padding: 10,
              color: "#b2b9bf",
              font: {
                size: 11,
                family: typography.fontFamily,
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              stepSize: 100,
              display: true,
              color: "#b2b9bf",
              padding: 10,
              font: {
                size: 11,
                family: typography.fontFamily,
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    };
  }

  const { data, options } = configs(chart.labels || [], chartDatasets);

  const renderChart = (
    <>
      {useMemo(
        () => (
          <Grid sx={{ height, width, textAlign:"center", margin:"10px"}}>
            <Line data={data} options={options} />
          </Grid>
        ),
        [chart, height, width]
      )}
    </>
  );

  return title || description ? <Card sx={{justifyContent:"center", alignItems: "center"}}>{renderChart}</Card> : renderChart;
}

// Declaring default props for BubbleChart
MixedChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
  width: "60rem",
};

export default MixedChart;
