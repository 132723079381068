import {apiClient} from "service/ApiClient";
import {DefaultResponse, Notice} from "../common/Types";
import {NoticeConstructor} from "../common/Constructors";


const getNoticeList = async function (page:number,
                                      size:number,
                                      sort:string,
                                      direction:"none" | "ASC" | "DESC",
                                      fromDate: string,
                                      toDate: string,
                                      title: string,
                                      writer: string) {
  return await apiClient.get<DefaultResponse>(`/notice?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&title=${title}&writer=${writer}`)
    .then((res:any) => res.data)
}

const deleteNotice = async function (noticeList: Notice[]) {
  return await apiClient.patch<DefaultResponse>("/notice/delete", noticeList).then((res:any) => res.data)
}

const updateNotice = async function (notice:Notice){
  return await apiClient.patch<DefaultResponse>("/notice", notice).then((res:any) => res.data)
}

const createNotice = async function (notice:NoticeConstructor){
  return await apiClient.post<DefaultResponse>("/notice", notice).then((res:any) => res.data)
}

const getNotice = async function (noticeId: any){
  return await apiClient.get<DefaultResponse>(`/notice/${noticeId}`).then((res:any) => res.data)
}

const NoticeService = {
  getNoticeList,
  deleteNotice,
  updateNotice,
  createNotice,
  getNotice
}

export default NoticeService;