/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import MDButton from "components/MDButton";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import MDDatePicker from "components/MDDatePicker";
import {Refresh} from "@mui/icons-material";
import MDInput from "components/MDInput";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import {useLocation, useNavigate} from "react-router-dom";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {DefaultResponse, Node} from "common/Types";
import DateUtil from "common/DateUtil";
import {BRAND_NAME, getDetailByCode} from "common/Constant";
import MDSnackbar from "components/MDSnackbar";
import {useTranslation} from "react-i18next";
import NodeService from "../../../../service/NodeService";
import MDBadge from "../../../../components/MDBadge";
import ConfirmationDialog from "../../../component/ConfirmationDialog";
import Icon from "@mui/material/Icon";

function NodeListView(): JSX.Element {

    const {t} = useTranslation();

    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const columns = [
        { Header: t("node.thead.nodeName"), accessor: "name", width: "15%", sortable: false},
        { Header: t("node.thead.nodeType"), accessor: "type", width: "15%", sortable: false},
        { Header: t("node.thead.farmName"), accessor: "farm", width: "15%", sortable: false },
        { Header: t("node.thead.ip"), accessor: "ip", width: "15%", sortable: false },
        { Header: t("node.thead.status"), accessor: "availability", width: "10%", sortable: false},
        { Header: t("node.thead.DUTCnt"), accessor: "dutCnt", width: "10%", sortable: false },
        { Header: t("node.thead.regDate"), accessor: "regDt", width: "10%", sortable: false },

    ];

    const [deleteNodeList, setDeleteNodeList] = useState<Node[]>([])


    /*snackbar start*/
    const [errMsg, setErrMsg] = useState<string>('');
    const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openErrMsg, setOpenErrMsg] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState<string>('');
    const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
    const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
    const toggleSnackbar = () => {
        setOpenErrMsg(!openErrMsg);
    }
    const toggleDialog = () => {
        setOpenConfirmMsg(!openConfirmMsg);
    }
    const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setErrMsg(msg)
        setErrColor(color)
        setOpenErrMsg(true)
    }
    const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
        setConfirmMsg(msg)
        setConfirmColor(color)
        setOpenConfirmMsg(true)
    }
    /*snackbar end*/

    /*paging start*/
    const entries = ["10", "25", "50", "100"];
    const [entriesPerPage, setEntriesPerPage] = useState( 10 )
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [sort, setSort] = useState("")
    const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")

    // Setting the entries starting point
    const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;

    // Setting the entries ending point
    let entriesEnd;
    const totalPageLength = Math.ceil(list.length / entriesPerPage);

    if (page === 1) {
        entriesEnd = entriesPerPage;
    } else if (page === totalPageLength ) {
        entriesEnd = list.length;
    } else {
        entriesEnd = entriesPerPage * page;
    }
    /*paging end*/

    /*searching start*/
    // Search input value state
    const [name, setName] = useState("")

    // 등록일 선택 검색
    const [ fromDate, setFromDate ] = useState<Date>()
    const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())

    // 등록일 handle function
    const handleFromDateChange = ( _fromDate: Date) => {
        setFromDate(_fromDate)
    }

    const handleToDateChange = ( _toDate: Date) => {
        setToDate(_toDate)
    }

    // 날짜 설정 부분
    useEffect(()=> {
        if(fromDate && toDate && fromDate > toDate){
            openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
            setFromDate(undefined)
            setToDate(undefined)
        }
    },[fromDate, toDate])

    const goToDetail = (row: any | undefined, type: string) => {
        const url = type === 'Create'? `/device/node`: `/device/node/${row.id}`;
        navigate({pathname: url}, {state: {menu_name: "Node", node: row, type: type}})
    }

    const {refetch: getNodeList} = useQuery<any, AxiosError>(
        ['getNodeList'],
        () => NodeService.getNodeList(page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), name),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    setList(res.data.content)
                    setTotal(res.data.total)
                }
            }
        }
    )

    const {refetch: deleteNode} = useQuery<any, AxiosError>(
        ['deleteNode'],
        () => NodeService.deleteNode(deleteNodeList),
        {
            onSuccess: (res: DefaultResponse ) => {
                if(res.code === 200 && res.subCode === 0){
                    navigate(0)
                }
            }
        }
    )

    // 체크박스 체크리스트 선택
    const [ checkList, setCheckList ] = useState(new Set<any>([]));

    // 체크박스 단일 선택
    const handleSingleCheck = ((row: any) => {
        if(row.dutCnt != 0){
            openAlert(t("ERRORMESSAGE.DELETE.NODE"), "warning")
        }else{
            if ( checkList.has( row.id ) ) {
                checkList.delete( row.id )
                setCheckList( new Set<any>( [ ...checkList ] ) );
            } else {
                setCheckList( new Set<any>([ ...checkList.add( row.id ) ] ) );
            }
        }
    });

    // 체크박스 전체 선택
    const handleAllCheck =  (( checked: boolean ) => {
        if( checked ) {
            // 전체 선택 클릭 시 모든 로우의 키들 담음
            const set = new Set<any>([]);
            for (let i = 0; i < Math.min(entriesPerPage, list.length); i++ ) {
                if(!list[i].dutCnt) set.add(list[i].id);
            }
            if(set.size<list.length) openAlert(t("ERRORMESSAGE.DELETE.NODE"), "warning")
            setCheckList( set );
        } else {
            setCheckList ( new Set<any>([]) );
        }
    });

    const onClickSave = () => {
        goToDetail({},'Create')
    }

    const onClickDelete = () => {
        if(checkList.size === 0){
            openAlert(t("ERRORMESSAGE.CONFIRM.DELETE.NODE"), "warning")
        } else{
            openConfirm(t("confirm.delete"), "warning")
        }
    }

    const handleYes = ()=>{
        const _deleteNodeList:Node[] = []
        checkList.forEach(value => {
            _deleteNodeList.push({
                id: value
            })
        })
        setDeleteNodeList(_deleteNodeList)
    }

    const handleNo = () => {
        toggleDialog()
    }


    // 페이지 변경시 전체 체크 해제
    useEffect( () => {
        setCheckList( new Set<any>([]) );
    }, [ page ] );

    useEffect( () => {
        getNodeList()
    }, [ ] );

    useEffect(() => {
        getNodeList()
    }, [ page ]);

    useEffect(() => {
        setPage(1)
        getNodeList()
    }, [ entriesPerPage]);

    useEffect( () => {
        if(deleteNodeList && deleteNodeList.length > 0)
            deleteNode()
    }, [deleteNodeList]);

    const onClickHeader = (_accessor:string) => {
        if(sort === _accessor){
            if(direction && direction === "DESC"){
                setDirection("ASC")
            }else{
                setDirection("DESC")
            }
        }else{
            setDirection("DESC")
            setSort(_accessor)
        }
    }

    const activeEnter = (ev:KeyboardEvent) => {
        if(ev.key === "Enter") {
            getNodeList();
        }
    }


    useEffect( () => {
        if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
            getNodeList()
        }
    }, [sort, direction]);

    function renderStatusIcon(status: string) {
        let content
        let color : "warning" | "success" | "error" | "primary" | "secondary" | "info" | "light" | "dark"
        if (status == "A") {
            content = t(getDetailByCode("AVAILABILITY", status))
            color = "success"
        } else if (status == "U")  {
            content = t(getDetailByCode("AVAILABILITY", status))
            color = "info"
        } else {
            return null
        }
        return <MDBadge container badgeContent={content} size="sm" color={color} variant={"contained"} minWidth={110}/>
    }

    return (
        /* list, detail 화면에서 공용으로 사용하는 최상위 layout 컴포넌트 */
        <TableLayout pageTitle={location.state?.menu_name}>
            <SearchLayout>
                <MDBox  display={"flex"} justifyContent={"space-between"}>
                    <MDBox display={"flex"} width="90%" alignItems="center">
                        <MDBox display={"flex"} alignItems="center">
                            <MDDatePicker
                                value={ fromDate }
                                input = {
                                    {
                                        size : "small",
                                        height: "37px",
                                        placeholder : t("search.fromDate"),
                                    }
                                }
                                options = {
                                    {
                                        maxDate : "today",
                                        // locale : Korean,
                                    }
                                }
                                onChange = { ( currentTarget: any ) => {
                                    const target = currentTarget[0];
                                    handleFromDateChange( target );
                                }}
                            />
                            <MDBox px={0.25}>~</MDBox>
                            <MDDatePicker
                                value={ toDate }
                                input = {
                                    {
                                        size : "small",
                                        height: "37px",
                                        placeholder : t("search.toDate"),
                                    }
                                }
                                options = {
                                    {
                                        maxDate : "today",
                                        // locale : Korean,
                                    }
                                }
                                onChange = { ( currentTarget: any ) => {
                                    const target = currentTarget[0];
                                    handleToDateChange( target );
                                }}
                            />
                            <MDBox px={0.25}/>
                            <MDButton iconOnly={true} onClick={ () => { setFromDate( undefined ); setToDate( undefined ); } }>
                                <Refresh/>
                            </MDButton>
                        </MDBox>

                        <MDBox alignItems="center" width="40%" pl={3}>
                            <MDInput
                                placeholder={t("search.name")}
                                value={name}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }: any) => {
                                    setName(currentTarget.value)
                                }}
                                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox display={"flex"}>
                        <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=> getNodeList()}>
                            {t("search.button.search")}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </SearchLayout>
            <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />
            {/* 테이블 전체 layout */}
            <TableContainer
                sx={{
                    boxShadow : "none", overflow : "auto",
                    "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
                    "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
                    ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
                }}
            >
                {/* 테이블 contents */}
                <Table>
                    <MDBox component={"thead"}>
                        <TableRow>
                            <DataTableHeadCell
                                width={ "1%" }
                                align={ "center" }
                                direction={ "none" }
                                sortable={false}
                            >
                                <Checkbox size={"small"}
                                          onChange={( e) => {
                                              handleAllCheck(e.target.checked);
                                          }}
                                          checked={ checkList.size === Math.min(entriesPerPage, list.length) && list.length > 0 } />

                            </DataTableHeadCell>
                            <DataTableHeadCell
                                width={"10%"}
                                align={"left"}
                                onClick={onClickHeader}
                                accessor={"no"}
                                direction={direction}
                                sort={sort}
                            >
                                {t("node.thead.no")}
                            </DataTableHeadCell>

                            {columns.map(( column : any, index : number ) => (
                                <DataTableHeadCell
                                    key={index}
                                    width={column.width}
                                    align={"left"}
                                    onClick={onClickHeader}
                                    accessor={column.accessor}
                                    direction={direction}
                                    sort={sort}
                                    sortable={column.sortable}
                                >
                                    {column.Header}
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    </MDBox>
                    <TableBody>
                        {list.map(( row : any, index : number ) => (
                            <TableRow key={index} >
                                <DataTableBodyCell
                                    align={ "center" }
                                >
                                    <Checkbox
                                        checked={ checkList.has( row.id ) }
                                        onChange={ (e) => {
                                            handleSingleCheck( row );
                                        }}
                                    />
                                </DataTableBodyCell>
                                <DataTableBodyCell
                                    key={index}
                                    align={ "left" }
                                    row={row}
                                >
                                    {entriesPerPage*(page-1)+index+1}

                                </DataTableBodyCell>
                                {columns.map(( column : any, index) => (
                                    <DataTableBodyCell
                                        key={index}
                                        align={ "left" }
                                        goToDetail={goToDetail}
                                        row={row}
                                    >
                                        { column.accessor === "availability" ? renderStatusIcon(row["availability"]) : column.accessor === "type" ? t(getDetailByCode("NODE_TYPE", row[column.accessor])) : row[column.accessor]
                                        }

                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {/* 테이블 footer */}
                {
                        <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page}
                                      setEntriesPerPage={setEntriesPerPage} onClickSave={onClickSave} onClickDelete={onClickDelete} onChangePage={setPage}/>

                }
            </TableContainer>
            <MDSnackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                color={errColor}
                icon="notifications"
                title={BRAND_NAME}
                content={errMsg}
                dateTime=""
                open={openErrMsg}
                close={toggleSnackbar}
            />
            <ConfirmationDialog
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={openConfirmMsg}
                close={toggleDialog}
                content={confirmMsg}
                color={confirmColor}
                handleYes={handleYes}
                handleNo={handleNo}
            />
        </TableLayout>
    );
}

export default NodeListView;