import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import MDDatePicker from "components/MDDatePicker";
import {Refresh} from "@mui/icons-material";
import MDInput from "components/MDInput";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import TestService from "service/TestService";
import {DefaultResponse,} from "common/Types";
import DateUtil from "common/DateUtil";
import {BRAND_NAME, getDetailByCode, LANGUAGE, SSE, USER_ID} from "common/Constant";
import MDSnackbar from "components/MDSnackbar";
import Card from "@mui/material/Card";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import TestExecutionHistoryDetailsModal from "./details/TestExecutionHistoryDetailsModal";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sseForUserRecoilState} from "store/recoilState";
import StorageUtil from "common/StorageUtil";
import {useTranslation} from "react-i18next";
import MDTypography from "components/MDTypography";
import MDBadge from "../../../../components/MDBadge";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import Icon from "@mui/material/Icon";

interface Props {
  executionId: number
  closeModalBtn: () => void
}
function TestExecutionHistoryModal({executionId, closeModalBtn}: Props): JSX.Element {

  const {t} = useTranslation();

  const [list, setList] = useState([])

  const columns = [
    { Header: t("testHistory.thead.name"), accessor: "name", sortable: true},
    { Header: t("testHistory.thead.buildName"), accessor: "buildName", sortable: true},
    { Header: t("testHistory.thead.buildNo"), accessor: "buildNo", sortable: true},
    { Header: t("testHistory.thead.userName"), accessor: "userName", sortable: true },
    { Header: t("testHistory.thead.deviceName"), accessor: "deviceName", sortable: true },
    { Header: t("testHistory.thead.status"), accessor: "status", sortable: true },
    { Header: t("testHistory.thead.startDt"), accessor: "startDt", sortable: true },
    { Header: t("testHistory.thead.endDt"), accessor: "endDt", sortable: true },
  ];

  /*paging start*/
  const entries = ["10", "25", "50", "100"];
  const [entriesPerPage, setEntriesPerPage] = useState( 10 )
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [sort, setSort] = useState("")
  const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")

  const [errMsg, setErrMsg] = useState<string>('');
  const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
  const [openErrMsg, setOpenErrMsg] = useState(false);
  const toggleSnackbar = () => {
    setOpenErrMsg(!openErrMsg);
  }

  const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
    setErrMsg(msg)
    setErrColor(color)
    setOpenErrMsg(true)
  }

  // Setting the entries starting point
  const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;

  // Setting the entries ending point
  let entriesEnd;
  const totalPageLength = Math.ceil(list.length / entriesPerPage);

  if (page === 1) {
    entriesEnd = entriesPerPage;
  } else if (page === totalPageLength ) {
    entriesEnd = list.length;
  } else {
    entriesEnd = entriesPerPage * page;
  }
  /*paging end*/

  /*searching start*/
  // Search input value state
  const [name, setName] = useState("")
  const [buildName, setBuildName] = useState("")
  const [buildNo, setBuildNo] = useState("")
  const [userName, setUserName] = useState("")
  const [deviceName, setDeviceName] = useState("")

  // 등록일 선택 검색
  const [ fromDate, setFromDate ] = useState<Date>(null)
  const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())

  // 등록일 handle function
  const handleFromDateChange = ( _fromDate: Date) => {
    setFromDate(_fromDate)
  }

  const handleToDateChange = ( _toDate: Date) => {
    setToDate(_toDate)
  }

  useEffect(()=> {
    if(fromDate && toDate && fromDate > toDate){
      openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
      setFromDate(undefined)
      setToDate(undefined)
    }
  },[fromDate, toDate])
  /*searching end*/

  const {refetch: getExecutionHistoryListById} = useQuery<DefaultResponse, AxiosError>(
    ["getExecutionHistoryListById"],
    () => TestService.getExecutionHistoryListByExecutionId(executionId, page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), name, buildName, buildNo, userName, deviceName),
    {
      onSuccess: (res: DefaultResponse) => {
        if(res.code === 200 && res.subCode === 0) {
          setList(res.data.content)
          setTotal(res.data.total)
        }
      }
    }
  )

  /* SSE 설정 */
  const setSseForUser = useSetRecoilState(sseForUserRecoilState);
  const sseForUser = useRecoilValue(sseForUserRecoilState);

  const handleConnectedEvent = (e: any) => {
    const { data: receivedData } = e;
    console.log(receivedData);
  };

  const handleTestExecutionHistoryEvent = (e: any) => {
    const { data: receivedData } = e;
    console.log(receivedData);
    getExecutionHistoryListById();
  };

  useEffect(()=>{
    const baseURI = process.env.REACT_APP_BACKEND_URI+process.env.REACT_APP_API_VERSION
    if(!sseForUser.sse){
      setSseForUser({sse: new EventSource(baseURI + "/sse/connect/" + StorageUtil.getLocalStorage(USER_ID))})
    }else{
      sseForUser.sse.addEventListener("Connected", handleConnectedEvent)
      sseForUser.sse.addEventListener(SSE.TEST_EXECUTION_HIST, handleTestExecutionHistoryEvent)
    }
  }, [sseForUser.sse])

  useEffect(()=>{
    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      if (sseForUser.sse) {
        sseForUser.sse.removeEventListener('Connected', handleConnectedEvent);
        sseForUser.sse.removeEventListener(SSE.TEST_EXECUTION_HIST, handleTestExecutionHistoryEvent);
      }
    };
  },[]);

  /* SSE 설정 끝 */

  useEffect(() => {
    getExecutionHistoryListById()
  }, [page]);

  useEffect(() => {
    setPage(1)
    getExecutionHistoryListById()
  }, [entriesPerPage]);

  const onClickHeader = (_accessor:string) => {
    if(sort === _accessor){
      if(direction && direction === "DESC"){
        setDirection("ASC")
      }else{
        setDirection("DESC")
      }
    }else{
      setDirection("DESC")
      setSort(_accessor)
    }
  }

  const activeEnter = (ev:KeyboardEvent) => {
    if(ev.key === "Enter") {
      getExecutionHistoryListById();
    }
  }

  useEffect( () => {
    if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
      getExecutionHistoryListById()
    }
  }, [sort, direction]);

  function renderTestExecutionStatus(status: string): JSX.Element {
    let color: "success" | "primary" | "secondary" | "warning" | "error"
    const content = t(getDetailByCode("TEST_EXECUTION_STATUS", status))
    if (status === "P") {
      color = "success"
    } else if (status === "F") {
      color = "primary"
    } else if (status === "E") {
      color = "secondary"
    } else if (status === "X") {
      color = "error"
    } else {
      color = "warning"
    }

    return (
      <MDBadge badgeContent={content}
               container color={color} size="sm" variant="contained"/>
    )
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [historyId, setHistoryId] = useState(undefined)
  const [historyStatus, setHistoryStatus] = useState(undefined)

  function onHandleClick(historyId: number, historyStatus: string) {
    setHistoryId(historyId)
    setHistoryStatus(historyStatus)
    setModalOpen(true)
  }

  function onHandleClickLog(row: any){
    const popupWidth = 1400;
    const popupHeight = 800;
    const popupX = (window.screen.width / 2) - (popupWidth / 2);
    const popupY= (window.screen.height / 2) - (popupHeight / 2);
    let hostname = window.location.hostname
    if(hostname !== '192.168.0.11'){
      hostname = process.env.REACT_APP_JENKINS
    }
    window.open(`http://${hostname}:8100/job/${row.buildName}/${row.buildNo}/console`, 'JENKINS_CONSOLE', 'status=no, height=' + popupHeight  + ', width=' + popupWidth  + ', left='+ popupX + ', top='+ popupY);
  }

  function handleClose() {
    setModalOpen(false)
    setTimeout(() => {
      setHistoryId(undefined)
      setHistoryStatus(undefined)
    }, 150)
  }

  const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(71.45) : pxToRem(85)

  return (

    <Card sx={{width : "100%", px : 4, py : 2,}}>
      <MDBox gap={2}
       sx={{
        overflow : "auto",
        "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
        "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
        ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
      }}>

        <MDBox display={"flex"} justifyContent={"space-between"} pb={1}>
          <MDTypography variant={"h5"}>
            Test Execution History List
          </MDTypography>
          <MDBox style={{cursor: "pointer"}} onClick={closeModalBtn} display="flex">
            <Icon fontSize={"medium"} sx={{ fontWeight: "bold" }}>clear</Icon>
          </MDBox>
        </MDBox>

        {/* 검색 영역 layout */}
        <SearchLayout>
          <MDBox  display={"flex"} justifyContent={"space-between"}>
            <MDBox display={"flex"} width="90%" alignItems="center">
              <MDBox display={"flex"} alignItems="center" >
                <MDDatePicker
                  value={ fromDate }
                  input = {
                    {
                      size : "small",
                      height: "37px",
                      placeholder : t("search.fromDate"),
                    }
                  }
                  options = {
                    {
                      maxDate : "today",
                      // locale : Korean,
                    }
                  }
                  onChange = { ( currentTarget: any ) => {
                    const target = currentTarget[0];
                    handleFromDateChange( target );
                  }}
                />
                <MDBox px={0.25}>~</MDBox>
                <MDDatePicker
                  value={ toDate }
                  input = {
                    {
                      size : "small",
                      height: "37px",
                      placeholder : t("search.toDate"),
                    }
                  }
                  options = {
                    {
                      maxDate : "today",
                      // locale : Korean,
                    }
                  }
                  onChange = { ( currentTarget: any ) => {
                    const target = currentTarget[0];
                    handleToDateChange( target );
                  }}
                />
                <MDBox px={0.25}/>
                <MDButton iconOnly={true} onClick={ () => { setFromDate( undefined ); setToDate( undefined ); } }>
                  <Refresh/>
                </MDButton>
              </MDBox>
              <MDBox alignItems="center" width="15%" pl={3}>
                <MDInput
                  placeholder={t("search.name")}
                  value={name}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }: any) => {
                    setName(currentTarget.value)
                  }}
                  onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                />
              </MDBox>
              <MDBox alignItems="center" width="15%" pl={3}>
                <MDInput
                  placeholder={t("search.buildName")}
                  value={buildName}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }: any) => {
                    setBuildName(currentTarget.value)
                  }}
                  onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                />
              </MDBox>
              <MDBox alignItems="center" width="15%" minWidth={"100px"} pl={3}>
                <MDInput
                  placeholder={t("search.buildNo")}
                  value={buildNo}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }: any) => {
                    setBuildNo(currentTarget.value)
                  }}
                  onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                />
              </MDBox>
              <MDBox alignItems="center" width="15%" minWidth={"100px"} pl={3}>
                <MDInput
                  placeholder={t("search.userName")}
                  value={userName}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }: any) => {
                    setUserName(currentTarget.value)
                  }}
                  onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                />
              </MDBox>
              <MDBox alignItems="center" width="15%" minWidth={"100px"} pl={3}>
                <MDInput
                  placeholder={t("search.deviceName")}
                  value={deviceName}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }: any) => {
                    setDeviceName(currentTarget.value)
                  }}
                  onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
                />
              </MDBox>
            </MDBox>
            <MDBox display={"flex"}>
              <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getExecutionHistoryListById()}>
                {t("search.button.search")}
              </MDButton>
            </MDBox>
          </MDBox>
        </SearchLayout>

        {/* 테이블 헤더 */}
        <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />

        {/* 테이블 전체 layout */}
        <Card>
        <TableContainer
          sx={{
            boxShadow : "none", overflow : "auto",
            "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
            "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
            ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
          }}
        >
          {/* 테이블 contents */}
          <Table>
            <MDBox component={"thead"}>
              <TableRow>

                {columns.map(( column : any, index : number ) => (
                  <DataTableHeadCell
                    key={index}
                    width={column.width}
                    align={"left"}
                    onClick={onClickHeader}
                    accessor={column.accessor}
                    direction={direction}
                    sort={sort}
                    sortable={column.sortable}
                  >
                    {column.Header}
                  </DataTableHeadCell>
                ))}
                <DataTableHeadCell align={"left"}>
                  {}
                </DataTableHeadCell>
              </TableRow>
            </MDBox>
            <TableBody>
              {list.map(( row : any, index : number ) => (
                <TableRow key={index} >

                  {columns.map(( column : any, index) => (
                    <DataTableBodyCell
                      key={index}
                      align={ "left" }
                      row={row}
                    >
                      {
                        column.accessor === "workType" ? getDetailByCode("WORK", row["workType"]):
                        column.accessor === "platformType" ? getDetailByCode("PLATFORM", row["platformType"]):
                        column.accessor === "status" ? renderTestExecutionStatus(row[column.accessor]):
                        row[ column.accessor ]
                      }
                    </DataTableBodyCell>
                  ))}
                  <DataTableBodyCell row={row}>
                    <MDBox display={"flex"}>
                      <MDButton variant={"outlined"} size="small" color={"dark"} onClick={() => {onHandleClick(row.id, row.status)}} sx={{minWidth : buttonMinWidth}}>
                        {t("button.result")}
                      </MDButton>
                      <MDBox display={"inline-block"} p={0.5}/>
                      <MDButton variant={"outlined"} size="small" color={"success"} onClick={() => {onHandleClickLog(row)}} sx={{minWidth : buttonMinWidth}}>
                        {t("button.log")}
                      </MDButton>
                    </MDBox>
                  </DataTableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* 테이블 footer */}
        <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page} buttonDisabled
                     setEntriesPerPage={setEntriesPerPage} onClickSave={() => {}} onClickDelete={() => {}} onChangePage={setPage}/>
        </Card>

        <MDSnackbar
          anchorOrigin={{horizontal: 'center', vertical: 'top'}}
          color={errColor}
          icon="notifications"
          title={BRAND_NAME}
          content={errMsg}
          dateTime=""
          open={openErrMsg}
          close={toggleSnackbar}
        />

        <Modal
          open={modalOpen} onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 200,
              sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
            },
          }}
        >
          <Fade in={modalOpen}>
            <MDBox
                display="flex"
                width="95vw" minHeight="85vh" maxHeight="80vh" shadow="xl"
                position="fixed" top="50%" left="50%"
                sx={{transform: "translate(-50%, -50%)"}}
            >
              <TestExecutionHistoryDetailsModal historyId={historyId} historyStatus={historyStatus} closeModalBtn={handleClose}/>
            </MDBox>
          </Fade>
        </Modal>

      </MDBox>
    </Card>
  )
}

export default TestExecutionHistoryModal