import {apiClient} from "service/ApiClient";
import {DefaultResponse, Farm} from "../common/Types";
import {FarmConstructor} from "../common/Constructors";


const getFarmList = async function (page:number,
                                    size:number,
                                    sort:string,
                                    direction:"none" | "ASC" | "DESC",
                                    fromDate: string,
                                    toDate: string,
                                    name: string) {
    return await apiClient.get<DefaultResponse>(`/farm?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&name=${name}`)
        .then((res:any) => res.data)
}
const deleteFarm = async function (farmList: Farm[]) {
    return await apiClient.patch<DefaultResponse>("/farm/delete", farmList).then((res:any) => res.data)
}

const getValidFarm = async function (){
    return await apiClient.get<DefaultResponse>("farm/valid").then((res:any) => res.data)
}

const saveFarm = async function (farm:FarmConstructor){
    return await apiClient.post<DefaultResponse>("farm", farm).then((res:any)=>res.data)
}

const FarmService = {
    getFarmList,
    deleteFarm,
    getValidFarm,
    saveFarm
}

export default FarmService;