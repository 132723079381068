import {apiClient} from "service/ApiClient";
import {DefaultResponse, TestExecution, TestSuite} from "common/Types";
import {MLPerfExecutionSaveConstructor, TestExecutionConstructor} from "../common/Constructors";

const getMlPerfExecutionList = async function (page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, domainType: string, testExecutionName: string, category: string, userName: string, deviceName: string) {
  return await apiClient.get<DefaultResponse>
  (`/mlperf/execution/list?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}&fromDate=${fromDate}&toDate=${toDate}&domainType=${domainType}&testExecutionName=${testExecutionName}&category=${category}&userName=${userName}&deviceName=${deviceName}`)
    .then((res:any) => res.data)
}

const getMlPerfExecutionHistoryList = async function (page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, name: string, buildName: string, buildNo: string, userName: string, projectName: string, deviceName: string) {
  return await apiClient.get<DefaultResponse>
  (`/mlperf/execution/history?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}`
    +`&fromDate=${fromDate}&toDate=${toDate}&name=${name}&buildName=${buildName}&buildNo=${buildNo}&userName=${userName}&projectName=${projectName}&deviceName=${deviceName}`)
    .then((res:any) => res.data)
}

const getMlPerfExecutionHistoryListByExecutionId = async function (executionId: number, page:number, size:number, sort:string, direction:"none" | "ASC" | "DESC", fromDate: string, toDate: string, name: string, buildName: string, buildNo: string, userName: string, projectName: string, deviceName: string) {
  return await apiClient.get<DefaultResponse>
  (`/mlperf/execution/history/${executionId}?page=${page}&size=${size}&sort=${sort},${(direction === "ASC" || direction === "DESC") ?direction:""}`
    +`&fromDate=${fromDate}&toDate=${toDate}&name=${name}&buildName=${buildName}&buildNo=${buildNo}&userName=${userName}&projectName=${projectName}&deviceName=${deviceName}`)
    .then((res:any) => res.data)
}

// 테스트 결과 불러와
const getMlPerfExecutionHistoryReport = async function (historyId: number) {
  return await apiClient.get<DefaultResponse>(`/mlperf/execution/history/report/${historyId}`).then((res: any) => res.data)
}

const getMlPerfExecutionStats = async function (executionId: number, fromDate: string, toDate: string, buildName: string, ) {
  return await apiClient.get<DefaultResponse>(`/mlperf/execution/stats/${executionId}?&fromDate=${fromDate}&toDate=${toDate}&buildName=${buildName}`).then((res: any) => res.data)
}

const getMlPerfExecutionTestCaseList = async function () {
  return await apiClient.get<DefaultResponse>("/mlperf/test-case").then((res:any) => res.data)
}

const saveMLPerfExecution = async function (mlPerfExecution:MLPerfExecutionSaveConstructor){
  return await apiClient.post<DefaultResponse>("/mlperf/mlperf-execution", mlPerfExecution).then((res:any) => res.data)
}

const executeMlPerfTest = async function (req: TestExecutionConstructor) {
  return await apiClient.post<DefaultResponse>("/mlperf/execute", req).then((res:any) => res.data)
}

const MlPerfService = {
  getMlPerfExecutionList,
  getMlPerfExecutionHistoryList,
  getMlPerfExecutionHistoryReport,
  getMlPerfExecutionStats,
  getMlPerfExecutionHistoryListByExecutionId,
  getMlPerfExecutionTestCaseList,
  saveMLPerfExecution,
  executeMlPerfTest,
}

export default MlPerfService