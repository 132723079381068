/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, JSXElementConstructor, Key, ReactElement } from "react";

// react-router components
import {Routes, Route, Navigate, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import {BRAND_NAME, MESSAGE} from 'common/Constant'

// Material Dashboard 2 PRO React TS routes
import routes from "routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import AuthenticationView from "layouts/authentication/AuthenticationView"

import {AxiosError} from "axios";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
// Images
import brandWhite from "assets/images/favicon_ted.svg";
import brandDark from "assets/images/favicon_ted.svg";
import MDSnackbar from "components/MDSnackbar";

//Recoil
import { RecoilRoot } from 'recoil';
import StorageUtil from "common/StorageUtil";
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [errMsg, setErrMsg] = useState<string>('');
  const [openErrMsg, setOpenErrMsg] = useState(false);
  const navigate = useNavigate()
  const toggleSnackbar = () => {
    setOpenErrMsg(!openErrMsg);
  }

  const queryClient = new QueryClient(
    {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          cacheTime: 0, // 시간안에 같은 key로 요청오면 캐싱된 데이터 리턴 1000 -> 1초, default: 0초, 공지사항, FAQ: 1시간(1000 * 60 * 60) 으로 오버라이딩 해서 사용
          retry: false, // false: 에러시 재시도 없음. 1: 재시도 1회
          enabled: false, // true: mount시 함수 호출, false mount시 함수 호출 되지 않음
          onError: (response: any) => {

            setErrMsg(response?.data?.error?response.data.error: MESSAGE.ERROR)
            setOpenErrMsg(true)
            if(response?.status === 401 || response?.status === 403){
              StorageUtil.initStorage()
              navigate("/authentication/sign-in")
            }
          }
        },
      }
    }
  )

  // Cache for the rtl
  useMemo(() => {
    const pluginRtl: any = rtlPlugin;
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [pluginRtl],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      }
    );

  return  (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {(layout === "dashboard" && location.pathname !== "/authentication/sign-in") && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName={BRAND_NAME}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<AuthenticationView/>} />
          </Routes>
          <MDSnackbar
            anchorOrigin={{horizontal: 'center', vertical: 'top'}}
            color="error"
            icon="notifications"
            title={BRAND_NAME}
            content={errMsg}
            dateTime=""
            open={openErrMsg}
            close={toggleSnackbar}
          />
        </ThemeProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
}
