import {apiClient} from "service/ApiClient";
import {DefaultResponse} from "common/Types";

const getRecentNotices = async function () {
  return await apiClient.get<DefaultResponse>("/dashboard/notices").then((res) => res.data)
}

const getToday = async function () {
  return await apiClient.get<DefaultResponse>("/dashboard/today").then((res) => res.data)
}

const getTotal = async function () {
  return await apiClient.get<DefaultResponse>("/dashboard/total").then((res) => res.data)
}

const getPieChart = async function () {
  return await apiClient.get<DefaultResponse>("/dashboard/chart/pie").then((res) => res.data)
}

const getLineChart = async function () {
  return await apiClient.get<DefaultResponse>("/dashboard/chart/line").then((res) => res.data)
}

const getActivities = async function () {
  return await apiClient.get<DefaultResponse>("/dashboard/activities").then((res) => res.data)
}

const DashboardService ={
  getRecentNotices,
  getToday,
  getTotal,
  getPieChart,
  getLineChart,
  getActivities,
}

export default DashboardService