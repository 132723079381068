import {loginClient} from "service/ApiClient";
import {DefaultResponse} from "common/Types";

const signIn = async function (id:string, pw:string) {
    let result;
    await loginClient.post<DefaultResponse>("/user/login", {'loginId':id, 'userPw':pw}).then((res ) => {result = res.data})
    return result
}

const AuthService = {
    signIn
}

export default AuthService;