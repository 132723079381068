import MDBox from "components/MDBox";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";
import MDDatePicker from "components/MDDatePicker";
import {Refresh} from "@mui/icons-material";
import MDInput from "components/MDInput";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import {useLocation, useNavigate} from "react-router-dom";
import TableLayout from "examples/LayoutContainers/CustomLayout/list/TableLayout";
import SearchLayout from "examples/LayoutContainers/CustomLayout/list/SearchLayout";
import TableHeader from "examples/LayoutContainers/CustomLayout/list/TableHeader";
import TableFooter from "examples/LayoutContainers/CustomLayout/list/TableFooter";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import TestService from "service/TestService";
import {DefaultResponse, RegularDevice, Select, TestExecution} from "common/Types";
import DateUtil from "common/DateUtil";
import Autocomplete from "@mui/material/Autocomplete";
import {BRAND_NAME, getCodeByDetail, LANGUAGE} from "../../../../common/Constant";
import MDSnackbar from "../../../../components/MDSnackbar";
import {TestExecutionConstructor} from "../../../../common/Constructors";
import MDBadgeDot from "../../../../components/MDBadgeDot";
import {Backdrop, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import TestExecutionHistoryModal from "../history/TestExecutionHistoryModal";
import {useTranslation} from "react-i18next";
import Card from "@mui/material/Card";
import DeviceService from "../../../../service/DeviceService";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import ConfirmationDialog from "../../../component/ConfirmationDialog";

function IntegrationTestListView(): JSX.Element {

  const {t} = useTranslation();
  const location = useLocation()
  const navigate = useNavigate()
  const [list, setList] = useState<TestExecution[]>([])
  const [regularDeviceList, setRegularDeviceList] = useState([])
  const [regularTestExecutionId, setRegularTestExecutionId] = useState(undefined)
  const [deviceList, setDeviceList] = useState([])
  const columns = [
    { Header: t("testExecution.thead.name"), accessor: "name", width: "20%", sortable: true},
    { Header: t("testExecution.thead.projectName"), accessor: "projectName", width: "15%", sortable: true},
    { Header: "test suite", accessor: "testSuiteName", width: "15%", sortable: false},
    { Header: t("testExecution.thead.userName"), accessor: "regUserName", width: "15%", sortable: false },
    { Header: t("testExecution.thead.regDate"), accessor: "regDt", width: "15%", sortable: true },
    { Header: t("testExecution.thead.device"), accessor: "deviceName", width: "15%", sortable: false},  //미사용중인 device를 select box로 출력
    { Header: "", accessor: "execution", width: "10%", sortable: false },
  ];
  const [deleteTestExecutionList, setDeleteTestExecutionList] = useState<TestExecution[]>([])

  /*paging start*/
  const entries = ["10", "25", "50", "100"];
  const [entriesPerPage, setEntriesPerPage] = useState( 10 )
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [sort, setSort] = useState("")
  const [direction, setDirection] = useState<"none" | "ASC" | "DESC">("none")

  /*snackbar start*/
  const [errMsg, setErrMsg] = useState<string>('');
  const [errColor, setErrColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
  const [openErrMsg, setOpenErrMsg] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState<string>('');
  const [confirmColor, setConfirmColor] = useState<"warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error">("warning");
  const [openConfirmMsg, setOpenConfirmMsg] = useState(false);
  const toggleSnackbar = () => {
    setOpenErrMsg(!openErrMsg);
  }
  const openAlert = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
    setErrMsg(msg)
    setErrColor(color)
    setOpenErrMsg(true)
  }
  const toggleDialog = () => {
    setOpenConfirmMsg(!openConfirmMsg);
  }
  const openConfirm = (msg: string, color: "warning" | "dark" | "light" | "info" | "primary" | "secondary" | "success" | "error") => {
    setConfirmMsg(msg)
    setConfirmColor(color)
    setOpenConfirmMsg(true)
  }
  /*snackbar end*/


  // Setting the entries starting point
  const entriesStart = page === 1 ? page : (page - 1) * entriesPerPage + 1;

  // Setting the entries ending point
  let entriesEnd;
  const totalPageLength = Math.ceil(list.length / entriesPerPage);

  if (page === 1) {
    entriesEnd = entriesPerPage;
  } else if (page === totalPageLength ) {
    entriesEnd = list.length;
  } else {
    entriesEnd = entriesPerPage * page;
  }
  /*paging end*/

  /*searching start*/
  // Search input value state
  const [testSuiteName, setTestSuiteName] = useState("")
  const [testExecutionName, setTestExecutionName] = useState("")
  const [userName, setUserName] = useState("")

  // 등록일 선택 검색
  const [ fromDate, setFromDate ] = useState<Date>(null)
  const [ toDate, setToDate ] = useState<Date>(DateUtil.getDefaultToDate())


  const _testExecution = new TestExecutionConstructor(
    undefined,
    undefined,
  )

  // 등록일 handle function
  const handleFromDateChange = ( _fromDate: Date) => {
    setFromDate(_fromDate)
  }

  const handleToDateChange = ( _toDate: Date) => {
      setToDate(_toDate)
  }


  useEffect(()=> {
    if(fromDate && toDate && fromDate > toDate){
      openAlert(t("ERRORMESSAGE.DATE.SELECT"), "warning")
      setFromDate(undefined)
      setToDate(undefined)
    }
  },[fromDate, toDate])

  /*searching end*/
  const goToDetail = (row: any | undefined, type: string) => {
    const url = type === 'Create'? `/test/execution`: `/test/execution/${row.id}`;
    navigate({pathname: url}, {state: {menu_name: "Test Execution", test_execution: row, type: type, execution_type: "IT", domain: getCodeByDetail("DOMAIN_TYPE",location.pathname.split('/')[2])}})
  }

  const {refetch: getExecutionList} = useQuery<any, AxiosError>(
    ['getExecutionListForRegular'],
    () => TestService.getExecutionListForRegular(page, entriesPerPage, sort, direction, DateUtil.getDateStringFormat(fromDate), DateUtil.getDateStringFormat(toDate), getCodeByDetail("DOMAIN_TYPE",location.pathname.split('/')[2]), testExecutionName, testSuiteName, userName, "IT"),
    {
      onSuccess: (res: DefaultResponse ) => {
        if(res.code === 200 && res.subCode === 0){
          const _list:TestExecution[] = []
          res.data.content.forEach((i:TestExecution)=>{
            _list.push({
              id: i.id,
              regularTestExecutionId: i.regularTestExecutionId,
              name: i.name,
              regDt: i.regDt,
              regUserName: i.regUserName,
              testSuiteName: i.testSuiteName,
              testSuiteId: i.testSuiteId,
              projectId: i.projectId,
              projectName: i.projectName,
              deviceName: i.deviceName?i.deviceName:"",
              device: null,
            })
          })
          setList(_list)
          setTotal(res.data.total)

          getRegularDevice()
        }
      }
    }
  )

  const {refetch: getRegularDevice} = useQuery<any, AxiosError>(
    ['getRegularDevice'],
    () => DeviceService.getRegularDevice(getCodeByDetail("DOMAIN_TYPE", location.pathname.split("/")[2])),
    {
      onSuccess: (res: DefaultResponse ) => {
        if(res.code === 200 && res.subCode === 0){
          setRegularDeviceList(res.data)
        }
      }
    }
  )

  const {refetch: deleteTestExecution} = useQuery<any, AxiosError>(
    ['deleteTestSuite'],
    () => TestService.deleteTestExecution(deleteTestExecutionList),
    {
      onSuccess: (res: DefaultResponse ) => {
        if(res.code === 200 && res.subCode === 0){
          navigate(0)
        }
      }
    }
  )

  const {refetch: startRegularTest} = useQuery<any, AxiosError>(
    ['startRegularTest'],
    () => TestService.startRegularTest(_testExecution),
    {
      onSuccess: (res: DefaultResponse ) => {
        if(res.code === 200 && res.subCode === 0){
          openAlert("Integration Test가 시작 되었습니다.", "success")
        }else{
          openAlert(res.error, "error")
        }
        getExecutionList()
      }
    }
  )

  const {refetch: stopRegularTest} = useQuery<any, AxiosError>(
    ['stopRegularTest'],
    () => TestService.stopRegularTest(regularTestExecutionId),
    {
      onSuccess: (res: DefaultResponse ) => {
        if(res.code === 200 && res.subCode === 0){
          openAlert("Integration Test가 중지 되었습니다.", "success")
        }else{
          openAlert(res.error, "error")
        }
        getExecutionList()
      }
    }
  )

  const onClickSave = () => {
    goToDetail({},'Create')
  }

  const onClickDelete = () => {
    if (checkList.size === 0) {
      openAlert(t("ERRORMESSAGE.CONFIRM.DELETE.TESTEXECUTION"), "warning")
    } else {
      openConfirm(t("confirm.delete"), "warning")
    }
  }

  const handleYes = () => {
    const _deleteTestExecutionList:TestExecution[] = []
    checkList.forEach(value => {
      _deleteTestExecutionList.push({
        id: value
      })
    })
    setDeleteTestExecutionList(_deleteTestExecutionList)
  }

  const handleNo = () => {
    toggleDialog()
  }

  // 체크박스 체크리스트 선택
  const [ checkList, setCheckList ] = useState(new Set<any>([]));
  // 체크박스 단일 선택

  const handleSingleCheck = ((key: any) => {
    if ( checkList.has( key ) ) {
      checkList.delete( key )
      setCheckList( new Set<any>( [ ...checkList ] ) );
    } else {
      setCheckList( new Set<any>([ ...checkList.add( key ) ] ) );
    }
  });

  // 체크박스 전체 선택
  const handleAllCheck =  (( checked: boolean ) => {
    if( checked ) {
      // 전체 선택 클릭 시 모든 로우의 키들 담음
      const set = new Set<any>([]);
      for (let i = 0; i < Math.min(entriesPerPage, list.length); i++ ) {
        set.add(list[i].id);
      }
      setCheckList( set );
    } else {
      setCheckList ( new Set<any>([]) );
    }
  });

  // 페이지 변경시 전체 체크 해제
  useEffect( () => {
    setCheckList( new Set<any>([]) );
  }, [page] );

  useEffect( () => {
    if(regularDeviceList && regularDeviceList.length >= 0){
      const _deviceList:Select[] = []
      regularDeviceList.forEach((i:RegularDevice) => {
        _deviceList.push({label:i.name, id:i.id})
      })
      setDeviceList(_deviceList)
    }
  }, [regularDeviceList] );

  useEffect( () => {
    getExecutionList()
  }, [] );

  useEffect(() => {
    getExecutionList()
  }, [ page ]);

  useEffect(() => {
    setPage(1)
    getExecutionList()
  }, [ entriesPerPage]);

  useEffect( () => {
    if(deleteTestExecutionList && deleteTestExecutionList.length > 0)
      deleteTestExecution()
  }, [deleteTestExecutionList]);

  const onClickHeader = (_accessor:string) => {
    if(sort === _accessor){
      if(direction && direction === "DESC"){
        setDirection("ASC")
      }else{
        setDirection("DESC")
      }
    }else{
      setDirection("DESC")
      setSort(_accessor)
    }
  }

  const activeEnter = (ev:KeyboardEvent) => {
    if(ev.key === "Enter") {
      getExecutionList();
    }
  }

  useEffect( () => {
    if(sort && sort !== '' && direction && (direction === 'DESC' || direction === 'ASC')){
      getExecutionList()
    }
  }, [sort, direction]);

  const changeDevice = (row: TestExecution, _device:any) => {
    const _list:TestExecution[] = []
    list.forEach((i:TestExecution) => {
      if(i.id === row.id){
        _list.push({
          id: i.id,
          regularTestExecutionId: i.regularTestExecutionId,
          name: i.name,
          regDt: i.regDt,
          regUserName: i.regUserName,
          testSuiteName: i.testSuiteName,
          testSuiteId: i.testSuiteId,
          projectId: i.projectId,
          projectName: i.projectName,
          deviceName: "",
          device: _device,
        })
      }else{
        _list.push(i)
      }
    })
    setList(_list)
  }

  const clickStart = (row: TestExecution) => {
    _testExecution.id = undefined
    _testExecution.deviceId = undefined

    if(row.id && row.device && row.device.id) {
      _testExecution.id = row.id
      _testExecution.deviceId = Number(row.device.id)
      startRegularTest()
    }else{
      openAlert(t("ERRORMESSAGE.DEVICE.SELECT"), "warning")
    }
  }

  const clickStop = (row: TestExecution) => {
    setRegularTestExecutionId(row.regularTestExecutionId)
  }

  useEffect(() => {
    if(regularTestExecutionId && regularTestExecutionId > 0){
      stopRegularTest()
    }
  }, [regularTestExecutionId])



  const [modalOpen, setModalOpen] = useState(false);
  const [executionId, setExecutionId] = useState(undefined)

  const clickHistory = (row: TestExecution) => {
    setExecutionId(row.id)
    setModalOpen(true)
  }


  function handleClose() {
    setModalOpen(false)
    setTimeout(() => {
      setExecutionId(undefined)
    }, 150)
  }

  // URL 변경 감지를 통해 getExecutionList 호출
  useEffect(() => {
    getExecutionList();
  }, [location.pathname]); // location.pathname이 변경될 때마다 호출


  const buttonMinWidth = localStorage.getItem(LANGUAGE) === "ko-KR" ? pxToRem(80) : pxToRem(85)

  return (
    /* list, detail 화면에서 공용으로 사용하는 최상위 layout 컴포넌트 */
<TableLayout pageTitle={location.state?.menu_name}>
      {/* 검색 영역 layout */}
      <SearchLayout>
        <MDBox  display={"flex"} justifyContent={"space-between"}>
          <MDBox display={"flex"} width="90%" alignItems="center">
            <MDBox display={"flex"} alignItems="center" >
              <MDDatePicker
                value={ fromDate }
                input = {
                  {
                    size : "small",
                    height: "37px",
                    placeholder : t("search.fromDate"),
                  }
                }
                options = {
                  {
                    maxDate : "today",
                    // locale : Korean,
                  }
                }
                onChange = { ( currentTarget: any ) => {
                  const target = currentTarget[0];
                  handleFromDateChange( target );
                }}
              />
              <MDBox px={0.25}>~</MDBox>
              <MDDatePicker
                value={ toDate }
                input = {
                  {
                    size : "small",
                    height: "37px",
                    placeholder : t("search.toDate"),
                  }
                }
                options = {
                  {
                    maxDate : "today",
                    // locale : Korean,
                  }
                }
                onChange = { ( currentTarget: any ) => {
                  const target = currentTarget[0];
                  handleToDateChange( target );
                }}
              />
              <MDBox px={0.25}/>
              <MDButton iconOnly={true} onClick={ () => { setFromDate( undefined ); setToDate( undefined ); } }>
                <Refresh/>
              </MDButton>
            </MDBox>
            <MDBox alignItems="center" width="15%" pl={3}>
              <MDInput
                placeholder={t("search.name")}
                value={testExecutionName}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setTestExecutionName(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
            <MDBox alignItems="center" width="15%" pl={3}>
              <MDInput
                placeholder="TEST SUITE"
                value={testSuiteName}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setTestSuiteName(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
            <MDBox alignItems="center" width="15%" minWidth={"100px"} pl={3}>
              <MDInput
                placeholder={t("search.userName")}
                value={userName}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setUserName(currentTarget.value)
                }}
                onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}
              />
            </MDBox>
          </MDBox>
          <MDBox display={"flex"}>
            <MDButton aria-autocomplete={"none"} variant={"outlined"} color={ "dark" } onClick={()=>getExecutionList()}>
              {t("search.button.search")}
            </MDButton>
          </MDBox>
        </MDBox>
      </SearchLayout>

      {/* 테이블 헤더 */}
      <TableHeader entriesStart={entriesStart} entriesEnd={entriesEnd < list.length ? entriesEnd : total} total={total} />

      {/* 테이블 전체 layout */}
    <Card>
      <TableContainer
        sx={{
          boxShadow : "none", overflow : "auto",
          "&::-webkit-scrollbar" : { opacity : 0, background : "transparent", borderRadius : 12, height : 5, width : 4,},
          "&::-webkit-scrollbar:hover" : { opacity : 0.1, },
          ":hover::-webkit-scrollbar-thumb" : { background : "lightgrey", borderRadius : 12, opacity : 0.1 }
        }}
      >
        {/* 테이블 contents */}
        <Table>
            <MDBox component={"thead"}>
              <TableRow>
                <DataTableHeadCell
                  width={ "1%" }
                  align={ "center" }
                  direction={ "none" }
                  sortable={false}
                >
                  <Checkbox size={"small"}
                            onChange={( e) => {
                              handleAllCheck(e.target.checked);
                            }}
                            checked={ checkList.size === Math.min(entriesPerPage, list.length) && list.length > 0} />

              </DataTableHeadCell>

              {columns.map(( column : any, index : number ) => (
                <DataTableHeadCell
                  key={index}
                  width={column.width}
                  align={"left"}
                  onClick={onClickHeader}
                  accessor={column.accessor}
                  direction={direction}
                  sort={sort}
                  sortable={column.sortable}
                >
                  {column.Header}
                </DataTableHeadCell>
              ))}
            </TableRow>
          </MDBox>
          <TableBody>
            {list.map(( row : any, index : number ) => (
              <TableRow key={index} >
                <DataTableBodyCell
                  align={ "center" }
                >
                  <Checkbox
                    checked={ checkList.has( row.id ) }
                    onChange={ (e) => {
                      handleSingleCheck( row.id );
                    }}
                  />
                </DataTableBodyCell>

                {columns.map(( column : any, index) => (
                  <DataTableBodyCell
                    key={index}
                    align={ "left" }
                    goToDetail={["deviceName", "execution"].indexOf(column.accessor) > -1 ?null:goToDetail}
                    row={row}
                  >
                    {
                      column.accessor === "deviceName" && row.deviceName === ""?
                        <MDBox minWidth={"150px"}>
                          <Autocomplete
                            fullWidth
                            disableClearable
                            value={row.device}
                            options={deviceList}
                            onChange={(event, newValue) => {
                              changeDevice(row, newValue)
                            }}
                            size="small"
                            renderInput={(params) => <MDInput {...params} placeholder={"DEVICE"} onKeyDown={(ev:KeyboardEvent) => activeEnter(ev)}/>}
                          />
                        </MDBox>:
                      column.accessor === "deviceName" && row.deviceName !== ""  ?
                        <MDBadgeDot size="md" color="warning" variant="gradient" padding="0"
                                    badgeContent={row.deviceName} /> :
                      column.accessor === "execution" && row.deviceName === ""?
                        <MDBox>
                          <MDButton color="info" size="small" variant="outlined" onClick={(e) => {clickStart(row)}} sx={{minWidth : buttonMinWidth}}>
                            {t("button.start")}
                          </MDButton>
                          <MDBox display={"inline-block"} p={0.5}/>
                          <MDButton color="gray" size="small" variant="outlined" onClick={(e) => {clickHistory(row)}} sx={{minWidth : buttonMinWidth}}>
                            {t("button.history")}
                          </MDButton>
                        </MDBox>:
                      column.accessor === "execution" && row.deviceName !== "" ?
                        <MDBox>
                          <MDButton color="error" size="small" variant="outlined" onClick={(e) => {clickStop(row)}} sx={{minWidth : buttonMinWidth}}>
                            {t("button.stop")}
                          </MDButton>
                          <MDBox display={"inline-block"} p={0.5}/>
                          <MDButton color="gray" size="small" variant="outlined" onClick={(e) => {clickHistory(row)}} sx={{minWidth : buttonMinWidth}}>
                            {t("button.history")}
                          </MDButton>
                        </MDBox>:
                      row[ column.accessor ]
                    }

                  </DataTableBodyCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

        {/* 테이블 footer */}
        <TableFooter entriesPerPage={entriesPerPage} entries={entries} totalCount={total} page={page}
                     setEntriesPerPage={setEntriesPerPage} onClickSave={onClickSave} onClickDelete={onClickDelete} onChangePage={setPage}/>
    </Card>

      <MDSnackbar
        anchorOrigin={{horizontal: 'center', vertical: 'top'}}
        color={errColor}
        icon="notifications"
        title={BRAND_NAME}
        content={errMsg}
        dateTime=""
        open={openErrMsg}
        close={toggleSnackbar}
      />
      <ConfirmationDialog
          anchorOrigin={{horizontal: 'center', vertical: 'top'}}
          open={openConfirmMsg}
          close={toggleDialog}
          content={confirmMsg}
          color={confirmColor}
          handleYes={handleYes}
          handleNo={handleNo}
      />

      <Modal
        open={modalOpen} onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 200,
            sx : {backgroundColor : 'rgba(0, 0, 0, 0.2)'}
          },
        }}
      >
        <Fade in={modalOpen}>
          <MDBox
              display="flex"
              width="95vw" minHeight="85vh" maxHeight="90vh" shadow="xl"
              position="fixed" top="50%" left="50%"
              sx={{transform: "translate(-50%, -50%)"}}
          >
            <TestExecutionHistoryModal executionId={executionId} closeModalBtn={handleClose}/>
          </MDBox>
        </Fade>
      </Modal>

      <Footer />
    </TableLayout>
  );
}

export default IntegrationTestListView;