import TimelineItem from "examples/Timeline/TimelineItem";
import {useQuery} from "@tanstack/react-query";
import {Activity, DefaultResponse} from "common/Types";
import {AxiosError} from "axios";
import DashboardService from "service/DashboardService";
import {useEffect, useState} from "react";
import CustomTimeLineList from "./CustomTimeLineList/CustomTimeLineList";
import {Skeleton} from "@mui/material";
import MDBox from "components/MDBox";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sseForAllRecoilState} from "store/recoilState";
import {SSE} from "common/Constant";
import {useTranslation} from "react-i18next";

function ActivityTimeLine(): JSX.Element {

  const {t} = useTranslation();

  const [activities, setActivities] = useState<Activity[]>([]);

  const {refetch: getActivities} = useQuery<DefaultResponse, AxiosError>(
    ["getActivities"],
    () => DashboardService.getActivities(),
    {
      onSuccess : (res: DefaultResponse) => {
        if (res.code === 200 && res.subCode === 0) {
          setActivities(res.data)
        }
      }
    }
  )

  const setSseForAll = useSetRecoilState(sseForAllRecoilState);
  const sseForAll = useRecoilValue(sseForAllRecoilState);

  const handleConnectedEvent = (e: any) => {
    const { data: receivedData } = e;
    console.log(receivedData);
  };

  const handleActivityEvent = (e: any) => {
    const { data: receivedData } = e;
    console.log(receivedData);
    getActivities()
  };

  useEffect(()=>{
    const baseURI = process.env.REACT_APP_BACKEND_URI+process.env.REACT_APP_API_VERSION
    if(!sseForAll.sse){
      setSseForAll({sse: new EventSource(baseURI + "/sse/connect")})
    }else{
      sseForAll.sse.addEventListener("Connected", handleConnectedEvent)
      sseForAll.sse.addEventListener(SSE.ACTIVITY, handleActivityEvent)
    }
  }, [sseForAll.sse])

  useEffect(() => {
    getActivities()
    return () => {
      if(sseForAll.sse) {
        sseForAll.sse.removeEventListener('Connected', handleConnectedEvent);
        sseForAll.sse.removeEventListener(SSE.ACTIVITY, handleActivityEvent);
        //sseForAll.sse.close()
      }
    }
  }, []);

  // sse 연결 추가



  function selectColor(status : string) : "info" | "success" | "primary" | "dark" | "error" {
    let color : "info" | "success" | "primary" | "dark" | "error"
    if (status === "R") {
      color = "info"
    } else if (status === "P") {
      color = "success"
    } else if (status === "F") {
      color = "primary"
    } else if (status === "X") {
      color = "error"
    } else {
      color = "dark"
    }
    return color
  }

  return (
      <CustomTimeLineList title={t("qaSummary.activity.activity")}>
      { activities.length === 0 ?
        (<MDBox
          position="relative"
          mb={3}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="2rem"
            height="2rem"
            borderRadius="50%"
            position="absolute"
            top="8%"
            left="2px"
            zIndex={2}
          >
           <Skeleton variant={"circular"} width="2rem" height="2rem"/>
          </MDBox>
          <MDBox ml={5.75} pt={0.5} lineHeight={0} maxWidth={"30rem"}>
            <Skeleton variant={"text"} height={"1rem"}/>
            <MDBox mt={0.5}>
              <Skeleton height={"1rem"}/>
            </MDBox>
            <MDBox mt={2} mb={1.5}>
              <Skeleton height={"2rem"}/>
            </MDBox>
          </MDBox>
        </MDBox>) :
        (activities.map((activity: Activity, key: number) => (
          visualViewport.height + visualViewport.width  > (360 * key) ?
          <TimelineItem
            key={activity.activityId}
            dateTime={activity.activityRegDt}
            icon={"notifications"}
            color={selectColor(activity.activityStatus)}
            title={activity.activityDescription}/>
            : null
        )))
      }
      </CustomTimeLineList>

    )
}

export default ActivityTimeLine